import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import _ from 'lodash'
import mapboxgl from 'mapbox-gl'
import { useTranslation } from 'react-i18next'
import Api from '../../../api'
import { forceCheck } from 'react-lazyload'
import { HOTELS_RESULTS_DETAIL_PAGE } from '../../../navigation/routes'
import HotelElementController from '../../hotelsComponents/_hotelsList/_hotelElement/controller'
import ResultsAnalytics from '../../../analytics/analyticsHotel/resultsAnalytics'
import { useSelector } from 'react-redux'
import { formatePrice, getPriceWithCurrency } from '../../../utils/prices'
import { isRub, isRus } from '../../../utils/isRus'

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const DEFAULT__ZOOM = 9
let mapId
let load = false
let markers = {}
let markersOnScreen = {}
let popup
let pointerOnScreen = ''

export default class MapBoxController {
  constructor () {
    this.api = new Api()
    this.t = useTranslation().t
    this.lang = useTranslation().i18n.language
    this.hotelElementController = new HotelElementController()
    this.analyticsHotel = new ResultsAnalytics()
    this.hotelData = useSelector(state => state.hotel)
    this.searchRS = useSelector(state => state.searchRS)
  }

  firstInit = (mapNode, initialOptions, setMap, hotels) => {
    load = false
    markers = {}
    markersOnScreen = {}
    const node = mapNode.current

    if (typeof window === 'undefined' || node === null) return

    const mapboxMap = new mapboxgl.Map({
      container: node,
      accessToken: process.env.REACT_APP_MAP_BOX_API_KEY,
      style: process.env.REACT_APP_MAP_BOX_STYLE,
      zoom: DEFAULT__ZOOM,
      cluster: true,
      ...initialOptions,
    })

    setMap(mapboxMap)

    mapboxMap.on('load', () => {
      load = true
      mapboxMap.addControl(new mapboxgl.NavigationControl(), 'top-right')
      if (_.size(hotels)) {
        this.addMarkers(mapboxMap, hotels)
      }
    })
    return () => {
      mapboxMap.remove()
      setMap(undefined)
    }
  }

  removeMarkers = (map) => {
    _.forEach(markersOnScreen, marker => {
      marker.remove()
    })
    markersOnScreen = {}
    if (popup) {
      popup.remove()
    }
  }

  addMarkers = (map, hotels, hotelState) => {
    if (!load) {
      setTimeout(() => {
        this.addMarkers(map, hotels)
      }, 1000)
    } else {
      if (mapId) {
        if (map.getLayer('markers' + mapId)) map.removeLayer('markers' + mapId)
        if (map.getLayer('clusters' + mapId)) map.removeLayer('clusters' + mapId)
        if (map.getLayer('marker_label' + mapId)) map.removeLayer('marker_label' + mapId)
        map.off('click', 'clusters' + mapId)
        map.off('click', 'marker_label' + mapId)
      }
      mapId = _.size(hotels) + new Date().getTime()
      map.addSource('markers' + mapId, {
        'type': 'geojson',
        'data': this.generateGeoJson(hotels),
        'cluster': true,
        'clusterMaxZoom': 14,
        'clusterRadius': 50,
        'clusterProperties': {
          'count': ['+', ['case', ['>=', ['get', 'price'], 0], 1, 0]],
          'price': ['min', ['get', 'price']],
          'currency': ['string', ['get', 'currency']],
        }
      })

      map.addLayer({
        id: 'clusters' + mapId,
        type: 'circle',
        source: 'markers' + mapId,
        filter: ['has', 'point_count'],
        paint: {
          'circle-radius': 25,
          'circle-opacity': 0
        }
      })

      map.addLayer({
        'id': 'marker_label' + mapId,
        'type': 'symbol',
        'source': 'markers' + mapId,
        'filter': ['!=', 'cluster', true],
        'layout': {
          'text-field': [
            'number-format',
            ['get', 'price'],
            { 'min-fraction-digits': 0, 'max-fraction-digits': 0 }
          ],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-size': 18,
          'text-allow-overlap': true
        },
        'paint': {
          'text-opacity': 0
        }
      })

      const updateMarkers = () => {
        const newMarkers = {}
        const features = map.querySourceFeatures('markers' + mapId)

        let id
        _.forEach(features, feature => {
          const coords = feature.geometry.coordinates
          const props = feature.properties
          id = props.cluster ? props.cluster_id : props.id

          let marker = markers[id]
          if (!marker) {
            const el = this.createMarker(props)
            marker = markers[id] = new mapboxgl.Marker({
              element: el
            }).setLngLat(coords)
          }
          newMarkers[id] = marker

          if (!markersOnScreen[id] && !document.getElementById(id)) {
            marker.addTo(map)
          }
        })

        for (id in markersOnScreen) {
          if (!newMarkers[id]) {
            markersOnScreen[id].remove()
          }
          if (document.getElementById(id)) {
            document.getElementById(id).remove()
          }
        }
        markersOnScreen = newMarkers
      }

      map.on('data', function () {
        map.on('move', updateMarkers)
        map.on('moveend', updateMarkers)
        updateMarkers()
      })

      map.on('click', 'clusters' + mapId, e => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters' + mapId]
        })
        if (!features[0]) return
        const clusterId = features[0].properties.cluster_id
        map.getSource('markers' + mapId).getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return
            map.flyTo({
              center: features[0].geometry.coordinates,
              zoom: zoom + 0.1
            })
          })
      })

      let isClickEvent = false

      const closePopup = (e) => {
        if (e.target.className === 'mapboxgl-canvas' && !isClickEvent) {
          this._deletePopup()
        }
      }

      // mouse events
      map.on('mouseover', 'marker_label' + mapId, e => {
        const popupEl = this._getPopup()
        if (!popupEl) isClickEvent = false
        if (!isClickEvent) this._openPopup(e, hotels, hotelState, map, false)
      })

      map.on('mouseout', 'marker_label' + mapId, () => {
        const canvas = document.querySelector('.mapboxgl-canvas')
        canvas.removeEventListener('mousemove', closePopup)
        canvas.addEventListener('mousemove', closePopup)
      })

      // click events
      map.on('click', 'marker_label' + mapId, e => {
        isClickEvent = true
        this._openPopup(e, hotels, hotelState, map, true)
      })
    }
  }

  addMarker = (map, hotel) => {
    map.on('load', () => {
      const lon = hotel.location ? hotel.location.lon : hotel.longitude
      const lat = hotel.location ? hotel.location.lat : hotel.latitude
      new mapboxgl.Marker().setLngLat([lon || 0, lat || 0]).addTo(map)
    })
  }

  addPointer = (map, longitude, latitude) => {
    this.removePointer()
    pointerOnScreen = new mapboxgl.Marker().setLngLat([longitude || 0, latitude || 0]).addTo(map)
  }

  removePointer = () => {
    if (pointerOnScreen) {
      pointerOnScreen.remove()
      pointerOnScreen = ''
    }
  }

  createMarker = props => {
    const count = props.count
    const id = props.cluster ? props.cluster_id : props.id
    let html =
      '<div data-id="' + id + '">'

    if (props.price) {
      html += '<div class="price">' +
        getPriceWithCurrency(
          props.price,
          props.currency,
          this.lang,
          { maximumFractionDigits: isRub(props.currency) ? 0 : 2 }) +
        '</div>'
    }
    if (count > 1) {
      html += '<div class="count">' + this.t('Hotel', { count: count }) + '</div>'
    }
    html += '</div>'

    const el = document.createElement('div')
    el.innerHTML = html
    return el.firstChild
  }

  _renderStar = stars => {
    let star = ''
    for (let i = 1; i <= stars; i++) {
      star += '<div class="star"></div>'
    }
    return star
  }

  generateGeoJson = hotels => {
    const geoJson = {
      type: 'FeatureCollection',
      features: [],
    }
    _.forEach(hotels, hotel => {
      const object = {
        'type': 'Feature',
        'properties': {
          'id': hotel.key,
          'name': hotel.name,
          'stars': hotel.stars,
          'reviewScore': (hotel.reviewScore || 0) + '/10',
          'reviewCount': (hotel.reviewCount || 0) + ' ' + this.t('Review', { count: hotel.reviewCount }),
          'photo': this.api.getPhotosUrl(hotel.key, 0),
          'price': hotel.priceInfo.total.convertedValue || hotel.priceInfo.total.value,
          'currency': hotel.priceInfo.total.convertedCurrency || hotel.priceInfo.total.currency,
          'coordinates': [hotel?.location?.lon || 0, hotel?.location?.lat || 0]
        },
        'geometry': {
          'type': 'Point',
          'coordinates': [hotel?.location?.lon || 0, hotel?.location?.lat || 0]
        }
      }
      geoJson.features.push(object)
    })
    return geoJson
  }

  setCenter = (map, center, setDefaultZoom) => {
    const options = {
      center: center,
    }
    if (setDefaultZoom) {
      options.zoom = typeof setDefaultZoom === 'boolean' ? DEFAULT__ZOOM : setDefaultZoom
      if (popup) {
        popup.remove()
      }
    }
    map.flyTo(options)
  }

  mapResize = (map, center) => {
    map.resize()
    if (center) {
      this.setCenter(map, map.getCenter())
    }
    forceCheck()
  }

  _openPopup = (e, hotels, hotelState, map, showActiveHotelInList = false) => {
    this._deletePopup()
    this._generatePopup(e, hotels, hotelState, map, showActiveHotelInList)
  }

  _generatePopup = (e, hotels, hotelState, map, showActiveHotelInList) => {
    const coordinates = e.features[0].geometry.coordinates.slice()
    const props = e.features[0].properties

    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
    }
    const hotelClick = _.find(hotels, ['key', props.id])
    popup = new mapboxgl.Popup({ anchor: 'center' })
      .setLngLat(coordinates)
      .setHTML(
        '<a href="' +
        HOTELS_RESULTS_DETAIL_PAGE +
        '?' +
        this.hotelElementController.getParams(hotelClick, hotelState) +
        '" target="_blank" class="hotelMini" id="hotelMini" data-hotelId="' + props.id + '">' +
        '<img src="' + props.photo + '" alt="" class="photo" />' +
        '<div class="desc">' +
        '<div class="desc__top">' +
        '<div class="stars">' +
        this._renderStar(props.stars) +
        '</div>' +
        '<div class="desc__name">' + props.name + '</div>' +
        '</div>' +
        '<div class="desc__footer">' +
        '<div class="desc__reviews">' +
        '<span>' + props.reviewScore + '</span>' +
        props.reviewCount +
        '</div>' +
        '<div class="desc__price">' + getPriceWithCurrency(props.price, props.currency, this.lang) + '</div>' +
        '</div>' +
        '</div>' +
        '</a>'
      )
      .addTo(map)

    this._createCustomOffsetPopup('.mapboxgl-popup', '#map')

    if (showActiveHotelInList) {
      const activeHotels = document.getElementsByClassName('activeHotel')
      if (activeHotels) {
        _.forEach(activeHotels, activeHotel => {
          activeHotel.classList.remove('activeHotel')
        })
      }
      const element = document.getElementsByClassName(props.id)
      if (_.size(element)) {
        element[0].scrollIntoView({
          block: 'center'
        })
        element[0].classList.add('activeHotel')
      }
    }
    document.getElementById('hotelMini').addEventListener('click', () => {
      this.analyticsHotel.clickDetailInformationMap(props, this.hotelData, this.searchRS)
    })
  }

  _createCustomOffsetPopup = (popupSelector, mapSelector) => {
    const popupCurrent = document.querySelector(popupSelector)
    const mapCurrent = document.querySelector(mapSelector)
    const rectPopup = popupCurrent.getBoundingClientRect()
    const rectMap = mapCurrent.getBoundingClientRect()
    const saveOffset = 20

    let popupOffsetX = 0
    let popupOffsetY = popupCurrent.clientHeight / 2 + saveOffset

    popup.setOffset([popupOffsetX, popupOffsetY])

    if (rectPopup.left < rectMap.left) {
      popupOffsetX = rectMap.left - rectPopup.left
      popup.setOffset([popupOffsetX, popupOffsetY])
    } else if (rectPopup.right > rectMap.right) {
      popupOffsetX = -(rectPopup.right - rectMap.right)
      popup.setOffset([popupOffsetX, popupOffsetY])
    }
    if (rectPopup.top < rectMap.top) {
      popupOffsetY = popupCurrent.clientHeight / 2 + saveOffset
      popup.setOffset([popupOffsetX, popupOffsetY])
    } else if (rectPopup.bottom + popupCurrent.clientHeight / 2 > rectMap.bottom) {
      popupOffsetY = -(popupCurrent.clientHeight / 2 + saveOffset)
      popup.setOffset([popupOffsetX, popupOffsetY])
    }
  }

  _deletePopup = () => {
    const openPopup = this._getPopup()
    openPopup?.remove()
  }

  _getPopup = () => {
    return document.querySelector('.mapboxgl-popup')
  }
}
