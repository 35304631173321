import { CLEAR_STATE } from '../actions'
import { UPDATE_ORDER_RESERVE } from './actions'

const defaultState = {
  model: {}
}

const orderReserve = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_ORDER_RESERVE:
      return {
        ...state,
        model: action.payload
      }
    case CLEAR_STATE:
      return defaultState
    default:
      return state
  }
}

export default orderReserve
