export const FLIGHTS_SEARCH_PAGE = '/flights'

export const HOTELS_SEARCH_PAGE = '/hotels'
export const HOTELS_RESULTS_PAGE = '/hotels/results'
export const HOTELS_RESULTS_DETAIL_PAGE = '/hotels/detail'
export const HOTELS_CONFIRMATION_PAGE = '/hotels/confirmation'
export const HOTELS_SUCCESS_PAGE = '/hotels/success'

export const ORDERS_PAGE = '/orders'
export const ORDERS_PAGE_UPCOMING = '/orders/upcoming'
export const ORDERS_PAGE_PAST = '/orders/past'

