import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import InputDropdown from '../_inputDropdown'
import DirectionFlightController from './controller'
import BoardInputList from './_list'
import _, { debounce } from 'lodash'


const DirectionFlightInput = ({ type }) => {
  const { t } = useTranslation()
  const controller = new DirectionFlightController()
  let refInput
  const field = useSelector(state => state.searchForm[type])
  const fieldAutocomplete = useSelector(state => state.searchForm[type].autocomplete)
  const debouncedAPICall = useMemo(() => debounce(controller.getAutocomplete, 400), [])
  useEffect(() => {
    controller.setDefaultData(type, field)
  }, [field.name])
  return (
    <div className='boardInput'>
      <div
        className={
        'boardInput__input ' +
        (field.showInput && ' boardInput__input-focus ') +
        (fieldAutocomplete.visible && ' boardInput__input-open ') +
        (field.error && ' boardInput__input-error ')
      }
        onClick={() => { controller.handleOnClickInput(type, refInput) }}
      >
        <div className={'boardInput__label ' + (!field.code && !field.showInput && 'boardInput__label-top')}>
          {type === 'origin' ? t('From') : t('To')}
        </div>
        <div className='boardInput__value '>
          <input
            ref={(input) => { refInput = input }}
            type='text'
            name={type}
            value={field.value}
            className={'boardInput__value-input ' + (field.showInput && 'active')}
            onChange={(e) => {
              controller.handleOnChange(e)
              debouncedAPICall(e)
            }}
            onBlur={controller.handleOnBlur}
            onFocus={controller.handleOnFocus}
            autoComplete='off'
          />
          {!field.showInput &&
            <>
              <div className='name'>
                <span>{field.name}</span> <span>{field.country}</span>
              </div>
              <div className='code'>
                {field.code}
              </div>
            </>}
        </div>
      </div>
      <InputDropdown
        visible={fieldAutocomplete.visible}
        child={
          _.map(_.concat(fieldAutocomplete.model.cities, fieldAutocomplete.model.airports), (element, index) =>
            <BoardInputList key={index} listElement={element} type={type} />
          )
        }
      />
      {field.error &&
        <div className='boardInput__error'>
          {field.error}
        </div>}
    </div>
  )
}

export default DirectionFlightInput
