import { CLEAR_STATE, START_SEARCH } from '../actions'
import { CLEAR_SEARCH } from '../search/actions'
import { CHANGE_LOCATION, CHANGE_PAGE } from './actions'
import { ORDERS_SCREEN_PAST, ORDERS_SCREEN_UPCOMING, SET_PREV_PAGE, RESULTS_SCREEN, SEARCH_SCREEN } from './types'

const defaultState = {
  name: SEARCH_SCREEN,
  prev: '',
  currentLocation: '',
  fullUrl: ''
}

const page = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_PAGE:
      return {
        ...state,
        name: action.payload
      }
    case CHANGE_LOCATION:
      return {
        ...state,
        currentLocation: action.payload.pathname,
        fullUrl: action.payload.pathname + action.payload.search
      }
    // case START_SEARCH:
    //   return {
    //     ...state,
    //     name: RESULTS_SCREEN
    //   }
    case CLEAR_SEARCH:
      return {
        ...state,
        name: SEARCH_SCREEN
      }
    case CLEAR_STATE:
      return {
        ...state,
        name: (state.name === ORDERS_SCREEN_UPCOMING || state.name === ORDERS_SCREEN_PAST)
          ? ORDERS_SCREEN_UPCOMING
          : SEARCH_SCREEN
      }
    case SET_PREV_PAGE:
      return {
        ...state,
        prev: action.payload
      }
    default:
      return state
  }
}

export default page
