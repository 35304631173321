import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getPriceWithCurrency } from '../../../../../utils/prices'

export default class PriceTooltipController {
  constructor () {
    this.i18n = useTranslation().i18n
  }

  getBasePrice = (hotel, checkin, checkout) => {
    const { value, currency } = hotel.priceInfo.base

    const nights = moment(checkout).diff(moment(checkin), 'days')
    const basePrice = getPriceWithCurrency(value, currency, this.i18n.language)
    const basePricePerNight = getPriceWithCurrency(
      Math.round((value / nights) * 100) / 100,
      currency,
      this.i18n.language)

    return {
      nights,
      basePrice,
      basePricePerNight
    }
  }

  getTaxesPrice = (hotel) => {
    const { value, currency } = hotel.priceInfo.taxes.total
    return getPriceWithCurrency(value, currency, this.i18n.language)
  }

  getTotalPrice = (hotel) => {
    const { value, currency } = hotel.priceInfo.total
    return getPriceWithCurrency(value, currency, this.i18n.language)
  }
}
