import React from 'react'
import './style.sass'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import hotelAmenities from '../../../../data/hotelAmenities'
import HotelScreenAnalytics from '../../../../analytics/analyticsHotel/hotelScreenAnalytics'
import { useIsVisibleElement } from '../../../../hooks/useIsVisibleElement'

const HotelPropertyAmenities = () => {
  const { t } = useTranslation()
  const { amenities } = useSelector(state => state.hotel.choice)
  const hotelAnalytics = new HotelScreenAnalytics()
  const [element] = useIsVisibleElement(hotelAnalytics.seeInfoAmenities, 100)

  return (
    <div className='hotelPropertyAmenities' id='Amenities' ref={element}>
      <div className='hotelPropertyAmenities__header'>
        {t('Property amenities')}
      </div>
      <div className='amenities'>
        {_.map(amenities, (amenity, key) =>
          <div
            key={'hotelPropertyAmenities' + key}
            className='amenity'
          >
            {t(hotelAmenities[key])}
          </div>
        )}
      </div>
    </div>
  )
}

export default HotelPropertyAmenities
