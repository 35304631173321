import { CLIENT_MODE_BUSINESSES } from '../reducers/user/constans'
import Config from './config'

const {
  REACT_APP_BASE_URL,
  REACT_APP_PAYMENT_URL,
  REACT_APP_PASSENGERS_SERVICE,
  REACT_APP_APPSPOT_URL
} = process.env
export default class Api extends Config {
    autocomplete = async (text, locale) => {
      return await this.request(REACT_APP_BASE_URL, 'flights/autocomplete', {
        text,
        locale
      })
    }

    autocompleteHotels = async (text, language) => {
      const params = new URLSearchParams({
        text, language
      })
      return await this.request(REACT_APP_BASE_URL, 'hotels/autocomplete', params, 'GET')
    }

    price = async (legs, pricingOption, people, currency) => {
      return await this.request(REACT_APP_BASE_URL, 'flights/ndc/price', {
        people,
        legs,
        pricingOption,
        currency
      })
    }

    orderCreate = async (
      { clientMode, companyId, legs, passengers, payment, corporateLoyaltyNumber },
      currency,
      pricingOption
    ) => {
      return await this.request(REACT_APP_PAYMENT_URL, 'flights/order', {
        clientMode,
        companyId,
        legs,
        pricingOption,
        passengers,
        payment,
        currency,
        corporateLoyaltyNumber
      })
    }

    orderReserve = async (
      { legs, passengers, payment, clientMode, companyId, corporateLoyaltyNumber },
      currency,
      pricingOption
    ) => {
      return await this.request(REACT_APP_PAYMENT_URL, 'flights/reserve', {
        clientMode,
        companyId,
        legs,
        pricingOption,
        passengers,
        payment,
        currency,
        corporateLoyaltyNumber
      })
    }

    orderIssue = async (
      { legs, passengers, payment, clientMode, companyId, corporateLoyaltyNumber },
      currency,
      pricingOption,
      reserve
    ) => {
      return await this.request(
        REACT_APP_PAYMENT_URL,
        `flights/${reserve.order.provider}/order/${reserve.orderId}/issue`,
        {
          clientMode,
          companyId,
          legs,
          pricingOption,
          passengers,
          payment,
          currency,
          corporateLoyaltyNumber
        })
    }

    orderHotel = async (order) => {
      return await this.request(REACT_APP_PAYMENT_URL, 'hotels/order', order)
    }

    getProofOfStay = async (provider, id) => {
      return await this.request(REACT_APP_PAYMENT_URL, `hotels/order/${provider}/${id}/proof-of-stay`, '', 'GET')
    }

    getPassengers = async (clientMode) => {
      let baseUrl = REACT_APP_PASSENGERS_SERVICE
      let url = 'passengers'
      let body = ''
      if (clientMode === CLIENT_MODE_BUSINESSES) {
        baseUrl = REACT_APP_APPSPOT_URL
        url = 'b2b/passengers'
        body = 'page_num=1&page_size=999'
      }
      const result = await this.request(baseUrl, url, body, 'GET')
      return clientMode === CLIENT_MODE_BUSINESSES ? result.passengers : result
    }

    savePassengers = async (passenger) => {
      return await this.request(REACT_APP_PASSENGERS_SERVICE, 'passengers', { ...passenger })
    }

    updatePassengers = async (id, passenger, clientMode) => {
      let baseUrl = REACT_APP_PASSENGERS_SERVICE
      let url = 'passengers/' + id
      let method = 'PUT'
      if (clientMode === CLIENT_MODE_BUSINESSES) {
        baseUrl = REACT_APP_APPSPOT_URL
        url = 'b2b/passengers/update'
        method = 'POST'
      }
      return await this.request(baseUrl, url, { ...passenger }, method)
    }

    deletePassengers = async (ids = null) => {
      return await this.request(REACT_APP_PASSENGERS_SERVICE, 'passengers/delete', ids)
    }

    getOrdersIds = async (params) => {
      return await this.request(REACT_APP_APPSPOT_URL, 'trips', params || '', 'GET')
    }

    getOrdersInfo = async (params) => {
      return await this.request(REACT_APP_APPSPOT_URL, 'trips/multiple', params + '&include_services=false', 'GET')
    }

    getProfile = async () => {
      return await this.request(REACT_APP_APPSPOT_URL, 'user/profile', '', 'GET')
    }

    getBusinessData = async () => {
      return await this.request(REACT_APP_APPSPOT_URL, 'b2b', '', 'GET')
    }

    getBusinessPermissions = async () => {
      return await this.request(REACT_APP_APPSPOT_URL, 'b2b/permissions', '', 'GET')
    }

    getOrderModel = async (provider, order) => {
      return await this.request(REACT_APP_BASE_URL, `flights/ndc/${provider}/order/${order}`, '', 'GET')
    }

    getPhotosUrl = (hotelKey, numberPhoto) => {
      return `${REACT_APP_BASE_URL}/api/hotels/photos/${hotelKey}/${numberPhoto}`
    }

    priceHotel = async (checkin, checkout, people, hotel, currency) => {
      return await this.request(REACT_APP_BASE_URL, 'hotels/price', { checkin, checkout, people, hotel, currency })
    }

    getHotelInfo = async (params) => {
      return await this.request(REACT_APP_BASE_URL, 'hotels/hotel', params, 'GET')
    }

    getExportBookings = async (startDate, endDate) => {
      return await this.request(
        REACT_APP_BASE_URL,
        'hotels/bookingsReport',
        {
          startDate,
          endDate
        },
        'post',
        true
      )
    }
}
