export const formatePrice = (num: number) => {
  return new Intl.NumberFormat().format(num)
}

interface getPriceWithCurrencyOptions {
  maximumFractionDigits?: number
}

export const getPriceWithCurrency = (
  num = 0,
  currency = 'USD',
  locale: string = undefined,
  { maximumFractionDigits }:getPriceWithCurrencyOptions = {}) => {
  let $return = new Intl.NumberFormat(locale, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits ?? 2,
    currency
  }).format(num)

  if (currency === 'AUD') {
    $return = $return.replace('$', 'A$')
  }

  return $return
}
