import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Icons from '../../../../pageComponents/icons'
import Popup from '../../../../pageComponents/popup'
import _ from 'lodash'
import HotelReview from '../_review'
const ReviewsPopup = ({ showAllReviews, setShowAllReviews }) => {
  const { t } = useTranslation()
  const { reviews } = useSelector(state => state.priceRS.model)

  return (
    <Popup
      child={
        <div className='reviewsPopup'>
          <div className='reviewsPopup__header'>
            {t('Top reviews')}
            <button
              className='reviewsPopup__close'
              onClick={() => setShowAllReviews(!showAllReviews)}
            >
              <Icons
                group='icon'
                icon='close'
                className='reviewsPopup__close-icon'
              />
            </button>
          </div>
          <div className='reviewsPopup__list'>
            {_.map(reviews, review =>
              <HotelReview key={'review' + review.created} review={review} full />
            )}
          </div>
        </div>
      }
      popupOpen={showAllReviews}
      setPopupOpen={setShowAllReviews}
    />
  )
}

export default ReviewsPopup
