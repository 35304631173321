export default class Analytics {
  logEvent = (name, feature, element, action, label) => {
    const separionLabels = this.separationStringForLabel(label, 90)

    const objForFirebase = {
      feature,
      element,
      action
    }
    if (Array.isArray(separionLabels)) {
      for (let i = 0; i < separionLabels.length; i++) {
        objForFirebase[`label_${i}`] = separionLabels[i]
      }
    } else {
      objForFirebase.label_0 = separionLabels
    }

    if (process.env.NODE_ENV !== 'development') {
      firebase.analytics().logEvent(name, { ...objForFirebase })
    }
  }

  separationStringForLabel = (string = '', length) => {
    const arr = []

    if (string.length <= length) {
      return string
    } else {
      const countStrings = Math.ceil(string.length / length)
      const arrFromString = String(string).split('')

      for (let i = 0; i < countStrings; i++) {
        const current90Symbols = arrFromString.splice(0, length)
        arr.push(current90Symbols.join(''))
      }
    }

    return arr
  }

  separationIntoAdultAndChild = (guests) => {
    const allGuests = guests.split(',')
    const adalt = allGuests.filter(i => i === 'A').join(',')
    const child = allGuests.filter(i => i !== 'A').join(',')
    const newArrGuests = [adalt !== '' && adalt, child !== '' && child].filter(i => i !== false)

    return newArrGuests
  }
}
