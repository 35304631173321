import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Tooltip from '../../../../pageComponents/tooltip'

const Exceeds = ({ hotel }) => {
  const { t } = useTranslation()
  const user = useSelector(state => state.user)
  return (
    <div className={'tooltip-hoverElement exceeds ' + hotel.b2bTravelPolicyStatus}>
      {hotel.b2bTravelPolicyStatus === 'exceeds'
        ? t('Exceeds Travel Policy')
        : ''}
      <Tooltip
        top
        child={
          <div className='tooltip__el'>
            <span className='white'>
              {t('You can buy only')} {user.business?.travel_policy?.hotel_classes.join(', ')} {t('stars hotels')}
            </span>
          </div>
        }
      />
    </div>
  )
}

export default Exceeds
