import React from 'react'
import HotelDetail from '../../../components/hotelsComponents/_hotelDetail'

const HotelsResultsDetailPage = () => {
  return (
    <div className='hotels__page container'>
      <HotelDetail />
    </div>
  )
}

export default HotelsResultsDetailPage
