import React, { useEffect, useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import { useSelector } from 'react-redux'
import { RangeSlider } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import Icons from '../../pageComponents/icons'
import StarsFilterController from './controller'
import currentLocation from '../../../hooks/currentLocation'

const Stars = ({ setMouseUp }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const filterStars = useSelector(state => state.filter.stars)
  const controller = new StarsFilterController()
  const { isHotelsPage } = currentLocation()
  const [isStarts, setIsStars] = useState(false)

  useEffect(() => {
    controller.changeActiveFilter(filterStars, setIsStars)
  }, [filterStars.value])

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (open) {
          setOpen(false)
        }
      }}
    >
      <div className={'stars filter ' + (open && 'filter-open')}>
        <div
          className={`stars__value filter__el ${isStarts ? 'filter__active' : ''}`}
          onClick={() => {
            setOpen(!open)
          }}
        >
          {t('Stars')}
          <Icons
            group='arrow'
            icon='down'
            color='#8F8F8F'
            className='filter__el-icon'
          />
          <a
            className='cross__icon-wrapper'
            onClick={controller.handleClearFilter}
          >
            <Icons
              group='icon'
              icon='close'
              className='wifi__label-icon'
              color='#3D73EC'
            />
          </a>
        </div>
        <div className='stars__list filter__list'>
          <div className='filter__list-wrap'>
            <div className='list__header'>
              <div className='left'>
                {t('Stars')}
                <div className='list__header-time'>
                  {filterStars.value[0]}
                  {' - '}
                  {filterStars.value[1]}

                </div>
              </div>
              <button
                className='right clear'
                onClick={controller.handleClearFilter}
              >
                {t('Clear')}
              </button>
            </div>
            <div
              className='stars__slider'
              onMouseUp={() => {
                if (isHotelsPage) {
                  setMouseUp(true)
                }
              }}
            >
              <RangeSlider
                barClassName=''
                value={[filterStars.value[0], filterStars.value[1]]}
                min={filterStars.limit[0]}
                max={filterStars.limit[1]}
                tooltip={false}
                onChange={controller.handleChangeSlider}
              />
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  )
}
export default Stars
