import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { changePrice, clearPrice } from '../../../reducers/filter/actions'
import { getPriceWithCurrency } from '../../../utils/prices'

export default class PriceFilterController {
  constructor () {
    this.dispatch = useDispatch()
    this.lang = useTranslation().i18n.language
  }

  handleChangeSlider = (value) => {
    this.dispatch(changePrice('value', value.sort((a, b) => a - b)))
  }

  handleClearFilter = (e) => {
    e.stopPropagation()
    this.dispatch(clearPrice())
  }

  getPriceFormat = (price, currencyObject) => {
    if (price) {
      if (typeof currencyObject === 'string') {
        return getPriceWithCurrency(Number(price.toFixed(0)), currencyObject, this.lang)
      }
      price = price.toFixed(0)
      price = price.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
      return currencyObject.symbolOnLeft
        ? `${currencyObject.symbol}${price}`
        : `${price}${currencyObject.symbol}`
    }
  }

  changeActiveFilter = (filter, action) => {
    filter.value[0] > filter.limit[0] ||
    filter.value[1] < filter.limit[1]
      ? action(true)
      : action(false)
  }
}
