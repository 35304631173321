import { useDispatch } from 'react-redux'
import Api from '../../../api'
import { updateButton } from '../../../reducers/button/actions'
import { FARE_BUTTON } from '../../../reducers/button/types'
import { FARE_SCREEN } from '../../../reducers/page/types'
import { updateOrderFlights, updatePriceRS } from '../../../reducers/order/actions'
import FareAnalytics from '../../../analytics/analyticsFlight/fareAnalytics'

export default class ChangeFareController {
  constructor () {
    this.dispatch = useDispatch()
    this.api = new Api()
    this.analytics = new FareAnalytics()
  }

  handleOnClick = (legs, pricingOption, people, currency) => {
    this.analytics.selectFare()
    this.dispatch(updateOrderFlights('pricingOption', pricingOption))
    this.dispatch(updateButton(FARE_BUTTON, 'disabled', true))
    this.dispatch(updateButton(FARE_BUTTON, 'isLoading', FARE_SCREEN))
    this.api.price(legs, pricingOption, people, currency)
      .then(priceRS => {
        if (priceRS && priceRS.pricingOption) {
          this.dispatch(updatePriceRS(priceRS))
        }
        this.dispatch(updateButton(FARE_BUTTON, 'disabled', false))
        this.dispatch(updateButton(FARE_BUTTON, 'isLoading', false))
      })
  }
}
