import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Icons from '../../pageComponents/icons'
import OrderController from './controller'

const Order = () => {
  const { t } = useTranslation()
  const controller = new OrderController()
  const order = useSelector(state => state.filter.order)
  return (
    <div
      className='order'
      onClick={() => {
        controller.handleClick(order)
      }}
    >
      {t('Sort by price')}
      <Icons
        className={'order__arrow ' + (order ? 'order__arrow-down' : 'order__arrow-top')}
        group='arrow'
        icon='sort'
        color='#9CBBFD'
      />
    </div>
  )
}

export default Order
