import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'

export const useDispatchActionFn = (action: AnyAction) => {
  const dispatch = useDispatch()

  const dispatchAction = useCallback(() => {
    dispatch(action)
  }, [])

  return dispatchAction
}
