import { Cookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import CardAnalyticsFlight from '../../../analytics/analyticsFlight/cardAnalytics'
import CardAnalyticsHotel from '../../../analytics/analyticsHotel/cardAnalytics'
import currentLocation from '../../../hooks/currentLocation'
import paymentValidation from '../../../hooks/paymentValidation'
import { resetError } from '../../../reducers/error/actions'
import { updateOrderHotels, updatePayment, updatePaymentInForm } from '../../../reducers/order/actions'
import _ from 'lodash'
import { CLIENT_MODE_BUSINESSES } from '../../../reducers/user/constans'

export default class CardController {
  constructor () {
    this.dispatch = useDispatch()
    this.t = useTranslation().t
    this.analyticsFlight = new CardAnalyticsFlight()
    this.analyticsHotel = new CardAnalyticsHotel()
    this.isFlightsPage = currentLocation().isFlightsPage
    this.isHotelsPage = currentLocation().isHotelsPage
  }

  handleOnChange = (e) => {
    const { name, value } = e.target
    const object = e.target.attributes.datatype.value
    let payload
    const field = []
    switch (name) {
      case 'number':
        payload = value.replace(/_/g, '').replace(/ /g, '')
        break
      case 'cc-exp':
        payload = value.split('/')
        field[0] = 'expMonth'
        field[1] = 'expYear'
        break
      case 'cvc':
        payload = value.replace(/[^+\d]/g, '')
        if (_.size(payload) > 3) return
        break
      case 'holder':
        if (!value.match(/^[a-zA-Z_ ]*$/)) return
        payload = value.toUpperCase()
        break
      default:
        payload = value
    }
    if (typeof payload === 'object') {
      payload.forEach((payloadEl, index) => {
        if (this.isFlightsPage) {
          this.analyticsFlight.editField(field[index])
        }
        this.dispatch(updatePaymentInForm(object, field[index], payloadEl))
        this.dispatch(updatePaymentInForm('errors', field[index] + '-error', undefined))
      })
    } else {
      if (this.isFlightsPage) {
        this.analyticsFlight.editField(name)
      }
      this.dispatch(updatePaymentInForm(object, name, payload || ''))
      this.dispatch(updatePaymentInForm('errors', name + '-error', undefined))
    }
    if (this.isHotelsPage) {
      this.analyticsHotel.editField(name)
    }
  }

  handleOnClick = (country) => {
    this.dispatch(updatePaymentInForm('billing', 'country', country.alpha2))
  }

   handleOnSave = (setPopupOpen, payment, bookingDetail, clientMode) => {
     const validation = paymentValidation(payment, this, bookingDetail)
     if (validation.length) {
       validation.forEach(error => {
         this.dispatch(updatePaymentInForm('errors', error.field, error.message))
       })
     } else {
       setPopupOpen(false)
       this.dispatch(resetError())
       if (this.isFlightsPage) {
         this.analyticsFlight.saveCard()
         if (payment.paymentMethod === CLIENT_MODE_BUSINESSES) {
           this.dispatch(updatePayment({ cardToken: payment.cardToken }))
         } else {
           this.dispatch(updatePayment({
             card: payment.card,
             billing: payment.billing,
           }))
         }
       }
       if (this.isHotelsPage) {
         this.analyticsHotel.saveCard()
         if (payment.paymentMethod === CLIENT_MODE_BUSINESSES) {
           this.dispatch(updateOrderHotels('cardToken', payment.cardToken))
         } else {
           if (bookingDetail.cardRequired) {
             this.dispatch(updateOrderHotels('card', payment.card))
           }
           if (bookingDetail.addressRequired) {
             this.dispatch(updateOrderHotels('address', payment.billing))
           }
         }
       }
       savePaymentBilling(payment)
     }
   }

  handleOnClickCancel = (setPopupOpen) => {
    if (this.isFlightsPage) {
      this.analyticsFlight.cancelCard()
    } else if (this.isHotelsPage) {
      this.analyticsHotel.cancelCard()
    }
    setPopupOpen(false)
  }

  getFieldProps = (field) => {
    const props = {}
    switch (field) {
      case 'number':
        props.name = 'number'
        props.label = this.t('Card number')
        props.placeholder = this.t('Card number')
        props.mask = '1111 1111 1111 1111'
        props.id = 'cardNumber'
        break
      case 'expMonth':
        props.name = 'cc-exp'
        props.label = this.t('Exp. date')
        props.placeholder = this.t('MM/YY')
        props.mask = '11/11'
        props.small = true
        props.id = 'cardExpiration'
        break
      case 'cvc':
        props.name = 'cvc'
        props.label = 'CVC'
        props.small = true
        props.id = 'cardCsc'
        break
      case 'holder':
        props.name = 'holder'
        props.label = this.t('Card holder')
        props.id = 'cardHolder'
        break
      case 'street':
        props.name = 'street'
        props.label = this.t('Street')
        break
      case 'city':
        props.name = 'city'
        props.label = this.t('City')
        break
      case 'state':
        props.name = 'state'
        props.label = this.t('State')
        break
      case 'postal':
        props.name = 'postal'
        props.label = this.t('Zip code')
        props.small = true
        break
      case 'country':
        props.name = 'country'
        props.label = this.t('Country')
        break
    }

    return props
  }

  handleOnClickAddPayment = (setPopupOpen) => {
    const cookies = new Cookies()
    const cookiesPaymentBilling = cookies.get('paymentBilling')
    if (cookiesPaymentBilling) {
      _.forEach(cookiesPaymentBilling, (value, key) => {
        this.dispatch(updatePaymentInForm('billing', key, value))
      })
    }
    setPopupOpen(true)
    if (this.isFlightsPage) {
      this.analyticsFlight.addCard()
    } else if (this.isHotelsPage) {
      this.analyticsHotel.clickAddCard()
    }
  }

  useCorpCard = (useCorpCard, setUseCorpCard) => {
    if (useCorpCard) {
      setUseCorpCard(true)
      this.dispatch(updatePaymentInForm())
    }
  }
}

const savePaymentBilling = (payment) => {
  const cookies = new Cookies()
  cookies.set('paymentBilling', JSON.stringify(payment.billing), {
    maxAge: 60 * 60 * 24 * 365,
    secure: true,
    path: '/'
  })
}
