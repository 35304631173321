import { CLEAR_STATE } from '../actions'
import { CLEAR_PASSENGER, CLEAR_PAYMENT, UPDATE_ORDER_FLIGHTS, UPDATE_PASSENGER, UPDATE_PAYMENT } from './actions'

const defaultState = {
  legs: null,
  pricingOption: null,
  passengers: [
    {
      isPayer: true,
      type: 'ADT',
    }
  ],
  payment: {
    card: {
      number: '',
      expMonth: '',
      expYear: '',
      cvc: '',
      holder: ''
    },
    billing: {
      street: '',
      city: '',
      state: '',
      postal: '',
      country: ''
    }
  },
}

const orderFlights = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_ORDER_FLIGHTS:
      return {
        ...state,
        [action.field]: action.payload
      }
    case UPDATE_PASSENGER: {
      return {
        ...state,
        passengers: Object.values({
          ...state.passengers,
          [action.number]: {
            ...state.passengers[action.number],
            ...action.payload
          }
        })
      }
    }
    case CLEAR_PASSENGER: {
      return {
        ...state,
        passengers: Object.values({
          ...state.passengers,
          [action.number]: {
            ...state.passengers[action.number],
            id: undefined,
            gender: undefined,
            title: undefined,
            document: undefined,
            birthDate: undefined,
            email: undefined,
            firstName: undefined,
            lastName: undefined,
            middleName: undefined,
            phone: undefined,
            checked: undefined,
            frequentFlyer: undefined,
            frequentGuest: undefined
          }
        })
      }
    }
    case UPDATE_PAYMENT: {
      return {
        ...state,
        payment: action.payload
      }
    }
    case CLEAR_PAYMENT: {
      return {
        ...state,
        payment: {
          billing: {
            street: '',
            city: '',
            state: '',
            country: '',
            postal: ''
          },
          card: {
            holder: '',
            cvc: '',
            expMonth: '',
            number: '',
            expYear: ''
          }
        },

      }
    }
    case CLEAR_STATE:
      return defaultState
    default:
      return state
  }
}

export default orderFlights
