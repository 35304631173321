import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import currentLocation from '../../../../hooks/currentLocation'
import Icons from '../../../pageComponents/icons'
import PassengerController from '../controller'
import Checkbox from './_checkbox'
import Inputs from './_inputs'
import Radio from './_radio'
import _ from 'lodash'

const PassengerForm = ({ setPopupOpen, openOptionsPassengerNumber, setOpenOptionsPassenger }) => {
  const { t } = useTranslation()
  const { isHotelsPage, isFlightsPage } = currentLocation()
  const controller = new PassengerController()
  const clientMode = useSelector(state => state.user.clientMode)
  let carriers
  let currentCarrier
  let currentCarrierName
  let currentChainsName
  let passengerForm
  let passengerType
  let passengerFormConfig
  let wheelchair
  let hotelState
  if (isFlightsPage) {
    carriers = useSelector(state => state.searchRS.model.carriers)
    currentCarrier = useSelector(state => _.first(_.first(state.orderFlights.legs).segments).carrier)
    currentCarrierName = carriers[currentCarrier].name
    passengerForm = useSelector(state => state.passengerForm)
    passengerType = useSelector(state => state.priceRS.model.passengers[openOptionsPassengerNumber].type)
    passengerFormConfig = useSelector(state => state.priceRS.model.passengerFormConfig[passengerType])
    wheelchair = _.find(passengerFormConfig, field => field.field === 'wheelchair')
  }
  if (isHotelsPage) {
    passengerForm = useSelector(state => state.bookerForm.form)
    if (!openOptionsPassengerNumber) {
      passengerFormConfig = useSelector(state => state.bookerForm.bookerFields)
    } else {
      passengerFormConfig = useSelector(state => state.bookerForm.extraGuestFields)
    }
    hotelState = useSelector(state => state.hotel.choice)
    if (_.size(hotelState.chains)) {
      // const chains = useSelector(state => state.searchRS.model.chains)
      currentChainsName = _.first(hotelState.chains)
    }
  }

  return (
    <div className='passengerForm'>
      <div className='popup__header'>
        {isHotelsPage
          ? passengerForm.id
            ? t('Edit guest')
            : t('New guest')
          : passengerForm.id
            ? t('Edit passenger')
            : t('New passenger')}
        <button
          className='popup__header-close'
          onClick={() => setPopupOpen(false)}
        >
          <Icons
            group='icon'
            icon='close'
            width={24}
            height={24}
            color='#8F8F8F'
          />
        </button>
      </div>
      <div className='popup__content'>
        <form className='form'>
          {passengerFormConfig.map((field, index) => {
            if (
              field.type === 'field' &&
              field.visible &&
              field.field !== 'wheelchair'
            ) {
              if (
                isFlightsPage ||
                (isHotelsPage && (field.field !== 'frequentGuest' || _.size(hotelState.chains)))
              )
                return (
                  <div
                    key={'field' + openOptionsPassengerNumber + index}
                    className={
                    'inputDiv inputDiv__' + field.field +
                    (passengerForm[field.field + '-error'] ? ' inputDiv__error' : '')
                  }
                    style={{
                      order: controller.getLabelAndOrder(field.field).order,
                      display: controller.getLabelAndOrder(field.field).order === '' && 'none'
                    }}
                  >
                    {field.header &&
                      <div className='inputDiv__header'>{t(field.header)}</div>}
                    {field.enum
                      ? <Radio field={field} error={passengerForm[field.field + '-error']} />
                      : <Inputs
                          field={field}
                          error={passengerForm[field.field + '-error']}
                          passengerType={passengerType}
                        />}
                  </div>
                )
            }
            return ''
          }
          )}
        </form>

        {passengerFormConfig.map((fields) => {
          if (fields.field === 'document' && fields.type === 'block' && fields.visible) {
            return (
              <React.Fragment
                key={'field' + openOptionsPassengerNumber + fields.field}
              >
                <div className='popup__subheader'>
                  {t('Document details')}
                </div>
                <form className='form'>
                  {fields.fields.map((field, fieldsIndex) => {
                    return (
                      <React.Fragment
                        key={'fields' + openOptionsPassengerNumber + fieldsIndex}
                      >
                        <div
                          className={
                              'inputDiv inputDiv__' + field.field +
                              (passengerForm[field.field + '-error'] ? ' inputDiv__error' : '')
                            }
                          style={{
                            order: controller.getLabelAndOrder(field.field).order,
                            display: controller.getLabelAndOrder(field.field).order === '' && 'none'
                          }}
                        >
                          {field.enum
                            ? <Radio field={field} error={passengerForm[field.field + '-error']} />
                            : <Inputs
                                field={field}
                                error={passengerForm[field.field + '-error']}
                                passengerType={passengerType}
                              />}
                        </div>
                        {field.field === 'documentExpiryDate' &&
                          <div
                            className='inputDiv inputDiv__documentExpiryDateUnlimited'
                            style={{
                              order: controller.getLabelAndOrder('documentExpiryDateUnlimited').order,
                              display: controller.getLabelAndOrder(field.field).order === '' && 'none'
                            }}
                          >
                            <Checkbox
                              field={{ field: 'documentExpiryDateUnlimited' }}
                            />
                          </div>}
                      </React.Fragment>
                    )
                  })}
                </form>
              </React.Fragment>
            )
          }
          return ''
        }
        )}
        {(!wheelchair || !wheelchair.visible)
          ? ''
          : (
            <>
              <div className='popup__subheader'>
                {t('Special assistance')}
              </div>
              <form className='form'>
                <div
                  className={
                    'inputDiv inputDiv__' + wheelchair.field +
                    (passengerForm[wheelchair.field + '-error'] ? ' inputDiv__error' : '')
                  }
                >
                  <Checkbox field={wheelchair} error={passengerForm[wheelchair.field + '-error']} />
                </div>
              </form>
            </>
            )}
      </div>
      <div className='popup__footer'>
        <button
          onClick={() => { controller.handleOnClickCancel(setPopupOpen) }}
          className='button button-cancel'
        >
          {t('Cancel')}
        </button>
        <button
          className='button button-save'
          onClick={() => {
            controller.handleOnClickSave(
              passengerForm,
              passengerFormConfig,
              setPopupOpen,
              openOptionsPassengerNumber,
              setOpenOptionsPassenger,
              passengerType,
              currentCarrierName,
              currentChainsName,
              clientMode
            )
          }}
        >
          {t('Save')}
        </button>
      </div>
    </div>
  )
}

export default PassengerForm
