import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'

export const useDispatchAction = (action: AnyAction, dependencies: React.DependencyList = []) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(action)
  }, dependencies)
}
