import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CLIENT_MODE_BUSINESSES } from '../../../../reducers/user/constans'
import Icons from '../../../pageComponents/icons'
import GuestsController from '../controller'
import Adults from './_adults'
import Children from './_children'
import _ from 'lodash'

const GuestsDropdown = () => {
  const { t } = useTranslation()
  const guests = useSelector(state => state.searchForm.guests)
  const controller = new GuestsController()
  const user = useSelector(state => state.user)
  return (
    <div className='guestsDropdown'>
      {_.map(guests.rooms, (room, indexRoom) => {
        return (
          <div
            key={'room' + indexRoom}
            className='guestsDropdown__room'
          >
            <div className='guestsDropdown__header'>
              {t('Room')} {indexRoom + 1}
              {!indexRoom
                ? ''
                : (
                  <button
                    className='guestsDropdown__header-remove'
                    onClick={() => controller.handleOnRemoveRoom(indexRoom, guests.rooms)}
                  >
                    <Icons
                      group='icon'
                      icon='close'
                    />
                  </button>
                  )}
            </div>
            <Adults indexRoom={indexRoom} />
            {user.clientMode !== CLIENT_MODE_BUSINESSES &&
              <Children indexRoom={indexRoom} />}
            {indexRoom === guests.rooms.length - 1 &&
              <button
                className='guestsDropdown__room-add'
                onClick={() => { controller.handleOnAddRoom(guests.rooms) }}
              >
                {t('Add room')}
              </button>}
          </div>
        )
      })}

    </div>
  )
}

export default GuestsDropdown
