import React from 'react'
import cx from 'classnames'

import './style.sass'

type SearchInputProps = React.ComponentProps<'input'>

export const SearchInput: React.FC<SearchInputProps> = React.memo(({ className, type = 'text', ...props }) => {
  return (
    <input
      type={type}
      className={cx('ordersSearch', className)}
      {...props}
    />
  )
})
