import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Icons from '../../pageComponents/icons'
import WifiController from './controller'

const Wifi = () => {
  const { t } = useTranslation()
  const wifi = useSelector(state => state.filter.wifi)
  const controller = new WifiController()
  return (
    <div className='wifi'>
      <input
        type='checkbox'
        id='wifi'
        checked={wifi}
        className='wifi__input'
        onChange={() => {
          controller.handleClick(!wifi)
        }}
      />
      <label
        htmlFor='wifi'
        className='wifi__label filter__el'
      >
        {t('WiFi')}
        <Icons
          group='icon'
          icon='close'
          className='wifi__label-icon'
          color='#3D73EC'
        />
      </label>
    </div>
  )
}
export default Wifi
