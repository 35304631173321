import React, { useState } from 'react'
import './style.sass'
import { useSelector } from 'react-redux'
import ResultsAnalytics from '../../../analytics/analyticsFlight/resultsAnalytics'
import ItinerariesElementController from './controller'
import Carrier from './_carrier'
import Info from './_info'
import Duration from './_duration'
import Transferring from './_transferring'
import Price from './_price'
import DetailList from './_detail/_list'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import _ from 'lodash'


const ItinerariesElement = ({ element, type, searchRS, allOutboundItineraries, onClick, indexElement }) => {
  if (!searchRS) return ''
  if (!Object.keys(searchRS).length) return ''
  const [openDetail, setOpenDetail] = useState(false)
  const people = useSelector(state => state.searchForm.passengers.value)
  const currency = useSelector(state => state.searchForm.currency)
  const filter = useSelector(state => state.filter)
  const zIndex = filter.order ? 1000 - indexElement : indexElement
  const controller = new ItinerariesElementController(element, searchRS, type)
  const carriers = controller.carrier()
  const duration = controller.duration()
  const direction = controller.direction()
  const analytics = new ResultsAnalytics()
  const itineraries = useSelector(state => state.itineraries)
  const setOpenDetailInformation = (isOpen) => {
    setOpenDetail(isOpen)
    analytics.clickDetailInformation(isOpen)
  }

  const handleChange = () => (event, isExpanded) => {
    setOpenDetailInformation(isExpanded)
  }
  return (
    <div
      className={
        'itinerariesElement ' +
        (openDetail && ' itinerariesElement-noClick ') +
        (type === 'outbound' && itineraries.outbound &&
          itineraries.outbound.outboundId === element.outboundId &&
          ' itinerariesElement-isElementClick ') +
        (type === 'inbound' && itineraries.inbound &&
          itineraries.inbound.inboundId === element.inboundId &&
          ' itinerariesElement-isElementClick ')
      }
      style={{
        zIndex: zIndex,
      }}
    >
      <Accordion onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <div
            aria-label='Acknowledge'
            className='itinerariesElement__top'
            onClick={(e) => {
              e.stopPropagation()
              if (!openDetail) {
                onClick(element, allOutboundItineraries, searchRS.segments, people, currency, openDetail)
              }
            }}
          >
            <Carrier carriers={carriers} />
            <Info carriers={carriers} duration={duration} openDetail={openDetail} element={element} />
            <Duration
              timeDuration={controller.getTimeFormat(duration.duration)}
              direction={direction}
              openDetail={openDetail}
            />
            <Transferring
              transferring={duration.transferring}
              openDetail={openDetail}
              onClick={() => {
                onClick(element, allOutboundItineraries, searchRS.segments, people, currency, openDetail)
              }}
            />

            <Price element={element} />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <DetailList
            openDetail={openDetail}
            element={element[type]}
            searchRS={searchRS}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default ItinerariesElement
