import { useDispatch } from 'react-redux'
import { CheckBusinessUser } from '../../../../../hooks/checkBusinessUser'
import {
  updateCorporateCardPaymentInForm, updateErrorInForm,
  updatePaymentInForm,
  updatePaymentMethod
} from '../../../../../reducers/order/actions'
import { CLIENT_MODE_BUSINESSES, CLIENT_MODE_PERSONAL } from '../../../../../reducers/user/constans'

export default class CorporateCardController {
  constructor () {
    this.dispatch = useDispatch()
  }

  firstInit = (user, isHotelsPage) => {
    this.setPaymentMethod(CheckBusinessUser(user) && !isHotelsPage ? CLIENT_MODE_BUSINESSES : CLIENT_MODE_PERSONAL)
  }

  setPaymentMethod = (paymentMethod) => {
    this.dispatch(updatePaymentMethod(paymentMethod))
    this.dispatch(updateErrorInForm())
  }

  setCard = (paymentMethod) => {
    this.dispatch(updateCorporateCardPaymentInForm(paymentMethod.card_token))
    this.dispatch(updatePaymentInForm('errors', 'cardToken-error', undefined))
  }
}
