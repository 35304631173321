import React, { useState, useEffect } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import useLocalStorage from '../../../../hooks/useLocalStorage'
import Icons from '../../icons'
import LangController from './controller'
import availableLanguages from '../../../../data/availableLanguages'

const Language = () => {
  const [openDropdown, setOpenDropdown] = useState(false)
  const { i18n } = useTranslation()
  const [ordersIds, setOrdersIds] = useLocalStorage('ordersIds')
  const [ordersList, setOrdersList] = useLocalStorage('ordersList')
  const controller = new LangController()
  useEffect(() => {
    controller.setLanguage()
  }, [])
  return (
    <div className='language'>
      <div
        className={'language__value ' + (openDropdown && 'active')}
        onClick={() => setOpenDropdown(!openDropdown)}
      >
        {availableLanguages[i18n.language.toLocaleLowerCase()]}
      </div>
      <OutsideClickHandler
        onOutsideClick={() => {
          if (openDropdown) {
            setOpenDropdown(false)
          }
        }}
      >
        <div className={'language__list ' + (openDropdown && 'language__list-open')}>
          <div className='language__list-wrap'>
            {Object.keys(availableLanguages).map(key => {
              return (
                <div
                  key={'availableLanguages' + key}
                  className={'language__list-el ' +
                  (i18n.language.toLocaleLowerCase() === key.toLocaleLowerCase() && 'active')}
                  onClick={() => {
                    controller.changeLang(key, setOpenDropdown, setOrdersIds, setOrdersList)
                  }}
                >
                  <Icons
                    group='icon'
                    icon={key}
                  />
                  {availableLanguages[key]}
                </div>
              )
            })}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default Language
