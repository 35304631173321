import React from 'react'
import './style.sass'

const Popup = ({ child, popupOpen, setPopupOpen }) => {
  return (
    <div className={'popup ' + (popupOpen && 'popup__open')}>
      <div
        className='popup__overflow'
        onClick={() => { setPopupOpen(!popupOpen) }}
      />
      <div className='popup__wrapper'>
        {child}
      </div>
    </div>
  )
}

export default Popup
