import { CLEAR_STATE } from '../actions'
import { SET_MAP } from './actions'

const defaultState = {
  node: null
}

const Map = (state = defaultState, action) => {
  switch (action.type) {
    case SET_MAP:
      return {
        ...state,
        node: action.payload
      }
    case CLEAR_STATE:
      return defaultState
    default:
      return state
  }
}

export default Map
