import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import KitCut from './_kitCut'
import _ from 'lodash'

const HotelReview = ({ review, full }) => {
  const { i18n } = useTranslation()
  const ratingText = [
    '',
    'terrible',
    'bad',
    'adequate',
    'good',
    'awesome',
  ]
  return (
    <div className='hotelReview'>
      <div className={
        'hotelReview__rating ' +
        'hotelReview__rating-' + ratingText[review.rating]
      }
      >
        {review.rating}
      </div>
      <div className='hotelReview__left'>
        <div className='text'>
          <KitCut review={review.text} initLimit={full ? _.size(review.text) : 150} />
        </div>
        <div className='author'>
          {review.author.name}
        </div>
        <div className='date'>
          <Moment
            unix
            fromNow
            locale={i18n.language}

          >
            {review.created}
          </Moment>
        </div>
      </div>
    </div>
  )
}

export default HotelReview
