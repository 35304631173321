import React from 'react'
import SearchScreen from '../../screen/searchScreen'

const HotelsSearchPage = () => {
  return (
    <div className='hotels__page'>
      <SearchScreen />
    </div>
  )
}

export default HotelsSearchPage
