import React, { useEffect, useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import ResultsAnalytics from '../../../../analytics/analyticsHotel/resultsAnalytics'
import Icons from '../../../pageComponents/icons'
import HotelElement from '../_hotelElement'

const RecentlyBookedHotels = () => {
  const [showRecently, setShowRecently] = useState(true)
  const { t } = useTranslation()
  const resultAnalytics = new ResultsAnalytics()
  const hotelData = useSelector(state => state.hotel)
  const searchRS = useSelector(state => state.searchRS)
  const recentlyBookedHotels = searchRS.model.recentlyBookedHotels
  const filter = useSelector(state => state.filter)
  useEffect(() => {
    setShowRecently(true)
  }, [recentlyBookedHotels])
  if (!_.size(recentlyBookedHotels) || !showRecently) {
    return ''
  }
  return (
    <div className='recentlyBookedHotels'>
      <div className='recentlyBookedHotels__header'>
        {t('Recently booked hotels')}
        {searchRS.status === 'success' &&
          <button
            className='recentlyBookedHotels__close'
            onClick={() => setShowRecently(false)}
          >
            <Icons
              className='recentlyBookedHotels__close'
              group='icon'
              icon='close'
              color='#ccc'
            />
          </button>}
      </div>
      <div className='recentlyBookedHotels__elements'>
        {_.map(recentlyBookedHotels, hotel =>
          <HotelElement
            hotel={hotel}
            actionAnalytic={() => resultAnalytics.clickDetailInformation(hotel, hotelData, searchRS)}
          />
        )}
      </div>
      <div className='recentlyBookedHotels__footer'>
        {t('Explore')} {_.first(recentlyBookedHotels).city}
        <span>{t('properties', { count: filter.allResultCount })}</span>
      </div>
    </div>
  )
}

export default RecentlyBookedHotels
