const origin = {
  name: 'Sheremetyevo International',
  city: 'Moscow',
  country: 'Russia',
  code: 'SVO'
}

const destination = {
  name: 'Koltsovo',
  city: 'Yekaterinburg',
  country: 'Russia',
  code: 'SVX'
}

export default { origin, destination }
