import { useDispatch } from 'react-redux'
import { updateItineraries } from '../../../reducers/itineraries/actions'
import { changePage } from '../../../reducers/page/actions'
import { RESULTS_SCREEN } from '../../../reducers/page/types'
import FareAnalytics from '../../../analytics/analyticsFlight/fareAnalytics'

export default class FareItineraryController {
  constructor () {
    this.dispatch = useDispatch()
    this.analytics = new FareAnalytics()
  }

  getDate = (searchRs, itineraries) => {
    return searchRs.segments[itineraries[0]].departureDate
  }

  handleClickChange = (type) => {
    this.dispatch(updateItineraries('inbound', null))
    if (type === 'outbound') {
      this.dispatch(updateItineraries('outbound', null))
      this.dispatch(updateItineraries('allInboundItineraries', null))
    }
    this.analytics.changeFlight(type)
    this.dispatch(changePage(RESULTS_SCREEN))
  }
}
