import React, { useEffect } from 'react'
import './style.sass'
import { useSelector } from 'react-redux'
import { CLIENT_MODE_BUSINESSES } from '../../../../reducers/user/constans'
import Icons from '../../../pageComponents/icons'
import Counter from './_counter'
import PeopleController from './controller'
import OutsideClickHandler from 'react-outside-click-handler'

const People = ({ showList, setShowList }) => {
  const controller = new PeopleController()
  const passengers = useSelector(state => state.searchForm.passengers)
  const user = useSelector(state => state.user)

  useEffect(() => {
    controller.dispatchPassengersAfterLoad(passengers.count)
  }, [passengers.count])
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (showList === 'people') {
          setShowList('people')
        }
      }}
    >
      <div className='people dropdown'>
        <div
          className={'dropdown__value ' + (showList === 'people' && 'dropdown__value-active')}
          onClick={() => setShowList('people')}
        >
          <Icons
            className='dropdown__value-img'
            group='icon'
            icon='person'
            color='#707070'
          />
          {controller.passengers(passengers.count)}
          <Icons
            className='dropdown__value-arrow'
            group='arrow'
            icon='down'
            color='#ccc'
          />
        </div>
        <div className={'dropdown__list ' + (showList === 'people' && 'dropdown__list-open')}>
          <div className='dropdown__list-wrap'>
            <Counter type='ADT' />
            {user.clientMode !== CLIENT_MODE_BUSINESSES &&
              <>
                <Counter type='CHD' />
                <Counter type='INF' />
              </>}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default People
