import { CLIENT_MODE_BUSINESSES } from '../reducers/user/constans'
import currentLocation from './currentLocation'
import _ from 'lodash'

export default function updatePassengerObject (passenger, number, clientMode) {
  const { isHotelsPage } = currentLocation()
  const object = {}
  Object.keys(passenger).forEach(field => {
    if (field === 'documents') {
      // Для документов извлекаем первые объект массива, если он есть
      if (passenger[field]?.length) {
        object.document = passenger[field][0]
      }
    } else if (field === 'middleName' && passenger[field] === null) {
      // если очество null, меняем его на пустой стринг
      object[field] = ''
    } else if (field === 'wheelchairRequired') {
      // если надо инвалидное кресло
      object.ssrcode = passenger[field] ? 'WCHR' : null
    } else if (field === 'frequentFlyer' || field === 'frequentGuest') {
      // если есть программа лояльности, то берем только номер
      if (_.size(passenger.frequentFlyer)) {
        object.frequentflyer = _.first(passenger.frequentFlyer).number
      } else if (_.size(passenger.frequentGuest)) {
        object.fgp = _.first(passenger.frequentGuest).number
      } else {
        object[field] = null
      }
    } else if (field === 'phone') {
      // в телефоне только цифры
      object[field] = (passenger[field] && (passenger[field]).replace(/\D+/g, '')) || null
    } else {
      object[field] = passenger[field]
    }
  })
  if (clientMode === CLIENT_MODE_BUSINESSES) {
    // для бизнеса добовляем businessAccountId равный id
    object.id = passenger.id
    object.businessAccountId = passenger.id
    object.home_country = object.country
  }
  if (isHotelsPage) {
    if (number) {
      return _.pick(object, ['id', 'businessAccountId', 'firstName', 'first_name', 'lastName', 'last_name'])
    }
    return _.pick(object,
      [
        'id',
        'businessAccountId',
        'firstName',
        'first_name',
        'lastName',
        'last_name',
        'phone',
        'email',
        'country',
        'home_country',
        'fgp'
      ]
    )
  }
  return object
}
