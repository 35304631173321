import React from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import moment from 'moment'
import { useSelector } from 'react-redux'

const ChooseOutboundTooltip = ({ outboundController }) => {
  const itineraries = useSelector(state => state.itineraries)
  const duration = outboundController.duration()
  const transferring = duration.transferring
  const { t, i18n } = useTranslation()
  return (
    <>
      <div className='tooltip__el'>
        <Moment
          format='MMM DD, dd'
          locale={i18n.language}
        >
          {itineraries.outboundDate}
        </Moment>
      </div>
      <div className='tooltip__el'>
        {[duration.departureDate, duration.arrivalDate].map(date => moment(date).format('LT')).join(' - ')}
        {duration.differenceOfDays > 0 &&
          <span className='differenceOfDays'>+{duration.differenceOfDays}</span>}
      </div>
      <div className='tooltip__el'>
        {t(transferring.count)}
      </div>
    </>

  )
}

export default ChooseOutboundTooltip
