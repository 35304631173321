import React, { useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import PropertyType from './_propertyType'
import PaymentAndBooking from './_paymentAndBooking'
import useCountActiveFilters from './useCountActiveFilters'

const Other = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const countActiveFilters = useCountActiveFilters()

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (open) {
          setOpen(false)
        }
      }}
    >
      <div className={'other filter ' + (open && 'filter-open')}>
        {countActiveFilters !== 0 && <div className='other__filter-count'>{countActiveFilters}</div>}
        <div
          className='other__value filter__el'
          onClick={() => {
            setOpen(!open)
          }}
        >
          {t('Other filters')}

        </div>
        <div className='other__list filter__list'>
          <div className='filter__list-wrap'>
            {/* after adding a new filter to "other filters", complement useCountActiveFilters */}
            <PaymentAndBooking />
            <PropertyType />
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default Other
