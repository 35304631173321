import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Tooltip from '../../../../pageComponents/tooltip'
import { Option } from '../../../../pageComponents/option'
import { MultipleOptions } from '../../../../pageComponents/multipleOptions'
import { useRoomOptions } from '../../../../../context'
import RoomFiltersController from './controller'
import { pickOptionsAsList, pickOptions } from '../../../../../utils/pickOption'

import './style.sass'

const RoomFilters = () => {
  const { t } = useTranslation()
  const { state, dispatch } = useRoomOptions()
  const priceRS = useSelector(store => store.priceRS.model)

  const controller = new RoomFiltersController(state?.prev, priceRS.rooms)

  const show = useMemo(() => controller.getAvailableOptions(), [priceRS.rooms?.length])

  const meals = useMemo(() => [
    { name: 'withoutMeal', label: t('Meals are not included'), checked: state.meal.withoutMeal },
    { name: 'breakfastIncl', label: t('Breakfast included'), checked: state.meal.breakfastIncl },
    { name: 'lunchIncl', label: t('Lunch included'), checked: state.meal.lunchIncl },
    { name: 'dinnerIncl', label: t('Dinner included'), checked: state.meal.dinnerIncl }
  ], [state.meal, show.meal])

  const options = useMemo(() => [
    { name: 'withPrepay', label: t('Prepay required'), checked: state.paymentOptions.withPrepay },
    { name: 'withoutPrepay', label: t('Prepay is not required'), checked: state.paymentOptions.withoutPrepay },
  ], [state.paymentOptions])

  const handleChangeRoomOption = useCallback((field) => (payload) => {
    dispatch({
      field,
      payload
    })
  }, [])

  const handleClearRoomOption = useCallback((name) => () => {
    dispatch({ field: 'clear', payload: name })
  }, [])

  const handleSetPreviousOptions = () => {
    dispatch({ field: 'setPrev' })
  }

  return (
    <div className='hotelRooms_filters'>
      <MultipleOptions
        name={t('Meal')}
        listName={t('Meal options')}
        items={meals}
        onChange={handleChangeRoomOption('meal')}
        onClear={handleClearRoomOption('meal')}
      />
      <Option name={t('Refundable')} checked={state.refundable} onChange={handleChangeRoomOption('refundable')} />
      <MultipleOptions
        name={t('Payment options')}
        listName={t('Payment options')}
        items={options}
        onChange={handleChangeRoomOption('paymentOptions')}
        onClear={handleClearRoomOption('paymentOptions')}
      />
      <div className='tooltip-hoverElement'>
        <button
          className='hotelRooms_filters__apply'
          disabled={!controller.checkPrevState()}
          onClick={handleSetPreviousOptions}
        >{t('Apply last filters')}
        </button>
        {controller.checkPrevState() && (
          <Tooltip
            bottom child={
              <div className='hotelRooms_filters__tooltip'>
                {controller.checkMeal() &&
                  <p className='tooltip__el'>{t('Meal')}
                    <span className='white'>
                      {pickOptionsAsList(state.prev?.meal, {
                        breakfastIncl: t('Breakfast included'),
                        dinnerIncl: t('Dinner included'),
                        lunchIncl: t('Lunch included'),
                        withoutMeal: t('Meals are not included')
                      })}
                    </span>
                  </p>}
                {controller.checkRefundable() &&
                  <p className='tooltip__el'>
                    {t('Cancellation rule')}<span className='white'>{t('Refundable')}</span>
                  </p>}
                {controller.checkPaymentOptions() &&
                  <p className='tooltip__el'>
                    {t('Prepay required')}
                    <span className='white'>
                      {pickOptionsAsList(state.prev?.paymentOptions, {
                        withPrepay: t('Prepay required'),
                        withoutPrepay: t('Prepay is not required')
                      })}
                    </span>
                  </p>}
              </div>
        }
          />
        )}
      </div>
    </div>
  )
}

export default RoomFilters
