import React from 'react'
import { useSelector } from 'react-redux'
import usePrice from '../../../hooks/usePrice'
import Icons from '../../pageComponents/icons'
import ChangeFareController from './controller'

const FareElement = ({ pricingOption, active, setActive }) => {
  const currencies = useSelector(state => state.searchRS.model.currencies)
  const price = usePrice(currencies, pricingOption)
  const controller = new ChangeFareController()
  const legs = useSelector(state => state.orderFlights.legs)
  const people = useSelector(state => state.searchForm.passengers.value)
  const currency = useSelector(state => state.searchForm.currency)
  return (
    <div
      className={'fareElement ' + (active && 'fareElement__active')}
      onClick={() => {
        controller.handleOnClick(legs, pricingOption, people, currency)
        setActive(pricingOption.ndc.offerId)
      }}
    >
      <div className='fareElement__price'>
        {price.value}
      </div>
      <div className='fareElement__name'>
        {pricingOption.fareInfo.title}
      </div>
      <div className='fareElement__features'>
        {pricingOption.fareInfo.mainFeatures.map((feature, featureIndex) => {
          return (
            <div
              key={'fareElement__features' + feature.description + featureIndex}
              className='feature'
            >
              <Icons
                group='icon'
                icon={feature.availability.replace('-', '_')}
                className='feature__icon'
                color='#707070'
              />
              {feature.description}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FareElement
