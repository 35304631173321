import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import _ from 'lodash'

const SuccessGuests = () => {
  const { t } = useTranslation()
  const { booker, extra_guests } = useSelector(state => state.orderRS.model.order)
  return (
    <div className='successGuests'>
      <div className='successGuests__header'>
        {t('guests')}
      </div>
      <div className='successGuests__guest'>
        {booker.firstName} {booker.lastName}
      </div>
      {_.map(extra_guests, guest =>
        <div className='successGuests__guest'>
          {guest.firstName} {guest.lastName}
        </div>
      )}
    </div>
  )
}

export default SuccessGuests
