/*eslint-disable*/
import React from 'react'

const Icons = ({ className = undefined, group, icon, color = '#000', width = undefined, height = undefined }): any => {
  icon = icon.toLowerCase()
  let returnIcon = ''
  const icons = {
    icon: {
      logo: <svg className={className} width="135" height="13" viewBox="0 0 135 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.316008 12.9207C0.158004 12.9207 0 12.8415 0 12.6829C0 12.6037 -2.94306e-08 12.6037 0.079002 12.5244L4.89813 0.396358C5.05613 0.158553 5.13513 0.0792847 5.37214 0.0792847H6.79417C7.03118 0.0792847 7.11018 0.158553 7.26819 0.396358L12.1663 12.6037C12.1663 12.6829 12.1663 12.6829 12.1663 12.7622C12.1663 12.9207 12.0873 13 11.9293 13H10.0333C9.79625 13 9.71725 12.9207 9.63825 12.6829L8.76923 10.622H3.39709L2.60707 12.6829C2.52807 12.9207 2.37006 13 2.21206 13H0.316008V12.9207ZM7.9002 8.32319L6.08316 3.40855L4.26611 8.32319H7.9002Z" fill={color} />
        <path d="M14.3783 12.9207C14.1413 12.9207 14.0623 12.7622 14.0623 12.6037V0.396358C14.0623 0.158553 14.2203 0.0792847 14.3783 0.0792847H19.4344C20.5404 0.0792847 21.5675 0.554894 22.2785 1.34758C22.9895 2.14026 23.3845 3.17075 23.3845 4.20123C23.3845 5.23172 22.9895 6.26221 22.1995 7.05489C21.4885 7.84758 20.4614 8.32318 19.2764 8.32318H16.4323V12.6037C16.4323 12.8415 16.3533 12.9207 16.1163 12.9207H14.3783ZM18.8814 6.10367C19.4344 6.10367 19.9084 5.94514 20.3034 5.5488C20.6194 5.23172 20.8564 4.75611 20.8564 4.20123C20.8564 3.64636 20.6984 3.17075 20.3034 2.85367C19.9084 2.45733 19.4344 2.2988 18.8814 2.2988H16.5113V6.10367H18.8814Z" fill={color} />
        <path d="M25.2016 12.9207C24.9646 12.9207 24.8856 12.7622 24.8856 12.6037V0.396358C24.8856 0.158553 25.0436 0.0792847 25.2016 0.0792847H30.2577C31.3637 0.0792847 32.3908 0.554894 33.1018 1.34758C33.8128 2.14026 34.2078 3.17075 34.2078 4.20123C34.2078 5.23172 33.8128 6.26221 33.0228 7.05489C32.3118 7.84758 31.2847 8.32318 30.0997 8.32318H27.2556V12.6037C27.2556 12.8415 27.1766 12.9207 26.9396 12.9207H25.2016ZM29.7837 6.10367C30.3367 6.10367 30.8107 5.94514 31.2057 5.5488C31.5217 5.23172 31.7587 4.75611 31.7587 4.20123C31.7587 3.64636 31.6007 3.17075 31.2057 2.85367C30.8107 2.45733 30.3367 2.2988 29.7837 2.2988H27.4136V6.10367H29.7837Z" fill={color} />
        <path d="M41.555 12.9207C41.318 12.9207 41.239 12.7621 41.239 12.6036V0.475541C41.239 0.237736 41.318 0.158468 41.555 0.158468H43.4511C43.6091 0.0791996 43.7671 0.237736 43.7671 0.475541V12.6036C43.7671 12.8414 43.6881 12.9207 43.4511 12.9207H41.555Z" fill={color} />
        <path d="M46.9271 12.9207C46.6901 12.9207 46.5321 12.7622 46.5321 12.5244V0.475626C46.5321 0.237821 46.6901 0.0792847 46.9271 0.0792847H48.9812C49.1392 0.0792847 49.3762 0.158553 49.4552 0.31709L55.1433 8.48172V0.31709C55.2223 0.158553 55.3803 0.0792847 55.4593 0.0792847H57.1974C57.4344 0.0792847 57.5924 0.237821 57.5924 0.475626V12.6037C57.5924 12.8415 57.4344 13 57.1974 13H55.4593C55.2223 13 55.1433 12.9207 54.9853 12.6829L49.0602 3.96343V12.5244C49.0602 12.7622 48.9812 12.9207 48.6652 12.9207H46.9271Z" fill={color} />
        <path d="M68.4947 12.9207C68.2577 12.9207 68.1787 12.8415 68.1787 12.6037V2.37806H64.9396C64.7026 2.37806 64.6236 2.2988 64.6236 2.06099V0.396358C64.6236 0.158553 64.7026 0.0792847 64.9396 0.0792847H74.0248C74.2619 0.0792847 74.3409 0.158553 74.3409 0.396358V2.06099C74.3409 2.2988 74.2619 2.37806 74.0248 2.37806H70.7068V12.6037C70.7068 12.8415 70.6278 12.9207 70.3907 12.9207H68.4947Z" fill={color} />
        <path d="M76.5529 12.9207C76.3159 12.9207 76.2369 12.7622 76.2369 12.6037V0.475612C76.2369 0.237807 76.3949 0.158539 76.5529 0.158539H78.449C78.607 0.158539 78.765 0.237807 78.765 0.475612V4.9939H84.5322V0.475612C84.5322 0.237807 84.6112 0.158539 84.8482 0.158539H86.7442C86.9022 0.158539 87.0602 0.237807 87.0602 0.475612V12.6829C87.0602 12.9207 86.9812 13 86.6652 13H84.8482C84.6112 13 84.5322 12.8415 84.5322 12.6829V7.37195H78.765V12.6037C78.765 12.8415 78.686 12.9207 78.37 12.9207H76.5529Z" fill={color} />
        <path d="M89.9043 12.9207C89.6673 12.9207 89.5883 12.8415 89.5883 12.6037V0.396358C89.5883 0.158553 89.6673 0.0792847 89.9043 0.0792847H98.3574C98.5945 0.0792847 98.6735 0.158553 98.6735 0.396358V2.06099C98.6735 2.2988 98.5154 2.37806 98.3574 2.37806H92.1163V4.99392H96.8564C97.0934 4.99392 97.2514 5.07319 97.2514 5.31099V6.97562C97.2514 7.21343 97.0934 7.2927 96.8564 7.2927H92.1163V10.4634H98.5155C98.7525 10.4634 98.9105 10.5427 98.9105 10.7805V12.4451C98.9105 12.6829 98.7525 12.7622 98.5155 12.7622H89.9043V12.9207Z" fill={color} />
        <path d="M105.389 12.9207C105.231 12.9207 105.073 12.8415 105.073 12.6829C105.073 12.6037 105.073 12.6037 105.152 12.5244L110.05 0.317073C110.129 0.0792683 110.287 0 110.445 0H111.867C112.104 0 112.183 0.0792683 112.341 0.317073L117.239 12.5244C117.239 12.6037 117.239 12.6037 117.239 12.6829C117.239 12.8415 117.16 12.9207 117.002 12.9207H115.106C114.869 12.9207 114.79 12.8415 114.711 12.6037L113.842 10.5427H108.549L107.759 12.6037C107.68 12.8415 107.522 12.9207 107.364 12.9207H105.389ZM113.052 8.32317L111.235 3.40854L109.418 8.32317H113.052Z" fill={color} />
        <path d="M119.53 12.9207C119.293 12.9207 119.214 12.7622 119.214 12.6037V0.475612C119.214 0.237807 119.293 0.158539 119.53 0.158539H121.426C121.663 0.158539 121.742 0.317075 121.742 0.475612V12.6037C121.742 12.8415 121.663 12.9207 121.426 12.9207H119.53Z" fill={color} />
        <path d="M124.823 12.9207C124.586 12.9207 124.507 12.8415 124.507 12.6037V0.396358C124.507 0.158553 124.665 0.0792847 124.823 0.0792847H130.274C131.459 0.0792847 132.486 0.554894 133.197 1.34758C133.908 2.14026 134.303 3.17075 134.303 4.2805C134.303 4.99392 134.066 5.62806 133.671 6.34148C133.276 6.97562 132.723 7.45123 132.091 7.84758L134.461 12.5244C134.461 12.6037 134.461 12.6037 134.461 12.6829C134.461 12.8415 134.382 12.9207 134.224 12.9207H132.012C131.775 12.9207 131.696 12.8415 131.617 12.6037L129.563 8.48172H126.956V12.6037C126.956 12.8415 126.877 12.9207 126.64 12.9207H124.823ZM129.721 6.18294C130.274 6.18294 130.827 5.94514 131.222 5.62806C131.617 5.31099 131.854 4.75611 131.854 4.2805C131.854 3.80489 131.617 3.25002 131.222 2.93294C130.827 2.61587 130.353 2.37806 129.721 2.37806H127.035V6.26221H129.721V6.18294Z" fill={color} />
      </svg>,
      hotels: <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.5 21H2.5V3H21.5V21H15.5H8.5ZM8.5 19H4.5V5H19.5V19H15.5V13H8.5V19ZM10.5 19H13.5V15H10.5V19ZM8.5 9V7H6.5V9H8.5ZM8.5 10V12H6.5V10H8.5ZM11.5 9V7H9.5V9H11.5ZM11.5 10V12H9.5V10H11.5ZM14.5 9V7H12.5V9H14.5ZM14.5 10V12H12.5V10H14.5ZM17.5 9V7H15.5V9H17.5ZM17.5 10V12H15.5V10H17.5Z" fill={color} /></svg>,
      hotel: <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.00011 22H20V3L4.00011 3L4.00011 22ZM18 20V5L6 5L6 20H9L9 14H15.0001V20H18ZM13 16V20H11L11 16H13Z" fill={color} />
        <path d="M8 7H10V9H8V7Z" fill={color} />
        <path d="M11 7L13 7V9H11V7Z" fill={color} />
        <path d="M14 7H16V9H14V7Z" fill={color} />
        <path d="M8 10H10V12H8V10Z" fill={color} />
        <path d="M11 10H13V12H11V10Z" fill={color} />
        <path d="M14 10H16V12H14V10Z" fill={color} />
      </svg>,
      airport: <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.82947 14.2187C8.57203 13.3217 9.47429 12.2925 10.4323 11.235L3.3253 6.36145L4.87501 4.81173C5.12144 4.5653 5.48595 4.47925 5.81657 4.58946L14.0796 7.34381C14.6029 6.80333 15.1029 6.29465 15.5616 5.83596C17.8638 3.53383 20.4578 2.6747 20.8916 3.10843C21.3253 3.54217 20.4662 6.13624 18.164 8.43837C17.6887 8.91371 17.1597 9.4335 16.5972 9.97747L19.1109 18.5242C19.2059 18.8472 19.1169 19.1963 18.8788 19.4344L17.6386 20.6747L12.765 13.5677C11.7075 14.5257 10.6783 15.428 9.78128 16.1705C9.7073 16.2318 9.63462 16.2913 9.56322 16.3493L10.3556 19.5187C10.434 19.8322 10.3421 20.1639 10.1136 20.3925L9.50602 21L7.40469 17.848C6.55558 18.2939 6.12096 18.2655 5.92771 18.0723C5.73446 17.879 5.70606 17.4444 6.152 16.5953L3 14.494L3.60754 13.8864C3.83607 13.6579 4.16775 13.566 4.4813 13.6444L7.65073 14.4368C7.70866 14.3654 7.76823 14.2927 7.82947 14.2187Z" fill={color} /></svg>,
      aircraft: <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.1797 2.82762C18.3451 1.77693 20.1814 1.65986 21.3848 2.86324C22.5881 4.0666 22.4711 5.90292 21.4204 7.06838C20.6134 7.9635 19.7115 8.86496 19.0166 9.53825C18.7376 9.80848 18.4905 10.0434 18.2942 10.2282L21.1147 18.6897L17.6507 22.1537L13.4081 15.0826L11.8575 16.6332L12.5027 20.1068L10.0092 22.6003L1.58578 14.1769L4.08554 11.6772L7.62107 12.3843L9.16542 10.84L2.09435 6.59731L5.55833 3.13333L14.0198 5.95381C14.2046 5.7575 14.4395 5.51038 14.7097 5.23145C15.383 4.53648 16.2845 3.63462 17.1797 2.82762ZM15.0523 7.78003L14.6142 8.26012L6.09852 5.42158L5.31986 6.20024L12.3909 10.4429L8.27842 14.5554L4.74289 13.8483L4.41421 14.1769L10.0092 19.7719L10.3441 19.437L9.69886 15.9634L13.8051 11.8571L18.0478 18.9282L18.8264 18.1495L15.9879 9.63388L16.468 9.19573L16.4727 9.19141L16.4884 9.177L16.5512 9.11915C16.6065 9.06811 16.6877 8.99279 16.7903 8.89678C16.9956 8.70468 17.286 8.43014 17.6249 8.10181C18.3049 7.443 19.1709 6.57669 19.935 5.72917C20.3635 5.25387 20.3293 4.63619 19.9706 4.27745C19.6118 3.91874 18.9942 3.88455 18.5188 4.31308C17.6713 5.07715 16.805 5.94305 16.1462 6.62308C15.8179 6.96198 15.5433 7.25237 15.3512 7.45765C15.2552 7.56025 15.1799 7.64148 15.1289 7.69676L15.071 7.75959L15.0566 7.77531L15.0523 7.78003Z" fill={color} />
      </svg>,
      city: <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 3C8.13401 3 5 6.13401 5 10C5 12.4714 6.98858 15.8507 10.9317 20.3044L12 21.4973L12.7443 20.6679C16.9025 16.0341 19 12.5382 19 10C19 6.13401 15.866 3 12 3ZM12 5C14.7614 5 17 7.23858 17 10L16.9958 10.1553C16.907 11.8399 15.3971 14.4844 12.4424 17.9725L12 18.487L11.848 18.3127C8.60647 14.5452 7 11.7277 7 10C7 7.23858 9.23858 5 12 5ZM12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z" fill={color} />
      </svg>,
      subway: <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.00009 14.6918C5 14.6918 5 14.6918 5 14.6918L5 14.6895L5.00001 14.6844L5.0001 14.6673L5.00072 14.6064C5.00141 14.5544 5.00276 14.4799 5.00543 14.3862C5.01075 14.1989 5.02132 13.9338 5.04237 13.6165C5.0842 12.9856 5.1685 12.1314 5.34061 11.2664C5.50947 10.4176 5.77775 9.47884 6.226 8.72781C6.67675 7.97263 7.4287 7.23071 8.5711 7.23071L15.429 7.23075C16.5948 7.23075 17.3444 8.02104 17.7844 8.79339C18.2272 9.57062 18.4933 10.5442 18.6612 11.4288C18.8324 12.33 18.9163 13.2206 18.9579 13.8791C18.971 14.087 18.9801 14.2735 18.9863 14.4317C18.9954 14.5266 19 14.6211 19 14.7143V14.9973L19 14.999L19 17.0001C19 18.3809 18.0671 19.5438 16.7972 19.8929L17.832 21.4452L16.1679 22.5546L14.4649 20H9.53513L7.83207 22.5546L6.16797 21.4452L7.20282 19.8929C5.93291 19.5438 5.00002 18.3808 5.00002 17L5 14.7143C5 14.7068 5.00003 14.6993 5.00009 14.6918ZM16.9895 14.5542C16.992 14.6216 16.9939 14.6825 16.9954 14.7361C16.9977 14.8235 16.9989 14.8914 16.9995 14.9365L16.9999 14.9866L17 14.9997V17.0001C17 17.5523 16.5523 18 16 18H8.00004C7.44774 18 7.00002 17.5523 7.00002 17L7 14.7143C6.99999 14.445 7.08589 14.2369 7.17986 14.116C7.22565 14.0572 7.26667 14.027 7.29207 14.0132C7.31508 14.0007 7.32726 14 7.33333 14H16.6667C16.6727 14 16.6849 14.0007 16.7079 14.0132C16.7333 14.027 16.7743 14.0572 16.8201 14.116C16.8945 14.2117 16.9639 14.3621 16.9895 14.5542ZM16.7326 12.0009C16.721 11.9346 16.7089 11.8682 16.6964 11.8019C16.543 10.9942 16.327 10.2755 16.0467 9.78348C15.7636 9.28661 15.5489 9.23075 15.429 9.23075L8.5711 9.23071C8.42795 9.23071 8.21574 9.29649 7.94336 9.75284C7.6685 10.2134 7.4547 10.89 7.30217 11.6566C7.27927 11.7717 7.25809 11.887 7.23849 12.002C7.2698 12.0007 7.30142 12 7.33334 12H16.6667C16.6888 12 16.7108 12.0003 16.7326 12.0009Z" fill={color}/>
        <path d="M14 15H16V17H14V15Z" fill="black"/>
        <path d="M8 15H10V17H8V15Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0499 3C6.52375 3 3 6.99685 3 12V22H1V12C1 5.95746 5.35511 1 12.0499 1C18.7579 1 23 5.97126 23 12V22H21V12C21 6.98304 17.5627 3 12.0499 3Z" fill={color}/>
      </svg>,
      person: <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.2 4H13.8C14.4627 4 15 4.51167 15 5.14286V10.8571C15 11.4883 14.4627 12 13.8 12H10.2C9.53726 12 9 11.4883 9 10.8571V5.14286C9 4.51167 9.53726 4 10.2 4Z" stroke={color} strokeWidth="2" />
        <path d="M4.89474 21V19C4.89474 17.9456 5.66767 17.0818 6.64807 17.0055L6.78947 17H17.2105C18.2094 17 19.0277 17.8159 19.1001 18.8507L19.1053 19V21H21V19C21 16.8578 19.4047 15.1089 17.3997 15.0049L17.2105 15H6.78947C4.76003 15 3.10319 16.684 3.00464 18.8004L3 19V21H4.89474Z" fill={color} />
      </svg>,
      person_add: <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 14.252V16.342C13.0949 16.022 12.1263 15.9239 11.1754 16.0558C10.2245 16.1877 9.3192 16.5459 8.53543 17.1002C7.75166 17.6545 7.11234 18.3888 6.67116 19.2414C6.22998 20.094 5.99982 21.04 6 22L4 21.999C3.99969 20.7779 4.27892 19.5729 4.8163 18.4764C5.35368 17.3799 6.13494 16.4209 7.10022 15.673C8.0655 14.9251 9.18918 14.4081 10.3852 14.1616C11.5811 13.9152 12.8177 13.9457 14 14.251V14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM18 17V14H20V17H23V19H20V22H18V19H15V17H18Z" fill={color} />
      </svg>,
      search: <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.88 15.4448C18.5901 14.2988 19 12.9473 19 11.5C19 7.35786 15.6421 4 11.5 4C7.35786 4 4 7.35786 4 11.5C4 15.6421 7.35786 19 11.5 19C13.4708 19 15.264 18.2399 16.6025 16.9968L19.2903 19.7045L20.7097 18.2955L17.88 15.4448ZM11.5 17C8.46243 17 6 14.5376 6 11.5C6 8.46243 8.46243 6 11.5 6C14.5376 6 17 8.46243 17 11.5C17 14.5376 14.5376 17 11.5 17Z" fill={color} />
      </svg>,
      fee: <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8.30762 12.207H7.75586V11.3867C6.51562 11.2939 5.53418 10.5957 5.5 9.42383H6.51074C6.59863 9.99512 6.96973 10.4199 7.75586 10.498V8.36914L7.55566 8.32031C6.31543 8.03223 5.64648 7.47559 5.64648 6.48438C5.64648 5.38574 6.52539 4.66797 7.75586 4.5752V3.75H8.30762V4.5752C9.52832 4.67285 10.4121 5.40527 10.4512 6.51367H9.43555C9.37207 5.89844 8.93262 5.54199 8.30762 5.46387V7.5L8.54199 7.55859C9.82617 7.85645 10.5293 8.38379 10.5293 9.42383C10.5293 10.625 9.55274 11.2988 8.30762 11.3867V12.207ZM7.75586 5.46387C7.05273 5.54688 6.68164 5.95215 6.68164 6.40137C6.68164 6.8457 6.97461 7.17285 7.75586 7.37305V5.46387ZM8.30762 10.5029C9.14258 10.4395 9.49414 10.0439 9.49414 9.52148C9.49414 9.01367 9.21582 8.71582 8.30762 8.50098V10.5029Z" fill={color} />
      </svg>,
      included: <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z" fill={color} />
      </svg>,
      not_available: <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z" fill={color} />
      </svg>,
      close: <svg className={className} width={width || "16"} height={height || "16"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z" fill={color} />
      </svg>,
      checked: <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.41 6L7 9.58L13.59 3L15 4.41L7 12.41L2 7.41L3.41 6Z" fill={color} />
      </svg>,
      history: <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM8 2.55556C11.0069 2.55556 13.4444 4.99312 13.4444 8C13.4444 11.0069 11.0069 13.4444 8 13.4444C4.99312 13.4444 2.55556 11.0069 2.55556 8C2.55556 4.99312 4.99312 2.55556 8 2.55556ZM8.3885 7.99883L8.38889 5.66667H6.83333V9.55556H10.7222V8L8.3885 7.99883Z" fill={color} />
      </svg>,
      cancel: <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM4.29289 5.70711L6.58579 8L4.29289 10.2929L5.70711 11.7071L8 9.41421L10.2929 11.7071L11.7071 10.2929L9.41421 8L11.7071 5.70711L10.2929 4.29289L8 6.58579L5.70711 4.29289L4.29289 5.70711Z" fill={color} />
      </svg>,
      global: <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM9.71 19.667C8.72341 17.5743 8.15187 15.3102 8.027 13H4.062C4.25659 14.5389 4.89392 15.9882 5.89657 17.1717C6.89922 18.3552 8.22401 19.2221 9.71 19.667ZM10.03 13C10.181 15.439 10.878 17.73 12 19.752C13.1523 17.6766 13.8254 15.3695 13.97 13H10.03ZM19.938 13H15.973C15.8481 15.3102 15.2766 17.5743 14.29 19.667C15.776 19.2221 17.1008 18.3552 18.1034 17.1717C19.1061 15.9882 19.7434 14.5389 19.938 13ZM4.062 11H8.027C8.15187 8.68979 8.72341 6.42569 9.71 4.333C8.22401 4.77788 6.89922 5.64475 5.89657 6.8283C4.89392 8.01184 4.25659 9.4611 4.062 11ZM10.031 11H13.969C13.8248 8.6306 13.152 6.32353 12 4.248C10.8477 6.32345 10.1746 8.63052 10.03 11H10.031ZM14.29 4.333C15.2766 6.42569 15.8481 8.68979 15.973 11H19.938C19.7434 9.4611 19.1061 8.01184 18.1034 6.8283C17.1008 5.64475 15.776 4.77788 14.29 4.333Z" fill={color} />
      </svg>,
      edit: <svg className={className} width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.873 477.873">
        <path d="M392.533 238.937c-9.426 0-17.067 7.641-17.067 17.067V426.67c0 9.426-7.641 17.067-17.067 17.067H51.2c-9.426 0-17.067-7.641-17.067-17.067V85.337c0-9.426 7.641-17.067 17.067-17.067H256c9.426 0 17.067-7.641 17.067-17.067S265.426 34.137 256 34.137H51.2C22.923 34.137 0 57.06 0 85.337V426.67c0 28.277 22.923 51.2 51.2 51.2h307.2c28.277 0 51.2-22.923 51.2-51.2V256.003c0-9.425-7.641-17.066-17.067-17.066z" />
        <path d="M458.742 19.142A65.328 65.328 0 00412.536.004a64.85 64.85 0 00-46.199 19.149L141.534 243.937a17.254 17.254 0 00-4.113 6.673l-34.133 102.4c-2.979 8.943 1.856 18.607 10.799 21.585 1.735.578 3.552.873 5.38.875a17.336 17.336 0 005.393-.87l102.4-34.133c2.515-.84 4.8-2.254 6.673-4.13l224.802-224.802c25.515-25.512 25.518-66.878.007-92.393zm-24.139 68.277L212.736 309.286l-66.287 22.135 22.067-66.202L390.468 43.353c12.202-12.178 31.967-12.158 44.145.044a31.215 31.215 0 019.12 21.955 31.043 31.043 0 01-9.13 22.067z" />
      </svg>,
      'ru-ru': <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" rx="12" fill="#fff" /><mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" rx="12" fill="#fff" /></mask><g mask="url(#a)" fillRule="evenodd" clipRule="evenodd"><path d="M0 24h24v-8H0v8z" fill="#EF5362" /><path d="M0 16h24V8H0v8z" fill="#4A89DC" /></g></svg>,
      'en-us': <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" rx="12" fill="#fff" /><mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" rx="12" fill="#fff" /></mask><g mask="url(#a)"><path fill="#EF5362" d="M0 19.333h36V24H0zM13.333 10H34v4.667H13.333zM13.333 0H34v5.333H13.333z" /><path fill="#4A89DC" d="M0 0h13.333v14.667H0z" /><path fillRule="evenodd" clipRule="evenodd" d="M7.621 11.936c-.183.13-.29.056-.237-.163l.198-.819a.741.741 0 00-.213-.668L6.77 9.77c-.17-.148-.126-.274.099-.28l.838-.024a.602.602 0 00.523-.406l.262-.894c.064-.217.18-.22.255-.007l.316.882a.633.633 0 00.546.39l.855.01c.224.002.27.125.1.273l-.65.57a.707.707 0 00-.195.665l.208.72c.063.218-.042.298-.234.18L9 11.42a.672.672 0 00-.682.022l-.696.495zM1.621 11.936c-.183.13-.29.056-.237-.163l.198-.819a.741.741 0 00-.213-.668L.77 9.77C.6 9.62.644 9.495.87 9.489l.838-.024a.602.602 0 00.523-.406l.263-.894c.063-.217.178-.22.254-.007l.316.882a.633.633 0 00.546.39l.855.01c.224.002.27.125.1.273l-.65.57a.707.707 0 00-.195.665l.208.72c.063.218-.042.298-.234.18L3 11.42a.672.672 0 00-.682.022l-.696.495zM7.621 5.936c-.183.13-.29.056-.237-.163l.198-.819a.741.741 0 00-.213-.668L6.77 3.77c-.17-.148-.126-.274.099-.28l.838-.024a.602.602 0 00.523-.406l.262-.894c.064-.217.18-.22.255-.007l.316.882a.633.633 0 00.546.39l.855.01c.224.002.27.125.1.273l-.65.57a.707.707 0 00-.195.665l.208.72c.063.218-.042.298-.234.18L9 5.42a.672.672 0 00-.682.022l-.696.495zM1.621 5.936c-.183.13-.29.056-.237-.163l.198-.819a.741.741 0 00-.213-.668L.77 3.77C.6 3.62.644 3.495.87 3.489l.838-.024a.602.602 0 00.523-.406l.263-.894c.063-.217.178-.22.254-.007l.316.882a.633.633 0 00.546.39l.855.01c.224.002.27.125.1.273l-.65.57a.707.707 0 00-.195.665l.208.72c.063.218-.042.298-.234.18L3 5.42a.672.672 0 00-.682.022l-.696.495z" fill="#fff" /></g></svg>,
      orders: <svg width="20" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M20 0v16H0V0h20zm-6 14V2h4v12h-4zM2 2h10v12H2V2z" fill="#000" /><path d="M10 4H4v2h6V4zM8 8H4v2h4V8z" fill="#000" /></svg>,
      portal: <svg className={className} width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M17 3H7v4H2v14h20V7h-5V3Zm-2 4V5H9v2h6ZM7 9H4v10h16V9H7Z" fill="#000"/></svg>,
      logout: <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 4v2h5v13H1v2h7V4H1z" fill="#000" /><path d="M21 12l-4-4m4 4l-4 4m4-4H11" stroke="#000" strokeWidth="2" strokeLinecap="square" /></svg>,
      star: <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.00016 12.4417L3.60254 14.4852C3.55513 14.5073 3.50196 14.4686 3.50826 14.4167L4.09285 9.60285L0.790381 6.05196C0.754777 6.01367 0.775084 5.95117 0.826391 5.94113L5.58531 5.00953L7.9419 0.771411C7.9673 0.725719 8.03302 0.725719 8.05843 0.771411L10.415 5.00953L15.1739 5.94113C15.2252 5.95117 15.2455 6.01367 15.2099 6.05196L11.9075 9.60285L12.4921 14.4167C12.4984 14.4686 12.4452 14.5073 12.3978 14.4852L8.00016 12.4417Z" fill={color} />
      </svg>,
      image: <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 5H4V19L13.292 9.706C13.4795 9.51853 13.7338 9.41321 13.999 9.41321C14.2642 9.41321 14.5185 9.51853 14.706 9.706L20 15.01V5ZM2 3.993C2.00183 3.73038 2.1069 3.47902 2.29251 3.29322C2.47813 3.10742 2.72938 3.00209 2.992 3H21.008C21.556 3 22 3.445 22 3.993V20.007C21.9982 20.2696 21.8931 20.521 21.7075 20.7068C21.5219 20.8926 21.2706 20.9979 21.008 21H2.992C2.72881 20.9997 2.4765 20.895 2.29049 20.7088C2.10448 20.5226 2 20.2702 2 20.007V3.993ZM8 11C7.46957 11 6.96086 10.7893 6.58579 10.4142C6.21071 10.0391 6 9.53043 6 9C6 8.46957 6.21071 7.96086 6.58579 7.58579C6.96086 7.21071 7.46957 7 8 7C8.53043 7 9.03914 7.21071 9.41421 7.58579C9.78929 7.96086 10 8.46957 10 9C10 9.53043 9.78929 10.0391 9.41421 10.4142C9.03914 10.7893 8.53043 11 8 11Z" fill={color} />
      </svg>,
      aircond: <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 2L8 14" stroke="#8F8F8F" strokeWidth="2" />
        <path d="M14 8L2 8" stroke="#8F8F8F" strokeWidth="2" />
        <path d="M12 4L8 8L4 12" stroke="#8F8F8F" strokeWidth="2" strokeLinecap="square" />
        <path d="M4 4L8 8L12 12" stroke="#8F8F8F" strokeWidth="2" strokeLinecap="square" />
      </svg>,
      bathroom: <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14 8.1V1H7.02604V4H6.5C5.94772 4 5.5 4.44772 5.5 5C5.5 5.55228 5.94772 6 6.5 6H9.5C10.0523 6 10.5 5.55228 10.5 5C10.5 4.44772 10.0523 4 9.5 4H9.02602L9.026 3H12V8.1H1L1.75469 13.3828C1.89545 14.3681 2.73929 15.1 3.73459 15.1H12.2654C13.2607 15.1 14.1046 14.3681 14.2453 13.3828L15 8.1H14Z" fill="#8F8F8F" />
      </svg>,
      wifi: <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="12.5" r="1.5" fill="#8F8F8F" />
        <path d="M1 5.80264C1.41156 5.56007 4.20587 4 7.99991 4C11.7945 4 14.5891 5.56052 15 5.80275M3.02775 9.33164C4.36905 8.44388 6.51314 8 8 8C9.48684 8 11.6307 8.44387 12.972 9.33161" stroke="#8F8F8F" strokeWidth="2" strokeLinejoin="bevel" />
      </svg>,
      food: <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16 6.00002C16 5.48293 16.1663 5.18541 16.3321 5.01961C16.5073 4.8444 16.7474 4.75 17 4.75C17.2526 4.75 17.4927 4.8444 17.6679 5.0196C17.8337 5.1854 18 5.48292 18 6.00001V11C18 11.5042 17.8405 11.8305 17.6276 12.07C17.3963 12.3302 17.0565 12.5358 16.6489 12.6887C16.4312 12.7703 16.2094 12.8312 16 12.8763V6.00002ZM14 6.00002V13.9999V22H16V14.9099C16.3888 14.8486 16.8652 14.7435 17.3511 14.5613C17.9435 14.3392 18.6037 13.9823 19.1224 13.3987C19.6595 12.7945 20 11.9959 20 11V6.00001C20 5.01709 19.6663 4.1896 19.0821 3.60539C18.5073 3.0306 17.7474 2.75 17 2.75C16.2526 2.75 15.4927 3.03061 14.9179 3.6054C14.3337 4.18961 14 5.0171 14 6.00002ZM6 3V4V10C6 10.0372 6.01732 10.1532 6.20485 10.3571C6.39053 10.5591 6.67014 10.7648 7.00117 10.9565C7.32479 11.144 7.65581 11.2942 7.91037 11.3985C7.94166 11.4113 7.9716 11.4234 8 11.4346C8.0284 11.4234 8.05834 11.4113 8.08963 11.3985C8.34419 11.2942 8.67521 11.144 8.99883 10.9565C9.32986 10.7648 9.60947 10.5591 9.79515 10.3571C9.98268 10.1532 10 10.0372 10 10V4V3H12V4.00005V10C12 10.7253 11.6423 11.3031 11.2674 11.7109C10.8905 12.1207 10.4201 12.4446 10.0012 12.6872C9.64143 12.8956 9.28696 13.0626 9 13.1854V22H7V13.1854C6.71304 13.0626 6.35857 12.8956 5.99883 12.6872C5.57986 12.4446 5.10947 12.1207 4.73265 11.7109C4.35768 11.3031 4 10.7253 4 10V4V3H6ZM9 3V10H7V3H9Z" fill={color} />
      </svg>,
      hotel_pointer: <svg className={className} width="56px" height="64px" viewBox="0 0 56 64" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="hotel-mark">
            <rect id="Rectangle" fill="#000000" fillRule="nonzero" x="0" y="0" width="56" height="56" rx="12" />
            <path d="M37,37 L37,19 L19,19 L19,37 L25,37 L25,32.5 L31,32.5 L31,37 L37,37 Z M25,25 L25,22 L22,22 L22,25 L25,25 Z M29.5,22 L29.5,25 L26.5,25 L26.5,22 L29.5,22 Z M34,25 L34,22 L31,22 L31,25 L34,25 Z M25,26.5 L25,29.5 L22,29.5 L22,26.5 L25,26.5 Z M29.5,29.5 L29.5,26.5 L26.5,26.5 L26.5,29.5 L29.5,29.5 Z M34,29.5 L34,26.5 L31,26.5 L31,29.5 L34,29.5 Z" id="Shape" fill="#FFFFFF" />
            <polygon id="Path" fill="#000000" fillRule="nonzero" points="36 56 20 56 28 64" />
          </g>
        </g>
      </svg>,
      singlebed: <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 7H10V15H4V5H2V20H4V17H20V20H22V11C22 8.79 20.21 7 18 7ZM20 15H12V9H18C19.1 9 20 9.9 20 11V15Z" fill="black"/>
        <path d="M8.75 11.25C8.75 12.2165 7.9665 13 7 13C6.0335 13 5.25 12.2165 5.25 11.25C5.25 10.2835 6.0335 9.5 7 9.5C7.9665 9.5 8.75 10.2835 8.75 11.25Z" fill="black"/>
      </svg>,
      doublebad:<svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 19V10.8H3V5H21V10.8H22V19H20V17H4V19H2ZM13 10H19V7H13V10ZM5 10H11V7H5V10ZM4 15H20V12H4V15ZM20 15H4H20Z" fill="black"/>
      </svg>,
      twinbed: <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.75 11.25C8.75 12.2165 7.9665 13 7 13C6.0335 13 5.25 12.2165 5.25 11.25C5.25 10.2835 6.0335 9.5 7 9.5C7.9665 9.5 8.75 10.2835 8.75 11.25Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18 7H10V13.124C10.5075 12.2094 11.1891 11.4047 12 10.7546V9H17H18C18.3401 9 18.6611 9.08603 18.9422 9.23739C20.0734 9.5195 21.1092 10.0424 21.9922 10.7484C21.8625 8.65573 20.1255 7 18 7ZM9 17C9 16.3094 9.08751 15.6392 9.25204 15H4V5H2V20H4V17H9Z" fill="black"/>
        <path d="M18 9H17C17.6698 9 18.3204 9.08232 18.9422 9.23739C18.6611 9.08603 18.3401 9 18 9Z" fill="black"/>
        <path d="M15.0837 19.3802H18.9241V18.2954H16.5636L16.9587 18.5532V17.9471L16.5636 18.5532L17.6451 17.6659C17.942 17.4226 18.1797 17.2005 18.3583 16.9996C18.5368 16.7987 18.6652 16.6079 18.7433 16.4271C18.8237 16.2463 18.8638 16.0655 18.8638 15.8846V15.8679C18.8638 15.5733 18.7857 15.3177 18.6295 15.1012C18.4732 14.8824 18.2533 14.7139 17.9699 14.5956C17.6886 14.475 17.356 14.4148 16.9721 14.4148C16.577 14.4148 16.231 14.484 15.9342 14.6224C15.6395 14.7585 15.4096 14.9505 15.2444 15.1983C15.0815 15.4438 15 15.7329 15 16.0655V16.0922H16.2924V16.0621C16.2924 15.946 16.3192 15.8434 16.3728 15.7541C16.4263 15.6648 16.5 15.5945 16.5938 15.5431C16.6897 15.4918 16.7991 15.4661 16.9219 15.4661C17.0402 15.4661 17.1417 15.4862 17.2266 15.5264C17.3136 15.5666 17.3795 15.6246 17.4241 15.7005C17.471 15.7764 17.4944 15.8668 17.4944 15.9717V15.9884C17.4944 16.0733 17.4676 16.1625 17.4141 16.2563C17.3627 16.35 17.2701 16.4661 17.1362 16.6045C17.0022 16.7407 16.8114 16.917 16.5636 17.1335L15.0837 18.436V19.3802Z" fill="black"/>
        <path d="M17 11.75C14.1005 11.75 11.75 14.1005 11.75 17C11.75 19.8995 14.1005 22.25 17 22.25C19.8995 22.25 22.25 19.8995 22.25 17C22.25 14.1005 19.8995 11.75 17 11.75Z" stroke="black" stroke-width="2"/>
      </svg>
      ,
      personmax: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.00009 1.51953H9.00009C10.1047 1.51953 11.0001 2.37425 11.0001 3.42862V6.61043C11.0001 7.6648 10.1047 8.51953 9.00009 8.51953H7.00009C5.89552 8.51953 5.00009 7.6648 5.00009 6.61043V3.42862C5.00009 2.37425 5.89552 1.51953 7.00009 1.51953Z" fill="#8F8F8F"/>
        <path d="M13.9475 12.6667V14H2.05273V12.6667C2.12938 11.2557 3.42164 9.99999 5.0001 9.99999H11.0001C12.5595 10.0693 13.9475 11.2385 13.9475 12.6667Z" fill="#8F8F8F"/>
      </svg>

    },
    arrow: {
      detail: <svg className={className} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z" fill={color} />
      </svg>,
      down: <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 10.58L4 6H12L8 10.58Z" fill="#ccc" />
      </svg>,
      right: <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.295 3.41L9.875 8L5.295 12.59L6.705 14L12.705 8L6.705 2L5.295 3.41Z" fill={color} />
      </svg>,
      direction: <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.05 3.59999L8.45 2.39999L5 6.99999L8.45 11.6L10.05 10.4L8.25 8H19V6H8.24999L10.05 3.59999Z" fill={color} />
        <path d="M14.2 13.6L15.8 12.4L19.25 17L15.8 21.6L14.2 20.4L16 18H5V16H16L14.2 13.6Z" fill={color} />
      </svg>,
      back: <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.5451 3H11.7335L2.57471 12.2698L11.6933 21H14.5237L14.5532 20.9692L6.22931 13H19V11H6.64039L14.5451 3Z" fill={color} />
      </svg>,
      next: <svg className={className} width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.4311 0H13.2426L20.4253 7.2698L13.3957 14H10.5037L16.7707 8H0V6H16.3597L10.4311 0Z" fill={color} />
      </svg>,
      sort: <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.9978 13.8359L4.57007 10.4009H7.36792L7.37524 2H8.64233L8.63501 10.4009H11.4329L7.9978 13.8359Z" fill={color} />
      </svg>,
      aircraft: <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.86479 6.99788C2.93393 6.99082 3.00463 6.98395 3.07692 6.97727C3.95347 6.89634 4.98608 6.82982 6.06361 6.77771L4.86957 0.5L6.52658 0.5C6.79008 0.5 7.03096 0.645911 7.1488 0.8769L10.0939 6.6499C10.6626 6.64087 11.2019 6.63636 11.6923 6.63636C14.1538 6.63636 16 7.54545 16 8C16 8.45455 14.1538 9.36364 11.6923 9.36364C11.1841 9.36364 10.6233 9.35879 10.0318 9.3491L6.80644 15.1447C6.68454 15.3637 6.4503 15.5 6.19573 15.5H4.86957L6.06361 9.22229C4.98608 9.17018 3.95347 9.10366 3.07692 9.02273C3.00463 9.01605 2.93393 9.00918 2.86479 9.00212L1.59395 11.0781C1.46823 11.2834 1.24179 11.4091 0.997433 11.4091H0.347826L0.909535 8.6564C0.217171 8.44514 0 8.20252 0 8C0 7.79748 0.217171 7.55486 0.909535 7.3436L0.347826 4.59091H0.997433C1.24179 4.59091 1.46823 4.71657 1.59395 4.92193L2.86479 6.99788Z" fill={color} />
      </svg>,
      left: <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 3.41L7.42 8L12 12.59L10.59 14L4.59 8L10.59 2L12 3.41Z" fill={color} />
      </svg>,
      'full-map': <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 4.53564L15.5355 1.00011L16.9497 2.41432L13.4142 5.94986L12 4.53564Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0H6.33333V2H2V6.33333H0V0ZM16 2H11.6667V0H18V6.33333H16V2ZM2 16V11.6667H0V18H6.33333V16H2ZM16 16V11.6667H18V18H11.6667V16H16Z" fill="black" />
        <path d="M12 4.53552L15.5355 0.999989L16.9497 2.4142L13.4142 5.94974L12 4.53552Z" fill="black" />
        <path d="M5.94975 4.53552L2.41421 0.999989L1 2.4142L4.53553 5.94974L5.94975 4.53552Z" fill="black" />
        <path d="M12 13.3787L15.5355 16.9142L16.9497 15.5L13.4142 11.9645L12 13.3787Z" fill="black" />
        <path d="M5.94975 13.3787L2.41421 16.9142L1 15.5L4.53553 11.9645L5.94975 13.3787Z" fill="black" />
      </svg>,
      'small-map': <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.6665 4.33333V0H11.6665V6.33333H17.9998V4.33333H13.6665Z" fill="black" />
        <path d="M17.6162 1.71201L14.0807 5.24755L12.6665 3.83333L16.202 0.297799L17.6162 1.71201Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4.33349 4.33333V0H6.33349V6.33333H0.000152588V4.33333H4.33349Z" fill="black" />
        <path d="M0.383739 1.71201L3.91927 5.24755L5.33349 3.83333L1.79795 0.297799L0.383739 1.71201Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4.33365 13.6667V18H6.33365V11.6667H0.000314713V13.6667H4.33365Z" fill="black" />
        <path d="M0.383902 16.288L3.91944 12.7525L5.33365 14.1667L1.79811 17.7022L0.383902 16.288Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13.6667 13.6667V18H11.6667V11.6667H18V13.6667H13.6667Z" fill="black" />
        <path d="M17.6164 16.288L14.0809 12.7525L12.6667 14.1667L16.2022 17.7022L17.6164 16.288Z" fill="black" />
      </svg>

    },
    card: {
      clear: <svg className={className} width="34px" height="24px" viewBox="0 0 34 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="card">
            <rect id="Rectangle" fill="#80848F" x="0" y="0" width="34" height="24" rx="4" />
            <rect id="Rectangle" fill="#EFEFF4" x="15" y="16" width="15" height="4" rx="2" />
          </g>
        </g>
      </svg>,
      corporatecard: <svg className={className} width="36px" height="24px" viewBox="0 0 36 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="corporateCard">
            <rect id="Rectangle" fill="#000000" fillRule="nonzero" x="0" y="0" width="36" height="24" rx="4" />
            <path d="M15.375,5.875 L21.625,5.875 L21.625,8.375 L24.75,8.375 L24.75,17.125 L12.25,17.125 L12.25,8.375 L15.375,8.375 L15.375,5.875 Z M20.375,7.125 L20.375,8.375 L16.625,8.375 L16.625,7.125 L20.375,7.125 Z M14.75,12.125 L22.25,12.125 L22.25,10.875 L14.75,10.875 L14.75,12.125 Z" id="Shape" fill="#FFFFFF" />
          </g>
        </g>
      </svg>,
      visa: <svg className={className} width="34" height="24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <rect fill="#FFF" width="34" height="24" rx="4" />
          <path fill="#4858A2" d="M15.05 8.752l-.983 6.018h1.572l.985-6.018z" />
          <g transform="translate(16 8)">
            <path d="M3.795 3.168c-.55-.278-.887-.464-.883-.745 0-.25.285-.518.9-.518a2.8 2.8 0 011.177.23l.142.07L5.344.9a3.908 3.908 0 00-1.41-.253c-1.554 0-2.649.816-2.658 1.986-.009.864.781 1.347 1.378 1.634.612.295.818.484.815.747-.004.404-.489.588-.941.588-.63 0-.964-.091-1.48-.316L.845 5.19.624 6.538c.368.168 1.047.314 1.752.32 1.654 0 2.727-.805 2.739-2.054.006-.685-.413-1.206-1.32-1.636z"
              fill="#4858A2" />
          </g>
          <g transform="translate(21 8)">
            <path d="M2.438 4.637a1074 1074 0 00.626-1.682c-.01.015.13-.349.209-.575l.106.52.364 1.737H2.438zM4.377.758H3.162c-.377 0-.658.107-.824.5L.002 6.771h1.652s.27-.742.331-.904l2.014.002c.047.21.192.902.192.902h1.46L4.376.758z"
              fill="#4858A2" />
          </g>
          <path d="M12.748 8.757l-1.54 4.103-.164-.833-.551-2.766c-.095-.381-.372-.495-.713-.508H7.244l-.02.126c.616.155 1.168.38 1.651.66l1.399 5.226 1.664-.002 2.476-6.006h-1.666z"
            fill="#4858A2" />
        </g>
      </svg>,
      mastercard: <svg className={className} width="40px" height="28px" viewBox="0 0 40 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="card-mastercard-dark">
            <rect id="Rectangle" fill="#363639" x="0" y="0" width="40" height="28" rx="4" />
            <ellipse id="Oval" fill="#FF5D5D" cx="14.8333" cy="14" rx="5.83333" ry="6" />
            <ellipse id="Oval" fill="#FFB224" cx="24.1667" cy="14" rx="5.83333" ry="6" />
          </g>
        </g>
      </svg>,
    }

  }
  if (group && icons[group]) {
    if (icons[group][icon]) {
      returnIcon = icons[group][icon]
    } else if (group === 'card') {
      returnIcon = icons[group]['clear']
    }
  } else if (icons[icon]) {
    returnIcon = icons[icon]
  }
  if (returnIcon === '') {
    console.error('icon not found, group: ' + group + ', icon: ' + icon)
  }
  return returnIcon
}
export default Icons
