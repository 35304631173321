import React from 'react'
import './style.sass'

const HotelName = ({ hotel }) => {
  return (
    <div className='hotelName'>
      {hotel.name}
    </div>
  )
}

export default HotelName
