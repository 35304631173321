import { useDispatch } from 'react-redux'
import { changeTime, clearTime } from '../../../reducers/filter/actions'

export default class TimeFilterController {
  constructor () {
    this.dispatch = useDispatch()
  }

  handleChangeSlider = (value, field) => {
    this.dispatch(changeTime(field, value.sort((a, b) => a - b)))
  }

  handleClearFilter = (field, e) => {
    e.stopPropagation()
    this.dispatch(clearTime(field))
  }

  changeActiveFilter = (filter, action) => {
    filter.departure.value[0] > filter.departure.limit[0] ||
    filter.departure.value[1] < filter.departure.limit[1] ||
    filter.arrival.value[0] > filter.arrival.limit[0] ||
    filter.arrival.value[1] < filter.arrival.limit[1]
      ? action(true)
      : action(false)
  }
}
