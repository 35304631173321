import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import currentLocation from '../../../../../hooks/currentLocation'
import CardController from '../../controller'
import Input from '../_input'

const NewCardForm = ({ bookingDetail }) => {
  const { t } = useTranslation()
  const payment = useSelector(state => state.paymentForm)
  const { isFlightsPage, isHotelsPage } = currentLocation()
  const controller = new CardController()
  const { card, billing, errors } = payment
  const baseInputs = useMemo(() => {
    return Object.keys(card).map(cardField => {
      if (cardField === 'expYear') return ''
      if (isHotelsPage && !bookingDetail.cvcRequired && cardField === 'cvc') return ''
      return (
        <Input
          key={cardField + 'card'}
          props={controller.getFieldProps(cardField)}
          reduxType='card'
          error={errors[cardField + '-error']}
        />
      )
    })
  }, [errors])
  const adressInputh = useMemo(() => {
    return Object.keys(billing).map(billingField => {
      return (
        <Input
          key={billingField + 'billing'}
          props={controller.getFieldProps(billingField)}
          reduxType='billing'
          error={errors[billingField + '-error']}
        />
      )
    })
  }, [errors])
  return (
    <>
      {(isFlightsPage || (isHotelsPage && bookingDetail.cardRequired)) &&
        <form className='form'>
          {baseInputs}
        </form>}
      {(isFlightsPage || (isHotelsPage && bookingDetail.addressRequired)) && (
        <>
          <div className='popup__subheader'>
            {t('Billing address')}
          </div>
          <form className='form'>
            {adressInputh}
          </form>
        </>
      )}
    </>
  )
}

export default NewCardForm
