import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import OptionReserveController from './controller'

const OptionReserve = ({ room, option }) => {
  const { t } = useTranslation()
  const clientMode = useSelector(state => state.user.clientMode)
  const controller = new OptionReserveController()

  return (
    <button
      type='button'
      className='optionReserve'
      onClick={() => controller.handleOnClickReserve(room, option, clientMode)}
    >
      {t('Reserve')}
    </button>
  )
}

export default OptionReserve
