import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import Api from '../../../api'
import { updateHotel } from '../../../reducers/hotel/actions'
import { showLoadBar } from '../../../reducers/loadBar/actions'
import { createExtraGuest, updateOrderHotels, updatePriceRS } from '../../../reducers/order/actions'
import _ from 'lodash'


export default class HotelDetailController {
  constructor () {
    this.api = new Api()
    this.location = useLocation()
    this.dispatch = useDispatch()
    this.searchForm = useSelector(state => state.searchForm)
    this.t = useTranslation().t
  }

  firstMount = () => {
    const query = new URLSearchParams(this.location.search)
    const key = query.get('key')
    const currency = query.get('currency')
    const checkIn = query.get('checkIn')
    const checkOut = query.get('checkOut')
    const people = query.get('people') || 'A'
    const hotelIdParams = new URLSearchParams()
    hotelIdParams.set('id', key)
    this.api.getHotelInfo(hotelIdParams)
      .then(hotelInfo => {
        this.setHotel(hotelInfo)
        this.setGuests(people)
        this.setDate(checkIn, checkOut)
      })
      .then(() => {
        this.api.priceHotel(
          moment(checkIn).format('YYYY-MM-DD'),
          moment(checkOut).format('YYYY-MM-DD'),
          people,
          key,
          currency
        )
          .then(priceRS => {
            if (priceRS && priceRS.status === 'success') {
              this.dispatch(updatePriceRS(priceRS))
            }
          })
        this.dispatch(showLoadBar(false))
      })
  }

  setHotel = (hotelInfo) => {
    if (hotelInfo && _.size(hotelInfo)) {
      this.dispatch(updateHotel('choice', hotelInfo))
      this.dispatch(updateOrderHotels('hotel', hotelInfo.key))
    }
  }

  setGuests = (people) => {
    const guests = {}
    const peopleSplits = people.split(',')
    let adults = 0
    let children = 0
    guests.people = people
    peopleSplits.forEach(peopleSplit => {
      if (peopleSplit === 'A') {
        adults++
      } else {
        children++
      }
    })
    guests.value =
      this.t('Adult', { count: adults }) +
      ', ' +
      this.t('Child', { count: children })
    guests.count = _.size(peopleSplits)
    this.dispatch(updateHotel('guests', guests))
    this.dispatch(updateOrderHotels('people', people))
    if (guests.count > 1) {
      const extraGuest = []
      for (let i = 0; i < guests.count - 1; i++) {
        extraGuest.push({
          firstName: null,
          lastName: null
        })
      }
      this.dispatch(createExtraGuest(extraGuest))
    }
  }

  setDate = (checkIn, checkOut) => {
    this.dispatch(updateHotel('checkinDate', checkIn))
    this.dispatch(updateHotel('checkoutDate', checkOut))
    this.dispatch(updateOrderHotels('checkin', moment(checkIn).format('YYYY-MM-DD')))
    this.dispatch(updateOrderHotels('checkout', moment(checkOut).format('YYYY-MM-DD')))
  }
}
