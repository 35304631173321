/* eslint-disable no-return-assign */
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import ResultsAnalytics from '../../../analytics/analyticsFlight/resultsAnalytics'
import Api from '../../../api'
import { updateButton } from '../../../reducers/button/actions'
import { FARE_BUTTON } from '../../../reducers/button/types'
import {
  DEPARTURE_EARLIEST,
  DEPARTURE_LATEST,
  PRICE_HIGHEST,
  PRICE_LOWEST,
  updateCounter
} from '../../../reducers/filter/actions'
import { setItineraries, updateItineraries } from '../../../reducers/itineraries/actions'
import { updateOrderFlights, updatePriceRS } from '../../../reducers/order/actions'
import { changePage } from '../../../reducers/page/actions'
import { FARE_SCREEN } from '../../../reducers/page/types'
import moment from 'moment'
import { CLIENT_MODE_BUSINESSES } from '../../../reducers/user/constans'
import ItinerariesElement from '../itinerariesElement'

const FARE_SCREEN_OPEN_DELAY = 1000

export default class ItinerariesController {
  constructor (searchRS) {
    this.api = new Api()
    this.searchRS = searchRS
    this.dispatch = useDispatch()
    this.analytics = new ResultsAnalytics()
    this.user = useSelector(state => state.user)
  }

  getFilteredOutbound = (itineraries) => {
    // Taking all outbound where the NDC is
    const allOutboundItineraries = this.searchRS.itineraries.filter(
      itinerary => {
        if (!itinerary.pricingOptions[0]) {
          console.error(itinerary)
        }
        return itinerary.pricingOptions[0]?.ndc !== null
      }
    )
    const uniqueOutboundItineraries = {}
    // From the flights found, form unique sorties
    allOutboundItineraries.forEach(itinerary => {
      // If you don't have this flight, write at once
      if (uniqueOutboundItineraries[itinerary.outboundId] === undefined) {
        uniqueOutboundItineraries[itinerary.outboundId] = itinerary
      } else {
        // Else, compare the price and write the lowest one
        const priceUnique = uniqueOutboundItineraries[itinerary.outboundId].pricingOptions[0].priceBreakdownConverted ||
          uniqueOutboundItineraries[itinerary.outboundId].pricingOptions[0].priceBreakdown
        const priceCurrent = itinerary.pricingOptions[0].priceBreakdownConverted ||
          itinerary.pricingOptions[0].priceBreakdown
        if (priceUnique.value > priceCurrent.value) {
          uniqueOutboundItineraries[itinerary.outboundId] = itinerary
        }
      }
    })
    // Write all and unique in state
    if (!_.isEqual(itineraries.allOutboundItineraries, allOutboundItineraries)) {
      this.dispatch(setItineraries({ allOutboundItineraries, uniqueOutboundItineraries }))
    }
    let exceedsCount = 0
    _.each(uniqueOutboundItineraries, itinerary => {
      if (_.first(itinerary.pricingOptions).b2bTravelPolicyStatus === 'exceeds') {
        exceedsCount++
      }
    })
    this.dispatch(updateItineraries('exceedsCount', exceedsCount))
    return {
      allOutboundItineraries,
      uniqueOutboundItineraries
    }
  }


  // click on outbound flight
  handleClickOutbound = (element, allInboundItineraries, segments, people, currency, openDetail) => {
    // write in state outbound flight
    this.dispatch(updateItineraries('outbound', element))
    this.analytics.selectFlight(openDetail, element.outbound)


    if (element.inbound) {
      // And in all flights find those that have the same outboundId
      // These will be inbound flights
      this.dispatch(updateItineraries(
        'allInboundItineraries',
        allInboundItineraries.filter(itinerary => element.outboundId === itinerary.outboundId)))
    } else {
      // find all segments in flight and write state
      const legs = this.legs(element, segments)
      this.dispatch(updateOrderFlights('legs', legs))
      setTimeout(() => {
        this.getPrice(element, legs, people, currency)
        this.dispatch(changePage(FARE_SCREEN))
      }, FARE_SCREEN_OPEN_DELAY)
    }
  }

  // click on inbound flight
  handleClickInbound = (element, allInboundItineraries, segments, people, currency, openDetail) => {
    // write in state inbound flight
    const legs = this.legs(element, segments)
    this.dispatch(updateItineraries('inbound', element))
    this.dispatch(updateOrderFlights('legs', this.legs(element, segments)))
    this.getPrice(element, legs, people, currency)
    this.analytics.selectFlight(openDetail, element.inbound)
    setTimeout(() => {
      this.dispatch(changePage(FARE_SCREEN))
    }, FARE_SCREEN_OPEN_DELAY)
  }

  legs = (itin, segments) => {
    const legs = []

    legs.push({
      segments: itin.outbound.map(id => ({
        ...segments[id]
      }))
    })

    if (itin.inbound) {
      legs.push({
        segments: itin.inbound.map(id => ({
          ...segments[id]
        }))
      })
    }

    return legs
  }

  getPrice = (element, legs, people, currency) => {
    const pricingOption = element.pricingOptions[0]
    this.dispatch(updateOrderFlights('pricingOption', pricingOption))
    this.dispatch(updateButton(FARE_BUTTON, 'disabled', true))
    this.dispatch(updateButton(FARE_BUTTON, 'isLoading', FARE_SCREEN))
    this.api.price(legs, pricingOption, people, currency)
      .then(priceRS => {
        if (priceRS && priceRS.pricingOption) {
          this.dispatch(updatePriceRS(priceRS))
        }
        this.dispatch(updateButton(FARE_BUTTON, 'disabled', false))
        this.dispatch(updateButton(FARE_BUTTON, 'isLoading', false))
      })
  }

  getSegments = (itinerary) => {
    return Object.values(this.searchRS.segments)
      .filter(segment => itinerary.indexOf(segment.flightId) >= 0)
      .sort((a, b) => moment(a.departureDate).unix() - moment(b.departureDate).unix())
  }

  getSortedItineraries = (itineraries, sortBy, type) => {
    // Если выбрана сортировка по цене
    const sortByPrice = (a, b) => {
      const pricingOptionsA = _.first(a.pricingOptions)
      const pricingOptionsB = _.first(b.pricingOptions)
      const priceObjectA = pricingOptionsA.priceBreakdownConverted || pricingOptionsA.priceBreakdown
      const priceObjectB = pricingOptionsB.priceBreakdownConverted || pricingOptionsB.priceBreakdown
      const valueA = priceObjectA.value
      const valueB = priceObjectB.value

      return sortBy.list[sortBy.value] === PRICE_LOWEST ? valueA - valueB : valueB - valueA
    }

    // Если выбрана сортировка по времени отправления
    const sortByDeparture = (a, b) => {
      const segmentsA = this.getSegments(a[type])
      const segmentsB = this.getSegments(b[type])
      const departureA = moment(_.first(segmentsA).departureDate).unix()
      const departureB = moment(_.first(segmentsB).departureDate).unix()

      return sortBy.list[sortBy.value] === DEPARTURE_LATEST ? departureB - departureA : departureA - departureB
    }

    return itineraries.sort((a, b) => {
      if ([PRICE_LOWEST, PRICE_HIGHEST].includes(sortBy.list[sortBy.value])) {
        return sortByPrice(a, b)
      }
      if ([DEPARTURE_LATEST, DEPARTURE_EARLIEST].includes(sortBy.list[sortBy.value])) {
        return sortByDeparture(a, b)
      }
      return 0
    })
  }

  getItinerariesHtml = (
    sortedResultCount,
    itineraries,
    sortBy,
    type,
    visibleController,
    allOutboundItineraries,
    showExceeds,
  ) => {
    let counter = 0
    const $return = itineraries &&
      this.getSortedItineraries(itineraries, sortBy, type)
        .map((element, index) => {
          const isNoVisible = visibleController.hideByFilter(element, type)
          const hideByTravelPolicy = visibleController.hideByTravelPolicy(element)
          if (hideByTravelPolicy && !showExceeds) {
            return false
          } else if (isNoVisible) {
            return false
          } else {
            counter++
            return (
              <ItinerariesElement
                key={`${type}Element${index}`}
                type={type}
                element={element}
                indexElement={index}
                searchRS={this.searchRS}
                allOutboundItineraries={allOutboundItineraries}
                onClick={type === 'outbound' ? this.handleClickOutbound : this.handleClickInbound}
              />
            )
          }
        })
    if (counter !== sortedResultCount) {
      this.dispatch(updateCounter('allResultCount', _.size(itineraries)))
      this.dispatch(updateCounter('sortedResultCount', counter))
    }
    return $return
  }
}
