import React from 'react'
import Icons from '../../pageComponents/icons'
import DirectionFlightController from './controller'

const BoardInputList = ({ listElement, type }) => {
  const controller = new DirectionFlightController()
  return (
    <div
      className={'inputDropdown-el ' + listElement.type}
      onClick={() => { controller.handleOnClickOption(type, listElement) }}
    >
      <Icons
        className='icon'
        group='icon'
        icon={listElement.type}
      />
      <div className='description'>
        {listElement.name}
        {listElement.type === 'airport'
          ? (
            <span className='description__airport'>
              {listElement.city.name} {listElement.country.name}
            </span>
            )
          : (
            <span className='description__city'>
              {' ' + listElement.country.name}
            </span>
            )}
      </div>
      <div className='code'>
        {listElement.type === 'airport' ? listElement.iata : listElement.code}
      </div>
    </div>
  )
}

export default BoardInputList
