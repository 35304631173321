import React from 'react'
import SuccessFlights from '../../../components/successComponents/success/_flights'
import SuccessHotels from '../../../components/successComponents/success/_hotels'
import currentLocation from '../../../hooks/currentLocation'

const SuccessScreen = () => {
  const { isFlightsPage, isHotelsPage } = currentLocation()
  return (
    <div className='successScreen'>
      {isFlightsPage && <SuccessFlights />}
      {isHotelsPage && <SuccessHotels />}
    </div>
  )
}

export default SuccessScreen
