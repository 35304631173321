export const UPDATE_ITINERARIES = 'UPDATE_ITINERARIES'
export const SET_ITINERARIES = 'SET_ITINERARIES'

export const updateItineraries = (field, payload) => ({
  type: UPDATE_ITINERARIES,
  field,
  payload
})
export const setItineraries = (payload) => ({
  type: SET_ITINERARIES,
  payload
})
