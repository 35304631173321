import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Icons from '../../../pageComponents/icons'
import DetailList from '../../../itinerariesComponents/itinerariesElement/_detail/_list'
import FareAnalytics from '../../../../analytics/analyticsFlight/fareAnalytics'

const DetailFare = ({ element, type, openInfo, setOpenInfo }) => {
  const { t } = useTranslation()
  const searchRS = useSelector(state => state.searchRS.model)
  const analytics = new FareAnalytics()
  return (
    <div className='detailFare'>

      {openInfo === false
        ? (
          <div className='detailFare__show'>
            <button
              onClick={() => {
                setOpenInfo(type)
                analytics.clickDetail(type, true)
              }}
              className='detailFare__show-button'
            >
              {t('Show details')}
              <Icons
                className='detailFare__show-arrow'
                group='arrow'
                icon='detail'
              />
            </button>
          </div>)
        : (
          <div className='detailFare__info'>
            <DetailList element={element[type]} searchRS={searchRS} />
            <div className='detailFare__show'>
              <button
                onClick={() => {
                  setOpenInfo(false)
                  analytics.clickDetail(type, false)
                }}
                className='detailFare__show-button'
              >
                {t('Show less')}
                <Icons
                  className='detailFare__show-arrow less'
                  group='arrow'
                  icon='detail'
                />
              </button>
            </div>

          </div>
          )}
    </div>
  )
}

export default DetailFare
