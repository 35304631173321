import moment from 'moment'
import React, { useEffect, useState } from 'react'
import './style.sass'
import 'rsuite/dist/styles/rsuite-default.css'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import OutsideClickHandler from 'react-outside-click-handler'
import { useSelector } from 'react-redux'
import { RangeSlider } from 'rsuite'
import FilterAnalytics from '../../../analytics/analyticsFlight/filterAnalytics'
import Icons from '../../pageComponents/icons'
import TimeFilterController from './controller'

const Time = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const filterTime = useSelector(state => state.filter.time)
  const controller = new TimeFilterController()
  const analytics = new FilterAnalytics()
  const [isTime, setIsTime] = useState(false)

  useEffect(() => {
    controller.changeActiveFilter(filterTime, setIsTime)
  }, [filterTime])
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (open) {
          setOpen(false)
          analytics.setTime(filterTime)
        }
      }}
    >
      <div className={'time filter ' + (open && 'filter-open')}>
        <div
          className={`time__value filter__el ${isTime ? 'filter__active' : ''}`}
          onClick={() => {
            if (open) {
              analytics.setTime(filterTime)
            }
            setOpen(!open)
          }}
        >
          {t('Time')}
          <Icons
            group='arrow'
            icon='down'
            className='filter__el-icon'
          />
          <a
            className='cross__icon-wrapper'
            onClick={(e) => {
              controller.handleClearFilter('departure', e)
              controller.handleClearFilter('arrival', e)
            }}
          >
            <Icons
              group='icon'
              icon='close'
              className='wifi__label-icon'
              color='#3D73EC'
            />
          </a>

        </div>
        <div className='time__list filter__list'>
          <div className='filter__list-wrap'>
            <div className='list__header'>
              <div className='left'>
                {t('Departure')}
                <div className='list__header-time'>
                  <Moment add={{ hours: filterTime.departure.value[0] }} format=' LT'>
                    {moment().startOf('day')}
                  </Moment>
                  {' - '}
                  <Moment add={{ hours: filterTime.departure.value[1] }} format=' LT'>
                    {moment().startOf('day')}
                  </Moment>
                </div>
              </div>
              <button
                className='right clear'
                onClick={(e) => controller.handleClearFilter('departure', e)}
              >
                {t('Clear')}
              </button>
            </div>
            <div className='time__slider'>
              <RangeSlider
                barClassName=''
                value={[filterTime.departure.value[0], filterTime.departure.value[1]]}
                min={filterTime.departure.limit[0]}
                max={filterTime.departure.limit[1]}
                step={1}
                tooltip={false}
                onChange={(value) => controller.handleChangeSlider(value, 'departure')}
              />
            </div>
            <div className='list__header'>
              <div className='left'>
                {t('Arrival')}
                <div className='list__header-time'>
                  <Moment add={{ hours: filterTime.arrival.value[0] }} format=' LT'>
                    {moment().startOf('day')}
                  </Moment>
                  {' - '}
                  <Moment add={{ hours: filterTime.arrival.value[1] }} format=' LT'>
                    {moment().startOf('day')}
                  </Moment>
                </div>
              </div>
              <button
                className='right clear'
                onClick={(e) => controller.handleClearFilter('arrival', e)}
              >
                {t('Clear')}
              </button>
            </div>
            <div className='time__slider'>
              <RangeSlider
                barClassName=''
                value={[filterTime.arrival.value[0], filterTime.arrival.value[1]]}
                min={filterTime.arrival.limit[0]}
                max={filterTime.arrival.limit[1]}
                step={1}
                tooltip={false}
                onChange={(value) => controller.handleChangeSlider(value, 'arrival')}
              />
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  )
}
export default Time
