import { useDispatch } from 'react-redux'
import FilterAnalyticsHotel from '../../../analytics/analyticsHotel/filterAnalytics'
import { changeChains } from '../../../reducers/filter/actions'

export default class ChainsController {
  constructor () {
    this.dispatch = useDispatch()
    this.analyticsHotel = new FilterAnalyticsHotel()
  }

  handleClick = (chains) => {
    this.analyticsHotel.setChains(chains)
    this.dispatch(changeChains(chains))
  }
}
