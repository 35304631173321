import React from 'react'
import './style.sass'
import Icons from '../../../../pageComponents/icons'
import PassengerController from '../../controller'
import GuestsAnalyticsHotel from '../../../../../analytics/analyticsHotel/guestsAnalytics'

const PassengerChoose = ({ index, passenger, checked, onClick, setPopupOpen, list, numberGuest }) => {
  const controller = new PassengerController()
  const analyticsHotel = new GuestsAnalyticsHotel()
  return (
    <div
      className={'passenger__choose ' +
      (checked && ' passenger__choose-checked ')}
      onClick={() => {
        numberGuest === 0
          ? analyticsHotel.addMainGuest()
          : analyticsHotel.addExtraGuest(numberGuest)
      }}
    >
      <div
        className='passenger__choose-wrap'
        onClick={() => {
          if (typeof onClick === 'function' && !checked) {
            onClick()
          }
        }}
      >
        <div className='passenger__choose-avatar'>
          {
          (passenger.firstName || passenger.first_name).substr(0, 1).toUpperCase() +
          (passenger.lastName || passenger.last_name).substr(0, 1).toUpperCase()
        }
        </div>
        <div className='passenger__choose-info'>
          <div className='passenger__choose-name'>
            {passenger.firstName || passenger.first_name} {passenger.lastName || passenger.last_name}
          </div>
          <div className='passenger__choose-email'>{passenger.email}</div>
        </div>
      </div>
      <button
        className='passenger__choose-remove'
        onClick={() => {
          list
            ? controller.handleClickEditPassenger(index, passenger, setPopupOpen)
            : controller.handleClickRemovePassenger(index)
        }}
      >
        <Icons
          group='icon'
          icon={list ? 'edit' : 'cancel'}
          color='#ccc'
        />
      </button>
    </div>
  )
}

export default PassengerChoose
