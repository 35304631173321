import React from 'react'
import './style.sass'

const Duration = ({ timeDuration, direction, openDetail }) => {
  return (
    <div className={'duration ' + (openDetail && ' duration__open')}>
      <div className='duration-time firstRow'>
        {timeDuration}
      </div>
      <div className='duration-direction secondRow'>
        {direction}
      </div>

    </div>
  )
}

export default Duration
