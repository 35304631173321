import React, { useEffect } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import ResultsAnalytics from '../../../analytics/analyticsFlight/resultsAnalytics'
import SortBy from '../../filterComponents/_sortBy'
import { useSelector } from 'react-redux'
import ItinerariesHeader from './_header'
import ItinerariesController from './controller'
import ChooseOutbound from './_chooseOutbound'
import ItinerariesElementsList from '../itinerariesElementsList'

const Itineraries = () => {
  const { t } = useTranslation()
  const itinerariesCount = useSelector(state => state.searchRS.count)
  const itinerariesStatus = useSelector(state => state.searchRS.status)
  const searchRS = useSelector(state => state.searchRS.model)
  if (!itinerariesStatus && !itinerariesCount && !Object.keys(searchRS).length) {
    return null
  }
  const controller = new ItinerariesController(searchRS)
  const itineraries = useSelector(state => state.itineraries)
  const outbound = itineraries.outbound
  const inbound = itineraries.inbound
  const allInboundItineraries = itineraries.allInboundItineraries
  const uniqueOutboundItineraries = itineraries.uniqueOutboundItineraries
  const analytics = new ResultsAnalytics()

  useEffect(() => {
    controller.getFilteredOutbound(itineraries)
  }, [itinerariesCount])

  useEffect(() => {
    if (searchRS.status === 'success') {
      analytics.showFlights(uniqueOutboundItineraries)
    }
  }, [searchRS.status])
  useEffect(() => {
    if (allInboundItineraries) {
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      }, 800)
    }
  }, [allInboundItineraries])

  return (
    <div
      className={
        'itineraries ' +
        (allInboundItineraries || outbound
          ? ' itineraries__inbound '
          : ' itineraries__outbound ') +
        (inbound && ' itineraries__inbound-choose ')
      }
    >
      <SortBy />
      <div className='outbound'>
        <ItinerariesHeader
          headerText={t('Outbound flights')}
          date={itineraries.outboundDate}
        />
        <ItinerariesElementsList />
      </div>
      {allInboundItineraries && (
        <div className='inbound'>
          <ChooseOutbound />
          <ItinerariesHeader
            headerText={t('Return flights')}
            date={itineraries.inboundDate}
          />
          <ItinerariesElementsList type='inbound' />
        </div>
      )}
    </div>
  )
}
export default Itineraries
