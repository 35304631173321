import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import HotelsSearchPage from '../page/hotels/search'
import HotelsResultsPage from '../page/hotels/results'
import HotelsResultsDetailPage from '../page/hotels/resultsDetail'
import HotelsConfirmationPage from '../page/hotels/confirmation'
import HotelsSuccessPage from '../page/hotels/success'
import {
  HOTELS_RESULTS_PAGE,
  HOTELS_RESULTS_DETAIL_PAGE,
  HOTELS_SEARCH_PAGE,
  HOTELS_CONFIRMATION_PAGE, HOTELS_SUCCESS_PAGE
} from '../routes'
import HotelsNavigationController from './controller'

const HotelsNavigation = () => {
  const controller = new HotelsNavigationController()
  return (
    <>
      <Route path={HOTELS_SEARCH_PAGE} exact component={HotelsSearchPage} />

      <Route exact path={HOTELS_RESULTS_PAGE}>
        <HotelsResultsPage />
        {/*{controller.availableResultsPage() ? <HotelsResultsPage /> : <Redirect to={HOTELS_SEARCH_PAGE} />}*/}
      </Route>

      <Route exact path={HOTELS_RESULTS_DETAIL_PAGE}>
        {controller.availableResultsDetailPage() ? <HotelsResultsDetailPage /> : <Redirect to={HOTELS_RESULTS_PAGE} />}
      </Route>

      <Route exact path={HOTELS_CONFIRMATION_PAGE}>
        <HotelsConfirmationPage />
      </Route>

      <Route exact path={HOTELS_SUCCESS_PAGE}>
        <HotelsSuccessPage />
      </Route>
    </>
  )
}

export default HotelsNavigation
