import { useDispatch } from 'react-redux'
import { setSortBy } from '../../../reducers/filter/actions'

export default class SortByController {
  constructor () {
    this.dispatch = useDispatch()
  }

  handleOnClick = (index, setOpenDropdown) => {
    this.dispatch(setSortBy(index))
    setOpenDropdown(false)
  }
}
