import AnalyticsFlight from './index'

export default class CardAnalytics extends AnalyticsFlight {
  addCard = () => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.PAYMENT,
      'addPaymentCardButton',
      this.ACTIONS.TAP
    )
  }

  saveCard = () => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.NEW_CARD,
      'paymentCardSaveButton',
      this.ACTIONS.TAP
    )
  }

  cancelCard = () => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.NEW_CARD,
      'paymentCardCancelButton',
      this.ACTIONS.TAP
    )
  }

  editField = (field) => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.NEW_CARD,
      'field',
      this.ACTIONS.TAP,
      field
    )
  }
}
