import moment from 'moment'

export default class ConfirmationHeaderController {
  getDate = (checkin, checkout) => {
    const momentCheckin = moment(checkin)
    const momentCheckout = moment(checkout)
    let date = momentCheckin.format('MMM DD - ')
    if (momentCheckin.month() === momentCheckout.month()) {
      date += momentCheckout.format('DD')
    } else {
      date += momentCheckout.format('MMM DD')
    }
    return date
  }
}
