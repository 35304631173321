export const SEARCH_SCREEN = 'SEARCH_SCREEN'
export const RESULTS_SCREEN = 'RESULTS_SCREEN'
export const FARE_SCREEN = 'FARE_SCREEN'
export const PURCHASE__SCREEN = 'PURCHASE__SCREEN'
export const SUCCESS_SCREEN = 'SUCCESS_SCREEN'

export const ORDERS_SCREEN_UPCOMING = 'ORDERS_SCREEN_UPCOMING'
export const ORDERS_SCREEN_PAST = 'ORDERS_SCREEN_PAST'

export const SET_PREV_PAGE = 'SET_PREV_PAGE'

export const screenSetting = (screen) => {
  let header = {
    background: 'white',
    textColor: '#707070',
    logoColor: 'black',
    showArrowBack: false
  }
  switch (screen) {
    case SEARCH_SCREEN:
      header = {
        background: 'transparent',
        textColor: 'white',
        logoColor: 'white'
      }
      break
    case FARE_SCREEN:
    case PURCHASE__SCREEN:
      header.showArrowBack = true
  }

  return {
    header
  }
}
