import { useEffect } from 'react'
import UseSavedState from '../hooks/useSavedState'
import LoginController from './page/login/controller'
import useLocalStorage from '../hooks/useLocalStorage'

export default function CheckToken () {
  const [ordersIds, setOrdersIds] = useLocalStorage('ordersIds', {})
  const [ordersList, setOrdersList] = useLocalStorage('ordersList', {})
  const controller = new LoginController(ordersIds, ordersList, setOrdersIds, setOrdersList)
  useEffect(() => {
    if (!UseSavedState()) {
      controller.newVisit()
    }
  }, [])

  return ''
}
