import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import getTimeFormat from '../../../../hooks/getTimeFormat'
import Tooltip from '../../../pageComponents/tooltip'
import SelectFlight from '../_selectFlight'

const Transferring = ({ transferring, openDetail, onClick }) => {
  const { t } = useTranslation()
  return (
    <div className='transferring tooltip-hoverElement'>
      <div className={'transferring-count firstRow ' + (openDetail ? 'firstRow-hide' : 'firstRow-show')}>
        {transferring.count}
      </div>
      {openDetail
        ? <SelectFlight
            onClick={onClick}
            openDetail={openDetail}
          />
        : !transferring.code.length || !transferring.times.length
            ? ''
            : (
              <>
                <div className={'transferring-where secondRow ' + (openDetail ? 'secondRow-hide' : 'secondRow-show')}>
                  {transferring.times.length === 1 && getTimeFormat(transferring.allTime, t)}{' '}
                  {transferring.code.join(', ')}
                </div>
                {transferring.code.length < 1
                  ? ''
                  : <Tooltip
                      child={
                          transferring.code.map((codeElement, index) => {
                            return (
                              <div
                                key={'transferring-tooltip-el' + transferring.airports[index]}
                                className='tooltip__el'
                              >
                                <span className='white'>{getTimeFormat(transferring.times[index], t)} </span>
                                {t('at')} {transferring.airports[index]}
                              </div>
                            )
                          })
                      }
                    />}
              </>
              )}
    </div>
  )
}

export default Transferring
