/*eslint-disable*/

import { CLEAR_STATE } from '../actions'
import {UPDATE_HOTEL, UPDATE_HOTEL_AUTOCOMPLETE_FEATURES, UPDATE_HOTEL_AUTOCOMPLETE_SHOW, UPDATE_HOTEL_AUTOCOMPLETE_VALUE} from './actions'
import _ from 'lodash'

const defaultState = {
  choice: null,
  reserve: null,
  checkinDate: null,
  checkoutDate: null,
  whereStay: null,
  guests: {
    people: '',
    value: ''
  },
  stay: {},
  autocomplete: {
    value: '',
    show: false,
    features: []
  },
  exceedsCount: 0,
  showExceeds: false

}

const Hotel = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_HOTEL:
      return {
        ...state,
        [action.field]: action.payload
      }
    case UPDATE_HOTEL_AUTOCOMPLETE_VALUE:
      return {
        ...state,
        autocomplete: {
          ...state.autocomplete,
          value: action.payload
        }
      }
    case UPDATE_HOTEL_AUTOCOMPLETE_SHOW:
      return {
        ...state,
        autocomplete: {
          ...state.autocomplete,
          show: action.payload
        }
      }
    case UPDATE_HOTEL_AUTOCOMPLETE_FEATURES:
      return {
        ...state,
        autocomplete: {
          ...state.autocomplete,
          features: action.payload
        }
      }
    case CLEAR_STATE:
      return _.pick(state, ['checkinDate', 'checkoutDate', 'whereStay', 'guests', 'stay'])
    default:
      return state
  }
}

export default Hotel
