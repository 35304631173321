import AnalyticsFlight from './index'

export default class PassengerAnalytics extends AnalyticsFlight {
  selectPassenger = (number) => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.PASSENGER,
      'selectPassengerField',
      this.ACTIONS.TAP,
      number
    )
  }

  passengerAction = (action, number) => {
    const element = {
      new: 'addPassengerButton',
      edit: 'changePassengerButton',
      remove: 'changePassengerButton',
    }

    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.PASSENGER,
      element[action],
      this.ACTIONS.TAP,
      number
    )
  }

  editField = (field) => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.NEW_PASSENGER,
      'field',
      this.ACTIONS.TAP,
      field
    )
  }

  savePassenger = () => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.NEW_PASSENGER,
      'addPassengerSuccessButton',
      this.ACTIONS.TAP
    )
  }

  cancelPassenger = () => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.NEW_PASSENGER,
      'addPassengerCancelButton',
      this.ACTIONS.TAP
    )
  }
}
