import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { HOTELS_SUCCESS_PAGE } from '../../../navigation/routes'
import { setError } from '../../../reducers/error/actions'
import { updateOrderRS } from '../../../reducers/order/actions'
import OrderType from '../../../data/OrderType'
import { changePage } from '../../../reducers/page/actions'
import { SUCCESS_SCREEN } from '../../../reducers/page/types'

export default class Iframe3DSController {
  constructor () {
    this.dispatch = useDispatch()
    this.history = useHistory()
  }

  listener = (event, setIFrame3DSpaymentUrl, orderType) => {
    let data = event.data

    if (data.source) {
      if (data.source.indexOf('react') > -1) {
        return
      }
    }

    try {
      data = JSON.parse(data)

      if (typeof data === 'string') {
        this.dispatch(setError({ name: 'Oops', message: data }))
        return setIFrame3DSpaymentUrl(false)
      }

      // in any other case it is success

      if (orderType === OrderType.HOTEL) {
        this.dispatch(updateOrderRS(data))
        this.history.push(HOTELS_SUCCESS_PAGE)
        return setIFrame3DSpaymentUrl(false)
      }

      if (orderType === OrderType.FLIGHT) {
        this.dispatch(updateOrderRS(data))
        this.dispatch(changePage(SUCCESS_SCREEN))
        return setIFrame3DSpaymentUrl(false)
      }
    } catch (error) {
      // the error returns as string
      // console.log(error)
    }
  }
}
