import './style.sass'
import ButtonBlue from '../../common/buttonBlue'

const SelectClearFilter = ({
  textOne = 'Select all', textTwo = 'Clear all', addedClass = '', controller, propertyes
}) => {
  return (
    <div className={`selectClearFilter ${addedClass}`}>
      <ButtonBlue
        text={textOne}
        addedClass={'button ' + (controller.activeButton(propertyes).select ? 'button-active' : '')}
        onClick={() => controller.handleOnClickButton(propertyes, true)}
      />
      <ButtonBlue
        text={textTwo}
        addedClass={'button ' + (controller.activeButton(propertyes).clear ? 'button-active' : '')}
        onClick={() => controller.handleOnClickButton(propertyes, false)}
      />
    </div>
  )
}

export default SelectClearFilter