import React, { useEffect, useState } from 'react'
import './style.sass'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import UseSavedState from '../../../hooks/useSavedState'
import { HOTELS_RESULTS_PAGE } from '../../../navigation/routes'
import Breakfast from '../_breakfast'
import Chains from '../_chains'
import Wifi from '../_wifi'
import Stars from '../_stars'
import Rating from '../_rating'
import Other from '../_other'
import FilterHotelsController from './hotelsController'
import FilterAnalytics from '../../../analytics/analyticsHotel/filterAnalytics'
import HotelPrice from '../_price/hotelPrice'

const FilterHotels = () => {
  const searchRS = useSelector(state => state.searchRS)
  const hotels = searchRS.model.hotels
  const filter = useSelector(state => state.filter)
  const controller = new FilterHotelsController()
  const analyticsHotel = new FilterAnalytics()
  const location = useLocation()
  const [mouseUp, setMouseUp] = useState(false)
  useEffect(() => {
    if (!UseSavedState())
      controller.setFilter(hotels, filter)
  }, [searchRS.count])

  useEffect(() => {
    searchRS.status === 'success' && mouseUp && analyticsHotel.setAllFilters(filter)
    setMouseUp(false)
  }, [mouseUp])

  return (
    <>
      <Breakfast />
      <Chains />
      {location.pathname === HOTELS_RESULTS_PAGE && searchRS.count
        ? (
          <>
            <Wifi />
            <HotelPrice setMouseUp={setMouseUp} />
            <Stars setMouseUp={setMouseUp} />
            <Rating setMouseUp={setMouseUp} />
            <Other />
          </>
          )
        : ''}
    </>
  )
}
export default FilterHotels
