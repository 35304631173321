import moment from 'moment'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import { changeLayovers, changePrice, setAirlines, setDuration, setPrice } from '../../../reducers/filter/actions'
import { getStopsDuration } from '../../../utils/stops'
import { SEARCH_SCREEN } from '../../../reducers/page/types'

export default class FilterFlightsController {
  constructor (searchRS, itineraries, filter, outbound) {
    this.dispatch = useDispatch()
    this.prevPage = useSelector(state => state.page.prev)
    this.searchRS = searchRS
    this.itineraries = itineraries
    this.filter = filter
    this.outbound = outbound
  }

  setFilter = () => {
    this.setFilterDuration()
    this.setFilterAirlines()
    this.setFilterPrice()
    this.setFilterLayovers()
  }

  getSegments = (itinerary) => {
    return Object.values(this.searchRS.segments)
      .filter(segment => itinerary.indexOf(segment.flightId) >= 0)
      .sort((a, b) => moment(a.departureDate).unix() - moment(b.departureDate).unix())
  }

  setFilterDuration = () => {
    const filterDuration = [0, 0]
    Object.values(this.itineraries).forEach(itinerary => {
      this.getSegmentDuration(
        this.getSegments(this.outbound ? itinerary.outbound : itinerary.inbound),
        filterDuration
      )
    })
    if (!_.isEqual(this.filter.duration.limit, filterDuration)) {
      this.dispatch(setDuration(filterDuration))
    }
  }

  getSegmentDuration = (segments, filterDuration) => {
    let duration = Object.values(segments).reduce((a, b) => a + b.duration, 0)
    segments.forEach((segment, segmentIndex) => {
      if (segmentIndex && segmentIndex !== segment.length) {
        const departureDate = moment(segment.departureDate)
        const prevArrivalDate = moment(segments[segmentIndex - 1].arrivalDate)
        duration = parseInt(duration + departureDate.diff(prevArrivalDate, 'minutes', false))
      }
    })
    if (!filterDuration[0] || filterDuration[0] > duration) {
      filterDuration[0] = duration
    }
    if (!filterDuration[1] || filterDuration[1] < duration) {
      filterDuration[1] = duration
    }
  }

  setFilterAirlines = () => {
    let segments
    let airlines = []
    _.forEach(this.itineraries, itinerary => {
      segments = this.getSegments(itinerary.outbound)
      segments.forEach(segment => airlines.push(this.searchRS.carriers[segment.carrier]))
    })
    airlines = [...new Set(airlines)]
    if (airlines.length && this.prevPage === SEARCH_SCREEN) {
      this.dispatch(setAirlines(_.compact(airlines)))
    }
  }

  setFilterPrice = () => {
    const filterPrice = [0, 0]
    let priceObject = {}
    Object.values(this.itineraries).forEach(itinerary => {
      const pricingOption = itinerary.pricingOptions[0]
      priceObject = pricingOption.priceBreakdownConverted || pricingOption.priceBreakdown
      const value = priceObject.value
      if (!filterPrice[0] || filterPrice[0] > value) {
        filterPrice[0] = value
      }
      if (!filterPrice[1] || filterPrice[1] < value) {
        filterPrice[1] = value
      }
    })
    if (!_.isEqual(this.filter.price.limit, filterPrice)) {
      this.dispatch(setPrice(filterPrice))
    }
    if (!_.isEqual(this.filter.price.currency, this.searchRS.currencies[priceObject.currency])) {
      this.dispatch(changePrice('currency', this.searchRS.currencies[priceObject.currency]))
    }
  }

  setFilterLayovers = () => {
    this.dispatch(changeLayovers('oneStop', false))
    this.dispatch(changeLayovers('twoStops', false))
    this.dispatch(changeLayovers('moreStops', false))
    const layoversLimit = [0, 0]
    // Берем маршруты
    Object.values(this.itineraries).forEach(itinerary => {
      let layoversSum = 0
      // Получаем все перелеты маршрута
      const segments = this.getSegments(this.outbound ? itinerary.outbound : itinerary.inbound)
      layoversSum = getStopsDuration(segments)
      // Ищем минимальное и максимальное время суммарного ожидания между перелетами (пересадки)
      if ((layoversLimit[0] === 0 || layoversSum < layoversLimit[0]) && layoversSum !== 0) {
        layoversLimit[0] = layoversSum
      }
      if (!layoversLimit[1] || layoversSum > layoversLimit[1]) {
        layoversLimit[1] = layoversSum
      }
    })
    this.dispatch(changeLayovers('limit', layoversLimit))
    this.dispatch(changeLayovers('value', layoversLimit))
  }
}
