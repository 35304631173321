import { CLEAR_STATE } from '../actions'
import {
  UPDATE_PAYMENT_IN_FORM,
  UPDATE_PAYMENT_FORM,
  UPDATE_CORPORATE_CARD_PAYMENT_IN_FORM,
  UPDATE_PAYMENT_METHOD, UPDATE_ERROR_IN_FORM
} from './actions'

const defaultState = {
  card: {
    number: '',
    expMonth: '',
    expYear: '',
    cvc: '',
    holder: ''
  },
  billing: {
    street: '',
    city: '',
    state: '',
    postal: '',
    country: ''
  },
  cardToken: undefined,
  paymentMethod: '',
  errors: {}
}

const paymentForm = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_PAYMENT_IN_FORM:
      return {
        ...state,
        [action.object]: {
          ...state[action.object],
          [action.field]: action.payload
        }
      }
    case UPDATE_CORPORATE_CARD_PAYMENT_IN_FORM:
      return {
        ...state,
        cardToken: action.payload,
      }
    case UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      }
    case UPDATE_ERROR_IN_FORM:
      return {
        ...state,
        errors: {},
      }
    case UPDATE_PAYMENT_FORM:
      return {
        ...state,
        card: action.payload.card,
        billing: action.payload.billing,
        errors: {}
      }
    case CLEAR_STATE:
      return defaultState
    default:
      return state
  }
}

export default paymentForm
