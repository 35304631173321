import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import cx from 'classnames'

import Icons from '../icons'
import { useToggle } from '../../../hooks/useToggle'

import './style.sass'
import { useTranslation } from 'react-i18next'

export interface DropdownProps {
  name: string
  listName: string
  onClear: () => void
  onClose: () => void
  active?: boolean
}

export const Dropdown: React.FC<DropdownProps> = (
  { name, listName, onClear, onClose, children, active = false }) => {
  const [open, toggle, toggleCustom] = useToggle()

  const { t } = useTranslation()

  const handleOutsideClick = () => {
    toggleCustom(false)
  }

  return (
    <OutsideClickHandler
      onOutsideClick={handleOutsideClick}
    >
      <div className={cx('dropdown-component', { 'dropdown-component-open': open })}>
        <div
          className={cx('dropdown-component__value', { 'dropdown-component__value_active': active })}
          onClick={toggle}
        >
          {name}
          <Icons
            group='arrow'
            icon='down'
            color='#8F8F8F'
            className='dropdown-component__value-icon'
          />
          <span
            onClick={(e) => {
              e.stopPropagation()
              onClose()
            }}
            className={cx('dropdown-component__value-close',
              { 'dropdown-component__value-close_active': active })}
          >
            <Icons
              group='icon'
              icon='close'
              color='#3D73EC'
            />
          </span>

        </div>
        <div className='dropdown-component_list'>
          <div className='dropdown-component_list-wrap'>
            <div className='dropdown-component_list_header'>
              <p className='dropdown-component_list__title'>{listName}</p>
              <p className='dropdown-component_list__clear' onClick={onClear}>{t('Clear')}</p>
            </div>
            <div className='dropdown-component_list_options'>
              {children}
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  )
}
