import React, { useEffect, useRef, useState } from 'react'
import './style.sass'
import { useSelector } from 'react-redux'
import PhotoGallery from '../../../pageComponents/photoGallery'
import HotelPhotosCount from '../_photosCount'
import HotelDetailPhotosController from './controller'
import HotelScreenAnalytics from '../../../../analytics/analyticsHotel/hotelScreenAnalytics'

const HotelPhotos = () => {
  const controller = new HotelDetailPhotosController()
  const analyticsHotel = new HotelScreenAnalytics()
  const hotel = useSelector(state => state.hotel.choice)
  const hotelInfo = useSelector(state => state.orderHotels)
  const [showGallery, setShowGallery] = useState(false)
  const photos = []
  const photosUrl = []

  const foto = useRef()
  useEffect(() => {
    const analyticsSeePhoto = () => {
      analyticsHotel.seePhotos(hotelInfo)
    }
    foto.current?.addEventListener('load', analyticsSeePhoto)
    return () => foto.current?.removeEventListener('load', analyticsSeePhoto)
  }, [])

  for (let i = 0; i < hotel.photosCount; i++) {
    photosUrl.push(controller.generateUrl(hotel.key, i))
    if (i < 5) {
      photos.push(
        <div
          key={`hotelDetailPhoto${i}`}
          className='hotelPhotos__photo'
          onClick={() => {
            setShowGallery(true)
            analyticsHotel.clickPhoto()
          }}
        >
          <img
            alt=''
            src={controller.generateUrl(hotel.key, i)}
            className='hotelPhotos__img'
            ref={foto}
          />
        </div>
      )
    }
  }


  return (
    <div className='hotelPhotos'>
      {photos}
      <HotelPhotosCount count={hotel.photosCount} />
      {showGallery &&
        <PhotoGallery photosUrl={photosUrl} setShowGallery={setShowGallery} analyticsType='hotel' />}
    </div>
  )
}

export default HotelPhotos
