import currentLocation from './currentLocation'
import _ from 'lodash'

export default function useSearchValidation (form, t) {
  const { isFlightsPage, isHotelsPage } = currentLocation()
  const error = []
  if (isFlightsPage) {
    if (!form.origin.code) {
      error.push({
        field: 'origin',
        message: t('From is required')
      })
    }
    if (!form.destination.code) {
      error.push({
        field: 'destination',
        message: t('To is required')
      })
    }
    if (!form.calendar.start) {
      error.push({
        field: 'calendar',
        message: t('Depart date is required')
      })
    }
  }

  if (isHotelsPage) {
    if (!_.size(form.whereStay.stay)) {
      error.push({
        field: 'whereStay',
        message: t('Where to stay is required')
      })
    }
    if (!form.calendar.end) {
      error.push({
        field: 'calendar',
        message: t('Checkout is required')
      })
    }
    if (!form.calendar.start) {
      error.push({
        field: 'calendar',
        message: t('Checkin is required')
      })
    }
    if (form.calendar.start !== null && form.calendar.end !== null && form.calendar.start === form.calendar.end) {
      error.push({
        field: 'calendar',
        message: t('Dates must be different')
      })
    }
  }
  return error
}

