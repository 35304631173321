import AnalyticsHotel from './index'
import _ from 'lodash'

export default class FilterAnalytics extends AnalyticsHotel {
  setBreakfast = (isBreakfast) => {
    this.logEvent(
      this.NAMES.SEARCH,
      this.FEATURE.FILTERS,
      'BreakfastFilter',
      isBreakfast ? this.ACTIONS.ENABLED : this.ACTIONS.DISABLED,
      ''
    )
  }

  setChains = (isChains) => {
    this.logEvent(
      this.NAMES.SEARCH,
      this.FEATURE.FILTERS,
      'ChainsOnlyFilter',
      isChains ? this.ACTIONS.ENABLED : this.ACTIONS.DISABLED,
      ''
    )
  }

  setAllFilters = (filters) => {
    let property
    if (filters.propertyTypes.length) {
      property = _.compact(filters.propertyTypes.map(i => i.checked && i.name))
    }

    this.logEvent(
      this.NAMES.RESULT,
      this.FEATURE.FILTERS,
      'filteredResults',
      this.ACTIONS.SEE,
      [
        `breakfast:${filters.breakfast ? 'on' : 'off'}`,
        `chains:${filters.chains ? 'on' : 'off'}`,
        `wifi:${filters.wifi ? 'on' : 'off'}`,
        `price:${filters.price.value[0]}-${filters.price.value[1]}`,
        `stars:${filters.stars.value[0]}-${filters.stars.value[1]}`,
        `rating:${filters.rating.value[0]}-${filters.rating.value[1]}`,
        // eslint-disable-next-line max-len
        `payment:${filters.paymentAndBooking.payNow.checked && 'Pay now'}, ${filters.paymentAndBooking.payLater.checked && 'Pay later'}`,
        `property:${property}`,
      ].join(';')
    )
  }

  allCountsHotels = (number) => {
    this.logEvent(
      this.NAMES.RESULT,
      this.FEATURE.FILTERS,
      'numberHotels',
      this.ACTIONS.SEE,
      `${number}`
    )
  }
}
