import { useDispatch } from 'react-redux'
import { updateButton } from '../../../reducers/button/actions'
import { PAY_BUTTON } from '../../../reducers/button/types'
import { updateItineraries } from '../../../reducers/itineraries/actions'
import { changePage } from '../../../reducers/page/actions'
import {
  FARE_SCREEN,
  PURCHASE__SCREEN,
  RESULTS_SCREEN, SEARCH_SCREEN,
  SUCCESS_SCREEN
} from '../../../reducers/page/types'

export default class HeaderController {
  constructor (page) {
    this.dispatch = useDispatch()
    this.page = page
  }

  showArrowBack = () => {
    return this.page !== SEARCH_SCREEN && this.page !== RESULTS_SCREEN
  }

  handleOnClick = () => {
    switch (this.page) {
      case FARE_SCREEN:
        this.dispatch(updateItineraries('inbound', null))
        this.dispatch(updateItineraries('outbound', null))
        this.dispatch(updateItineraries('allInboundItineraries', null))
        this.dispatch(changePage(RESULTS_SCREEN))
        break
      case PURCHASE__SCREEN:
        this.dispatch(changePage(FARE_SCREEN))
        break
      case SUCCESS_SCREEN:
        this.dispatch(changePage(PURCHASE__SCREEN))
        this.dispatch(updateButton(PAY_BUTTON, 'isLoading', false))
        this.dispatch(updateButton(PAY_BUTTON, 'disabled', false))
        break
    }
  }
}
