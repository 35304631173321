import { useDispatch } from 'react-redux'
import Api from '../../../../api'

export default class HotelDetailPhotosController {
  constructor () {
    this.api = new Api()
    this.dispatch = useDispatch()
  }

  generateUrl = (hotelKey, number) => {
    return this.api.getPhotosUrl(hotelKey, number)
  }
}
