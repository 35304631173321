import React, { useEffect, useState, useRef } from 'react'
import './style.sass'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useSelector } from 'react-redux'
import HotelsMapSearch from '../../hotelsComponents/_hotelsList/_hotelsMap/_search'
import MapBoxController from './controller'
import _ from 'lodash'


let oldHotels
let oldCenter
const MapboxMap = ({
  initialOptions = {},
  hotels,
  hotel,
  fullMap,
  search
}) => {
  const controller = new MapBoxController()
  const [map, setMap] = useState()
  const hotelState = useSelector(state => state.hotel)

  const mapNode = useRef(null)

  useEffect(() => {
    controller.firstInit(mapNode, initialOptions, setMap, hotels)
  }, [])

  useEffect(() => {
    if (map && _.size(hotels) && !_.isEqual(hotels, oldHotels)) {
      oldHotels = hotels
      controller.addMarkers(map, hotels, hotelState)
    } else if (map && !_.size(hotels)) {
      oldHotels = hotels
      controller.removeMarkers(map)
      controller.removePointer()
    }
  }, [map, hotels, hotelState.choice])

  useEffect(() => {
    if (map && hotel) {
      controller.addMarker(map, hotel)
    }
  }, [map])

  useEffect(() => {
    if (map && !_.isEqual(oldCenter, initialOptions.center)) {
      oldCenter = initialOptions.center
      controller.setCenter(map, initialOptions.center, true)
    }
  }, [initialOptions.center])

  useEffect(() => {
    if (map) {
      controller.mapResize(map, initialOptions.center)
    }
  }, [fullMap])

  return (
    <div
      id='map'
      className='mapboxMap'
      ref={mapNode}
      style={{ width: '100%', height: '100%' }}
    >
      {map && search &&
        <HotelsMapSearch map={map} />}
    </div>
  )
}

export default MapboxMap
