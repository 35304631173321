import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { clearAllFilter } from '../../../reducers/filter/actions'

const ResultCounter = () => {
  const { t } = useTranslation()
  const allResultCount = useSelector(state => state.filter.allResultCount)
  const sortedResultCount = useSelector(state => state.filter.sortedResultCount)
  const dispatch = useDispatch()

  if (allResultCount === 0) return null
  return (
    <div className='resultCounter'>
      <div className='resultCounter__text'>
        {allResultCount === sortedResultCount
          ? t('results', { count: allResultCount })
          : t('of_results', {
            count: allResultCount,
            sortedResultCount: sortedResultCount
          })}
      </div>
      {allResultCount !== sortedResultCount &&
        <button
          className='resultCounter__clearFilter'
          onClick={() => dispatch(clearAllFilter())}
        >
          {t('Clear filters')}
        </button>}
    </div>
  )
}

export default ResultCounter
