import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import usePrice from '../../../../hooks/usePrice'
import PeopleController from '../../../searchComponents/_peopleAndCabin/_people/controller'

const Price = ({ element }) => {
  const { t } = useTranslation()
  const calendar = useSelector(state => state.searchForm.calendar)
  const passengers = useSelector(state => state.searchForm.passengers)
  const controller = new PeopleController()
  const outbound = useSelector(state => state.itineraries.outbound)
  const currencies = useSelector(state => state.searchRS.model.currencies)
  let priceOutbound
  if (outbound) {
    priceOutbound = usePrice(currencies, outbound.pricingOptions[0])
  }
  const price = usePrice(currencies, element.pricingOptions[0], priceOutbound)
  return (
    <div className='price'>
      <div className='price-value firstRow'>
        {priceOutbound ? '+' : ''}
        {price.value}
      </div>
      <div className='price-trip secondRow'>
        {calendar.outbound && calendar.inbound
          ? t('Round trip')
          : t('One-way')}
        , {controller.passengers(passengers.count)}
        {priceOutbound &&
          <span>
           &nbsp;- {price.valueSum}
          </span>}
      </div>
    </div>
  )
}

export default Price
