import { useLayoutEffect, useRef, useState } from 'react'
import { throttle } from 'lodash'

export const useIsVisibleElement = (action, offset, params) => {
  const [elIsVisible, setElIsVisible] = useState(false)
  const element = useRef()

  // offset отступ от края экрана, чтобы elIsVisible становилось true не с первого пикселя, а когда покажется на указанное количество пикселей

  useLayoutEffect(() => {
    const listener = throttle(() => {
      const offsetTop = element.current?.getBoundingClientRect().top
      if (offsetTop <= window.innerHeight - offset) {
        setElIsVisible(true)
      }
    }, 200)

    window.addEventListener('scroll', listener)

    return () => window.removeEventListener('scroll', listener)
  }, [])


  useLayoutEffect(() => {
    const offsetTop = element.current?.getBoundingClientRect().top
    if (offsetTop <= window.innerHeight - offset) {
      params ? action(...params) : action()
    }
  }, [elIsVisible])

  return [element, elIsVisible]
}
