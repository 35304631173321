import React from 'react'
import './style.sass'
import OptionBeds from './_beds'
import OptionMeals from './_meals'
import OptionPerson from './_person'
import OptionPrice from './_price'
import OptionReserve from './_reserve'
import OptionLeft from './_left'
import OptionTerms from './_terms'

const RoomDetailOption = ({ room, option }) => {
  return (
    <div className='roomDetailOption'>
      <div className='optionName'>
        {option.name}
      </div>
      <div className='column'>
        <OptionBeds option={option} />
        <OptionMeals option={option} />
        <OptionPerson option={option} />
      </div>
      <OptionTerms option={option} />
      <OptionPrice option={option} />
      <div className='column'>
        <OptionReserve room={room} option={option} />
        <OptionLeft option={option} />
      </div>
    </div>
  )
}

export default RoomDetailOption
