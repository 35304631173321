import { CLEAR_STATE, START_SEARCH } from '../actions'
import { CLEAR_PRICE_RS, UPDATE_PRICE_RS } from './actions'

const defaultState = {
  model: {
    provider: null,
    pricingOption: null,
    itineraries: null,
    passengers: null,
    services: null,
    seats: null,
    segments: null,
    total: null,
    supportedVouchers: null,
    passengerFormConfig: null,
    walletPaymentRequest: null
  }
}

const priceRS = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_PRICE_RS:
      return {
        ...state,
        model: action.payload
      }
    case CLEAR_PRICE_RS:
    case CLEAR_STATE:
    case START_SEARCH:
      return defaultState
    default:
      return state
  }
}

export default priceRS
