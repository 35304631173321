import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { socketDisconnect } from '../../../../api/socket'
import currentLocation from '../../../../hooks/currentLocation'
import { HOTELS_SEARCH_PAGE } from '../../../../navigation/routes'
import { clearState } from '../../../../reducers/actions'
import { updateOrderHotels } from '../../../../reducers/order/actions'
import { updateSearchForm } from '../../../../reducers/search/actions'

export default class CurrencyController {
  constructor () {
    this.dispatch = useDispatch()
    this.i18n = useTranslation().i18n
    this.history = useHistory()
  }

  setCurrency = (currency, setOpenDropdown, setCurrency, reload = false) => {
    if (reload) {
      const { isHotelsPage } = currentLocation()

      socketDisconnect()
      this.dispatch(clearState())
      if (isHotelsPage) {
        this.history.push(HOTELS_SEARCH_PAGE)
      }
    }
    this.dispatch(updateSearchForm('currency', currency))
    this.dispatch(updateOrderHotels('currency', currency))

    setCurrency(currency)
    setOpenDropdown(false)
  }
}
