import React, { useState, useEffect } from 'react'
import './style.sass'
import Slider from 'react-slick'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import Icons from '../icons'
import OutsideClickHandler from 'react-outside-click-handler'
import ResultsAnalytics from '../../../analytics/analyticsHotel/resultsAnalytics'
import { useSelector } from 'react-redux'
import HotelScreenAnalytics from '../../../analytics/analyticsHotel/hotelScreenAnalytics'


const PhotoGallery = ({ photosUrl, setShowGallery, hotel, analyticsType, analyticsValue }) => {
  const { width } = useWindowDimensions()
  const hotelData = useSelector(state => state.hotel)
  const searchRS = useSelector(state => state.searchRS)
  const analyticsHotel = new ResultsAnalytics()
  const analyticsScreenHotel = new HotelScreenAnalytics()
  const WIDTH_NAVIGATION_PHOTO = 84
  const slidesToShow = Math.floor(width / WIDTH_NAVIGATION_PHOTO)
  let refSlider1
  let refSlider2
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  useEffect(() => {
    setNav1(refSlider1)
    setNav2(refSlider2)
  }, [])
  const photosHtml = []

  for (let i = 0; i < photosUrl.length; i++) {
    photosHtml.push(
      <div
        key={`hotelDetailPhoto${i}`}
        className='photoGallery__photo'
      >
        <img
          alt=''
          src={photosUrl[i]}
          className='photoGallery__photo-img  '
        />
      </div>
    )
  }
  return (
    <div className='photoGallery'>
      <OutsideClickHandler
        onOutsideClick={() => setShowGallery(false)}
      >
        <div className='photoGallery__main'>
          <button
            className='photoGallery__close'
            onClick={() => setShowGallery(false)}
          >
            <Icons
              group='icon'
              icon='close'
              className='photoGallery__close-icon'
            />
          </button>
          <Slider
            asNavFor={nav2}
            ref={slider => (refSlider1 = slider)}
            infinite={false}
            afterChange={() => {
              analyticsType === 'result' && analyticsHotel.scrollPhoto(hotel, hotelData, searchRS)
              analyticsType === 'hotel' && analyticsScreenHotel.scrollPhoto()
              analyticsType === 'room' && analyticsScreenHotel.scrollPhotoRoom({ ...analyticsValue })
            }}
          >
            {photosHtml}
          </Slider>
        </div>
        <div className='photoGallery__nav'>
          <Slider
            asNavFor={nav1}
            ref={slider => (refSlider2 = slider)}
            slidesToScroll={1}
            slidesToShow={slidesToShow}
            swipeToSlide
            focusOnSelect
            arrows={false}
            // centerMode
            // centerPadding='4px'
            infinite={false}
          >
            {photosHtml}
          </Slider>
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default PhotoGallery
