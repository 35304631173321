import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CLIENT_MODE_BUSINESSES } from '../../../reducers/user/constans'
import ItinerariesController from '../itineraries/controller'
import ItinerariesNotFound from '../itineraries/_notFound'
import ItinerariesTravelPolicy from '../itinerariesTravelPolicy'

import ItinerariesElementsListController from './controller'

const ItinerariesElementsList = ({ type = 'outbound' }) => {
  const { t } = useTranslation()
  const filter = useSelector(state => state.filter)
  const searchRS = useSelector(state => state.searchRS)
  const searchRSModel = searchRS.model
  const user = useSelector(state => state.user)
  const itineraries = useSelector(state => state.itineraries)
  const uniqueOutboundItineraries = itineraries.uniqueOutboundItineraries
  const allInboundItineraries = itineraries.allInboundItineraries
  const allOutboundItineraries = itineraries.allOutboundItineraries
  const sortedResultCount = useSelector(state => state.filter.sortedResultCount)
  const sortBy = useSelector(state => state.filter.sortBy)
  const exceedsCount = useSelector(state => state.itineraries.exceedsCount)
  const showExceeds = useSelector(state => state.itineraries.showExceeds)

  const controller = new ItinerariesController(searchRSModel)
  const visibleController = new ItinerariesElementsListController(filter, searchRSModel)

  const outboundElementsList = !allInboundItineraries && controller.getItinerariesHtml(
    sortedResultCount,
    Object.values(uniqueOutboundItineraries),
    sortBy,
    'outbound',
    visibleController,
    allOutboundItineraries,
    showExceeds,
    exceedsCount
  )

  const inboundElementsList = allInboundItineraries && controller.getItinerariesHtml(
    sortedResultCount,
    allInboundItineraries,
    sortBy,
    'inbound',
    visibleController,
    showExceeds,
    exceedsCount
  )


  return (
    <div className='itineraries__elements'>
      <ItinerariesTravelPolicy
        user={user}
        searchRS={searchRS}
        exceedsCount={exceedsCount}
        showExceeds={showExceeds}
      />
      {type === 'outbound' &&
      !searchRS.count &&
      (searchRSModel.status === 'success' || searchRSModel.status === 'failed')
        ? (
          <ItinerariesNotFound text={t('No flights found, select different dates and search again')} />)
        : outboundElementsList}

      {type === 'inbound' && inboundElementsList &&
      !inboundElementsList?.filter(Boolean).length
        ? (
          <ItinerariesNotFound text={t('No flights found, select different dates and search again')} />)
        : inboundElementsList}

    </div>
  )
}

export default ItinerariesElementsList
