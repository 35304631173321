import React from 'react'
import './style.sass'
import Icons from '../../../../pageComponents/icons'

const FullMap = ({ fullMap, setFullMap }) => {
  return (
    <div className='hotelsMap__full-wrapper'>
      <button
        className='hotelsMap__full'
        onClick={() => setFullMap(!fullMap)}
      >
        <Icons
          group='arrow'
          icon='full-map'
          className='hotelsMap__full-iconBig'
        />
        <Icons
          group='arrow'
          icon='small-map'
          className='hotelsMap__full-iconSmall'
        />
      </button>
    </div>
  )
}

export default FullMap
