import AnalyticsFlight from './index'

export default class OrderAnalytics extends AnalyticsFlight {
  constructor () {
    super()
    this.name = 'b2b_bf_checkout'
    this.feature = 'main'
  }

  orderCreate = (action, params) => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.MAIN,
      'orderCreate',
      action,
      params ? Object.keys(params).join(';') : undefined
    )
  }
}
