import { CLEAR_STATE, START_SEARCH } from '../actions'
import { CLEAR_ORDER_RS, UPDATE_ORDER_RS } from './actions'

const defaultState = {
  model: {}
}

const orderRS = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_ORDER_RS:
      return {
        ...state,
        model: action.payload
      }
    case CLEAR_ORDER_RS:
    case CLEAR_STATE:
    case START_SEARCH:
      return defaultState
    default:
      return state
  }
}

export default orderRS
