import countries from '../data/iso3166'
import { isValidPhoneNumber } from 'libphonenumber-js'
import moment from 'moment'
import _ from 'lodash'

export default function passengerValidation (passenger, config, controller) {
  const error = []
  config
    .filter(input => input.visible && input.required)
    .forEach(Input => {
      if (Input.type === 'field') {
        validatePassengerInput(error, passenger, Input, controller)
      } else {
        Input.fields.forEach(input => {
          validatePassengerInput(error, passenger, input, controller)
        })
      }
    })
  return error
}


const validatePassengerInput = (error, passenger, input, controller) => {
  const ALLOWED_CHARS_IN_NAMES = /^[a-zA-Z -]+$/
  const ALLOWED_CHARS_IN_EMAIL = /.+@.+\..+/
  if (input.field !== 'documentExpiryDate' || !passenger.documentExpiryDateUnlimited) {
    if (passenger[input.field] === undefined || passenger[input.field] === null || !passenger[input.field].length) {
      if (passenger.documents && passenger.documents.length) {
        const replaceField = input.field.replace('document', '')
        const docField = replaceField[0].toLowerCase() + replaceField.slice(1)
        if (passenger.documents[0][docField] === undefined || !passenger.documents[0][docField]) {
          errorPush(error, input.field, controller)
        }
      } else {
        errorPush(error, input.field, controller)
      }
    } else if (input.field.indexOf('Name') >= 0) {
      if (!ALLOWED_CHARS_IN_NAMES.test(passenger[input.field])) {
        errorPush(
          error,
          input.field,
          controller,
          'Only latin characters, space and hyphen are allowed ' +
          (controller.getLabelAndOrder(input.field).errorLabel || controller.getLabelAndOrder(input.field).label))
      }
    } else if (input.field.indexOf('mail') >= 0) {
      if (!ALLOWED_CHARS_IN_EMAIL.test(passenger[input.field])) {
        errorPush(
          error,
          input.field,
          controller,
          (controller.getLabelAndOrder(input.field).errorLabel || controller.getLabelAndOrder(input.field).label) +
          ' incorrect'
        )
      }
    } else if (input.field === 'documentCountryCode') {
      if (Object.keys(countries).indexOf(passenger[input.field]) === -1) {
        errorPush(
          error,
          input.field,
          controller,
          (controller.getLabelAndOrder(input.field).errorLabel || controller.getLabelAndOrder(input.field).label) +
          ' incorrect'
        )
      }
    } else if (input.field === 'phone') {
      const phoneField = '+' + passenger[input.field].replace(/[^\d;]/g, '')
      if (!isValidPhoneNumber(phoneField)) {
        errorPush(
          error,
          input.field,
          controller,
          (controller.getLabelAndOrder(input.field).errorLabel || controller.getLabelAndOrder(input.field).label) +
          ' incorrect'
        )
      }
    } else if (input.field === 'birthDate') {
      const limit = moment(input.limit)
      const now = moment(input.now)
      const dateInForm = moment(passenger[input.field])
      const isDateInLimit =
        dateInForm.isBetween(limit, now)

      if (passenger.birthDate.includes('_')) {
        errorPush(
          error,
          input.field,
          controller,
          (controller.getLabelAndOrder(input.field).errorLabel || controller.getLabelAndOrder(input.field).label) +
          ' incorrect'
        )
      } else if (!isDateInLimit && input.limit && passenger[input.field]) {
        errorPush(
          error,
          input.field,
          controller,
          (controller.getLabelAndOrder(input.field).errorLabel || controller.getLabelAndOrder(input.field).label) +
          ' incorrect'
        )
      }
    } else if (input.field === 'documentExpiryDate') {
      if (passenger?.birthDate?.includes('_')) {
        errorPush(
          error,
          input.field,
          controller,
          (controller.getLabelAndOrder(input.field).errorLabel || controller.getLabelAndOrder(input.field).label) +
          ' incorrect'
        )
      }
    }
  }
}

const errorPush = (error, field, controller, message) => {
  error.push({
    field: `${field}-error`,
    message: message || (controller.getLabelAndOrder(field).errorLabel || controller.getLabelAndOrder(field).label) +
      ' is required'
  })
}
