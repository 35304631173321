import React from 'react'
import { useSelector } from 'react-redux'
import PassengerController from '../controller'

const Checkbox = ({ field, error }) => {
  const controller = new PassengerController()
  const passengerForm = useSelector(state => state.passengerForm)
  const name = controller.getLabelAndOrder(field.field).name || field.field
  return (
    <div className='inputDiv__checkbox'>
      <input
        type='checkbox'
        id={field.field}
        name={name}
        defaultChecked={passengerForm[name]}
        checked={passengerForm[name]}
        value='need'
        onChange={controller.handleOnChange}
      />
      <label htmlFor={field.field}>{controller.getLabelAndOrder(field.field).label}{field.required ? '*' : ''}</label>
      <div className='inputDiv__checkbox-row'>
        {error &&
          <div className='error'>
            {error}
          </div>}
      </div>
    </div>
  )
}

export default Checkbox
