import { UPDATE_BROWSER_INFORMATION } from './actions'

const defaultState = {
  browser: '',
  pageSize: ''
}

const browserInformation = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_BROWSER_INFORMATION:
      return {
        ...state,
        [action.field]: action.payload
      }
    default:
      return state
  }
}

export default browserInformation
