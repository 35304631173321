import React from 'react'
import SuccessHotels from '../../../components/successComponents/success/_hotels'

const HotelsSuccessPage = () => {
  return (
    <div className='hotels__page container'>
      <SuccessHotels />
    </div>
  )
}

export default HotelsSuccessPage
