import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { changePrice, changePropertyType, setPrice } from '../../../reducers/filter/actions'
import hotelTypes from '../../../data/hotelTypes'

export default class FilterHotelsController {
  constructor () {
    this.dispatch = useDispatch()
  }

  setFilter = (hotels, filter) => {
    this.setPrice(hotels, filter)
    this.setProperty(hotels, filter)
  }

  setPrice = (hotels, filter) => {
    const filterPrice = [0, 0]
    let currency = ''
    let value = 0
    _.forEach(hotels, hotel => {
      value = hotel.priceInfo.totalDayPrice
      currency = hotel.priceInfo.total.convertedCurrency || hotel.priceInfo.total.currency
      if (!filterPrice[0] || filterPrice[0] > value) {
        filterPrice[0] = value
      }
      if (!filterPrice[1] || filterPrice[1] < value) {
        filterPrice[1] = value
      }
    })


    if (!_.isEqual(filter.price.limit, filterPrice)) {
      this.dispatch(setPrice(filterPrice))
    }
    if (!_.isEqual(filter.price.currency, currency)) {
      this.dispatch(changePrice('currency', currency))
    }
  }

  setProperty = (hotels, filter) => {
    let propertyTypes = []
    _.forEach(hotels, hotel => {
      propertyTypes.push(hotel.hotelType)
    })
    propertyTypes = _.uniq(propertyTypes)
    const propertyTypesObject = []
    _.forEach(propertyTypes, propertyType => {
      const findHotelType = _.find(hotelTypes, hotelType => hotelType.hotel_type_id === propertyType)
      const findFilter = _.find(filter.propertyTypes, filterType => filterType.type === propertyType)
      propertyTypesObject.push({
        type: propertyType,
        name: findHotelType.name,
        checked: findFilter ? findFilter.checked : (findHotelType.default_checked || false)
      })
    })
    if (!_.isEqual(filter.propertyTypes, propertyTypesObject)) {
      this.dispatch(changePropertyType(propertyTypesObject))
    }
  }
}
