export const CHANGE_DIRECT = 'CHANGE_DIRECT'
export const SET_DURATION = 'SET_DURATION'
export const CHANGE_DURATION = 'CHANGE_DURATION'
export const CLEAR_DURATION = 'CLEAR_DURATION'
export const CHANGE_TIME = 'CHANGE_TIME'
export const CLEAR_TIME = 'CLEAR_TIME'
export const SET_AIRLINES = 'SET_AIRLINES'
export const CHANGE_AIRLINE = 'CHANGE_AIRLINE'
export const CHANGE_ORDER = 'CHANGE_ORDER'
export const SET_PRICE = 'SET_PRICE'
export const CHANGE_PRICE = 'CHANGE_PRICE'
export const CLEAR_PRICE = 'CLEAR_PRICE'
export const SET_PRICE_HOTELS = 'SET_PRICE_HOTELS'
export const SET_SORT_BY = 'SET_SORT_BY'
export const CHANGE_LAYOVERS = 'CHANGE_LAYOVERS'
export const CLEAR_LAYOVERS = 'CLEAR_LAYOVERS'

export const PRICE_LOWEST = 'Price (Lowest)'
export const PRICE_HIGHEST = 'Price (Highest)'
export const DEPARTURE_EARLIEST = 'Departure (Earliest)'
export const DEPARTURE_LATEST = 'Departure (Latest)'
export const CHANGE_STARS = 'CHANGE_STARS'
export const CLEAR_STARS = 'CLEAR_STARS'
export const CHANGE_RATING = 'CHANGE_RATING'
export const CLEAR_RATING = 'CLEAR_RATING'
export const CHANGE_BREAKFAST = 'CHANGE_BREAKFAST'
export const CHANGE_CHAINS = 'CHANGE_CHAINS'
export const CHANGE_WIFI = 'CHANGE_WIFI'
export const CHANGE_SEARCH = 'CHANGE_SEARCH'
export const CLEAR_SEARCH_FILTER = 'CLEAR_SEARCH_FILTER'
export const SET_PROPERTY_TYPE = 'SET_PROPERTY_TYPE'
export const CHANGE_PROPERTY_TYPE = 'CHANGE_PROPERTY_TYPE'
export const CHANGE_PAYMENT_AND_BOOKING = 'CHANGE_PAYMENT_AND_BOOKING'
export const UPDATE_COUNTER = 'UPDATE_COUNTER'
export const CLEAR_ALL_FILTER = 'CLEAR_ALL_FILTER'


export const changeDirect = (payload) => ({
  type: CHANGE_DIRECT,
  payload
})

export const setDuration = (payload) => ({
  type: SET_DURATION,
  payload
})

export const changeDuration = (field, payload) => ({
  type: CHANGE_DURATION,
  field,
  payload
})


export const clearDuration = () => ({
  type: CLEAR_DURATION,
})

export const changeTime = (field, payload) => ({
  type: CHANGE_TIME,
  field,
  payload
})

export const clearTime = (field) => ({
  type: CLEAR_TIME,
  field,
})

export const setAirlines = (payload) => ({
  type: SET_AIRLINES,
  payload
})

export const changeAirline = (payload) => ({
  type: CHANGE_AIRLINE,
  payload,
})

export const changeOrder = () => ({
  type: CHANGE_ORDER,
})

export const setPrice = (payload) => ({
  type: SET_PRICE,
  payload
})
export const changePrice = (field, payload) => ({
  type: CHANGE_PRICE,
  field,
  payload
})
export const clearPrice = () => ({
  type: CLEAR_PRICE
})
export const setPriceHotels = (payload) => ({
  type: SET_PRICE_HOTELS,
  payload
})
export const setSortBy = (payload) => ({
  type: SET_SORT_BY,
  payload
})

export const changeBreakfast = (payload) => ({
  type: CHANGE_BREAKFAST,
  payload
})
export const changeChains = (payload) => ({
  type: CHANGE_CHAINS,
  payload
})
export const changeWifi = (payload) => ({
  type: CHANGE_WIFI,
  payload
})
export const changeSearch = (payload) => ({
  type: CHANGE_SEARCH,
  payload
})
export const clearSearchFilter = () => ({
  type: CLEAR_SEARCH_FILTER,
})
export const changeStars = (field, payload) => ({
  type: CHANGE_STARS,
  field,
  payload
})
export const clearStars = () => ({
  type: CLEAR_STARS
})
export const changeRating = (field, payload) => ({
  type: CHANGE_RATING,
  field,
  payload
})
export const clearRating = () => ({
  type: CLEAR_RATING
})

export const setPropertyType = (payload) => ({
  type: SET_PROPERTY_TYPE,
  payload
})

export const changePropertyType = (payload) => ({
  type: CHANGE_PROPERTY_TYPE,
  payload,
})
export const changePaymentAndBooking = (payload) => ({
  type: CHANGE_PAYMENT_AND_BOOKING,
  payload,
})

export const changeLayovers = (field, payload) => ({
  type: CHANGE_LAYOVERS,
  field,
  payload
})

export const clearLayovers = () => ({
  type: CLEAR_LAYOVERS
})

export const updateCounter = (field, payload) => ({
  type: UPDATE_COUNTER,
  field,
  payload
})

export const clearAllFilter = () => ({
  type: CLEAR_ALL_FILTER
})
