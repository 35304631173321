import { CLEAR_STATE } from '../actions'
import { UPDATE_BUTTON } from './actions'

const defaultState = {
  SEARCH_BUTTON: {
    disabled: false,
    active: false,
    isLoading: false
  },
  FARE_BUTTON: {
    disabled: false,
    active: false,
    isLoading: false
  },
  PAY_BUTTON: {
    disabled: false,
    active: false,
    isLoading: false
  },
  CONFIRMATION_BUTTON: {
    disabled: false,
    active: false,
    isLoading: false
  }
}

const button = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_BUTTON:
      return {
        ...state,
        [action.button]: {
          ...state[action.button],
          [action.field]: action.payload
        }
      }
    case CLEAR_STATE:
      return defaultState
    default:
      return state
  }
}

export default button
