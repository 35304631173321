import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import Tooltip from '../../../pageComponents/tooltip'

const Info = ({ duration, carriers, openDetail, element }) => {
  const { t, i18n } = useTranslation()
  const user = useSelector(state => state.user)

  return (
    <div className='info'>
      <div className='info__leftBlock'>
        <div className={'info-time firstRow ' + (!openDetail ? 'firstRow-show' : 'firstRow-hide')}>
          <Moment
            format='LT '
            locale={i18n.language}
          >
            {duration.departureDate}
          </Moment>
          <Moment
            format=' - LT '
            locale={i18n.language}
          >
            {duration.arrivalDate}
          </Moment>
          {duration.differenceOfDays > 0 &&
            <span className='differenceOfDays'>+{duration.differenceOfDays}</span>}
        </div>
        <div className={'info-name secondRow ' + (!openDetail ? 'secondRow-show' : 'secondRow-hide')}>
          <span className='info-name'>
            {_.map(_.compact(carriers), carrier => carrier.name).join(', ')}
          </span>
        </div>
        <div className={'info-time info-time-departure firstRow ' + (openDetail ? 'firstRow-show' : 'firstRow-hide')}>
          <span className='departure'>
            {t('Departure')}
          </span>
          <Moment
            format='MMM dd, DD'
            locale={i18n.language}
          >
            {duration.departureDate}
          </Moment>
        </div>
      </div>
      {element && !openDetail &&
        <div className='info__rightBlock '>
          <div className='tooltip-hoverElement'>
            <span className='info__exceeds '>
              {_.first(element.pricingOptions)?.b2bTravelPolicyStatus === 'exceeds'
                ? t('Exceeds Travel Policy')
                : ''}
            </span>
            <Tooltip
              top
              child={
                <div className='tooltip__el'>
                  <span className='white'>
                    {t('You can buy only')} {
                  _.map(user.business?.travel_policy?.flight_ticket_classes, classes =>
                    t(_.upperFirst(classes.replace('_', ' ')))
                  ).join(', ')
                } {t('class tickets')}
                  </span>
                </div>
            }
            />
          </div>
        </div>}
    </div>
  )
}

export default Info
