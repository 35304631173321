import AnalyticsFlight from './index'

export default class ProfileAnalytics extends AnalyticsFlight {
  selectOptions = (options) => {
    this.logEvent(
      this.NAMES.PROFILE,
      this.FEATURE.PROFILE,
      'selectOptions',
      this.ACTIONS.TAP,
      options
    )
  }

  selectLanguage = (language) => {
    this.logEvent(
      this.NAMES.PROFILE,
      this.FEATURE.PROFILE,
      'selectLanguage',
      this.ACTIONS.TAP,
      language
    )
  }
}
