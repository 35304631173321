import React from 'react'
import './style.sass'
import HotelsList from '../../../components/hotelsComponents/_hotelsList'
import Itineraries from '../../../components/itinerariesComponents/itineraries'
import currentLocation from '../../../hooks/currentLocation'

const ResultScreen = () => {
  const { isFlightsPage, isHotelsPage } = currentLocation()

  return (
    <div className='resultScreen'>
      {isFlightsPage && <Itineraries />}
      {isHotelsPage && <HotelsList />}
    </div>
  )
}

export default ResultScreen
