import { useCallback } from 'react'
import _ from 'lodash'

import Api from 'api'
import { useLoadBar } from 'modules/common'
import { ORDERS_SCREEN_UPCOMING } from 'reducers/page/types'

import { OrderType } from '../types'
import { usePopup } from 'context/popup'

export const useRequestHelp = () => {
  const api = new Api()
  const { setOptions, show } = usePopup()

  const [showLoadBar, hideLoadBar] = useLoadBar()

  const
    handleManageBooking = useCallback(async (order: OrderType) => {
      try {
        showLoadBar(ORDERS_SCREEN_UPCOMING)

        if (_.first(order.hotels)) {
          const { aita_order } = _.first(order.hotels)

          api.getProofOfStay(aita_order.provider, aita_order.order_id).then((res) => {
            if (res) {
              setOptions({
                title: 'Help requested',
                text: "We've received your request, your personal manager will contact with you soon",
                buttonText: 'Well, thank you'
              })
              show(true)
            }
            hideLoadBar()
          })
        }
      } catch (error) {
        console.error(error)
      }
    }, [])

  return handleManageBooking
}
