import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import Icons from '../../../../../../../pageComponents/icons'

const OptionMeals = ({ option }) => {
  const { t } = useTranslation()
  const { breakfastIncl, lunchIncl, dinnerIncl } = option
  const icon = (
    <Icons
      group='icon'
      icon='food'
      className='meal__icon'
    />
  )

  const _renderIncludes = () => {
    const includes = []
    if (!breakfastIncl && !lunchIncl && !dinnerIncl) {
      includes.push(<div key='Meals are not included' className='meal'>{icon}{t('Meals are not included')}</div>)
    } else {
      if (breakfastIncl) {
        includes.push(<div key='Breakfast included' className='meal meal__active'>{icon}{t('Breakfast included')}</div>)
      }
      if (lunchIncl) {
        includes.push(<div key='Lunch included' className='meal meal__active'>{icon}{t('Lunch included')}</div>)
      }
      if (dinnerIncl) {
        includes.push(<div key='Dinner included' className='meal meal__active'>{icon}{t('Dinner included')}</div>)
      }
    }

    return includes
  }

  return (
    <div className='optionMeals'>
      {_renderIncludes()}
    </div>
  )
}

export default OptionMeals
