import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Icons from '../../pageComponents/icons'
import BreakfastController from './controller'

const Breakfast = () => {
  const { t } = useTranslation()
  const breakfast = useSelector(state => state.filter.breakfast)
  const controller = new BreakfastController()
  return (
    <div className='breakfast'>
      <input
        type='checkbox'
        id='breakfast'
        checked={breakfast}
        className='breakfast__input'
        onChange={() => {
          controller.handleClick(!breakfast)
        }}
      />
      <label
        htmlFor='breakfast'
        className='breakfast__label filter__el'
      >
        {t('Breakfast')}
        <Icons
          group='icon'
          icon='close'
          className='breakfast__label-icon'
          color='#3D73EC'
        />
      </label>
    </div>
  )
}
export default Breakfast
