import React from 'react'
import './style.sass'
import Icons from '../../../pageComponents/icons'

const HotelPhotosCount = ({ count }) => {
  return (
    <div className='hotelPhotosCount'>
      <Icons
        group='icon'
        icon='image'
        className='hotelPhotosCount-icon'
      />
      {count}
    </div>

  )
}

export default HotelPhotosCount
