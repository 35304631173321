import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import Icons from '../../../../pageComponents/icons'
import DetailListController from './controller'
import getSegments from '../../../../../hooks/getSegments'

const DetailList = ({ openDetail, element, searchRS }) => {
  if (!searchRS) return ''
  if (!Object.keys(searchRS).length) return ''
  const { t, i18n } = useTranslation()
  const controller = new DetailListController(searchRS, element)
  const segments = getSegments(element)
  return (
    <div className={'detail__list ' + (openDetail && 'detail__list-open')}>
      {segments.map((segment, index) => {
        const carrier = controller.getCarrier(segment)
        const transferring = controller.getTransferring(index)
        return (
          <div
            key={segment.flightId}
            className='detail__list-segment'
          >
            {!index
              ? ''
              : (
                <div className='segment__transferring'>
                  <div className='segment__transferring-icon'>
                    <Icons
                      group='icon'
                      icon='history'
                      color='#D19402'
                    />
                  </div>
                  <div className='segment__transferring-wrap'>
                    <div className='segment__transferring-time'>
                      <span>{controller.getTimeFormat(transferring.minutes)}</span>
                      {t('layover in')} {controller.getAirport(segment.origin)?.city}
                    </div>
                    {controller.showOtherTerminal(index) &&
                      <div className='segment__transferring-terminal'>
                        {t('Departing from a different airport')}
                      </div>}
                  </div>
                </div>
                )}
            <div className='segment__info'>
              {!carrier
                ? null
                : <img src={controller.getCarrier(segment)} alt='' className='segment__info-logo' />}
              <div className='segment__info-flight'>
                {segment.carrier} {segment.number}
              </div>
            </div>
            {transferring.days
              ? (
                <div className='segment__nextDay'>
                  {t('Depart the next day')} (
                  <Moment
                    format='MMM DD'
                    locale={i18n.language}
                  >
                    {segment.departureDate}
                  </Moment>
                  )
                </div>
                )
              : ''}
            <div className='segment__direction'>
              <Moment
                format='LT '
                locale={i18n.language}
              >
                {segment.departureDate}
              </Moment>
              {controller.getAirport(segment.origin)?.name || ''} &nbsp;
              ({controller.getAirport(segment.origin)?.code || ''})
              {segment.originTerminal
                ? '  ∙  ' + t('Terminal') + ' ' + segment.originTerminal
                : ''}
            </div>
            <div className='segment__duration'>
              {t('Travel time')}: {controller.getTimeFormat(segment.duration)}
            </div>
            <div className='segment__direction'>
              <Moment
                format='LT '
                locale={i18n.language}
              >
                {segment.arrivalDate}
              </Moment>
              {controller.getAirport(segment.destination)?.name || ''} &nbsp;
              ({controller.getAirport(segment.destination)?.code || ''})
              {segment.destinationTerminal
                ? '  ∙  ' + t('Terminal') + ' ' + segment.destinationTerminal
                : ''}
            </div>
            {controller.getDifferentDate(segment) > 0 &&
              <div className='segment__nextDay'>
                {t('Arrival the next day')} (
                <Moment
                  format='MMM DD'
                  locale={i18n.language}
                >
                  {segment.arrivalDate}
                </Moment>
                )
              </div>}

          </div>
        )
      })}
    </div>
  )
}

export default DetailList
