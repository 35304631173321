import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import usePrice from '../../../hooks/usePrice'
import { FARE_BUTTON } from '../../../reducers/button/types'
import LoadButton from '../../pageComponents/loadButton'
import FarePriceController from './controller'

const Price = () => {
  const { t } = useTranslation()
  const fare = useSelector(state => state.orderFlights.pricingOption.fareInfo)
  const orderPricingOption = useSelector(state => state.orderFlights.pricingOption)
  const priceRSPricingOption = useSelector(state => state.priceRS.model.pricingOption)
  const error = useSelector(state => state.error)
  const currencies = useSelector(state => state.searchRS.model.currencies)
  let price
  if (priceRSPricingOption) {
    price = usePrice(currencies, priceRSPricingOption)
  } else {
    price = usePrice(currencies, orderPricingOption)
  }
  const clientMode = useSelector(state => state.user.clientMode)
  const passengers = useSelector(state => state.searchForm.passengers.count)
  const controller = new FarePriceController(price, fare, clientMode)
  const FareButton = useSelector(state => state.button[FARE_BUTTON])
  return (
    <div className='farePrice'>
      <div className='farePrice__header'>
        {t('Price details')}
      </div>
      <div className='farePrice__passengers row'>
        <div className='passengers'>
          {t('Adult', { count: passengers.ADT })}
          {passengers.CHD
            ? ', ' + passengers.CHD + ' ' + t('Children')
            : ''}
          {passengers.INF
            ? ', ' + passengers.INF + ' ' + t('Infants')
            : ''}
        </div>
        <div className='price'>
          {price.base}
        </div>
      </div>
      <div className='farePrice__fare  row'>
        <div className='fare'>
          {fare.title}
        </div>
        <div className='price'>
          {price.base}
        </div>
      </div>
      <div className='farePrice__tax row'>
        <div className='tax'>
          {t('Taxes and fares')}
        </div>
        <div className='price'>
          {price.tax}
        </div>
      </div>
      <div className='farePrice__total row'>
        <div className='total'>
          {t('Total')}:
        </div>
        <div className='price'>
          {price.value}
        </div>
      </div>
      {!error.hasError &&
        <button
          className='farePrice__button'
          onClick={controller.handleOnClick}
          disabled={FareButton.disabled}
        >
          {FareButton.isLoading
            ? <LoadButton />
            : t('Continue')}
        </button>}
    </div>
  )
}

export default Price
