import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PassengerController from '../../controller'
import DayPicker from 'react-day-picker'
import MaskedInput from 'react-maskedinput'
import OutsideClickHandler from 'react-outside-click-handler'
import YearMonthForm from '../YearMonthForm'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import { useTranslation } from 'react-i18next'

const DatePicker = ({ field, error, passengerType }) => {
  const { t } = useTranslation()
  const controller = new PassengerController()
  const user = useSelector(state => state.user)
  const [openDropdown, setOpenDropdown] = useState(false)
  const passengerForm = useSelector(state => state.passengerForm)
  const documentExpiryDateUnlimited = useSelector(state => state.passengerForm.documentExpiryDateUnlimited)
  const value = passengerForm[field.field]
  const { currentYear, nowMonth, fromMonth } = controller.limitededYear(field.field, passengerType)
  const toMonth = new Date(currentYear + (field.field === 'documentExpiryDate' ? 50 : 0), 0)

  const [month, setMonth] = useState(nowMonth)

  const [inputValue, setInputValue] = useState('')

  return (
    <>
      <label htmlFor={field.field}>{controller.getLabelAndOrder(field.field).label}{field.required ? '*' : ''}</label>
      <div className='dropdown'>
        <MaskedInput
          mask='11.11.1111'
          placeholder={t('DD.MM.YYYY')}
          type='text'
          name={field.field}
          id={field.field}
          className={'input ' + (openDropdown && 'input-focus')}
          value={passengerForm.id
            ? controller.setValueSavedPassenger(value)
            : inputValue}
          onChange={(e) => {
            setInputValue(e.target.value)
            controller.handleOnChangeDateInput(e.target)
          }}
          onClick={() => setOpenDropdown(true)}
          autoComplete='off'
          tabIndex={controller.getLabelAndOrder(field.field).order}
          disabled={
            (field.field === 'documentExpiryDate' && documentExpiryDateUnlimited) ||
            controller.checkReadOnlyStatus(field.field, user, passengerForm)
          }
        />
        {error &&
          <div className='error'>
            {error}
          </div>}
        <OutsideClickHandler
          onOutsideClick={() => {
            setOpenDropdown(false)
          }}
        >
          <div className={
                  'input__dropdown ' +
                  (openDropdown && ' input__dropdown-open ') +
                  (field.field === 'documentExpiryDate' && 'input__dropdown-top')
                }
          >
            <DayPicker
              month={month}
              fromMonth={fromMonth}
              toMonth={toMonth}
              fixedWeeks
              onDayClick={day => {
                controller.handleOnClickDayCalendar(day, field.field)
                setInputValue(controller.setValueSavedPassenger(day))
                setOpenDropdown(false)
              }}
              captionElement={({ date, localeUtils }) => (
                <YearMonthForm
                  date={date}
                  localeUtils={localeUtils}
                  onChange={setMonth}
                  fromMonth={fromMonth}
                  toMonth={toMonth}
                />
              )}
            />
          </div>
        </OutsideClickHandler>
      </div>
    </>
  )
}

export default DatePicker
