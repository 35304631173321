import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ChangeFare from '../../../components/fareComponents/_changeFare'
import FareHeader from '../../../components/fareComponents/_header'
import Itinerary from '../../../components/fareComponents/_Itinerary'
import Price from '../../../components/fareComponents/_price'

const FareScreen = () => {
  const { t } = useTranslation()
  const outbound = useSelector(state => state.itineraries.outbound)
  const inbound = useSelector(state => state.itineraries.inbound)
  return (
    <div className='fareScreen fare'>
      <FareHeader
        text={t('You’ve selected')}
      />
      <div className='fare__row'>
        <div className='fare__options fare__block'>
          <div className='itineraries'>
            <Itinerary element={outbound} type='outbound' />
            {inbound &&
              <Itinerary element={inbound} type='inbound' />}
          </div>
          <ChangeFare />
        </div>
        <div className='fare__price fare__block'>
          <Price />
        </div>
      </div>
    </div>
  )
}

export default FareScreen
