import React from 'react'
import { useSelector } from 'react-redux'
import PassengerController from '../controller'

const Radio = ({ field, error }) => {
  const controller = new PassengerController()
  const passengerForm = useSelector(state => state.passengerForm)
  const user = useSelector(state => state.user)

  return (
    <div className='inputDiv__radio'>
      <label>{controller.getLabelAndOrder(field.field).label}{field.required ? '*' : ''}</label>
      <div className='inputDiv__radio-row'>
        {field.enum.map((radio, index) => {
          return (
            <div key={field.field + radio.value + index} className='radio'>
              <input
                type='radio'
                name={field.field}
                id={radio.value}
                defaultChecked={radio.value === passengerForm[field.field]}
                checked={radio.value === passengerForm[field.field]}
                value={radio.value}
                onChange={controller.handleOnChange}
                disabled={controller.checkReadOnlyStatus(field.field, user, passengerForm)}
              />
              <label htmlFor={radio.value}>{radio.name}</label>
            </div>
          )
        })}
        {error &&
          <div className='error'>
            {error}
          </div>}
      </div>
    </div>
  )
}

export default Radio
