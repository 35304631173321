import React, { useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import { useSelector } from 'react-redux'
import SortByController from './controller'

const SortBy = () => {
  const { t } = useTranslation()
  const [openDropdown, setOpenDropdown] = useState(false)
  const controller = new SortByController()
  const filterSortBy = useSelector(state => state.filter.sortBy)
  return (
    <div className='sortBy'>
      <div className='sortBy__label'>
        {t('Sort by')}
      </div>
      <div
        className='sortBy__value'
        onClick={() => setOpenDropdown(!openDropdown)}
      >
        {t(filterSortBy.list[filterSortBy.value])}
      </div>
      <OutsideClickHandler
        onOutsideClick={() => {
          if (openDropdown) {
            setOpenDropdown(false)
          }
        }}
      >
        <div className={'sortBy__dropdown ' + (openDropdown && 'sortBy__dropdown-open')}>
          {filterSortBy.list.map((element, index) =>
            <div
              key={'sortBy__dropdown-element' + element}
              className={'element ' + (index === filterSortBy.value && 'element__active')}
              onClick={() => controller.handleOnClick(index, setOpenDropdown)}
            >
              {t(element)}
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default SortBy
