import { CLEAR_STATE } from '../actions'
import { SET_INFO, RESET_INFO, SHOW_INFO, HIDE_INFO } from './actions'

const defaultState = {
  show: false,
  header: '',
  message: '',
}

const infoPopup = (state = defaultState, action) => {
  switch (action.type) {
    case SET_INFO:
      return {
        ...state,
        show: true,
        header: action.payload.header || '',
        message: action.payload.message || '',
      }
    case CLEAR_STATE:
    case RESET_INFO:
      return defaultState
    case SHOW_INFO:
      return {
        ...state,
        show: true
      }
    case HIDE_INFO:
      return {
        ...state,
        show: false
      }
    default:
      return state
  }
}

export default infoPopup
