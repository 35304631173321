import iuliia from 'iuliia'
import _ from 'lodash'
import { useCallback } from 'react'

import { useAppSelector } from 'reducers'

import { OrderType } from '../types'
import { checkIsFlight, checkIsHotel } from '../utils/checks'
import { getOrderDetails } from '../utils/getters'

export const useHiddenFromSearch = () => {
  const search = useAppSelector(state => state.orders.search)

  const checkIfVisible = useCallback((order: OrderType, isHotel = false) => {
    const { orderDetail: { aita_order } } = getOrderDetails(order, isHotel)
    const searchLowerCase = search.toLowerCase()
    const searchLowerCaseTranslit = iuliia.translate(searchLowerCase, iuliia.ICAO_DOC_9303)

    if (checkIsFlight(aita_order)) {
      return Boolean(_.find(aita_order.passengers, passenger =>
        passenger.first_name.toLowerCase().includes(searchLowerCase) ||
        passenger.first_name.toLowerCase().includes(searchLowerCaseTranslit) ||
        passenger.last_name.toLowerCase().includes(searchLowerCase) ||
        passenger.last_name.toLowerCase().includes(searchLowerCaseTranslit)
      ))
    }
    if (checkIsHotel(aita_order)) {
      return (
        aita_order.booker.firstName.toLowerCase().includes(searchLowerCase) ||
        aita_order.booker.firstName.toLowerCase().includes(searchLowerCaseTranslit) ||
        aita_order.booker.lastName.toLowerCase().includes(searchLowerCase) ||
        aita_order.booker.lastName.toLowerCase().includes(searchLowerCaseTranslit)
      )
    }
    return true
  }, [search])

  return checkIfVisible
}
