import React from 'react'
import '../style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import BookingReference from '../../_bookingReference'
import Passengers from '../../_passenger'
import Flights from '../../_flights'

const SuccessFlights = () => {
  const { t } = useTranslation()
  const orderRS = useSelector(state => state.orderRS.model)
  return (
    <div className='success'>
      <div className='success__header'>
        {orderRS.name || (orderRS.configuration ? '' : t('Success, have a nice flight!'))}
        <span>
          {orderRS.message || t('Order details')}
        </span>
      </div>
      {!orderRS.name &&
        <>
          {orderRS.order.passengers[0].email
            ? (
              <div className='success__email'>
                {t('Email sent on')}
                <span> {orderRS.order.passengers[0].email}</span>
              </div>)
            : ''}

          <BookingReference />
          <Flights />
          <Passengers />
        </>}
    </div>
  )
}
export default SuccessFlights
