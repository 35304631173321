import React from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { usePage, ListElement, useDispatchOnce } from 'modules/common'
import {
  useOrdersPageAnalytics,
  useSeparateOrders,
  useHiddenFromSearch,
} from 'modules/orders'
import { ORDERS_SCREEN_UPCOMING, ORDERS_SCREEN_PAST } from 'reducers/page/types'
import { useHandleManageBooking } from 'modules/orders/hooks/useHandleManageBooking'
import { updateOrders } from 'reducers/orders/actions'
import { uniqByOrders } from 'modules/orders/utils/uniqByOrders'
import { OrderType } from '../../types'
import { useRequestHelp } from 'modules/orders/hooks/useRequestHelp'

interface MainOrdersProps {
  page: typeof ORDERS_SCREEN_UPCOMING | typeof ORDERS_SCREEN_PAST
  orders: OrderType[]
}

export const MainOrders: React.FC<MainOrdersProps> = React.memo(({ page, orders }) => {
  useDispatchOnce(updateOrders('search', ''))
  usePage(page)
  const handleLinkClick = useHandleManageBooking()
  const handleRequestHelp = useRequestHelp()
  const isHidden = useHiddenFromSearch()

  const { t } = useTranslation()

  const parent = useOrdersPageAnalytics()
  const list = useSeparateOrders(orders)

  return (
    <div className='orders' ref={parent}>
      {_.size(list)
        ? _.map(uniqByOrders(list), order =>
          <React.Fragment key={order.id}>
            {(_.size(_.first(order?.flights)) && _.first(order?.flights).aita_order)
              ? <ListElement
                  order={order}
                  isHidden={!isHidden(order)}
                  onLinkClick={() => handleLinkClick(order)}
                />
              : null}
            {(_.size(_.first(order?.hotels)) && _.first(order.hotels).deleted !== true &&
              _.first(order.hotels).aita_order)
              ? <ListElement
                  order={order}
                  isHotel
                  isHidden={!isHidden(order, true)}
                  onLinkClick={() => handleLinkClick(order)}
                  onRequestHelp={() => handleRequestHelp(order)}
                />
              : null}
          </React.Fragment>)
        : <div className='notFound'>{t('No orders found')}</div>}
    </div>
  )
})
