import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import GuestsController from '../controller'
import SearchAnalyticHotel from '../../../../analytics/analyticsHotel/searchAnalytics'

const Adults = ({ indexRoom }) => {
  const { t } = useTranslation()
  const controller = new GuestsController()
  const guests = useSelector(state => state.searchForm.guests)

  return (
    <div className='adults'>
      <div className='adults__text'>
        {t('Adults')}
      </div>
      <div className='adults__calc'>
        <button
          className='adults__calc-btn adults__calc-minus'
          disabled={guests.rooms[indexRoom].adults === 1}
          onClick={() => {
            controller.handleOnChangeAdults(false, guests.rooms, indexRoom)
          }}
        />
        <span className='adults__calc-value'>{guests.rooms[indexRoom].adults}</span>
        <button
          className='adults__calc-btn adults__calc-plus'
          disabled={guests.rooms[indexRoom].adults === 8}
          onClick={() => {
            controller.handleOnChangeAdults(true, guests.rooms, indexRoom)
          }}
        />
      </div>
    </div>
  )
}

export default Adults
