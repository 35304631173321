import { useCallback } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Api from 'api'
import { useLoadBar } from 'modules/common'
import { updateOrderRS } from 'reducers/order/actions'
import { changePage } from 'reducers/page/actions'
import { ORDERS_SCREEN_UPCOMING, SUCCESS_SCREEN } from 'reducers/page/types'
import { HOTELS_SUCCESS_PAGE } from 'navigation/routes'

import { OrderType } from '../types'

export const useHandleManageBooking = () => {
  const api = new Api()

  const dispatch = useDispatch()
  const history = useHistory()

  const [showLoadBar, hideLoadBar] = useLoadBar()

  const
  // отображенеие детальной инормации
    handleManageBooking = useCallback(async (order: OrderType) => {
      try {
        showLoadBar(ORDERS_SCREEN_UPCOMING)

        // для полетов делаем отдельный запрос
        if (_.first(order.flights)) {
          const flight = _.first(order.flights)
          const res = await api.getOrderModel(
            flight?.aita_order.provider,
            flight?.aita_order.order_id)

          hideLoadBar()

          if (res) {
            const result = Object.assign(res, { aita_order: flight?.aita_order })

            dispatch(updateOrderRS(result))
            dispatch(changePage(SUCCESS_SCREEN))
            history.push('/')
          }
        } else {
          // для отелей все сразу есть
          const hotels = _.first(order.hotels)
          const OrderObj = {
            trip: {
              hotel: hotels
            },
            order: hotels.aita_order
          }
          hideLoadBar()
          dispatch(updateOrderRS(OrderObj))
          dispatch(changePage(SUCCESS_SCREEN))
          history.push(HOTELS_SUCCESS_PAGE)
        }
      } catch (error) {
        console.error(error)
      }
    }, [])

  return handleManageBooking
}
