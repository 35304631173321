import React from 'react'
import './style.sass'
import { useDispatch, useSelector } from 'react-redux'
import { socketDisconnect } from '../../../api/socket'
import { clearState } from '../../../reducers/actions'
import { hideInfo } from '../../../reducers/infoPopup/actions'
import { changePage } from '../../../reducers/page/actions'
import { SEARCH_SCREEN } from '../../../reducers/page/types'
import SummaryController from '../../purchaseComponents/_summary/controller'
import Icons from '../icons'

const InfoPopup = ({ order, currency, pricingOption, setIFrame3DSpaymentUrl }) => {
  const dispatch = useDispatch()
  const controller = new SummaryController()
  const info = useSelector(state => state.infoPopup)
  const reserve = useSelector(state => state.orderReserve.model)
  return (
    <div className={'infoPopup ' + (info.show && 'infoPopup__show')}>
      <div className='infoPopup__wrapper'>
        <button
          onClick={() => dispatch(hideInfo())}
          className='infoPopup__close'
        >
          <Icons
            group='icon'
            icon='close'
          />
        </button>
        <div className='infoPopup__wrapper-header'>
          {info.header}
        </div>
        <div className='infoPopup__wrapper-message'>
          {info.message}
        </div>
        <div className='infoPopup__wrapper-buttons'>
          <button
            className='infoPopup__wrapper-button accent'
            onClick={() => {
              controller.orderIssue(order, currency, pricingOption, setIFrame3DSpaymentUrl, reserve)
              dispatch(hideInfo())
            }}
          >
            Yes, book
          </button>
          <button
            className='infoPopup__wrapper-button'
            onClick={() => {
              dispatch(clearState())
              socketDisconnect()
              dispatch(changePage(SEARCH_SCREEN))
            }}
          >
            Start new search
          </button>
        </div>
      </div>
    </div>
  )
}

export default InfoPopup
