import { useDispatch } from 'react-redux'
import ResultsAnalytics from '../../../../analytics/analyticsFlight/resultsAnalytics'
import { updateItineraries } from '../../../../reducers/itineraries/actions'

export default class ChooseOutboundController {
  constructor () {
    this.dispatch = useDispatch()
    this.analytics = new ResultsAnalytics()
  }

  handleOnClick = () => {
    this.dispatch(updateItineraries('outbound', null))
    this.dispatch(updateItineraries('allInboundItineraries', null))
    this.analytics.clickChangeOutbound()
  }
}
