import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import SearchAnalyticHotel from '../../../analytics/analyticsHotel/searchAnalytics'
import Api from '../../../api'
import generateHotelPeople from '../../../hooks/generateHotelPeople'
import { setError } from '../../../reducers/error/actions'
import { updateHotel } from '../../../reducers/hotel/actions'
import { updateSearchForm } from '../../../reducers/search/actions'
import _ from 'lodash'

export default class GuestsController {
  constructor () {
    this.dispatch = useDispatch()
    this.api = new Api()
    this.i18n = useTranslation().i18n
    this.t = useTranslation().t
    this.analyticsHotel = new SearchAnalyticHotel()
  }

  getValue = (searchForm) => {
    const guests = generateHotelPeople(searchForm, this.t)
    this.dispatch(updateHotel('guests', guests))
    return guests.value
  }

  handleOnFocus = (setInputFocus) => {
    setInputFocus(true)
    this.dispatch(updateSearchForm('guests', 'visible', true))
  }

  handleOnBlur = (setInputFocus, countAdaltsWithChilds) => {
    setTimeout(() => {
      setInputFocus(false)
      this.dispatch(updateSearchForm('guests', 'visible', false))
      this.analyticsHotel.setGuests(countAdaltsWithChilds)
    }, 500)
  }

  handleOnChangeAdults = (increment, rooms, index) => {
    const newRooms = _.cloneDeep(rooms)
    if (increment) {
      if (newRooms[index].adults < 8)
        newRooms[index].adults += 1
    } else {
      if (newRooms[index].adults > 1) {
        newRooms[index].adults -= 1
      }
    }
    this.dispatch(updateSearchForm('guests', 'rooms', newRooms))
  }

  handleOnClickAgeChildren = (indexRoom, indexChild, rooms, age) => {
    const newRooms = _.cloneDeep(rooms)
    if (typeof indexChild === 'number') {
      newRooms[indexRoom].children[indexChild] = age
    } else {
      if (_.size(newRooms[indexRoom].children) < 8) {
        newRooms[indexRoom].children.push(age)
      }
    }
    this.dispatch(updateSearchForm('guests', 'rooms', newRooms))
  }

  handleOnRemoveChildren = (indexRoom, indexChild, rooms) => {
    const newRooms = _.cloneDeep(rooms)
    _.remove(newRooms[indexRoom].children, (child, index) => index === indexChild)
    this.dispatch(updateSearchForm('guests', 'rooms', newRooms))
  }

  handleOnAddRoom = (/*rooms*/) => {
    this.analyticsHotel.clickRooms()
    this.dispatch(setError({
      name: 'Information',
      message: 'Sorry, this functionality is in development'
    }))
    // TODO Open when multiple room selections are available
    // const newRooms = _.cloneDeep(rooms)
    // newRooms.push({
    //   adults: 1,
    //   children: []
    // })
    // this.dispatch(updateSearchForm('guests', 'rooms', newRooms))
  }

  handleOnRemoveRoom = (indexRoom, rooms) => {
    const newRooms = _.cloneDeep(rooms)
    _.remove(newRooms, (room, index) => index === indexRoom)
    this.dispatch(updateSearchForm('guests', 'rooms', newRooms))
  }
}
