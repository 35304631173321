import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import { OrderType, AitaOrder } from '../types'

// сортировка всех ордеров по времени (новые вверху)
export const sortOrders = (a: OrderType, b: OrderType, direction: 'asc' | 'desc') => {
  let dateA
  let dateB
  if (a.flights?.[0]?.aita_order) {
    dateA = moment(a.flights?.[0].aita_order?.outbound_segments[0]?.departure_date).unix()
  }
  if (b.flights?.[0]?.aita_order) {
    dateB = moment(b.flights?.[0].aita_order?.outbound_segments[0]?.departure_date).unix()
  }
  if (a.hotels?.[0]?.aita_order && !a.hotels?.[0]?.deleted) {
    dateA = dateA || moment(a.hotels?.[0].aita_order?.date_from).unix()
  }
  if (b.hotels?.[0]?.aita_order && !b.hotels?.[0]?.deleted) {
    dateB = dateB || moment(b.hotels?.[0].aita_order?.date_from).unix()
  }
  if (direction === 'asc') {
    return dateA - dateB
  }
  if (direction === 'desc') {
    return dateB - dateA
  }
  return 0
}
