import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { changeLocation } from '../reducers/page/actions'

const UsePageViews = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(
    () => {
      dispatch(changeLocation(location))
    },
    [location]
  )
  return null
}

export default UsePageViews
