import { useDispatch, useSelector } from 'react-redux'
import Api from '../../../api'
import { updateOrders } from '../../../reducers/orders/actions'
import { changePage } from '../../../reducers/page/actions'
import { ORDERS_SCREEN_UPCOMING } from '../../../reducers/page/types'

export default class OrdersController {
  constructor () {
    this.dispatch = useDispatch()
    this.api = new Api()
    this.orders = useSelector(state => state.orders)
  }

  setScreen = () => {
    this.dispatch(changePage(ORDERS_SCREEN_UPCOMING))
    this.dispatch(updateOrders('search', ''))
  }
}
