import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getStopsDuration } from '../../../utils/stops'
import _ from 'lodash'


const NO_STOP = 1
const ONE_STOP = 2
const TWO_STOPS = 3
export default class ItinerariesElementsListController {
  constructor (filter, searchRS) {
    this.t = useTranslation().t
    this.filter = filter
    this.searchRS = searchRS
  }

  hideByFilter = (element, type = 'outbound') => {
    const itineraries = type === 'outbound' ? element.outbound : element.inbound
    const lengthList = type === 'outbound' ? element.outbound?.length : element.inbound?.length

    return (
      this._filterAirlines(itineraries) ||
      this._filterDirection(itineraries) ||
      this._filterDuration(itineraries) ||
      this._filterTimeDeparture(itineraries) ||
      this._filterTimeArrival(itineraries) ||
      this._filterPrice(element) ||
      this._filterLayovers(lengthList, itineraries) ||
      false
    )
  }

  hideByTravelPolicy = element => {
    return _.first(element.pricingOptions).b2bTravelPolicyStatus === 'exceeds'
  }

  _duration = (itineraries) => {
    const segments = Object.values(this.searchRS.segments)
      .filter(segment => itineraries.indexOf(segment.flightId) >= 0)
      .sort((a, b) => moment(a.departureDate).unix() - moment(b.departureDate).unix())

    const itinerariesCount = itineraries.length
    const transferring = {}
    let duration = Object.values(segments).reduce((a, b) => a + b.duration, 0) || 0
    transferring.allTime = 0
    transferring.times = []
    transferring.code = []
    transferring.airports = []
    if (itinerariesCount === 1) {
      transferring.count = this.t('Direct')
    } else {
      transferring.count = this.t('stop', { count: itinerariesCount - 1 })
      itineraries.forEach((itinerary, index) => {
        if (index && index !== itinerariesCount) {
          const departureDate =
            this.searchRS.segments[itinerary] !== undefined
              ? moment(this.searchRS.segments[itinerary].departureDate)
              : moment()
          const prevArrivalDate =
            this.searchRS.segments[itineraries[index - 1]] !== undefined
              ? moment(this.searchRS.segments[itineraries[index - 1]].arrivalDate)
              : moment()
          const transferringTime = departureDate.diff(prevArrivalDate, 'minutes', false)
          duration = parseInt(duration + transferringTime)
          transferring.allTime = parseInt(transferring.allTime + transferringTime)
          transferring.times.push(moment.duration(transferringTime, 'minutes'))
          const code = this.searchRS.segments[itinerary] ? this.searchRS.segments[itinerary].origin : ''
          transferring.code.push(code)
          transferring.airports.push(code.length ? this.searchRS.airports[code]?.name : '')
        }
      })
      transferring.allTime = moment.duration(transferring.allTime, 'minutes')
    }
    const departureDate =
      this.searchRS.segments[itineraries[0]]
        ? this.searchRS.segments[itineraries[0]].departureDate
        : moment()
    const arrivalDate =
      this.searchRS.segments[itineraries[itineraries.length - 1]]
        ? this.searchRS.segments[itineraries[itineraries.length - 1]].arrivalDate
        : moment()
    const startDepartureDate = moment(departureDate).startOf('day')
    const startArrivalDate = moment(arrivalDate).startOf('day')
    const differenceOfDays = startArrivalDate.diff(startDepartureDate, 'day', true)
    return {
      departureDate,
      arrivalDate,
      differenceOfDays,
      duration: moment.duration(duration, 'minutes'),
      transferring
    }
  }

  _filterDirection = (itineraries) => {
    return this.filter.directFlightsOnly && itineraries.length > 1
  }

  _filterDuration = (itineraries) => {
    const duration = this._duration(itineraries).duration
    const filterDurationStart = moment.duration(this.filter.duration.value[0], 'minutes')
    const filterDurationEnd = moment.duration(this.filter.duration.value[1], 'minutes')
    return filterDurationStart > duration || filterDurationEnd < duration
  }

  _filterTimeDeparture = (itineraries) => {
    return this._filterTime(itineraries)
  }

  _filterTimeArrival = (itineraries) => {
    return this._filterTime(itineraries, 'arrivalDate')
  }

  _filterTime = (itineraries, type = 'departureDate') => {
    const date = moment(this._duration(itineraries)[type])
    const timeInFilter = type === 'departureDate' ? this.filter.time.departure : this.filter.time.arrival

    const filterStart = moment(date).hours(timeInFilter.value[0]).minutes(0)
    const filterEnd = moment(date).hours(timeInFilter.value[1]).minutes(0)
    return date.isBefore(filterStart) || date.isAfter(filterEnd)
  }

  _filterAirlines = (itineraries) => {
    const segments = Object.values(this.searchRS.segments)
      .filter(segment => itineraries.indexOf(segment.flightId) >= 0)
      .sort((a, b) => moment(a.departureDate).unix() - moment(b.departureDate).unix())
    let $return = 0
    segments.forEach(segment => {
      _.forEach(this.filter.airlines, (airline) => {
        if (segment.carrier === airline.code && !airline.checked) {
          $return++
        }
      })
    })
    return $return === segments.length
  }

  _filterPrice = (element) => {
    const priceObject =
      element.pricingOptions[0].priceBreakdownConverted ||
      element.pricingOptions[0].priceBreakdown
    return this.filter.price.value[0] > priceObject.value || this.filter.price.value[1] < priceObject.value
  }

  _filterLayovers = (lengthList, itineraries) => {
    const segments = Object.values(this.searchRS.segments)
      .filter(segment => {
        return itineraries.indexOf(segment.flightId) >= 0
      })
      .sort((a, b) => moment(a.departureDate).unix() - moment(b.departureDate).unix())
    const length = lengthList

    const { oneStop, twoStops, moreStops, limit, value: layovers } = this.filter.layovers

    let result = true
    if ((oneStop && length === ONE_STOP) ||
    (twoStops && length === TWO_STOPS) ||
    (moreStops && length > TWO_STOPS)) {
      result = false
    }
    if (!oneStop && !twoStops && !moreStops) {
      result = false
    }
    const stopDuration = getStopsDuration(segments)

    if ((layovers[0] > stopDuration || layovers[1] < stopDuration) && length !== NO_STOP) {
      return true
    }
    if ((limit[0] !== layovers[0] || limit[1] !== layovers[1]) && length === NO_STOP) {
      return true
    }

    return result
  }
}
