const replacementDaysAndMonth = value => {
  const normalizeValue = value.split('.')
  let newValue = [...normalizeValue]
  newValue.splice(0, 0, newValue[1])
  newValue.splice(2, 1)
  newValue = newValue.join('.')

  return newValue
}

export default replacementDaysAndMonth