import { CLEAR_STATE } from '../actions'
import { UPDATE_PASSENGER_IN_FORM, CLEAR_PASSENGER_IN_FORM } from './actions'

const defaultState = {}

const passengerForm = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_PASSENGER_IN_FORM:
      return {
        ...state,
        [action.field]: action.payload
      }
    case CLEAR_STATE:
    case CLEAR_PASSENGER_IN_FORM:
      return defaultState
    default:
      return state
  }
}

export default passengerForm
