import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import replacementDaysAndMonth from './replacementDaysAndMonth'

const handleOnChangeDateInput = (target, setUpdate, lang) => {
  const { name, value } = target
  const correctValue = lang.includes('ru') ? replacementDaysAndMonth(value) : value

  const toDateFormat = moment(correctValue).format('YYYY.MM.DD')
  const isValid = moment(toDateFormat, 'YYYY.MM.DD').isValid()

  if (isValid) {
    setUpdate(name, toDateFormat)
    setUpdate(name + '-error', undefined)
  } else {
    setUpdate(name, value)
  }
}

export default handleOnChangeDateInput