import React, { useState } from 'react'
import './style.sass'
import { Cookies } from 'react-cookie'

import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import avatar from '../../../../assets/img/avatar.png'
import useLocalStorage from '../../../../hooks/useLocalStorage'
import LoginController from '../../../../navigation/page/login/controller'
import { clearState } from '../../../../reducers/actions'
import { changePage } from '../../../../reducers/page/actions'
import { ORDERS_SCREEN_UPCOMING, screenSetting, SEARCH_SCREEN } from '../../../../reducers/page/types'
import ProfileAnalytics from '../../../../analytics/analyticsFlight/profileAnalytics'
import { CLIENT_MODE_BUSINESSES } from '../../../../reducers/user/constans'
import Icons from '../../icons'
import { socketDisconnect } from '../../../../api/socket'
import currentLocation from '../../../../hooks/currentLocation'
import { setFrom } from '../../../../reducers/orders/actions'


const Account = () => {
  const { t } = useTranslation()
  const page = useSelector(state => state.page.name)
  const user = useSelector(state => state.user)
  const profile = user.profile
  const businessData = user.business
  const businessInfo = user.businessInfo
  const dispatch = useDispatch()
  const [openDropdown, setOpenDropdown] = useState(false)
  const { isFlightsPage, isHotelsPage } = currentLocation()
  const analyticsFlight = new ProfileAnalytics()
  const loginController = new LoginController()
  const [ordersIds, setOrdersIds] = useLocalStorage('ordersIds', {})
  const [ordersList, setOrdersList] = useLocalStorage('ordersList', {})
  const cookies = new Cookies()


  let profileAvatar = avatar
  if (profile.success && profile.info.avatar && profile.info.avatar.url) {
    profileAvatar = profile.info.avatar.url
  }

  let userName = ''
  if (profile.success && profile.info.name) {
    userName = profile.info.name.split(' ')[0]
  }
  if (user.clientMode === CLIENT_MODE_BUSINESSES) {
    userName = user.business?.success ? user.business.account_info.first_name : ''
  } else {
    userName = (profile.success && profile.info.name) ? profile.info.name.split(' ')[0] : ''
  }

  return (
    <div
      className={'account ' + (openDropdown && 'account__openDropdown')}
      style={{
        color: screenSetting(page).header.textColor
      }}
    >
      <div className='account__name' onClick={() => setOpenDropdown(!openDropdown)}>
        {userName}
      </div>
      <div className='account__avatar' onClick={() => setOpenDropdown(!openDropdown)}>
        {profile.success
          ? <img src={profileAvatar} alt='avatar' />
          : ''}

      </div>
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpenDropdown(false)
        }}
      >
        <div className='account__dropdown'>
          <div className='account__dropdown-wrap'>
            <Link
              to='/'
              className='account__link'
              onClick={() => {
                analyticsFlight.selectOptions('flights')
                dispatch(clearState())
                socketDisconnect()
                dispatch(changePage(SEARCH_SCREEN))
                setOpenDropdown(false)
              }}
            >
              <Icons
                group='icon'
                icon='airport'
              />
              {t('Flights')}
            </Link>
            <Link
              to='/hotels'
              className='account__link'
              onClick={() => {
                analyticsFlight.selectOptions('flights')
                dispatch(clearState())
                socketDisconnect()
                dispatch(changePage(SEARCH_SCREEN))
                setOpenDropdown(false)
              }}
            >
              <Icons
                group='icon'
                icon='hotels'
              />
              {t('Hotels')}
            </Link>
            <Link
              to='/orders'
              className='account__link'
              onClick={() => {
                if (isFlightsPage) {
                  dispatch(setFrom('flights'))
                  analyticsFlight.selectOptions('orders')
                } else if (isHotelsPage) {
                  dispatch(setFrom('hotel'))
                }
                dispatch(changePage(ORDERS_SCREEN_UPCOMING))
                setOpenDropdown(false)
              }}
            >
              <Icons
                group='icon'
                icon='orders'
              />
              {t('Orders')}
            </Link>
            {businessData.success && businessInfo?.success && businessInfo?.permission_level !== 'basic' &&
              <a
                href={process.env.REACT_APP_PORTAL_URL + '?token=' + cookies.get('auth_token')}
                className='account__link'
              >
                <Icons
                  group='icon'
                  icon='portal'
                />
                {t('Portal')}
              </a>}
            <div
              className='account__link'
              onClick={() => {
                analyticsFlight.selectOptions('logout')
                loginController.logout(setOrdersIds, setOrdersList)
              }}
            >
              <Icons
                group='icon'
                icon='logout'
              />
              {t('Logout')}
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  )
}
export default Account
