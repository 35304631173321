import { CLEAR_STATE } from '../actions'
import {
  CHANGE_DIRECT,
  CHANGE_DURATION,
  CHANGE_TIME,
  SET_AIRLINES,
  CHANGE_AIRLINE,
  CHANGE_ORDER,
  CLEAR_DURATION,
  CLEAR_TIME,
  CHANGE_PRICE,
  CLEAR_PRICE,
  SET_DURATION,
  SET_PRICE,
  CHANGE_BREAKFAST,
  CHANGE_CHAINS,
  CHANGE_WIFI,
  CHANGE_SEARCH,
  CHANGE_STARS,
  CLEAR_STARS,
  CHANGE_RATING,
  CLEAR_RATING,
  SET_PROPERTY_TYPE, CHANGE_PROPERTY_TYPE, CHANGE_PAYMENT_AND_BOOKING,
  SET_SORT_BY,
  DEPARTURE_EARLIEST, DEPARTURE_LATEST, PRICE_HIGHEST, PRICE_LOWEST,
  CLEAR_SEARCH_FILTER, CHANGE_LAYOVERS, CLEAR_LAYOVERS, SET_PRICE_HOTELS,
  UPDATE_COUNTER, CLEAR_ALL_FILTER
} from './actions'

const defaultState = {
  directFlightsOnly: false,
  layovers: {
    oneStop: false,
    twoStops: false,
    moreStops: false,
    limit: [0, 0],
    value: [0, 0]
  },
  duration: {
    limit: [0, 0],
    value: [0, 0]
  },
  time: {
    departure: {
      limit: [0, 24],
      value: [0, 24]
    },
    arrival: {
      limit: [0, 24],
      value: [0, 24]
    }
  },
  airlines: [],
  price: {
    limit: [0, 0],
    value: [0, 0],
  },
  hotelsPrice: [],
  order: true,
  sortBy: {
    list: [
      PRICE_LOWEST,
      PRICE_HIGHEST,
      DEPARTURE_EARLIEST,
      DEPARTURE_LATEST,
    ],
    value: 0
  },
  breakfast: false,
  chains: false,
  wifi: false,
  search: '',
  stars: {
    limit: [0, 5],
    value: [0, 5]
  },
  rating: {
    limit: [0, 10],
    value: [0, 10]
  },
  propertyTypes: [],
  paymentAndBooking: {
    payNow: {
      name: 'Pay now',
      checked: true
    },
    payLater: {
      name: 'Pay later',
      checked: true
    },
  },
  allResultCount: 0,
  sortedResultCount: 0

}

const filter = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_DIRECT:
      return {
        ...state,
        directFlightsOnly: action.payload
      }
    case SET_DURATION:
      return {
        ...state,
        duration: {
          ...state.duration,
          limit: action.payload,
          value: action.payload
        }
      }
    case CHANGE_DURATION:
      return {
        ...state,
        duration: {
          ...state.duration,
          [action.field]: action.payload
        }
      }
    case CLEAR_DURATION:
      return {
        ...state,
        duration: {
          ...state.duration,
          value: state.duration.limit
        }
      }
    case CHANGE_TIME:
      return {
        ...state,
        time: {
          ...state.time,
          [action.field]: {
            ...state.time[action.field],
            value: action.payload
          }
        }
      }
    case CLEAR_TIME:
      return {
        ...state,
        time: {
          ...state.time,
          [action.field]: {
            ...state.time[action.field],
            value: state.time[action.field].limit
          }
        }
      }
    case SET_AIRLINES:
      return {
        ...state,
        airlines: action.payload.map(({ airline, ...o }) => ({
          ...o,
          airline,
          checked: true
        }))
      }
    case CHANGE_AIRLINE:
      return {
        ...state,
        airlines: action.payload
      }
    case CHANGE_ORDER:
      return {
        ...state,
        order: !state.order
      }
    case SET_PRICE:
      return {
        ...state,
        price: {
          ...state.price,
          limit: action.payload,
          value: action.payload
        }
      }
    case CHANGE_PRICE:
      return {
        ...state,
        price: {
          ...state.price,
          [action.field]: action.payload
        }
      }
    case CLEAR_PRICE:
      return {
        ...state,
        price: {
          ...state.price,
          value: state.price.limit
        }
      }
    case SET_PRICE_HOTELS:
      return {
        ...state,
        hotelsPrice: action.payload
      }
    case CHANGE_BREAKFAST:
      return {
        ...state,
        breakfast: action.payload
      }
    case CHANGE_CHAINS:
      return {
        ...state,
        chains: action.payload
      }
    case CHANGE_WIFI:
      return {
        ...state,
        wifi: action.payload
      }
    case CHANGE_SEARCH:
      return {
        ...state,
        search: action.payload
      }
    case CLEAR_SEARCH_FILTER:
      return {
        ...state,
        search: ''

      }
    case CHANGE_STARS:
      return {
        ...state,
        stars: {
          ...state.stars,
          [action.field]: action.payload
        }
      }
    case CLEAR_STARS:
      return {
        ...state,
        stars: {
          ...state.stars,
          value: state.stars.limit
        }
      }
    case CHANGE_RATING:
      return {
        ...state,
        rating: {
          ...state.rating,
          [action.field]: action.payload
        }
      }
    case CLEAR_RATING:
      return {
        ...state,
        rating: {
          ...state.rating,
          value: state.rating.limit
        }
      }
    case SET_PROPERTY_TYPE:
      return {
        ...state,
        propertyTypes: action.payload.map((type, index) => ({
          ...index,
          type,
          checked: true
        }))
      }
    case CHANGE_PROPERTY_TYPE:
      return {
        ...state,
        propertyTypes: action.payload
      }
    case CHANGE_PAYMENT_AND_BOOKING:
      return {
        ...state,
        paymentAndBooking: action.payload
      }
    case SET_SORT_BY:
      return {
        ...state,
        sortBy: {
          ...state.sortBy,
          value: action.payload
        }
      }
    case CHANGE_LAYOVERS:
      return {
        ...state,
        layovers: {
          ...state.layovers,
          [action.field]: action.payload !== undefined ? action.payload : !state.layovers[action.field]
        }
      }
    case CLEAR_LAYOVERS:
      return {
        ...state,
        layovers: {
          ...state.layovers,
          oneStop: false,
          twoStops: false,
          moreStops: false,
          value: state.layovers.limit
        }
      }
    case UPDATE_COUNTER:
      return {
        ...state,
        [action.field]: action.payload
      }
    case CLEAR_ALL_FILTER:
      return {
        ...state,
        directFlightsOnly: false,
        layovers: {
          ...state.layovers,
          oneStop: false,
          twoStops: false,
          moreStops: false,
          value: state.layovers.limit
        },
        duration: {
          ...state.duration,
          value: state.duration.limit
        },
        time: {
          departure: {
            limit: [0, 24],
            value: [0, 24]
          },
          arrival: {
            limit: [0, 24],
            value: [0, 24]
          }
        },
        airlines: state.airlines.map(({ airline, ...o }) => ({
          ...o,
          airline,
          checked: true
        })),
        price: {
          ...state.price,
          value: state.price.limit,
        },
        hotelsPrice: state.hotelsPrice.map(({ hotelPrice, ...o }) => ({
          ...o,
          hotelPrice,
          isChecked: false
        })),
        order: true,
        sortBy: {
          list: [
            PRICE_LOWEST,
            PRICE_HIGHEST,
            DEPARTURE_EARLIEST,
            DEPARTURE_LATEST,
          ],
          value: 0
        },
        breakfast: false,
        chains: false,
        wifi: false,
        search: '',
        stars: {
          limit: [0, 5],
          value: [0, 5]
        },
        rating: {
          limit: [0, 10],
          value: [0, 10]
        },
        propertyTypes: state.propertyTypes.map(({ propertyType, ...o }) => ({
          ...o,
          propertyType,
          checked: true
        })),
        paymentAndBooking: {
          payNow: {
            name: 'Pay now',
            checked: true
          },
          payLater: {
            name: 'Pay later',
            checked: true
          },
        },
      }

    case CLEAR_STATE:
      return defaultState
    default:
      return state
  }
}

export default filter
