import iuliia from 'iuliia'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import ResultsAnalyticsFlight from '../../../analytics/analyticsFlight/resultsAnalytics'
import ResultsAnalyticsHotel from '../../../analytics/analyticsHotel/resultsAnalytics'
import SearchAnalyticsHotel from '../../../analytics/analyticsHotel/searchAnalytics'
import { search } from '../../../api/socket'
import generateHotelPeople from '../../../hooks/generateHotelPeople'
import { HOTELS_RESULTS_PAGE } from '../../../navigation/routes'
import { updateHotel } from '../../../reducers/hotel/actions'
import { showLoadBar } from '../../../reducers/loadBar/actions'
import { setSearchForm } from '../../../reducers/search/actions'
import { arraysIntersection } from '../../../utils/arrays'
import { stringSplitAndToLowerCase } from '../../../utils/strings'

export default class HotelsListController {
  constructor () {
    this.searchRS = useSelector(state => state.searchRS)
    this.whereStay = useSelector(state => state.searchForm.whereStay)
    this.dispatch = useDispatch()
    this.translation = useTranslation()
    this.hotel = useSelector(state => state.hotel)
    this.searchAnalyticsHotel = new SearchAnalyticsHotel()
    this.resultAnalyticsFlight = new ResultsAnalyticsFlight()
    this.resultAnalyticsHotel = new ResultsAnalyticsHotel()
    this.history = useHistory()
    this.query = new URLSearchParams(useLocation().search)
  }

  firstMount = (user) => {
    const cityname = this.query.get('cityname')
    const name = this.query.get('name')
    const region = this.query.get('region')
    const country = this.query.get('country')
    const checkin = this.query.get('checkin')
    const checkout = this.query.get('checkout')
    const people = this.query.get('people') || 'A'
    const longitude = this.query.get('longitude')
    const latitude = this.query.get('latitude')
    const autocomplete_type = this.query.get('autocomplete_type')
    const autocomplete_id = this.query.get('autocomplete_id')
    const currency = this.query.get('currency')

    const searchForm = {
      whereStay: this.setWhereStay(cityname, name, autocomplete_id, autocomplete_type, longitude, latitude),
      calendar: this.setCalendar(checkin, checkout),
      guests: this.setGuests(people),
      currency: currency,
    }
    this.dispatch(setSearchForm(searchForm))
    this.dispatch(updateHotel('checkinDate', checkin))
    this.dispatch(updateHotel('checkoutDate', checkout))
    this.dispatch(updateHotel('stay', { longitude, latitude, city: cityname, region, country }))
    this.dispatch(showLoadBar(HOTELS_RESULTS_PAGE))

    const searchAnalytics = {
      searchAnalyticsFlight: null,
      resultAnalyticsFlight: this.resultAnalyticsFlight,
      resultAnalyticsHotel: this.resultAnalyticsHotel,
      searchAnalyticsHotel: this.searchAnalyticsHotel
    }
    search(
      searchForm,
      this.searchRS,
      this.dispatch,
      this.translation,
      this.hotel,
      searchAnalytics,
      user
    )
  }

  setWhereStay = (cityname, name, autocomplete_id, autocomplete_type, longitude, latitude) => {
    return {
      value: '',
      stay: {
        city: cityname,
        name: name || cityname,
        type: autocomplete_type,
        id: autocomplete_id,
        longitude: longitude,
        latitude: latitude
      },
      autocomplete: {
        visible: false,
        model: {
          items: {},
          cities: {}
        }
      },
      error: false
    }
  }

  setCalendar = (checkin, checkout) => {
    return {
      visible: false,
      start: (new Date(checkin)),
      end: (new Date(checkout)),
      error: false
    }
  }

  setGuests = (people) => {
    let adults = 0
    const children = []
    people.split(',').forEach(peopleSplit => {
      if (peopleSplit === 'A') {
        adults++
      } else {
        children.push(peopleSplit)
      }
    })
    return {
      visible: false,
      value: '',
      rooms: [
        {
          adults: adults,
          children: children
        }
      ]
    }
  }

  getHotelsList = (searchRS, filter) => {
    let hotels = searchRS.hotels
    hotels = _.unionWith(hotels, (a, b) => a.id === b.id)
    hotels = hotels.filter((hotel) => this.filterObsoleteHotels(searchRS.obsoleteHotels, hotel))
    if (filter.breakfast) {
      hotels = hotels.filter(filterBreakfast)
    }
    if (filter.chains) {
      hotels = hotels.filter(filterChains)
    }
    if (filter.wifi) {
      hotels = hotels.filter(filterWifi)
    }
    if (_.size(filter.search)) {
      hotels = hotels.filter(hotel => filterSearch(filter.search, hotel))
    }
    const checkedHotelsPrices = filter.hotelsPrice.filter(interval => interval.isChecked)
    hotels = hotels.filter(hotel => filterPrice(checkedHotelsPrices, hotel))
    hotels = hotels.filter(hotel => filterPriceRange(filter.price.value, hotel))
    hotels = hotels.filter(hotel => filterStars(filter.stars.value, hotel))
    hotels = hotels.filter(hotel => filterRating(filter.rating.value, hotel))
    hotels = hotels.filter(hotel => filterPropertyType(filter.propertyTypes, hotel))
    hotels = hotels.filter(hotel => filterPaymentAndBooking(filter.paymentAndBooking, hotel))
    hotels = hotels.sort((a, b) => sort(filter, a, b))
    hotels = setFirstRelevant(this.whereStay, this.query.get('name'), hotels)
    return hotels
  }

  filterObsoleteHotels = (obsoleteHotels, hotel) => {
    return !obsoleteHotels?.includes(hotel.key)
  }
}

const sort = (filter, a, b) => {
  if (filter.order) {
    return a.priceInfo.total.value - b.priceInfo.total.value
  }
  return b.priceInfo.total.value - a.priceInfo.total.value
}

const setFirstRelevant = (whereStay, name, hotels) => {
  if (whereStay.stay.type === 'hotel' && whereStay.stay.name?.toLowerCase().includes(name?.toLowerCase())) {
    const words = stringSplitAndToLowerCase(name)
    const [first] = hotels.map((hotel) => {
      let points = 0
      points += arraysIntersection(stringSplitAndToLowerCase(hotel.name), words)?.length
      return { ...hotel, points }
    }).sort((a, b) => b.points - a.points)
    if (first) {
      return [first, ...hotels.filter(item => item.id !== first?.id)]
    }
  }
  return hotels
}


const filterBreakfast = (hotel) => {
  return hotel.breakfastIncl
}

const filterChains = (hotel) => {
  return _.size(hotel.chains)
}

const filterWifi = (hotel) => {
  const wifiAmenitiesList = ['178', '179', '222', '223', '259', '286', '344']
  return _.find(hotel.amenities, (availible, amenity) => wifiAmenitiesList.includes(amenity))
}

const filterSearch = (searchValue, hotel) => {
  const searchLowerCase = searchValue.toLowerCase()
  const searchLowerCaseTranslit = iuliia.translate(searchLowerCase, iuliia.ICAO_DOC_9303)
  const hotelNameLowerCase = hotel.name.toLowerCase()
  return hotelNameLowerCase.includes(searchLowerCase) || hotelNameLowerCase.includes(searchLowerCaseTranslit)
}

const filterPrice = (checkedPrices, hotel) => {
  const value = hotel.priceInfo.totalDayPrice

  if (checkedPrices.length) {
    return checkedPrices.some(item => {
      if (!item.end) {
        return item.start <= value
      } else {
        return item.start <= value && item.end >= value
      }
    })
  } else {
    return true
  }
}

const filterPriceRange = (price, hotel) => {
  const value = hotel.priceInfo.totalDayPrice
  return price[0] <= value && price[1] >= value
}

const filterStars = (stars, hotel) => {
  const value = hotel.stars
  return stars[0] <= value && stars[1] >= value
}

const filterRating = (rating, hotel) => {
  const value = hotel.reviewScore
  return rating[0] <= value && rating[1] >= value
}

const filterPropertyType = (propertyTypes, hotel) => {
  const findType = _.find(propertyTypes, property => {
    return property.type === hotel.hotelType
  })
  return findType ? findType.checked : false
}

const filterPaymentAndBooking = (paymentAndBooking, hotel) => {
  if (hotel.prepayRequired) {
    return paymentAndBooking.payNow.checked
  }
  if (!hotel.prepayRequired) {
    return paymentAndBooking.payLater.checked
  }
}
