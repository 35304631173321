import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Checkbox from '../_checkbox'
import PropertyTypeController from './controller'

const PaymentAndBooking = () => {
  const { t } = useTranslation()
  const paymentAndBooking = useSelector(state => state.filter.paymentAndBooking)
  const controller = new PropertyTypeController()
  return (
    <div className='paymentAndBooking'>
      <div className='list__header'>
        {t('Payment and booking')}
      </div>
      <div className='list__el'>
        {_.map(paymentAndBooking, (filter, index) =>
          <Checkbox
            key={'paymentAndBooking' + index}
            id={'paymentAndBooking' + index}
            filter={paymentAndBooking}
            type={index}
            name={filter.name}
            checked={filter.checked}
            index={index}
            onChange={controller.handleOnChangeElement}
          />
        )}
      </div>
    </div>
  )
}

export default PaymentAndBooking
