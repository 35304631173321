import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Api from '../../../api'
import { updateHotel } from '../../../reducers/hotel/actions'
import { updateSearchForm, updateSearchFormAutocomplete } from '../../../reducers/search/actions'
import _ from 'lodash'
import SearchAnalyticHotel from '../../../analytics/analyticsHotel/searchAnalytics'
import reverseKeyboard from '../../../hooks/reverseKeyboard'

export default class WhereStayController {
  constructor () {
    this.dispatch = useDispatch()
    this.api = new Api()
    this.i18n = useTranslation().i18n
    this.analyticsHotel = new SearchAnalyticHotel()
  }

  handleOnChange = e => {
    const { value } = e.target
    this.dispatch(updateSearchForm('whereStay', 'value', value))
    this.dispatch(updateSearchForm('whereStay', 'error', false))
    if (value.length) {
      let cycle = 0
      const getRequest = (inputValue) => {
        this.api.autocompleteHotels(inputValue, this.i18n.language).then(result => {
          if (result.items && result.cities) {
            if (!result.items.length && !result.cities.length && cycle < 1) {
              cycle += 1
              const reverseKeyboardText = reverseKeyboard(inputValue)
              getRequest(reverseKeyboardText)
            }
            this.dispatch(updateSearchFormAutocomplete('whereStay', 'model', result))
          }
        })
      }
      getRequest(value)
    }
  }

  isInputActive = (inputFocus, whereStay) => {
    if (inputFocus) {
      return true
    } else if (whereStay.value || _.size(whereStay.stay)) {
      return true
    }
    return false
  }

  handleOnFocus = setInputFocus => {
    setInputFocus(true)
    this.dispatch(updateSearchFormAutocomplete('whereStay', 'visible', true))
  }

  handleOnBlur = setInputFocus => {
    setTimeout(() => {
      setInputFocus(false)
      this.dispatch(updateSearchFormAutocomplete('whereStay', 'visible', false))
      this.dispatch(updateSearchFormAutocomplete('whereStay', 'model', { items: {}, cities: {} }))
      this.dispatch(updateSearchForm('whereStay', 'value', ''))
    }, 500)
  }

  handleOnClick = item => {
    this.dispatch(updateSearchForm('whereStay', 'stay', item))
    this.dispatch(updateSearchForm('whereStay', 'error', false))
    this.analyticsHotel.searchLocation(item.countryName)
    this.dispatch(updateHotel('whereStay', item.countryName))
  }

  getValue = (whereStay, inputFocus) => {
    if (inputFocus || !whereStay.stay.name) {
      return whereStay.value
    }
    return whereStay.stay.name || ''
  }
}
