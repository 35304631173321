import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'


import { Checkbox } from '../../pageComponents/checkbox'
import { Dropdown } from '../../pageComponents/dropdown'
import { Slider } from '../../pageComponents/Molecules/Slider'
import { LayoversController } from './controller'
import getTimeFormat from '../../../hooks/getTimeFormat'

import './style.sass'

export const Layovers: React.FC = () => {
  const [valueState, setValueState] = useState([])
  const { t } = useTranslation()
  const { oneStop, twoStops, moreStops, limit, value } = useSelector((state: any) => state.filter.layovers)
  const { filter } = useSelector((state: any) => state)

  const controller = new LayoversController(setValueState, filter)

  useEffect(() => {
    if (limit?.[0] === value?.[0] && limit?.[1] === value?.[1]) {
      setValueState(value)
    }
  }, [value?.[0], value?.[1]])

  const active = Boolean(oneStop ||
    twoStops || moreStops || limit?.[0] !== value?.[0] || limit?.[1] !== value?.[1])

  if (limit?.[0] === 0 && limit?.[1] === 0) {
    return null
  }

  return (
    <Dropdown
      name={t('Layovers')}
      listName={t('Layovers')}
      onClear={controller.handleClear}
      onClose={controller.handleClose(limit)}
      active={active}
    >
      <Checkbox
        checked={oneStop}
        id='oneStop'
        name={t('stop', { count: 1 })}
        onChange={controller.handleClick('oneStop')}
      />
      <Checkbox
        checked={twoStops}
        id='twoStops'
        name={t('stop', { count: 2 })}
        onChange={controller.handleClick('twoStops')}
      />
      <Checkbox
        checked={moreStops}
        id='moreStops'
        name={'> ' + t('stop', { count: 2 })}
        onChange={controller.handleClick('moreStops')}
      />
      <Slider
        onClear={controller.handleSliderClear(limit)}
        title={t('Layovers duration')}
        intervalStart={getTimeFormat(valueState?.[0] || value[0], t)}
        intervalEnd={getTimeFormat(valueState?.[1] || value[1], t)}
        barClassName=''
        value={valueState.length > 0 ? valueState : value}
        min={limit[0]}
        max={limit[1]}
        step={5}
        tooltip={false}
        onChange={controller.handleChangeValue}
      />
    </Dropdown>
  )
}

export default Layovers
