import moment from 'moment'
import { useTranslation } from 'react-i18next'

export default class ItinerariesElementController {
  constructor (element, searchRS, type) {
    this.itineraries = type === 'outbound' ? element.outbound : element.inbound
    this.searchRS = searchRS
    this.segments = Object.values(searchRS.segments)
      .filter(segment => {
        return this.itineraries.indexOf(segment.flightId) >= 0
      })
      .sort((a, b) => moment(a.departureDate).unix() - moment(b.departureDate).unix())
    this.t = useTranslation().t
  }

  // airline data
  carrier = () => {
    const carriers = []
    this.itineraries.forEach((itinerary, index) => {
      if (this.searchRS.segments[this.itineraries[index]] !== undefined) {
        carriers.push(this.searchRS.carriers[this.searchRS.segments[this.itineraries[index]].carrier])
      }
    })
    return [...new Set(carriers)]
  }

  // flight times data
  duration = () => {
    const itinerariesCount = this.itineraries.length
    const transferring = {}
    let duration = Object.values(this.segments).reduce((a, b) => a + b.duration, 0) || 0
    transferring.allTime = 0
    transferring.times = []
    transferring.code = []
    transferring.airports = []
    if (itinerariesCount === 1) {
      transferring.count = this.t('Direct')
    } else {
      transferring.count = this.t('stop', { count: itinerariesCount - 1 })
      this.itineraries.forEach((itinerary, index) => {
        if (index && index !== itinerariesCount) {
          const departureDate =
            this.searchRS.segments[itinerary] !== undefined
              ? moment(this.searchRS.segments[itinerary].departureDate)
              : moment()
          const prevArrivalDate =
            this.searchRS.segments[this.itineraries[index - 1]] !== undefined
              ? moment(this.searchRS.segments[this.itineraries[index - 1]].arrivalDate)
              : moment()
          const transferringTime = departureDate.diff(prevArrivalDate, 'minutes', false)
          duration = parseInt(duration + transferringTime)
          transferring.allTime = parseInt(transferring.allTime + transferringTime)
          transferring.times.push(moment.duration(transferringTime, 'minutes'))
          const code = this.searchRS.segments[itinerary] ? this.searchRS.segments[itinerary].origin : ''
          transferring.code.push(code)
          transferring.airports.push(code.length ? this.searchRS.airports[code]?.name : '')
        }
      })
      transferring.allTime = moment.duration(transferring.allTime, 'minutes')
    }
    const departureDate =
      this.searchRS.segments[this.itineraries[0]]
        ? this.searchRS.segments[this.itineraries[0]].departureDate
        : moment()
    const arrivalDate =
      this.searchRS.segments[this.itineraries[this.itineraries.length - 1]]
        ? this.searchRS.segments[this.itineraries[this.itineraries.length - 1]].arrivalDate
        : moment()
    const startDepartureDate = moment(departureDate).startOf('day')
    const startArrivalDate = moment(arrivalDate).startOf('day')
    const differenceOfDays = startArrivalDate.diff(startDepartureDate, 'day', true)
    return {
      departureDate,
      arrivalDate,
      differenceOfDays,
      duration: moment.duration(duration, 'minutes'),
      transferring
    }
  }

  // directional data
  direction = () => {
    if (this.searchRS.segments[this.itineraries[0]] === undefined ||
      this.searchRS.segments[this.itineraries[this.itineraries.length - 1]] === undefined) {
      return ''
    }
    return this.searchRS.segments[this.itineraries[0]].origin +
      ' - ' +
      this.searchRS.segments[this.itineraries[this.itineraries.length - 1]].destination
  }

  // directional data
  allDirection = () => {
    let allDirection = ''
    this.itineraries.forEach((el, index) => {
      const itinerary = this.searchRS.segments[el]
      allDirection = allDirection + (!index ? itinerary.origin + ' - ' : ' - ') + itinerary.destination
    })
    return allDirection
  }

  // take on moment duration, return 2d 3h 15m
  getTimeFormat = (duration) => {
    if (duration.length !== 0) {
      return (
        (duration.days() ? duration.days() + this.t('d') + ' ' : '') +
        (duration.hours() ? duration.hours() + this.t('h') + ' ' : '') +
        (duration.minutes() ? duration.minutes() + this.t('m') + ' ' : '')
      )
    }
  }
}
