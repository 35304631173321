import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import RoomDetailsController from './contoller'
import RoomDetailElement from './_element'
import HotelScreenAnalytics from '../../../../../analytics/analyticsHotel/hotelScreenAnalytics'
import { useIsVisibleElement } from '../../../../../hooks/useIsVisibleElement'
import { useRoomOptions } from '../../../../../context'
import { NotFound } from '../../../../pageComponents/notFound'

import './style.sass'
import { useTranslation } from 'react-i18next'

const RoomDetails = () => {
  const hotelAnalytics = new HotelScreenAnalytics()

  const { t } = useTranslation()
  const { state: roomsOptions } = useRoomOptions()
  const priceRS = useSelector(state => state.priceRS.model)
  const countRooms = useSelector(state => state.priceRS.model.rooms?.length)

  const controller = new RoomDetailsController(priceRS.rooms, roomsOptions)

  const [element] = useIsVisibleElement(hotelAnalytics.seeInfoAboutRooms, 100, [countRooms])

  if (!priceRS.status || priceRS.status !== 'success') return ''

  const rooms = controller.getFilteredRooms()

  return (
    <div className='roomDetails 1' ref={element}>
      {_.map(rooms, (room) =>
        <RoomDetailElement
          key={room.key}
          room={room}
        />
      )}
      {_.size(rooms) === 0 &&
        <div className='roomDetails_notfound'>
          <NotFound>{t('No rooms found, try to change the filters')}</NotFound>
        </div>}
    </div>
  )
}

export default RoomDetails
