import React from 'react'
import './style.sass'

const InfoElement = ({ header, text, small }) => {
  return (
    <div className='infoElement'>
      <div className='infoElement__header'>{header}</div>
      <div className='infoElement__text'>
        {text}
      </div>
      {small &&
        <div className='infoElement__small'>
          {small}
        </div>}
    </div>
  )
}

export default InfoElement
