import { CLEAR_STATE } from '../actions'
import {
  CLEAR_BOOKER,
  CLEAR_EXTRA_GUEST,
  CREATE_EXTRA_GUEST,
  UPDATE_BOOKER,
  UPDATE_EXTRA_GUEST,
  UPDATE_ORDER_HOTELS
} from './actions'

const defaultState = {
  'booker': {
    'id': null,
    'country': null,
    'email': null,
    'firstName': null,
    'lastName': null,
    'phone': null,
  },
  'extraGuests': [],
  'fgp': null,
  'checkin': null,
  'checkout': null,
  'currency': null,
  'hotel': null,
  'people': null,
  'language': null,
  'price': null,
  'room': null,
  'comment': null
}

const orderHotels = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_ORDER_HOTELS:
      return {
        ...state,
        [action.field]: action.payload
      }

    case UPDATE_BOOKER:
      return {
        ...state,
        booker: action.payload
      }

    case CLEAR_BOOKER:
      return {
        ...state,
        booker: defaultState.booker
      }

    case CREATE_EXTRA_GUEST:
      return {
        ...state,
        extraGuests: action.payload
      }

    case CLEAR_EXTRA_GUEST:
      return {
        ...state,
        extraGuests: Object.values({
          ...state.extraGuests,
          [action.number]: {
            ...state.extraGuests[action.number],
            firstName: null,
            lastName: null,
            id: undefined
          }
        })
      }


    case UPDATE_EXTRA_GUEST: {
      return {
        ...state,
        extraGuests: Object.values({
          ...state.extraGuests,
          [action.number]: {
            ...state.extraGuests[action.number],
            ...action.payload
          }
        })
      }
    }

    case CLEAR_STATE:
      return defaultState
    default:
      return state
  }
}

export default orderHotels
