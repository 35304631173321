import { useSelector } from 'react-redux'
import { SEARCH_SCREEN } from '../../reducers/page/types'
import AnalyticsFlight from './index'

export default class FilterAnalytics extends AnalyticsFlight {
  constructor () {
    super()
    this.page = useSelector(state => state.page.name)
    this.hasOutbound = useSelector(state => state.itineraries.outbound)
  }

  setDirectFlight = (isDirectFlightsOnly) => {
    const feature = this.page === SEARCH_SCREEN
      ? 'directFlights'
      : this.FEATURE.FILTERS

    this.logEvent(
      this.getName(this.hasOutbound, this.page),
      feature,
      'directOnlyButton',
      this.ACTIONS.TAP,
      isDirectFlightsOnly ? 'on' : 'off'
    )
  }

  setDuration = (durations) => {
    this.logEvent(
      this.getName(this.hasOutbound),
      this.FEATURE.FILTERS,
      'duration',
      this.ACTIONS.TAP,
      durations.join('-')
    )
  }

  setTime = (times) => {
    const label = [
      times.departure.value.join('-'),
      times.arrival.value.join('-')
    ].join(';')

    this.logEvent(
      this.getName(this.hasOutbound),
      this.FEATURE.FILTERS,
      'times',
      this.ACTIONS.TAP,
      label
    )
  }

  setAirlines = (airlines) => {
    const label = airlines
      .map(airline => [airline.name, (airline.checked ? 'on' : 'off')].join('-'))
      .join(';')

    this.logEvent(
      this.getName(this.hasOutbound),
      this.FEATURE.FILTERS,
      'airlines',
      this.ACTIONS.TAP,
      label
    )
  }

  setPrice = (prices) => {
    this.logEvent(
      this.getName(this.hasOutbound),
      this.FEATURE.FILTERS,
      'prices',
      this.ACTIONS.TAP,
      prices.join('-')
    )
  }

  setOrder = (order) => {
    this.logEvent(
      this.getName(this.hasOutbound),
      this.FEATURE.RESULTS,
      'sortButton',
      this.ACTIONS.TAP,
      order ? 'DESC' : 'ASC'
    )
  }
}
