import React from 'react'
import './style.sass'
import { useSelector } from 'react-redux'
import MapboxMap from '../../../../pageComponents/mapBox'

const MainInformationMap = ({ hotel }) => {
  const hotelState = useSelector(state => state.hotel.choice)
  const lon = hotelState.location?.lon || 0
  const lat = hotelState.location?.lat || 0
  return (
    <div className='mainInformationMap'>
      <div className='mainInformationMap__map'>
        <MapboxMap
          initialOptions={{
            center: [lon, lat],
            zoom: 15
          }}
          hotel={hotelState}
          search={false}
        />
      </div>

      <div className='address'>{hotel.address}</div>
    </div>
  )
}

export default MainInformationMap
