import React, { useState } from 'react'
import './style.sass'
import DateFare from './_date'
import FlightFare from './_flight'
import DetailFare from './_detail'

const Itinerary = ({ element, type }) => {
  const [openInfo, setOpenInfo] = useState(false)

  return (
    <div className={'itinerary ' + (openInfo && 'itinerary__openInfo')}>
      <DateFare element={element} type={type} />
      <FlightFare element={element} type={type} openInfo={openInfo} />
      <DetailFare element={element} type={type} openInfo={openInfo} setOpenInfo={setOpenInfo} />
    </div>
  )
}

export default Itinerary
