import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { updateHotel } from '../../../../reducers/hotel/actions'
import { CLIENT_MODE_BUSINESSES } from '../../../../reducers/user/constans'

const HotelTravelPolicy = ({ exceedsCount, showExceeds }) => {
  const { t } = useTranslation()
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  if (user.clientMode !== CLIENT_MODE_BUSINESSES) return ''
  if (!exceedsCount) return ''
  if (showExceeds) return ''
  return (
    <div className='hotelTravelPolicy'>
      <div className='hotelTravelPolicy__text'>
        {t("Results in accordance with your company's travel policy")}
      </div>
      <button
        className='hotelTravelPolicy__button'
        onClick={() => dispatch(updateHotel('showExceeds', true))}
      >
        {t('Show all')}
      </button>
    </div>
  )
}

export default HotelTravelPolicy
