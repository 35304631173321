import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'

const OptionLeft = ({ option }) => {
  const { t } = useTranslation()
  const { roomsLeft } = option

  if (!roomsLeft) return ''

  if (roomsLeft > 10) {
    return (
      <div className='optionLeft'>
        {t('left10+')}
      </div>
    )
  }

  return (
    <div className={'optionLeft ' + (roomsLeft <= 2 && 'optionLeft-red')}>
      {t('leftHotelRooms', { count: roomsLeft })}
    </div>
  )
}

export default OptionLeft
