import React from 'react'
import './style.sass'
import DirectionFlightInput from './_input'
import Icons from '../../pageComponents/icons'
import DirectionFlightController from './controller'

const DirectionFlight = () => {
  const controller = new DirectionFlightController()
  return (
    <div className='directionFlight'>
      <div className='directionFlight__inputs'>
        <DirectionFlightInput type='origin' />
        <button
          onClick={controller.handleOnClickChangeDirection}
          className='directionFlight__change'
        >
          <Icons
            group='arrow'
            icon='direction'
            color='#141414'
          />
        </button>
        <DirectionFlightInput type='destination' />
      </div>
    </div>
  )
}
export default DirectionFlight
