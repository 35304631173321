export default class FareHeaderController {
  getDirection = (searchRs, itineraries) => {
    const origin = searchRs.segments[itineraries[0]].origin
    const destination = searchRs.segments[itineraries[itineraries.length - 1]].destination
    return {
      origin: searchRs.airports[origin],
      destination: searchRs.airports[destination]
    }
  }
}
