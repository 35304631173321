export const UPDATE_HOTEL = 'UPDATE_HOTEL'
export const UPDATE_HOTEL_AUTOCOMPLETE_VALUE = 'UPDATE_HOTEL_AUTOCOMPLETE_VALUE'
export const UPDATE_HOTEL_AUTOCOMPLETE_FEATURES = 'UPDATE_HOTEL_AUTOCOMPLETE_FEATURES'
export const UPDATE_HOTEL_AUTOCOMPLETE_SHOW = 'UPDATE_HOTEL_AUTOCOMPLETE_SHOW'

export const updateHotel = (field, payload) => ({
  type: UPDATE_HOTEL,
  field,
  payload
})
export const updateHotelAutocompleteValue = (payload) => ({
  type: UPDATE_HOTEL_AUTOCOMPLETE_VALUE,
  payload
})
export const updateHotelAutocompleteFeatures = (payload) => ({
  type: UPDATE_HOTEL_AUTOCOMPLETE_FEATURES,
  payload
})
export const updateHotelAutocompleteShow = (payload) => ({
  type: UPDATE_HOTEL_AUTOCOMPLETE_SHOW,
  payload
})
