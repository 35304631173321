import React from 'react'
import './style.sass'
import Icons from '../../../../pageComponents/icons'

const HotelStars = ({ hotel }) => {
  const _renderStar = () => {
    const star = []
    for (let i = 1; i <= hotel.stars; i++) {
      star.push(
        <Icons key={'star' + i} group='icon' icon='star' className='star' />
      )
    }
    return star
  }

  return (
    <div className='stars'>
      {_renderStar()}
    </div>
  )
}
export default HotelStars
