import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'

interface TabProps {
    active: boolean
    to: string
}

export const Tab: React.FC<TabProps> = React.memo(({ active, to, children }) => {
  return (
    <Link
      replace
      to={to}
      className={cx('tab__el', { 'tab__el-active': active })}
    >
      {children}
    </Link>
  )
})
