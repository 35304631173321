import Analytics from '../index'
import { SEARCH_SCREEN } from '../../reducers/page/types'

export default class AnalyticsHotel extends Analytics {
  NAMES = {
    SEARCH: 'b2b_bh_search',
    RESULT: 'b2b_bh_result',
    HOTEL_DETAILS: 'b2b_bh_hotelDetails',
    CHECKOUT: 'b2b_bh_checkout',
    ERROR: 'b2b_bh_error',
    ORDER_LIST: 'b2b_bh_orderList'
  }

  FEATURE = {
    MAIN:'main',
    FILTERS: 'filters',
    SET_GUESTS: 'setGuests',
    DATES: 'dates',
    HOTEL_LIST: 'hotelsList',
    HOTEL_MAP: 'hotelsMap',
    GUEST: 'guest',
    NEW_GUEST: 'newGuest',
    PAYMENT: 'payment',
    NEW_CARD: 'newCard',
    SORT: 'sort',
    ROOMS: 'rooms',
    PHOTO: 'photo',
    PAST: 'past',
    FUTURE: 'future'
  }

  ACTIONS = {
    TAP: 'tap',
    SEE: 'see',
    SUCCESS: 'success',
    ERROR: 'error',
    LOADED: 'loaded',
    ENABLED: 'enabled',
    DISABLED: 'disabled',
    SCROLL: 'scroll'
  }

  getName = (hasOutbound, page = undefined) => {
    return page === SEARCH_SCREEN
      ? this.NAMES.SEARCH
      : hasOutbound
        ? this.NAMES.INBOUND
        : this.NAMES.OUTBOUND
  }
}
