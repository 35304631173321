import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Icons from '../../../pageComponents/icons'
import Radio from './_radio'
import CabinController from './controller'
import OutsideClickHandler from 'react-outside-click-handler'

const Cabin = ({ showList, setShowList }) => {
  const { t } = useTranslation()
  const controller = new CabinController()
  const cabin = useSelector(state => state.searchForm.cabin)

  const handleChecked = (e) => {
    controller.handleOnClick(e)
    setShowList('cabin')
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (showList === 'cabin') {
          setShowList('cabin')
        }
      }}
    >
      <div className='cabin dropdown'>
        <div
          className={'dropdown__value ' + (showList === 'cabin' && 'dropdown__value-active')}
          onClick={() => setShowList('cabin')}
        >
          {t(cabin.name)}
          <Icons
            className='dropdown__value-arrow'
            group='arrow'
            icon='down'
            color='#ccc'
          />
        </div>
        <div className={'dropdown__list ' + (showList === 'cabin' && 'dropdown__list-open')}>
          <div className='dropdown__list-wrap'>
            {controller.getDropdownList().map(item =>
              <Radio
                key={`dropdown__list-cabin:${item.name}`}
                item={item} checked={cabin.value === item.value}
                onClick={handleChecked}
              />)}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default Cabin
