import React, { useState } from 'react'
import './style.sass'
import Moment from 'react-moment'
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler'
import DayPicker from 'react-day-picker/DayPicker'
import MomentLocaleUtils from 'react-day-picker/moment'
import { useTranslation } from 'react-i18next'
import LoadButton from '../../../../../components/pageComponents/loadButton'
import ExportCalendarController from './controller'

export const Calendar = ({ openPopup, setOpenPopup }) => {
  const { t, i18n } = useTranslation()
  const [from, setStartDate] = useState()
  const [to, setEndDate] = useState()
  const [error, setError] = useState()
  const [finalMessage, setFinalMessage] = useState()
  const [load, setLoad] = useState(false)
  const modifiers = { start: from, end: to }
  const controller = new ExportCalendarController()

  return (
    <div className={'calendar ' + (openPopup && 'calendar__open')}>
      <OutsideClickHandler
        onOutsideClick={() => {
          controller.handleClickClose(openPopup, setOpenPopup, setStartDate, setEndDate, setError, setFinalMessage)
        }}
      >
        <div className='calendar__wrp'>
          <div
            className='calendar__close' onClick={() => {
              controller.handleClickClose(openPopup, setOpenPopup, setStartDate, setEndDate, setError, setFinalMessage)
            }}
          />
          {finalMessage
            ? (
              <div className='calendar__success'>
                <div className='calendar__success-header'>
                  {finalMessage === 'success'
                    ? t('success_head')
                    : t('error_head')}
                </div>
                <div
                  className='calendar__success-message' dangerouslySetInnerHTML={{
                    __html: finalMessage === 'success'
                      ? t('success_msg')
                      : (
                          t('error_msg') +
                          '<a href="mailto:support@appintheair.com" target="_blank">support@appintheair.com</a>'
                        )
                  }}
                />
              </div>
              )
            : (
              <div className='calendar__body'>
                <div
                  className={'datePicker__input ' +
                  (error && ' datePicker__input-error ')}
                >
                  <div className='datePicker__element'>
                    <div className={'datePicker__label ' + (!from && 'datePicker__label-center')}>
                      {t('Start')}
                    </div>
                    <Moment
                      className={'datePicker__value ' + (from && 'datePicker__value-show')}
                      format='L'
                      locale={i18n.language}
                    >
                      {from}
                    </Moment>
                  </div>
                  <div className='datePicker__element'>
                    <div className={'datePicker__label ' + (!to && 'datePicker__label-center')}>
                      {t('End')}
                    </div>
                    <Moment
                      className={'datePicker__value ' + (to && 'datePicker__value-show')}
                      format='L'
                      locale={i18n.language}
                    >
                      {to}
                    </Moment>
                  </div>
                  {error &&
                    <div className='calendar__error'>
                      {error}
                    </div>}
                </div>
                <DayPicker
                  selectedDays={[from, { from, to }]}
                  modifiers={modifiers}
                  firstDayOfWeek={1}
                  locale={i18n.language}
                  localeUtils={MomentLocaleUtils}
                  toMonth={new Date()}
                  disabledDays={{ after: new Date() }}
                  numberOfMonths={2}
                  onDayClick={(day) => controller.handleInClickDay(day, from, to, setStartDate, setEndDate)}
                />
              </div>
              )}
          <div className='calendar__footer'>
            <button
              onClick={() => {
                if (finalMessage) {
                  controller.handleClickClose(
                    openPopup, setOpenPopup, setStartDate, setEndDate, setError, setFinalMessage
                  )
                } else {
                  controller.handleInClickDownload(
                    from, to, setError, setLoad, setFinalMessage
                  )
                }
              }}
            >
              {load
                ? <LoadButton />
                : finalMessage
                  ? t('Close')
                  : t('Download')}
            </button>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  )
}
