import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Icons from '../../../pageComponents/icons'
import Tooltip from '../../../pageComponents/tooltip'
import Carrier from '../../itinerariesElement/_carrier'
import ChooseOutboundTooltip from './_tooltip'
import ChooseOutboundController from './controller'
import ItinerariesElementController from '../../itinerariesElement/controller'

const ChooseOutbound = () => {
  const { t } = useTranslation()
  const searchRS = useSelector(state => state.searchRS.model)
  const itineraries = useSelector(state => state.itineraries)
  const chooseOutbound = itineraries.outbound
  const outboundController = new ItinerariesElementController(chooseOutbound, searchRS, 'outbound')
  const controller = new ChooseOutboundController()
  return (
    <div className='chooseOutbound'>
      <Carrier carriers={outboundController.carrier()} />
      <div className='about tooltip-hoverElement'>
        <div className='about-direction'>
          {outboundController.direction()}{' '}
          {outboundController.carrier().map(carrier => carrier.name).join(', ')}
        </div>
        <button
          className='about-change'
          onClick={controller.handleOnClick}
        >
          {t('Change')}
        </button>
        <Tooltip
          top
          child={
            <ChooseOutboundTooltip outboundController={outboundController} />
          }
        />
      </div>
      <div className='select'>
        <Icons
          group='arrow'
          icon='right'
          color='#ADADAD'
          className='select__arrow'
        />
        {t('Select return flight')}
      </div>
    </div>
  )
}

export default ChooseOutbound
