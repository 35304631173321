import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OrderAnalytics from '../../../analytics/analyticsHotel/orderAnalytics'
import { updateOrderHotels } from '../../../reducers/order/actions'

export const SpecialRequestDropDownController = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const valueRequest = useSelector(state => state.orderHotels.comment)
  const analyticsHotel = new OrderAnalytics()

  const setValueRequest = (value) => {
    dispatch(updateOrderHotels('comment', value || null))
  }
  const handleChange = (e) => {
    const { value } = e.target
    setValueRequest(value)
    if (value.length > 2) {
      setOpen(false)
    }
    if (!value.length) {
      setOpen(true)
    }
  }

  const handleCheckText = (text, id, email) => {
    setOpen(false)
    text = replaceEmail(text, id, email)
    setValueRequest(text)
  }

  const handleFocus = () => {
    if (!valueRequest) {
      setOpen(true)
    }
  }

  const handleBlur = () => {
    setTimeout(() => {
      setOpen(false)
    }, 500)
    analyticsHotel.changeRequest('request')
  }

  const replaceEmail = (text, id, email) => {
    const find = 'email'
    if (id === 3 && email) {
      const index = text.lastIndexOf(find)
      if (index >= 0) {
        text = text.substring(0, index) + email + text.substring(index + find.length)
      }
    }
    return text
  }
  return {
    open,
    valueRequest,
    handleChange,
    handleCheckText,
    handleFocus,
    handleBlur,
    replaceEmail
  }
}
