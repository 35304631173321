import { useDispatch } from 'react-redux'
import { changePropertyType } from '../../../../reducers/filter/actions'

export default class PropertyTypeController {
  constructor () {
    this.dispatch = useDispatch()
  }

  handleOnChangeElement = (propertyTypes, type) => {
    const newPropertyTypes = propertyTypes.map(property => {
      if (property.type === type) {
        property.checked = !property.checked
      }
      return property
    })
    this.dispatch(changePropertyType(newPropertyTypes))
  }

  activeButton = (filter) => {
    let count = 0
    filter.forEach(property => property.checked && count++)
    return {
      clear: count,
      select: count < filter.length
    }
  }

  handleOnClickButton = (filter, checked) => {
    const newPropertyTypes = filter.map(property => {
      property.checked = checked
      return property
    })

    this.dispatch(changePropertyType(newPropertyTypes))
  }
}
