import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { updateOrderFlights } from '../../../../reducers/order/actions'
import { updateSearchForm } from '../../../../reducers/search/actions'
import SearchAnalyticFlight from '../../../../analytics/analyticsFlight/searchAnalytics'
import currentLocation from '../../../../hooks/currentLocation'

export default class PeopleController {
  constructor () {
    this.dispatch = useDispatch()
    this.analytics = new SearchAnalyticFlight()
    this.t = useTranslation().t
  }

  passengers = (passengers) => {
    const countPassengers = passengers.ADT + passengers.CHD + passengers.INF
    if (passengers.ADT && !passengers.CHD && !passengers.INF) {
      return this.t('Adult', { count: passengers.ADT })
    } else {
      return this.t('Passenger', { count: countPassengers })
    }
  }

  counterDescription = (type) => {
    if (type === 'ADT') {
      return this.t('Adult') + '<span>' + this.t('Older than 12') + '</span>'
    }
    if (type === 'CHD') {
      return this.t('Children') + '<span>2 – 11</span>'
    }
    if (type === 'INF') {
      return this.t('Infants') + '<span>' + this.t('up to 2') + '</span>'
    }
  }

  disabledCounterBtn = (passengers, type, action) => {
    const maxPassenger = 9
    const sumPassenger = Object.values(passengers).reduce((sum, el) => sum + el, 0)
    if (type === 'ADT') {
      if (action === 'minus') return passengers.ADT === passengers.INF || passengers.ADT === 1
      if (action === 'plus') return sumPassenger === maxPassenger
    }
    if (type === 'CHD') {
      if (action === 'minus') return passengers.CHD === 0
      if (action === 'plus') return sumPassenger === maxPassenger
    }
    if (type === 'INF') {
      if (action === 'minus') return passengers.INF === 0
      if (action === 'plus') return passengers.INF === passengers.ADT || sumPassenger === maxPassenger
    }
  }

  clickCounterBtn = (passengers, type, action) => {
    const { isFlightsPage } = currentLocation()
    const newPassengers = {
      ...passengers,
      [type]: passengers[type] + (action === 'plus' ? 1 : -1)
    }
    this.dispatch(updateSearchForm('passengers', 'count', newPassengers))
    this.dispatch(updateSearchForm('passengers', 'value', Object.values(newPassengers).join(';')))
    if (isFlightsPage) {
      this.analytics.setPassenger(newPassengers)
    }
    const orderPassengers = this._createOrderPassengers(newPassengers)
    this.dispatch(updateOrderFlights('passengers', orderPassengers))
  }

  dispatchPassengersAfterLoad = (passengers) => {
    const orderPassengers = this._createOrderPassengers(passengers)
    this.dispatch(updateOrderFlights('passengers', orderPassengers))
  }

  _createOrderPassengers = (passengers) => {
    const orderPassengers = []
    Object.keys(passengers).forEach(Type => {
      for (let i = 0; i < passengers[Type]; i++) {
        orderPassengers.push({
          isPayer: Type === 'ADT' && !i,
          type: Type,
        })
      }
    })
    return orderPassengers
  }
}
