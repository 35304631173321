import React from 'react'
import _ from 'lodash'

import { Checkbox } from '../checkbox'

import './style.sass'
import { Dropdown, DropdownProps } from '../dropdown'

interface MultipleOptionsProps<T = any> extends DropdownProps {
  items?: Array<T>
  onChange: (value: T) => void
}

export const MultipleOptions: React.FC<MultipleOptionsProps> = ({ items, onChange, ...rest }) => {
  const handleChange = (optionName) => () => {
    onChange(optionName)
  }

  return (
    <Dropdown {...rest}>
      {_.map(items, (item) =>
        <Checkbox
          key={item.name}
          name={item.label}
          id={item.name}
          checked={item.checked}
          onChange={handleChange(item)}
        />)}
    </Dropdown>
  )
}
