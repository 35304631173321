import React, { useEffect, useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CheckBusinessUser } from '../../../../hooks/checkBusinessUser'
import currentLocation from '../../../../hooks/currentLocation'
import { CLIENT_MODE_BUSINESSES, CLIENT_MODE_PERSONAL } from '../../../../reducers/user/constans'
import Icons from '../../../pageComponents/icons'
import CardController from '../controller'
import CorporateCard from './_corporateCard'
import CorporateCardController from './_corporateCard/controller'
import NewCardForm from './_newCard'

const CardForm = ({ setPopupOpen, bookingDetail }) => {
  const { t } = useTranslation()
  const payment = useSelector(state => state.paymentForm)
  const user = useSelector(state => state.user)
  const controller = new CardController()
  const corpCardController = new CorporateCardController()
  const paymentMethod = useSelector(state => state.paymentForm.paymentMethod)
  const { isHotelsPage } = currentLocation()
  useEffect(() => {
    corpCardController.firstInit(user, isHotelsPage)
  }, [])

  return (
    <div className='cardForm'>
      <div className='popup__header 1'>
        {CheckBusinessUser(user) && paymentMethod === CLIENT_MODE_PERSONAL && !isHotelsPage &&
          <button
            className='popup__header-back'
            onClick={() => corpCardController.setPaymentMethod(CLIENT_MODE_BUSINESSES)}
          >
            <Icons
              group='arrow'
              icon='back'
            />
          </button>}
        {CheckBusinessUser(user) && paymentMethod === CLIENT_MODE_BUSINESSES && !isHotelsPage
          ? t('Payment method')
          : t('New card')}
        <button
          className='popup__header-close'
          onClick={() => setPopupOpen(false)}
        >
          <Icons
            group='icon'
            icon='close'
            width={24}
            height={24}
            color='#8F8F8F'
          />
        </button>
      </div>
      <div className='popup__content'>
        {CheckBusinessUser(user) && paymentMethod === CLIENT_MODE_BUSINESSES && !isHotelsPage
          ? <CorporateCard user={user} />
          : <NewCardForm bookingDetail={bookingDetail} />}
      </div>
      <div className='popup__footer'>
        <button
          onClick={() => { controller.handleOnClickCancel(setPopupOpen) }}
          className='button button-cancel'
        >
          {t('Cancel')}
        </button>
        <button
          className='button button-save'
          onClick={() => {
            controller.handleOnSave(
              setPopupOpen,
              payment,
              bookingDetail
            )
          }}
        >
          {t('Save')}
        </button>
      </div>
    </div>
  )
}

export default CardForm
