import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { useTranslation } from 'react-i18next'
import iuliia from 'iuliia'
import { CheckBusinessUser } from '../../../../hooks/checkBusinessUser'

import currentLocation from '../../../../hooks/currentLocation'
import { CLIENT_MODE_BUSINESSES } from '../../../../reducers/user/constans'
import PassengerController from '../controller'
import PassengerAdd from './_passengerAdd'
import PassengerChoose from './_passengerChoose'
import GuestsAnalytics from '../../../../analytics/analyticsHotel/guestsAnalytics'

import './style.sass'
import _ from 'lodash'

const Passenger = ({
  passenger, index, openOptionsPassenger, setOpenOptions, popupOpen, setPopupOpen, number
}) => {
  const controller = new PassengerController()
  const hotelAnalytics = new GuestsAnalytics()
  const user = useSelector(state => state.user)

  const { isFlightsPage, isHotelsPage } = currentLocation()
  const { t } = useTranslation()
  const clientMode = useSelector(state => state.user.clientMode)

  const savePassengers = useSelector(state => state.user.passengers)
  const orderFlightsPassengers = useSelector(state => state.orderFlights.passengers)

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [passengerFind, setPassengerFind] = useState('')

  let passengerFormConfig
  if (isFlightsPage) {
    passengerFormConfig = useSelector(state => state.priceRS.model.passengerFormConfig[passenger.type])
  }
  if (isHotelsPage) {
    if (index) {
      passengerFormConfig = useSelector(state => state.bookerForm.extraGuestFields)
    } else {
      passengerFormConfig = useSelector(state => state.bookerForm.bookerFields)
    }
  }

  useEffect(() => {
    const selectFirst = user.clientMode === CLIENT_MODE_BUSINESSES && user?.businessInfo?.permission_level === 'basic'
    if (selectFirst && !controller.isChecked(passenger.id, savePassengers)) {
      setOpenOptions(0)
      controller.handleClickPassenger(
        savePassengers[0],
        0,
        setOpenOptions,
        setPassengerFind,
        passengerFormConfig,
        setPopupOpen,
        clientMode
      )
    }
  }, [])


  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (openOptionsPassenger === index) {
          setOpenOptions(index)
          setDropdownOpen(false)
        }
      }}
      disabled={popupOpen}
    >
      <div className='passenger'>
        <div
          className={'passenger__value ' + (openOptionsPassenger === index && 'passenger__value-focus')}
          onClick={(e) => {
            if (
              typeof e.target.className === 'string' &&
              e.target.className.includes('passenger') &&
              e.target.className !== 'passenger__choose-remove'
            ) {
              if (_.size(savePassengers) === 0) {
                controller.handleClickNewPassenger(setPopupOpen, index)
              } else {
                setDropdownOpen(true)
              }
              setOpenOptions(index)
              hotelAnalytics.selectedGuest()
            }
          }}
        >
          {(!controller.isChecked(passenger.id, savePassengers) || openOptionsPassenger === index)
            ? <PassengerAdd
                index={index}
                number={number}
                passengerFind={passengerFind}
                setPassengerFind={setPassengerFind}
              />
            : <PassengerChoose checked index={index} passenger={passenger} />}
        </div>
        <div className={'passenger__list ' +
        (openOptionsPassenger === index && dropdownOpen && 'passenger__list-open')}
        >
          <div className='passenger__list-have'>
            {savePassengers &&
            savePassengers
              .filter(savePassenger => {
                if (isFlightsPage && passenger.type !== savePassenger.type) {
                  return false
                }

                const searchLowerCase = passengerFind.toLowerCase()
                const searchLowerCaseTranslit = iuliia.translate(searchLowerCase, iuliia.ICAO_DOC_9303)
                const firstNameLowerCase = savePassenger.firstName.toLowerCase()
                const lastNameLowerCase = savePassenger.lastName.toLowerCase()

                return firstNameLowerCase.includes(searchLowerCase) ||
                  lastNameLowerCase.includes(searchLowerCase) ||
                  firstNameLowerCase.includes(searchLowerCaseTranslit) ||
                  lastNameLowerCase.includes(searchLowerCaseTranslit)
              })
              .sort((a, b) => {
                return ('' + a.lastName).localeCompare(b.lastName)
              })
              .map((savePassenger, savePassengerIndex) => {
                return (
                  <PassengerChoose
                    key={'people' + savePassenger.id}
                    passenger={savePassenger}
                    index={savePassengerIndex}
                    checked={controller.isChecked(savePassenger.id, orderFlightsPassengers)}
                    numberGuest={index}
                    onClick={() => {
                      controller.handleClickPassenger(
                        savePassenger,
                        openOptionsPassenger,
                        setOpenOptions,
                        setPassengerFind,
                        passengerFormConfig,
                        setPopupOpen,
                        clientMode
                      )
                    }}
                    setPopupOpen={setPopupOpen}
                    list
                  />
                )
              })}
          </div>
          {!CheckBusinessUser(user) &&
            <div className='passenger__list-add'>
              <button
                onClick={() => {
                  controller.handleClickNewPassenger(setPopupOpen, index)
                }}
                className='add__button'
              >
                {isHotelsPage
                  ? t('Register new guest')
                  : t('Register new passenger')}
              </button>
            </div>}
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default Passenger
