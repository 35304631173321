import React from 'react'
import { useSelector } from 'react-redux'
import SearchScreen from '../../screen/searchScreen'
import ResultScreen from '../../screen/resultScreen'
import FareScreen from '../../screen/fareScreen'
import PurchaseScreen from '../../screen/purchaseScreen'
import SuccessScreen from '../../screen/successScreen'
import Breadcrumbs from '../../../components/pageComponents/breadcrumbs'
import {
  FARE_SCREEN,
  RESULTS_SCREEN,
  SEARCH_SCREEN,
  SUCCESS_SCREEN,
  PURCHASE__SCREEN
} from '../../../reducers/page/types'
const FlightsPage = () => {
  const page = useSelector(state => state.page.name)

  return (
    <div className='flight__page'>
      {(page === FARE_SCREEN || page === PURCHASE__SCREEN) &&
        <Breadcrumbs />}
      {(page === SEARCH_SCREEN || page === RESULTS_SCREEN) &&
        <>
          <SearchScreen />
          {(page === RESULTS_SCREEN) &&
            <div className='container'>
              <ResultScreen />
            </div>}
        </>}
      <div className='container'>
        {page === FARE_SCREEN && <FareScreen />}
        {page === PURCHASE__SCREEN && <PurchaseScreen />}
        {page === SUCCESS_SCREEN && <SuccessScreen />}
      </div>
    </div>
  )
}

export default FlightsPage
