import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FareHeaderController from './controller'


const FareHeader = ({ text }) => {
  const { t } = useTranslation()
  const controller = new FareHeaderController()
  const outbound = useSelector(state => state.itineraries.outbound)
  const inbound = useSelector(state => state.itineraries.inbound)
  const searchRS = useSelector(state => state.searchRS.model)
  const direction = controller.getDirection(searchRS, outbound.outbound)

  return (
    <div className='fareHeader'>
      {text}
      <span>
        {direction.origin.city} {t('to')} {direction.destination.city}
        {inbound &&
          ' ' + t('and back')}
      </span>
    </div>
  )
}

export default FareHeader
