import './style.sass'

const Checkbox = ({ text = '', addedClass = '', checked = false, onChange }) => {
  return (
    <div className={`checkBox ${addedClass}`}>
      <label className='checkBox__label'>
        <input
          className='checkBox__input'
          type='checkbox'
          checked={checked}
          onChange={onChange}
        />
        <span className='checkBox__text'>{text}</span>
      </label>
    </div>
  )
}

export default Checkbox
