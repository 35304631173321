import React, { useEffect } from 'react'
import './style.sass'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import HotelLocation from './_location'
import HotelMainInformation from './_mainInformation'
import HotelDetailMenu from './_menu'
import HotelPhotos from './_photos'
import HotelPropertyAmenities from './_propertyAmenities'
import HotelReviews from './_reviews'
import HotelRooms from './_rooms'
import HotelDetailController from './controller'
import _ from 'lodash'
import HotelScreenAnalytics from '../../../analytics/analyticsHotel/hotelScreenAnalytics'
import { RoomOptionsProvider } from '../../../context'

const HotelDetail = () => {
  const controller = new HotelDetailController()
  const hotelAnalytics = new HotelScreenAnalytics()
  const location = useLocation()
  const hotel = useSelector(state => state.hotel.choice)
  const orderHotel = useSelector(state => state.orderHotels)
  const analyticsHotel = new HotelScreenAnalytics()

  useEffect(() => {
    if (orderHotel.hotel &&
      orderHotel.currency &&
      orderHotel.checkin &&
      orderHotel.checkout &&
      orderHotel.people) {
      analyticsHotel.seeInfoAboutHotel(orderHotel)
    }
  }, [orderHotel])

  useEffect(() => {
    controller.firstMount()
  }, [location.search])

  useEffect(() => {
    hotel && hotelAnalytics.dataIsLoaded()
  }, [hotel])

  if (!_.size(hotel)) return null
  return (
    <RoomOptionsProvider previousOptions={JSON.parse(window.localStorage.getItem('roomOptions'))}>
      <div className='HotelDetail'>
        {/*<HotelGoBack />*/}
        <HotelPhotos />
        <HotelDetailMenu />
        <HotelMainInformation />
        <HotelRooms />
        <HotelLocation />
        <HotelPropertyAmenities />
        <HotelReviews />
      </div>
    </RoomOptionsProvider>

  )
}

export default HotelDetail
