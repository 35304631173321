import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import Icons from '../../../../../../../pageComponents/icons'

const OptionTerms = ({ option }) => {
  const { t } = useTranslation()
  const { refundable, prepayRequired, fareRules } = option
  return (
    <div className='optionTerms'>
      <div className={'term ' + (refundable && 'term__active')}>
        <Icons
          group='icon'
          icon='checked'
          className='optionTerms__icon'
        />
        {refundable
          ? t('Refundable')
          : t('Non Refundable')}
      </div>
      <div className='description'>
        {fareRules && fareRules.policy.cancellationDescription}
      </div>
      <div className={'term ' + (!prepayRequired && 'term__active')}>
        <Icons
          group='icon'
          icon='checked'
          className='optionTerms__icon'
        />
        {prepayRequired
          ? t('Deposit Required')
          : t('No Deposit Required')}
      </div>
    </div>
  )
}

export default OptionTerms
