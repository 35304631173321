export const SET_INFO = 'SET_INFO'
export const RESET_INFO = 'RESET_INFO'
export const SHOW_INFO = 'SHOW_INFO'
export const HIDE_INFO = 'HIDE_INFO'

export const setInfo = (payload) => ({
  type: SET_INFO,
  payload
})
export const resetInfo = () => ({
  type: RESET_INFO,
})
export const showInfo = () => ({
  type: SHOW_INFO,
})
export const hideInfo = () => ({
  type: HIDE_INFO,
})
