import React from 'react'

import { OrderHeader, Tabs } from 'modules/orders'

import './style.sass'
import { Export } from '../components/export'


export const OrdersLayout: React.FC = ({ children }) => {
  return (
    <div className='orders__page'>
      <div className='container'>
        <OrderHeader />
        <div className='row'>
          <Tabs />
          <Export />
        </div>
        {children}
      </div>
    </div>
  )
}
