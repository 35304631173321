import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import MapboxMap from '../../../pageComponents/mapBox'
import { useIsVisibleElement } from '../../../../hooks/useIsVisibleElement'
import HotelScreenAnalytics from '../../../../analytics/analyticsHotel/hotelScreenAnalytics'


const HotelLocation = () => {
  const { t } = useTranslation()
  const hotelState = useSelector(state => state.hotel.choice)
  const analyticsHotel = new HotelScreenAnalytics()
  const [element] = useIsVisibleElement(analyticsHotel.seeInfoAboutCart, 100)

  const lon = hotelState.location?.lon || 0
  const lat = hotelState.location?.lat || 0

  return (
    <div className='hotelLocation' id='Location' ref={element}>
      <div className='hotelLocation__header'>
        {t('Location')}
      </div>
      <div className='hotelLocation__address'>
        {hotelState.address}
      </div>
      <div className='hotelLocation__map'>
        <MapboxMap
          initialOptions={{
            center: [lon, lat],
            zoom: 15
          }}
          hotel={hotelState}
          search={false}
        />
      </div>
      {/*<div className='hotelLocation__distance'>*/}
      {/*  <div className='left'>*/}
      {/*    <div className='hotelLocation__header'>*/}
      {/*      Nearby ?*/}
      {/*    </div>*/}
      {/*    <div className='hotelLocation__distance-element'>*/}
      {/*      City center <span>2,1 km</span>*/}
      {/*    </div>*/}
      {/*    <div className='hotelLocation__distance-element'>*/}
      {/*      Central railway station <span>6,7 km</span>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className='right'>*/}
      {/*    <div className='hotelLocation__header'>*/}
      {/*      Airports*/}
      {/*    </div>*/}
      {/*    <div className='hotelLocation__distance-element'>*/}
      {/*      JFK International Airport <span>28 km</span>*/}
      {/*    </div>*/}
      {/*    <div className='hotelLocation__distance-element'>*/}
      {/*      LaGuardia Airport <span>29,2 km</span>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}

export default HotelLocation
