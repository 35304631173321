export default function usePrice (currencies, pricingOption, priceOutbound) {
  const priceObject = pricingOption.priceBreakdownConverted || pricingOption.priceBreakdown
  const currencyObject = currencies[priceObject.currency]
  if (currencyObject === undefined) return false
  const currencyObjectUSD = currencies.USD
  if (currencyObjectUSD === undefined) return false
  const valueUSD = pricingOption.priceBreakdown.value || 0
  const base = priceObject.base || 0
  const tax = priceObject.tax || 0
  let value = priceObject.value || 0
  let valueSum = 0
  if (priceOutbound) {
    value = value - priceOutbound.number
    valueSum = parseInt(value) + parseInt(priceOutbound.number)
  }
  return {
    value: generateString(currencyObject, value),
    valueUSD: generateString(currencyObjectUSD, valueUSD),
    valueSum: generateString(currencyObject, valueSum),
    base: generateString(currencyObject, base),
    tax: generateString(currencyObject, tax),
    number: value.toFixed(2),
  }
}

const generateString = (currencyObject, value) => {
  value = value.toFixed(2)
  value = value.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
  return currencyObject.symbolOnLeft
    ? `${currencyObject.symbol}${value}`
    : `${value}${currencyObject.symbol}`
}
