import React from 'react'
import './style.sass'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const HotelReviews = ({ hotel }) => {
  const { t } = useTranslation()
  const hotelChoice = useSelector(state => state.hotel.choice)
  return (
    <div className='hotelReviews'>
      {hotel.reviewScore ? hotel.reviewScore + '/10' : null}
      <br />
      <AnchorLink
        href='#Reviews'
        offset='120'
      >
        {hotel.reviewCount
          ? hotel.reviewCount + ' ' + t('Review', { count: hotel.reviewCount })
          : hotelChoice ? t('Review') : ''}
      </AnchorLink>
    </div>
  )
}

export default HotelReviews
