import React from 'react'
import { useTranslation } from 'react-i18next'

const Radio = ({ item, checked, onClick }) => {
  const { t } = useTranslation()
  return (
    <div className='radio'>
      <input
        type='radio'
        id={item.name}
        value={item.value}
        onClick={onClick}
        defaultChecked={checked}
        name='dropdown__list-cabin'
        className='radio__input'
      />
      <label htmlFor={item.name} className='radio__label'>
        {t(item.name)}
      </label>
    </div>
  )
}

export default Radio
