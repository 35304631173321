import React, { useState } from 'react'
import './style.sass'
import { useSelector } from 'react-redux'
import { CLIENT_MODE_BUSINESSES } from '../../../reducers/user/constans'
import Dropdown from './_dropdown'
import People from './_people'
import Cabin from './_cabin'

const PeopleAndCabin = () => {
  const [showList, setShowList] = useState(false)
  const user = useSelector(state => state.user)
  const setShow = (name) => {
    setShowList(showList !== name ? name : false)
  }
  return (
    <div className='peopleAndCabin'>
      {user.clientMode === CLIENT_MODE_BUSINESSES && user?.businessInfo?.permission_level === 'basic'
        ? ''
        : <Dropdown child={<People showList={showList} setShowList={setShow} />} />}
      <Dropdown child={<Cabin showList={showList} setShowList={setShow} />} />
    </div>
  )
}
export default PeopleAndCabin
