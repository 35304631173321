import { CLEAR_STATE, START_SEARCH } from '../actions'
import { SET_ITINERARIES, UPDATE_ITINERARIES } from './actions'

const defaultState = {
  outbound: null,
  inbound: null,
  outboundDate: null,
  inboundDate: null,
  allOutboundItineraries: [],
  uniqueOutboundItineraries: {},
  allInboundItineraries: null,
  exceedsCount: 0,
  showExceeds: false
}

const itineraries = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_ITINERARIES:
      return {
        ...state,
        [action.field]: action.payload
      }
    case SET_ITINERARIES:
      return {
        ...state,
        allOutboundItineraries: action.payload.allOutboundItineraries,
        uniqueOutboundItineraries: action.payload.uniqueOutboundItineraries,
      }
    case START_SEARCH:
    case CLEAR_STATE:
      return defaultState
    default:
      return state
  }
}

export default itineraries
