import { useDispatch, useSelector } from 'react-redux'
import Api from '../../../api'
import { CheckBusinessUser } from '../../../hooks/checkBusinessUser'
import { updateButton } from '../../../reducers/button/actions'
import { PAY_BUTTON } from '../../../reducers/button/types'
import { setInfo } from '../../../reducers/infoPopup/actions'
import { updateOrderRS, updateReseveRS } from '../../../reducers/order/actions'
import { changePage } from '../../../reducers/page/actions'
import { SUCCESS_SCREEN } from '../../../reducers/page/types'
import OrderAnalyticsFlight from '../../../analytics/analyticsFlight/orderAnalytics'
import _ from 'lodash'
import getSegments from '../../../hooks/getSegments'
import { useTranslation } from 'react-i18next'
import { CLIENT_MODE_BUSINESSES } from '../../../reducers/user/constans'
import { getPriceWithCurrency } from '../../../utils/prices'

export default class SummaryController {
  constructor () {
    this.lang = useTranslation().i18n.language
    this.api = new Api()
    this.dispatch = useDispatch()
    this.analyticsFlight = new OrderAnalyticsFlight()
    this.passengerFormConfig = useSelector(state => state.priceRS.model.passengerFormConfig)
  }

  getFlightInfo = (itineraries) => {
    const segments = getSegments(itineraries)
    return {
      departureDate: _.first(segments).departureDate,
      arrivalDate: _.last(segments).arrivalDate,
      origin: _.first(segments).origin,
      destination: _.last(segments).destination
    }
  }

  showBookButton = (passengers, payment) => {
    let allChecked = 0
    passengers.forEach(passenger => {
      if (passenger.id) {
        allChecked++
      }
    })
    return passengers.length === allChecked && (payment.card?.number || payment.cardToken)
  }

  handleClickBook = (order, currency, pricingOption, setIFrame3DSpaymentUrl, isTwoStepBooking, user) => {
    if (CheckBusinessUser(user)) {
      order.clientMode = CLIENT_MODE_BUSINESSES
      order.companyId = user.business.company_info.id
    }
    this.analyticsFlight.orderCreate(this.analyticsFlight.ACTIONS.TAP, pricingOption.classes)

    this.dispatch(updateButton(PAY_BUTTON, 'disabled', true))
    this.dispatch(updateButton(PAY_BUTTON, 'isLoading', true))

    if (isTwoStepBooking) {
      this.orderReserve(order, currency, pricingOption, setIFrame3DSpaymentUrl)
    } else {
      this.orderCreate(order, currency, pricingOption, setIFrame3DSpaymentUrl)
    }
  }


  orderCreate = (order, currency, pricingOption, setIFrame3DSpaymentUrl) => {
    const orderCheckedRequiredDocs = this._checkRequiredDocs(order)

    return this.api.orderCreate(orderCheckedRequiredDocs, currency, pricingOption)
      .then(orderRS => {
        this.parseOrderRS(orderRS, setIFrame3DSpaymentUrl)
      })
  }

  _checkRequiredDocs = (order) => {
    const passengersCheckedRequiredDocs = order?.passengers.map(passenger => {
      const documents = this.passengerFormConfig[passenger.type].find(elem => elem.field === 'document')
      if (!documents?.required) {
        delete passenger.document
        delete passenger.documentExpiryDateUnlimited
      }
      return passenger
    })

    order.passengers = passengersCheckedRequiredDocs
    return order
  }

  orderReserve = (order, currency, pricingOption, setIFrame3DSpaymentUrl) => {
    return this.api.orderReserve(order, currency, pricingOption)
      .then(reserveRS => {
        if (reserveRS?.order) {
          if (!reserveRS.order.isPriceChanged) {
            this.orderIssue(order, currency, pricingOption, setIFrame3DSpaymentUrl, reserveRS)
          } else {
            const { priceBreakdownConverted, priceBreakdown } = reserveRS?.order?.pricingOption
            const price = priceBreakdownConverted || priceBreakdown
            const info = {
              header: 'Booking',
              message: 'Please confirm your booking for ' + getPriceWithCurrency(price.value, price.currency, this.lang)
            }
            this.dispatch(setInfo(info))
          }
        }
        this.dispatch(updateReseveRS(reserveRS))
      })
      .catch(e => {
        console.error(e)
      })
  }

  orderIssue = (order, currency, pricingOption, setIFrame3DSpaymentUrl, reserve) => {
    return this.api.orderIssue(order, currency, pricingOption, reserve)
      .then(issueRS => {
        this.parseOrderRS(issueRS, setIFrame3DSpaymentUrl)
      })
  }


  parseOrderRS = (orderRS, setIFrame3DSpaymentUrl) => {
    if (orderRS) {
      this.dispatch(updateOrderRS(orderRS))
      if (orderRS.paymentStatus === '3ds') {
        setIFrame3DSpaymentUrl(orderRS.paymentUrl)
      } else {
        this.dispatch(changePage(SUCCESS_SCREEN))
      }
    } else {
      this.analyticsFlight.orderCreate(this.analyticsFlight.ACTIONS.ERROR)
    }
    this.dispatch(updateButton(PAY_BUTTON, 'disabled', false))
    this.dispatch(updateButton(PAY_BUTTON, 'isLoading', false))
  }
}
