import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'

export default class HotelsNavigationController {
  constructor () {
    this.history = useHistory()
    this.location = useLocation()
  }

  availableResultsPage = () => {
    const query = new URLSearchParams(this.location.search)
    const cityname = query.get('cityname')
    const checkin = query.get('checkin')
    const checkout = query.get('checkout')
    const people = query.get('people')
    const longitude = query.get('longitude')
    const latitude = query.get('latitude')
    const autocomplete_type = query.get('autocomplete_type')
    const autocomplete_id = query.get('autocomplete_id')
    const currency = query.get('currency')
    return (
      cityname &&
      checkin &&
      checkout &&
      people &&
      longitude &&
      latitude &&
      autocomplete_type &&
      autocomplete_id &&
      currency
    )
  }

  availableResultsDetailPage = () => {
    const query = new URLSearchParams(this.location.search)
    const key = query.get('key')
    const currency = query.get('currency')
    const checkIn = query.get('checkIn')
    const checkOut = query.get('checkOut')
    const people = query.get('people')
    return (key && currency && checkIn && checkOut && people)
  }
}
