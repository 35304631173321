import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'

const Checkbox = ({ filter, id, type, checked, name, onChange }) => {
  const { t } = useTranslation()
  return (
    <div className='checkbox'>
      <input
        className='checkbox__input'
        type='checkbox'
        id={id}
        checked={checked}
        onChange={() => onChange(filter, type)}
      />
      <label className='checkbox__label' htmlFor={id}>
        {t(name)}
      </label>

    </div>
  )
}

export default Checkbox
