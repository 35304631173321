import { useCallback } from 'react'
import { RoomOptionsInitialState } from '../../../../../context/RoomOptionsContext/types'
import { OptionType, RoomType } from '../../../../../reducers/order/types'

interface Option {
  meal: {
    breakfastIncl: boolean;
    lunchIncl: boolean;
    dinnerIncl: boolean;
  };
  refundable: boolean;
  paymentOptions: boolean;
}

export default class RoomFiltersController {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    private readonly prev: RoomOptionsInitialState,
    private readonly rooms?: RoomType[],
  ) {}

    checkAvailability = (options?: Option) => {
      return Object.values(options?.meal || {}).some(Boolean) || options?.paymentOptions || options?.refundable
    }

    checkPrevState = () => {
      return this.checkMeal() || this.checkRefundable() || this.checkPaymentOptions()
    }

    getAvailableOptions = useCallback(() => {
      const options = {
        meal: this.checkRoomsWithMeal(),
        refundable: this.checkRoomsWithRefundable(),
        paymentOptions: this.checkRoomsWithPrepay(),
      }
      return options
    }, [this.rooms?.length])

    checkMeal = () => {
      return Object.values(this.prev?.meal || {}).some(Boolean)
    }

    checkRefundable = () => {
      return this.prev?.refundable
    }

    checkPaymentOptions = () => {
      return this.prev?.paymentOptions.withPrepay || this.prev?.paymentOptions.withoutPrepay
    }


  private checkRoomsWithRefundable = () => {
    // Check if there are rooms with refundable and not refundable options
    const filteredRooms = this.rooms?.filter(room => {
      return room.options.some(option => option.refundable === true) &&
      room.options.some(option => option.refundable === false)
    })
    return filteredRooms?.length > 0
  }

  private checkRoomsWithPrepay = () => {
    // Check if there are rooms with required and not required prepay option
    const filteredRooms = this.rooms?.filter(room => {
      return room.options.some(option => option.prepayRequired === true) &&
      room.options.some(option => option.prepayRequired === false)
    })
    return filteredRooms?.length > 0
  }

  private checkRoomsWithMeal = () => {
    let meal = {
      breakfastIncl: false,
      lunchIncl: false,
      dinnerIncl: false
    }
    this.rooms?.forEach(room => {
      room.options.forEach(option => {
        meal = {
          breakfastIncl: this.checkRoomWithMeal(option, 'breakfastIncl') || meal.breakfastIncl,
          lunchIncl: this.checkRoomWithMeal(option, 'lunchIncl') || meal.lunchIncl,
          dinnerIncl: this.checkRoomWithMeal(option, 'dinnerIncl') || meal.dinnerIncl,
        }
      })
    })
    return meal
  }

  private checkRoomWithMeal = (
    option: OptionType,
    key: 'breakfastIncl' | 'lunchIncl' | 'dinnerIncl'
  ) => {
    return option[key]
  }
}
