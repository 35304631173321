import { useDispatch, useSelector } from 'react-redux'
import Api from '../../../../api'
import moment from 'moment'

export default class HotelElementController {
  constructor () {
    this.api = new Api()
    this.dispatch = useDispatch()
    this.searchForm = useSelector(state => state.searchForm)
  }

  getParams = (hotel, hotelState) => {
    const params = new URLSearchParams()
    params.set('key', hotel.key)
    params.set('checkIn', moment(hotelState?.checkinDate).format('YYYY-MM-DD'))
    params.set('checkOut', moment(hotelState?.checkoutDate).format('YYYY-MM-DD'))
    params.set('people', hotelState?.guests.people)
    params.set('currency', this.searchForm.currency)
    return params.toString()
  }
}
