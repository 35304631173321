import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Icons from '../../pageComponents/icons'
import DirectFlightOnlyController from './controller'

const DirectFlightsOnly = () => {
  const { t } = useTranslation()
  const directFlightsOnly = useSelector(state => state.filter.directFlightsOnly)
  const controller = new DirectFlightOnlyController()
  return (
    <div className='directFlightsOnly'>
      <input
        type='checkbox'
        id='directFlightsOnly'
        checked={directFlightsOnly}
        className='directFlightsOnly__input'
        onChange={() => {
          controller.handleClick(!directFlightsOnly)
        }}
      />
      <label
        htmlFor='directFlightsOnly'
        className='directFlightsOnly__label filter__el'
      >
        {t('Direct flights only')}
        <Icons
          group='icon'
          icon='close'
          className='directFlightsOnly__label-icon'
          color='#3D73EC'
        />
      </label>
    </div>
  )
}
export default DirectFlightsOnly
