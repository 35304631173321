import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Icons from '../../../pageComponents/icons'
import GuestsController from '../controller'
import ChildrenSelect from './_childrenSelect'


const Children = ({ indexRoom }) => {
  const { t } = useTranslation()
  const guests = useSelector(state => state.searchForm.guests)
  const childrenAge = guests.rooms[indexRoom].children
  const controller = new GuestsController()
  const [visible, setVisible] = useState(false)

  return (
    <div className='children'>
      <div className='children__text'>
        {t('Children')}
        <span>
          0 - 17
        </span>
      </div>
      <div className='children__add'>
        {_.map(childrenAge, (age, indexChild) => {
          return (
            <OutsideClickHandler
              key={`child ${indexChild} ${indexRoom}`}
              onOutsideClick={() => { setVisible(false) }}
            >
              <div
                className={'children__add-btn ' + (visible === indexChild + 1 && 'open')}
              >
                <button
                  className='button button-active'
                  onClick={() => setVisible(indexChild + 1)}
                >
                  {t('Year', { count: age })}
                </button>
                <button
                  className='button-icon'
                  onClick={() => {
                    controller.handleOnRemoveChildren(indexRoom, indexChild, guests.rooms)
                  }}
                >
                  <Icons
                    group='icon'
                    icon='close'
                  />
                </button>
                <ChildrenSelect
                  ageCurrent={age}
                  indexRoom={indexRoom}
                  indexChild={indexChild}
                  setVisible={setVisible}
                  visible={visible === indexChild + 1}
                />
              </div>
            </OutsideClickHandler>
          )
        })}
        {_.size(childrenAge) >= 8
          ? ''
          : (
            <OutsideClickHandler
              onOutsideClick={() => {
                setVisible(false)
              }}
            >
              <div className={'children__add-btn ' + (visible === 0 && 'open')}>
                <button
                  onClick={() => setVisible(0)}
                  className='button'
                >
                  {t('Add a child')}
                </button>
                <ChildrenSelect
                  indexRoom={indexRoom}
                  setVisible={setVisible}
                  visible={visible === 0}
                />
              </div>
            </OutsideClickHandler>)}
      </div>
    </div>
  )
}

export default Children
