import React from 'react'
import './style.sass'
import { useSelector } from 'react-redux'
import HandleSaveToPC from '../../../hooks/handleSaveToPC'

const SaveState = () => {
  const allState = useSelector(state => state)
  return (
    <div
      className='saveState'
      onDoubleClick={() => HandleSaveToPC(allState, 'error_log')}
    />
  )
}

export default SaveState
