import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { hideError } from '../../../reducers/error/actions'
import Icons from '../icons'
import ErrorController from './controller'

const ErrorPopup = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const error = useSelector(state => state.error)
  const controller = new ErrorController()
  return (
    <div className={'errorPopup ' + (error.show && 'errorPopup__show')}>
      <div className='errorPopup__wrapper'>
        <button
          onClick={() => {
            error.header === 'OrderCriticalError'
              ? controller.handleSearchAgain()
              : dispatch(hideError())
          }}
          className='errorPopup__close'
        >
          <Icons
            group='icon'
            icon='close'
          />
        </button>
        <div className='errorPopup__wrapper-header'>{t('Error')}</div>
        <div className='errorPopup__wrapper-message'>
          {error.message} <br />
          {error.header === 'OrderCriticalError'
            ? (
              <button
                onClick={controller.handleSearchAgain}
              >
                {t('Start new search')}
              </button>
              )
            : (
              <a
                href='mailto:support@appintheair.com'
                target='blank'
                onClick={controller.handleClickSupport}
              >
                support@appintheair.com
              </a>
              )}
        </div>

      </div>
    </div>
  )
}

export default ErrorPopup
