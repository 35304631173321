import { ORDERS_SCREEN_PAST, ORDERS_SCREEN_UPCOMING, RESULTS_SCREEN } from '../page/types'

export const SHOW_LOAD_BAR = 'SHOW_LOAD_BAR'

export const showLoadBar = (payload) => ({
  type: SHOW_LOAD_BAR,
  payload
})

export const loadBarSetting = (screen, isHotelsPage) => {
  if (isHotelsPage) return {}
  let loadBar = {
    top: 106,
  }
  switch (screen) {
    case RESULTS_SCREEN:
      loadBar = {
        top: 225
      }
      break
    case ORDERS_SCREEN_UPCOMING:
    case ORDERS_SCREEN_PAST:
      loadBar = {
        top: 67,
      }
  }

  return {
    loadBar
  }
}

