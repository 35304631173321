import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ResultsAnalyticsFlight from '../../../analytics/analyticsFlight/resultsAnalytics'
import ResultsAnalyticsHotel from '../../../analytics/analyticsHotel/resultsAnalytics'
import { search } from '../../../api/socket'
import currentLocation from '../../../hooks/currentLocation'
import generateHotelPeople from '../../../hooks/generateHotelPeople'
import { startSearch } from '../../../reducers/actions'
import { changeSearch, clearRating, clearSearchFilter, clearStars } from '../../../reducers/filter/actions'
import {
  updateHotelAutocompleteFeatures,
  updateHotelAutocompleteValue,
  updateHotel
} from '../../../reducers/hotel/actions'
import { updateItineraries } from '../../../reducers/itineraries/actions'
import { showLoadBar } from '../../../reducers/loadBar/actions'
import { changePage, setPrevPage } from '../../../reducers/page/actions'
import { RESULTS_SCREEN, SEARCH_SCREEN } from '../../../reducers/page/types'
import { updateSearchForm } from '../../../reducers/search/actions'
import useSearchValidation from '../../../hooks/useSearchValidation'
import SearchAnalyticFlight from '../../../analytics/analyticsFlight/searchAnalytics'
import SearchAnalyticsHotel from '../../../analytics/analyticsHotel/searchAnalytics'

export default class SearchButtonController {
  constructor () {
    this.dispatch = useDispatch()
    this.translation = useTranslation()
    this.searchAnalyticsFlight = new SearchAnalyticFlight()
    this.searchAnalyticsHotel = new SearchAnalyticsHotel()
    this.hotel = useSelector(state => state.hotel)
    this.resultAnalyticsFlight = new ResultsAnalyticsFlight()
    this.resultAnalyticsHotel = new ResultsAnalyticsHotel()
    this.history = useHistory()
    this.t = useTranslation().t
  }

  handleOnClick = (searchForm, searchRS, user) => {
    this.dispatch(changeSearch(''))
    this.dispatch(clearStars())
    this.dispatch(clearRating())
    this.dispatch(clearSearchFilter())
    const { isHotelsPage, isFlightsPage } = currentLocation()

    const validation = useSearchValidation(searchForm, this.t)

    if (validation.length) {
      validation.forEach(error => {
        this.dispatch(updateSearchForm(error.field, 'error', error.message))
      })
    } else {
      this.dispatch(startSearch())
      if (isFlightsPage) {
        this.dispatch(setPrevPage(SEARCH_SCREEN))
        this.dispatch(updateItineraries('outboundDate', searchForm.calendar.start))
        this.dispatch(updateItineraries('inboundDate', searchForm.calendar.end))
        this.dispatch(changePage(RESULTS_SCREEN))
        this.dispatch(showLoadBar(RESULTS_SCREEN))

        const searchAnalytics = {
          searchAnalyticsFlight: this.searchAnalyticsFlight,
          resultAnalyticsFlight: this.resultAnalyticsFlight,
          resultAnalyticsHotel: null,
          searchAnalyticsHotel: null
        }
        search(
          searchForm,
          searchRS,
          this.dispatch,
          this.translation,
          this.hotel,
          searchAnalytics,
          user
        )
        this.searchAnalyticsFlight.startSearch(searchForm)
      }
      if (isHotelsPage) {
        const params = new URLSearchParams()
        params.set('cityname', searchForm.whereStay.stay.city)
        params.set('name', searchForm.whereStay.stay.name)
        params.set('region', searchForm.whereStay.stay.region)
        params.set('country', searchForm.whereStay.stay.country)
        params.set('checkin', moment(searchForm.calendar.start).format('YYYY-MM-DD'))
        params.set('checkout', searchForm.calendar.end ? moment(searchForm.calendar.end).format('YYYY-MM-DD') : null)
        params.set('people', generateHotelPeople(searchForm, this.translation.t).people)
        params.set('longitude', searchForm.whereStay.stay.longitude)
        params.set('latitude', searchForm.whereStay.stay.latitude)
        params.set('autocomplete_type', searchForm.whereStay.stay.type)
        params.set('autocomplete_id', searchForm.whereStay.stay.id)
        params.set('currency', searchForm.currency)

        params.set('unix', moment().unix())

        this.searchAnalyticsHotel.startSearch(this.hotel, searchForm)
        this.dispatch(updateHotelAutocompleteValue(''))
        this.dispatch(updateHotelAutocompleteFeatures([]))

        this.dispatch(updateHotel('exceedsCount', 0))
        this.dispatch(updateHotel('showExceeds', false))

        this.history.push(
          '/hotels/results?' + params.toString()
        )
      }
    }
  }
}
