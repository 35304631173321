import { useDispatch, useSelector } from 'react-redux'
import Api from '../../../../../api'
import ResultsAnalytics from '../../../../../analytics/analyticsHotel/resultsAnalytics'


export default class HotelPhotoController {
  constructor () {
    this.api = new Api()
    this.dispatch = useDispatch()
    this.analyticsHotel = new ResultsAnalytics()
    this.hotelData = useSelector(state => state.hotel)
    this.searchRS = useSelector(state => state.searchRS)
  }

  getSettingSlider = (setActiveSlide, hotel) => {
    return {
      dots: false,
      arrows: true,
      lazyLoad: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: current => {
        this.analyticsHotel.scrollPhoto(hotel, this.hotelData, this.searchRS)
        this.setActiveSlide(current, setActiveSlide, hotel)
      }
    }
  }

  setActiveSlide = (current, setActive, hotel) => {
    if (current < 2) {
      setActive(current)
    }
    if (current >= 2 && current < (hotel.photosCount - 2)) {
      setActive(2)
    }
    if (current >= (hotel.photosCount - 2)) {
      setActive(5 - (hotel.photosCount - current))
    }
  }

  generateUrl = (hotelKey, number) => {
    return this.api.getPhotosUrl(hotelKey, number)
  }
}
