import React from 'react'
import './style.sass'
import { Link } from 'react-router-dom'
import Icons from '../../../pageComponents/icons'
import currentLocation from '../../../../hooks/currentLocation'


const MenuElement = ({ icon, text, link = false }) => {
  const location = currentLocation(link)
  return (
    <Link to={link} className={'menuElement ' + (location.activeLink && 'menuElement__active')}>
      <Icons group='icon' icon={icon} color={location.activeLink ? '#000' : '#fff'} />
      <span className='menuElement__text'>
        {text}
      </span>
    </Link>
  )
}
export default MenuElement
