import { useDispatch } from 'react-redux'
import ErrorAnalyticsFlights from '../../../analytics/analyticsFlight/errorAnalytics'
import ErrorAnalyticsHotels from '../../../analytics/analyticsHotel/errorAnalytics'
import { socketDisconnect } from '../../../api/socket'
import currentLocation from '../../../hooks/currentLocation'
import { clearState } from '../../../reducers/actions'
import { changePage } from '../../../reducers/page/actions'
import { SEARCH_SCREEN } from '../../../reducers/page/types'

export default class ErrorController {
  constructor () {
    this.analyticsFlight = new ErrorAnalyticsFlights()
    this.analyticsHotel = new ErrorAnalyticsHotels()
    this.dispatch = useDispatch()
  }

  handleClickSupport = () => {
    const { isFlightsPage, isHotelsPage } = currentLocation()
    if (isFlightsPage) {
      this.analyticsFlight.clickSupport()
    } else if (isHotelsPage) {
      this.analyticsHotel.clickSupport()
    }
  }

  handleSearchAgain = () => {
    this.dispatch(clearState())
    socketDisconnect()
    this.dispatch(changePage(SEARCH_SCREEN))
  }
}

