import React from 'react'
import ConformationScreen from '../../screen/confirmationScreen'

const HotelsConfirmationPage = () => {
  return (
    <div className='hotels__page container'>
      <ConformationScreen />
    </div>
  )
}

export default HotelsConfirmationPage
