import React from 'react'
import { useSelector } from 'react-redux'
import currentLocation from '../../../../../hooks/currentLocation'
import PassengerController from '../../controller'
import 'moment/locale/ru'
import 'moment/locale/en-gb'


const Phone = ({ field, error }) => {
  const controller = new PassengerController()
  const { isHotelsPage, isFlightsPage } = currentLocation()
  const user = useSelector(state => state.user)
  let passengerForm
  if (isFlightsPage) {
    passengerForm = useSelector(state => state.passengerForm)
  }
  if (isHotelsPage) {
    passengerForm = useSelector(state => state.bookerForm.form)
  }
  const value = controller.getFormatPhone(passengerForm[field.field])
  return (
    <>
      <label htmlFor={field.field}>
        {controller.getLabelAndOrder(field.field).label}{field.required ? '*' : ''}
      </label>
      <input
        type='text'
        name={field.field}
        id={field.field}
        className='input'
        value={value || ''}
        onChange={controller.handleOnChange}
        tabIndex={controller.getLabelAndOrder(field.field).order}
        onKeyUp={controller.handleOnKeyUp}
        disabled={controller.checkReadOnlyStatus(field.field, user, passengerForm)}
      />
      {error &&
        <div className='error'>
          {error}
        </div>}
    </>
  )
}

export default Phone
