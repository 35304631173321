import countries from '../data/iso3166'
import { CLIENT_MODE_BUSINESSES } from '../reducers/user/constans'
import currentLocation from './currentLocation'
import moment from 'moment'
import _ from 'lodash'


export default function paymentValidation (payment, controller, bookingDetail) {
  const error = []
  const { isHotelsPage, isFlightsPage } = currentLocation()
  const { card, billing, cardToken, paymentMethod } = payment
  if (paymentMethod === CLIENT_MODE_BUSINESSES && !isHotelsPage) {
    if (!cardToken) {
      errorPush(
        error,
        'cardToken',
        controller,
        'card token is required'
      )
    }
  } else {
    if (isFlightsPage || (isHotelsPage && bookingDetail.cardRequired)) {
      Object.keys(card).forEach(field => {
        if (field === 'cvc') {
          if (isFlightsPage || (isHotelsPage && bookingDetail.cvcRequired)) {
            validationCVC(card[field], error, controller)
          }
        } else {
          if (card[field] === '' || !card[field].length) {
            errorPush(error, field, controller)
          }
          if (field === 'number') {
            validationNumber(card[field], error, controller)
          }
          if (field === 'expMonth') {
            validationExpDate(card.expMonth, card.expYear, error, controller)
          }
        }
      })
    }
    if (isFlightsPage || (isHotelsPage && bookingDetail.addressRequired)) {
      Object.keys(billing).forEach(field => {
        if (billing[field] === '' || !billing[field].length) {
          errorPush(error, field, controller)
        } else if (field === 'country' && (Object.keys(countries).indexOf(billing[field]) === -1)) {
          errorPush(
            error,
            field,
            controller,
            controller.getFieldProps(field).label + ' incorrect'
          )
        }
      })
    }
  }
  return error
}

const errorPush = (error, field, controller, message) => {
  error.push({
    field: `${field}-error`,
    message: message || controller.getFieldProps(field).label + ' is required'
  })
}

const validationCVC = (value, error, controller) => {
  // cvc приходит числом, для размера нужен стринг
  const stringValue = value.toString()
  // если пустой, то ошибка required
  if (!_.size(stringValue)) {
    errorPush(error, 'cvc', controller)
  // если меньше 3, то ошибка incorrect
  } else if (_.size(stringValue) < 3) {
    errorPush(error, 'cvc', controller, controller.getFieldProps('cvc').label + ' incorrect')
  }
}

const validationNumber = (value, error, controller) => {
  // Если пусто, то ошибка required
  if (!_.size(value)) {
    errorPush(error, 'number', controller)
  } else {
    // Если меньше 16 цифр, то ошибка incorrect
    if (_.size(value) < 16) {
      errorPush(error, 'number', controller, controller.getFieldProps('number').label + ' incorrect')
    } else {
      // Если карта не валидна, то ошибка non-valid
      if (!isValidCardNumber(value)) {
        errorPush(error, 'number', controller, controller.getFieldProps('number').label + ' non-valid')
      }
    }
  }
}

const validationExpDate = (month, year, error, controller) => {
  // убраем маску
  month = month.replace(/_/g, '')
  year = year.replace(/_/g, '')
  // Если пусто, то ошибка required
  if (!_.size(month) || !_.size(year)) {
    errorPush(error, 'expMonth', controller)
  } else {
    const fullDate = moment('20' + year + '-' + month + '-1').endOf('month')
    const diff = fullDate.diff(moment(), 'days')
    // Если разница сегодня и конца месяца в карте отрицательная
    // карта просрочена, то ошибка overdue
    if (diff < 0) {
      errorPush(error, 'expMonth', controller, controller.getFieldProps('expMonth').label + ' overdue')
    }
  }
}

// Luhn algorithm to check if the card number is valid
function isValidCardNumber (cardNumber) {
  if (!process.env.REACT_APP_API_ENVIRONMENT) {
    if (VALID_TEST_CARD_LIST.includes(cardNumber)) {
      return true
    }
  }
  const trimmed = String(cardNumber).replace(/[\s]/g, '')
  const length = trimmed.length
  let odd = false
  let total = 0
  let calc
  let calc2

  if (!/^[0-9]+$/.test(trimmed)) {
    return false
  }

  for (let i = length; i > 0; i--) {
    calc = parseInt(trimmed.charAt(i - 1))
    if (!odd) {
      total += calc
    } else {
      calc2 = calc * 2

      switch (calc2) {
        case 10: calc2 = 1; break
        case 12: calc2 = 3; break
        case 14: calc2 = 5; break
        case 16: calc2 = 7; break
        case 18: calc2 = 9; break
      }
      total += calc2
    }
    odd = !odd
  }

  return (total !== 0 && (total % 10) === 0)
}

const VALID_TEST_CARD_LIST = [
  '4111111111111111', // ex. used in Mapcard
  '4111111111111112',
  '4111111111111114',
  '4111111111111115',
]
