import React from 'react'
import Icons from '../icons'

import './style.sass'

export const Option = React.memo(({ checked, onChange, name }) => {
  return (
    <div className='option-component'>
      <input
        type='checkbox'
        id={`option-component_${name}`}
        checked={checked}
        className='option-component__input'
        onChange={onChange}
      />
      <label
        htmlFor={`option-component_${name}`}
        className='option-component__label'
      >
        {name}
        <Icons
          group='icon'
          icon='close'
          className='option-component__icon'
          color='#3D73EC'
        />
      </label>
    </div>
  )
})
