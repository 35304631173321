import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import Icons from '../../../../../../../pageComponents/icons'

const OptionBeds = ({ option }) => {
  const { t } = useTranslation()
  const bedTypes = {
    SINGLE: {
      name: 'Singlebed',
      icon: 'singlebed',
      showCount: false,
    },
    DOUBLE: {
      name: 'Doublebed',
      icon: 'doublebad',
      showCount: true,
    },
    TWIN: {
      name: 'Twinbed',
      icon: 'twinbed',
      showCount: false,
    },
    QUEEN: {
      name: 'Queenbed',
      icon: 'doublebad',
      showCount: true,
    },
    KING: {
      name: 'Kingbed',
      icon: 'doublebad',
      showCount: true,
    },
  }
  const bedType = option.bedType || 'UNKNOWN'
  const bedsCount = option.beds

  if (!bedType || bedType === 'UNKNOWN') return ''
  return (
    <div className='optionBeds'>
      {bedType &&
        <>
          <Icons
            group='icon'
            icon={bedTypes[bedType]?.icon}
          />
          {t(bedTypes[bedType]?.name, { count: bedsCount })}
        </>}
    </div>
  )
}

export default OptionBeds
