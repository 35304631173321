import React from 'react'

import './style.sass'

export const NotFound: React.FC = ({ children }) => {
  return (
    <div className='not-found'>
      {children}
    </div>
  )
}
