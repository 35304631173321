import _ from 'lodash'

const generateHotelPeople = (searchForm, t) => {
  const room = _.first(searchForm.guests.rooms)
  const adults = room.adults
  const children = _.size(room.children)

  let people = ''
  people += _.repeat('A,', adults)
  if (!children) {
    people = people.slice(0, -1)
  }
  people += room.children.join(',')

  let value = ''
  value += t('Adult', { count: adults })
  if (children) {
    value += ', ' + t('Child', { count: children })
  }
  const count = adults + children

  return { people, value, count }
}

export default generateHotelPeople
