import React from 'react'
import './style.sass'
import { useSelector } from 'react-redux'
import { HOTELS_RESULTS_DETAIL_PAGE } from '../../../../navigation/routes'
import HotelName from './_name'
import HotelPhoto from './_photo'
import HotelReviews from './_reviews'
import HotelPrice from './_price'
import HotelStars from './_stars'
import Exceeds from './_exceeds'
import HotelElementController from './controller'
import { Link } from 'react-router-dom'

const HotelElement = ({ hotel, actionAnalytic }) => {
  const controller = new HotelElementController()
  const hotelState = useSelector(state => state.hotel)
  return (
    <div
      className='hotelElement'
    >
      <HotelPhoto hotel={hotel} />
      <Link
        to={HOTELS_RESULTS_DETAIL_PAGE + '?' + controller.getParams(hotel, hotelState)}
        target='_blank'
        className='hotelElement__description'
        onClick={actionAnalytic}
      >
        <div className='description__top'>
          <HotelStars hotel={hotel} />
          <Exceeds hotel={hotel} />
          <HotelName hotel={hotel} />
        </div>
        <div className='description__down'>
          <HotelReviews hotel={hotel} />
          <HotelPrice hotel={hotel} />
        </div>
      </Link>
    </div>
  )
}

export default HotelElement
