import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import GuestsController from '../controller'

const ChildrenSelect = ({ indexRoom, indexChild, ageCurrent, visible, setVisible }) => {
  const { t } = useTranslation()
  const controller = new GuestsController()
  const rooms = useSelector(state => state.searchForm.guests.rooms)

  const _renderYears = () => {
    const content = []
    for (let age = 0; age <= 17; age++) {
      content.push(
        <div
          key={'childrenSelect__el' + age + indexRoom + (indexChild || '-1')}
          className={'childrenSelect__el ' + (age === ageCurrent && 'active')}
          onClick={() => {
            controller.handleOnClickAgeChildren(indexRoom, indexChild, rooms, age)
            setVisible(false)
          }}
        >
          {t('Year', { count: age })}
        </div>
      )
    }
    return content
  }

  return (
    <div className={'childrenSelect ' + (visible && 'childrenSelect__open')}>
      <div className='childrenSelect__wrap'>
        {_renderYears()}
      </div>
    </div>
  )
}

export default ChildrenSelect
