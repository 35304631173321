import _ from 'lodash'
import { Cookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import currentLocation from '../hooks/currentLocation'
import UseSavedState from '../hooks/useSavedState'
import { resetError, setError, setErrorDetail } from '../reducers/error/actions'
import ErrorAnalyticsFlight from '../analytics/analyticsFlight/errorAnalytics'
import ErrorAnalyticsHotel from '../analytics/analyticsHotel/errorAnalytics'

export default class Config {
  constructor () {
    this.dispatch = useDispatch()
    this.i18n = useTranslation().i18n
    this.analyticsFlight = new ErrorAnalyticsFlight()
    this.analyticsHotel = new ErrorAnalyticsHotel()
  }

  showErrorPopup = [
    // 'autocomplete',
    'price',
    'order',
    'reserve',
    'passengers',
    'trips',
    'user',
  ]

  request = async (baseUrl = 'base', url, body, method = 'POST', download = false) => {
    if (!UseSavedState()) {
      this.dispatch(resetError())
    }
    const cookies = new Cookies()
    const { isFlightsPage, isHotelsPage } = currentLocation()
    const {
      REACT_APP_BASE_PATH,
      REACT_APP_API_ENVIRONMENT_FLIGHT,
      REACT_APP_API_ENVIRONMENT_HOTEL
    } = process.env

    let fullUrl = `${baseUrl}${REACT_APP_BASE_PATH}/${url}`
    if (method === 'GET' && body !== '') {
      fullUrl = fullUrl + '?' + body.toString()
    }
    const headers = {
      'Accept': 'application/json',
      'accept-language': this.i18n.language,
      'aita-device-language': this.i18n.language.split('-')[0],
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookies.get('auth_token'),
      'Aita-Api-Environment': isFlightsPage ? REACT_APP_API_ENVIRONMENT_FLIGHT : REACT_APP_API_ENVIRONMENT_HOTEL,
      'aita-user-agent': 'AitaWeb/1.0.0'
    }

    // eslint-disable-next-line no-undef
    const res = await fetch(fullUrl, {
      method: method,
      headers: headers,
      body: method !== 'GET' ? JSON.stringify(body) : null

    })
    if (res.ok) {
      if (download) {
        return res
      } else {
        return res.json()
      }
    } else {
      if (res.status === 401) {
        console.error(`Error auth_token. Code error ${res.status}`)
        cookies.remove('auth_token')
        window.location.href = '/login'
      }
    }
    this.showErrorPopup.forEach(element => {
      if (url.includes(element)) {
        const clearBody = removePaymentInfoFromBody(body)
        const errorDetail = {
          requestUrl: fullUrl,
          method: method,
          headers: headers,
          body: clearBody,
          date: new Date()
        }
        if (res.status < 500) {
          res.json()
            .then(error => {
              if (isFlightsPage) {
                this.analyticsFlight.seeError(error.name + ';' + error.message)
              } else if (isHotelsPage) {
                this.analyticsHotel.seeError(error.name + ';' + error.message)
              }
              this.dispatch(setError(error))
              errorDetail.error = error
            })
        } else {
          res.json()
            .then(error => {
              if (isFlightsPage) {
                this.analyticsFlight.seeError(error.name + ';' + error.message)
              } else if (isHotelsPage) {
                this.analyticsHotel.seeError(error.name + ';' + error.message)
              }
              errorDetail.error = error
            })
          this.dispatch(setError({
            name: 'Oops',
            message: 'something went wrong'
          }))
        }
        this.dispatch(setErrorDetail(errorDetail))
      }
    })
  }
}

const removePaymentInfoFromBody = (body) => {
  const cloneDeepBody = _.cloneDeep(body)
  if (cloneDeepBody.payment && cloneDeepBody.payment.card) {
    cloneDeepBody.payment.card = {
      number: '',
      expMonth: '',
      expYear: '',
      cvc: '',
      holder: ''
    }
  }
  return cloneDeepBody
}

