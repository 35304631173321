import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CardController from '../controller'
import MaskedInput from 'react-maskedinput'
import countries from '../../../../data/iso3166'
import OutsideClickHandler from 'react-outside-click-handler'
import creditCardType from 'credit-card-type'


const Input = ({ props, reduxType, error }) => {
  const { t } = useTranslation()
  const controller = new CardController()
  const [openDropdown, setOpenDropdown] = useState(false)
  let value
  let cardClass = ''
  if (props.name === 'expDate') {
    value = useSelector(state => state.paymentForm[reduxType].expMonth) +
      useSelector(state => state.paymentForm[reduxType].expYear)
  } else {
    value = useSelector(state => state.paymentForm[reduxType][props.name])
  }
  if (props.name === 'number') {
    if (value.length > 3) {
      cardClass = creditCardType(value).length ? creditCardType(value)[0].niceType : ''
    } else {
      cardClass = ''
    }
  }
  return (
    <div className={'inputDiv ' +
    ('inputDiv__' + props.name + ' ' + cardClass) +
    (props.small ? ' inputDiv__small ' : '') +
    (error ? ' inputDiv__error ' : '')}
    >
      <label htmlFor={props.id || props.name}>{props.label}</label>
      {props.mask
        ? <MaskedInput
            type='text'
            name={props.name}
            id={props.id || props.name}
            value={value}
            placeholder={props.placeholder}
            mask={props.mask}
            size='20'
            onChange={controller.handleOnChange}
            datatype={reduxType}
          />
        : (
          <>
            <OutsideClickHandler
              onOutsideClick={() => {
                if (openDropdown)
                  setOpenDropdown(false)
              }}
            >
              <input
                type={props.name === 'cvc' ? 'password' : 'text'}
                name={props.name}
                id={props.id || props.name}
                value={value}
                placeholder={props.placeholder}
                onChange={controller.handleOnChange}
                datatype={reduxType}
                autoComplete={props.name === 'country' ? 'nope' : 'on'}
                onFocus={() => setOpenDropdown(true)}
              />
              {props.name === 'country' &&
                <div className={'inputDiv__dropdown ' + (openDropdown && 'inputDiv__dropdown-open')}>
                  <div className='inputDiv__dropdown-wrap'>
                    {Object.keys(countries)
                      .sort((a, b) => {
                        return (t(countries[a].country)).localeCompare(t(countries[b].country))
                      })
                      .map(country => {
                        if (t(countries[country].country).toLowerCase().indexOf(value.toLowerCase()) === -1) {
                          return ''
                        }
                        return (
                          <div
                            key={'country' + country}
                            className='inputDiv__dropdown-country'
                            onClick={() => {
                              controller.handleOnClick(countries[country])
                              setOpenDropdown(false)
                            }}
                          >
                            {t(countries[country].country)}
                          </div>
                        )
                      })}
                  </div>
                </div>}
            </OutsideClickHandler>
          </>)}
      {error &&
        <div className='error'>
          {error}
        </div>}
    </div>
  )
}

export default Input
