import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const SuccessCancellation = () => {
  const { t } = useTranslation()
  const orderRS = useSelector(state => state.orderRS.model)
  if (!orderRS.order.fare?.policy?.cancel) return ''
  return (
    <div className='successCancellation'>
      <div className='successCancellation__header'>
        {t('cancellation policy')}
      </div>
      <div className='successCancellation__policy'>
        {orderRS.order.fare?.policy?.cancel}
      </div>
    </div>
  )
}

export default SuccessCancellation
