export const SET_ERROR = 'UPDATE_ERROR'
export const SET_ERROR_DETAIL = 'SET_ERROR_DETAIL'
export const RESET_ERROR = 'RESET_ERROR'
export const HIDE_ERROR = 'HIDE_ERROR'

export const setError = (payload) => ({
  type: SET_ERROR,
  payload
})
export const setErrorDetail = (payload) => ({
  type: SET_ERROR_DETAIL,
  payload
})
export const resetError = () => ({
  type: RESET_ERROR,
})
export const hideError = () => ({
  type: HIDE_ERROR,
})
