import React, { useState } from 'react'
import './styke.sass'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ReviewsPopup from './_popup'
import HotelReview from './_review'
import { useIsVisibleElement } from '../../../../hooks/useIsVisibleElement'
import HotelScreenAnalytics from '../../../../analytics/analyticsHotel/hotelScreenAnalytics'
import { useScroll } from '../../../../hooks/useScroll'

const HotelReviews = () => {
  const { t } = useTranslation()
  const { reviews } = useSelector(state => state.priceRS.model)
  const [showAllReviews, setShowAllReviews] = useState(false)
  const hotelAnalytics = new HotelScreenAnalytics()
  const [element] = useIsVisibleElement(hotelAnalytics.seeInfoAboutReviews, 100)

  if (!_.size(reviews)) return <div ref={element} />
  return (
    <div className='hotelReviews' id='Reviews' ref={element}>
      <div className='hotelReviews__header'>
        {t('Top reviews')}
        <button
          onClick={() => {
            setShowAllReviews(true)
            hotelAnalytics.clickMoreReviews()
          }}
          className='hotelReviews__more'
        >
          {t('Show all reviews')}
        </button>
      </div>
      <div className='reviews'>
        {_.map(reviews.slice(0, 3), review =>
          <HotelReview key={'review' + review.created} review={review} />
        )}
      </div>
      <ReviewsPopup showAllReviews={showAllReviews} setShowAllReviews={setShowAllReviews} />
    </div>
  )
}

export default HotelReviews
