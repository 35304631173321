import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Icons from '../../pageComponents/icons'
import ChainsController from './controller'

const Chains = () => {
  const { t } = useTranslation()
  const chains = useSelector(state => state.filter.chains)
  const controller = new ChainsController()
  return (
    <div className='chains'>
      <input
        type='checkbox'
        id='chains'
        checked={chains}
        className='chains__input'
        onChange={() => {
          controller.handleClick(!chains)
        }}
      />
      <label
        htmlFor='chains'
        className='chains__label filter__el'
      >
        {t('Chains only')}
        <Icons
          group='icon'
          icon='close'
          className='chains__label-icon'
          color='#3D73EC'
        />
      </label>
    </div>
  )
}
export default Chains
