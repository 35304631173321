import React, { useState } from 'react'
import './style.sass'
import _ from 'lodash'
import RoomDetailOptionsMore from './_more'
import RoomDetailOccupancy from './_occupancy'
import RoomDetailOption from './_options'
import RoomDetailAmenities from './_labels'
import RoomDetailName from './_name'
import RoomDetailPhotos from './_photo'

const RoomDetailElement = ({ room }) => {
  const START_OPTIONS_SHOW = 2
  const ALL_OPTIONS_SHOW = _.size(room.options)
  const [slice, setSlice] = useState(START_OPTIONS_SHOW)
  const sortRooms = _.sortBy(room.options, item => item.price.value)
  return (
    <div className='roomDetailElement'>
      <div className='roomDetailElement__main'>
        <RoomDetailPhotos room={room} />
        <div className='roomDetailElement__information'>
          <RoomDetailName name={room.name} />
          <RoomDetailOccupancy room={room} />
          <RoomDetailAmenities room={room} />
        </div>
      </div>
      {_.map(sortRooms.slice(0, slice), option =>
        <RoomDetailOption
          key={'roomDetailOption' + option.key}
          room={room}
          option={option}
        />
      )}
      <RoomDetailOptionsMore
        room={room}
        slice={slice}
        setSlice={setSlice}
        START_OPTIONS_SHOW={START_OPTIONS_SHOW}
        ALL_OPTIONS_SHOW={ALL_OPTIONS_SHOW}
      />

    </div>
  )
}

export default RoomDetailElement
