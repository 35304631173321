import './assets/style/main.sass'
import React from 'react'
import Navigation from './navigation'
import { CookiesProvider } from 'react-cookie'
import { Provider } from 'react-redux'
import { store } from './reducers'
import CheckToken from './navigation/checkToken'
import { PopupProvider } from 'context/popup'

const App = () => {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <PopupProvider>
          <CheckToken />
          <Navigation />
        </PopupProvider>
      </Provider>
    </CookiesProvider>
  )
}

export default App
