import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const SuccessHotelInfo = () => {
  const { t } = useTranslation()
  const orderRS = useSelector(state => state.orderRS.model)

  return (
    <div className='successHotelInfo'>
      <div className='name'>
        {orderRS.trip.hotel.name}
      </div>
      <div className='description'>
        1 {t('Room')}: {orderRS.trip.hotel.room.name}
      </div>
    </div>
  )
}

export default SuccessHotelInfo
