import { useDispatch } from 'react-redux'
import { changeWifi } from '../../../reducers/filter/actions'

export default class WifiController {
  constructor () {
    this.dispatch = useDispatch()
  }

  handleClick = (wifi) => {
    this.dispatch(changeWifi(wifi))
  }
}
