import { useTranslation } from 'react-i18next'
import Api from '../../../../../api'
import moment from 'moment'

export default class ExportCalendarController {
  constructor () {
    this.api = new Api()
    this.t = useTranslation().t
  }

  handleClickClose = (openPopup, setOpenPopup, setStartDate, setEndDate, setError, setFinalMessage) => {
    if (openPopup) {
      setOpenPopup(false)
      setStartDate(undefined)
      setEndDate(undefined)
      setError(undefined)
      setTimeout(() => {
        setFinalMessage(undefined)
      }, 1000)
    }
  }

  handleInClickDay = (day, startDate, endDate, setStartDate, setEndDate) => {
    let newStartDate = startDate
    let newEndDate = endDate
    // пустые даты
    if (!startDate && !endDate) {
      newStartDate = day
    }
    // есть только первая дата
    if (startDate && !endDate) {
      // вторая дата меньше текушей?
      if (day < startDate) {
        newStartDate = day
        newEndDate = startDate
      } else if (day > startDate) {
        newEndDate = day
      } else {
        newStartDate = day
        newEndDate = day
      }
    }
    // есть две даты
    if (startDate && endDate) {
      if (+day === +startDate || +day === +endDate) {
        if (+day === +startDate) {
          // выбор равен первому дню, оставляем одну дату
          newStartDate = day
          newEndDate = null
        } else if (+day === +endDate) {
          // выбор равен второму дню, оставляем одну дату
          newStartDate = day
          newEndDate = null
        }
      } else if (day < startDate) {
        // выбор меньше первой, ставм новую на первое место
        newStartDate = day
      } else if (day > endDate) {
        // выбор больше второго, ставм новую на второе место
        newEndDate = day
      } else {
        // выбор между выбраными датами, смотрим какая ближе
        if (day - startDate < endDate - day) {
          newStartDate = day
        } else {
          newEndDate = day
        }
      }
    }
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  handleInClickDownload = (startDate, endDate, setError, setLoad, setFinalMessage) => {
    if (!startDate) {
      setError(this.t('Dates are required'))
    } else if (!endDate) {
      setError(this.t('End date is required'))
    } else {
      setError(undefined)
      setLoad(true)
      this.api.getExportBookings(
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD')
      )
        .then(res => res.blob())
        .then(blob => {
          const file = window.URL.createObjectURL(blob)
          window.location.assign(file)
          setLoad(false)
          setFinalMessage('success')
        })
        .catch((e) => {
          console.error('export bookings error: ', e)
          setFinalMessage('error')
        })
    }
  }
}
