import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import OptionPriceController from './controller'

const OptionPrice = ({ option }) => {
  const { t } = useTranslation()
  const controller = new OptionPriceController()
  const { checkinDate, checkoutDate } = useSelector(state => state.hotel)
  const price = controller.getPrice(option.price, checkinDate, checkoutDate)
  return (
    <div className='optionPrice'>
      <div className='optionPrice__total'>
        {price.total}
      </div>
      <div className='optionPrice__description'>
        <b>
          {option.prepayRequired
            ? t('Pay now')
            : t('Pay later')}
        </b>
        {option.billInfo?.show
          ? (
            <b className='optionPrice__description-danger'>
              {option.billInfo?.text}
            </b>)
          : ''}
        {t('Total for')} {t('night', { count: price.nights })}
        {' '}
        {t('incl. taxes')} <br />
        {price.nights > 1 &&
          <>
            <b>{price.perNight}</b> {t('avg. per night')}
          </>}
      </div>
    </div>
  )
}

export default OptionPrice
