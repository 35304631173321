import React from 'react'
import './style.sass'

const Dropdown = ({ child }) => {
  return (
    <div className='dropdown'>
      {child}
    </div>
  )
}

export default Dropdown
