import { FLIGHTS_SEARCH_PAGE, HOTELS_SEARCH_PAGE, ORDERS_PAGE } from '../navigation/routes'

const currentLocation = (link) => {
  const { pathname } = window.location

  return {
    currentPage: pathname,
    isFlightsPage: pathname.includes(FLIGHTS_SEARCH_PAGE),
    isHotelsPage: pathname.includes(HOTELS_SEARCH_PAGE),
    isOrdersPage: pathname.includes(ORDERS_PAGE),
    activeLink: link === pathname,
  }
}

export default currentLocation
