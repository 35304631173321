import { UPDATE_ORDERS, CLEAR_ORDERS, SET_FROM } from './actions'

const defaultState = {
  ids: null,
  info: null,
  cleanOrders: null,
  search: '',
  from: ''
}

const orders = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_ORDERS:
      return {
        ...state,
        [action.field]: action.payload
      }
    case SET_FROM:
      return { ...state, from: action.payload }
    case CLEAR_ORDERS:
      return defaultState
    default:
      return state
  }
}

export default orders
