import { useDispatch } from 'react-redux'
import { updateSearchForm } from '../../../../reducers/search/actions'
import SearchAnalytic from '../../../../analytics/analyticsFlight/searchAnalytics'
import currentLocation from '../../../../hooks/currentLocation'

export default class CabinController {
  constructor () {
    this.dispatch = useDispatch()
    this.analytics = new SearchAnalytic()
  }

  getDropdownList = () => {
    return [
      { value: 'e', name: 'Economy' },
      { value: 'p', name: 'Premium economy' },
      { value: 'b', name: 'Business' },
      { value: 'f', name: 'First' }
    ]
  }

  handleOnClick = (e) => {
    const { isFlightsPage } = currentLocation()
    this.dispatch(updateSearchForm('cabin', 'value', e.target.value))
    this.dispatch(updateSearchForm('cabin', 'name', e.target.id))
    if (isFlightsPage) {
      this.analytics.setClasses(e.target.value)
    }
  }
}
