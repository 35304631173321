import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Api from '../../../api'
import KitCut from '../../hotelsComponents/_hotelDetail/_reviews/_review/_kitCut'

const ConfirmationRoomInfo = () => {
  const { t } = useTranslation()
  const hotelState = useSelector(state => state.hotel)
  const reserveState = useSelector(state => state.hotel.reserve)
  const api = new Api()
  const isBreakfast =
    reserveState.option.breakfastIncl ? `, ${t('breakfast is included')}` : `, ${t('breakfast is not included')}`

  const review = reserveState.option.name.trim() + isBreakfast
  return (
    <div className='confirmationRoomInfo confirmationScreen__block'>
      <div className='confirmationRoomInfo__hotel'>
        <img
          src={api.getPhotosUrl(hotelState.choice.key, 0)}
          className='photo'
          alt=''
        />
        <div className='confirmationRoomInfo__hotel-info'>
          <div className='name'>
            {hotelState.choice.name}
          </div>
          <div className='address'>
            {hotelState.choice.address}
          </div>
        </div>
      </div>
      <div className='confirmationRoomInfo__room'>
        {review && (
          <div className='name'>
            <KitCut review={review} initLimit={150} />
            {hotelState.reserve.option.billInfo?.show &&
              <span className='description__red'>{hotelState.reserve.option.billInfo?.text}</span>}
          </div>
        )}
        {reserveState.option.description && (
          <div className='description'>
            <KitCut review={reserveState.option.description} initLimit={150} />
          </div>
        )}
        <div className='people'>
          {/* TODO в ru локали на en языке */}
          {t('For')} {hotelState.guests.value}
        </div>
      </div>
      <div className='confirmationRoomInfo__cancellation'>
        <div className={'description ' + (reserveState.option.refundable && 'description__green')}>
          {reserveState.option.refundable
            ? t('Refundable')
            : t('Non Refundable')}
        </div>
        <div className='people'>
          {reserveState.option.fareRules?.policy?.cancellationDescription}
        </div>
      </div>
    </div>
  )
}

export default ConfirmationRoomInfo
