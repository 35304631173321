import { useDispatch } from 'react-redux'
import TripsAnalytics from '../../../analytics/analyticsFlight/tripsAnalytics'
import Api from '../../../api'
import currentLocation from '../../../hooks/currentLocation'
import { showLoadBar } from '../../../reducers/loadBar/actions'
import { updateOrders } from '../../../reducers/orders/actions'
import _ from 'lodash'
import { ORDERS_SCREEN_UPCOMING } from '../../../reducers/page/types'

const MAX_IDS_PER_REQUEST = 3

export default class OrdersController {
  constructor (ordersIds, ordersList, setOrdersIds, setOrdersList) {
    this.dispatch = useDispatch()
    this.api = new Api()
    this.ordersIds = ordersIds
    this.ordersList = ordersList
    this.setOrdersIds = setOrdersIds
    this.setOrdersList = setOrdersList
    this.analytics = new TripsAnalytics()
  }

  getOrders = () => {
    const { isOrdersPage, isFlightsPage } = currentLocation()
    if (isOrdersPage) {
      this.dispatch(showLoadBar(ORDERS_SCREEN_UPCOMING))
    }
    this.api.getOrdersIds()
      .then(async ids => {
        if (ids.success && ids.updated.length) {
          await this.getOrdersChunk(ids)
        } else {
          if (isOrdersPage) {
            this.dispatch(showLoadBar(false))
          }
        }
        this.dispatch(updateOrders('ids', ids))
        if (isOrdersPage) {
          this.dispatch(showLoadBar(false))
        }
        if (isFlightsPage) {
          this.analytics.tripsSee()
        }
      })
  }

  getOrdersChunk = async (ids) => {
    const chunksIds = _.chunk(ids.updated, MAX_IDS_PER_REQUEST)
    const fetchPromises = chunksIds.map(chunkIds =>
      this.getOrdersInfo(chunkIds)
    )
    await Promise.all(fetchPromises)
  }

  getOrdersInfo = async (ids) => {
    const idsParams = new URLSearchParams({
      ids: ids
    })
    await this.api.getOrdersInfo(idsParams)
      .then(this.filterOrders)
      .catch(() => console.error('error receiving orders, ids: ', ids))
  }

  cleanOrders = {}
  filterOrders = (info) => {
    if (info.success && info.trips.length) {
      const filterOrders = info.trips.filter(order => (_.size(order.flights) || _.size(order.hotels)))
      filterOrders.forEach(order => {
        if (!order.deleted && !order.aita_order) {
          const orderId = _.size(order.flights)
            ? order.flights[0]?.aita_order?.order_id
            : order.hotels[0]?.aita_order?.order_id
          if (orderId && !this.cleanOrders[orderId]) {
            this.cleanOrders[orderId] = order
          }
        }
      })
      Object.assign(this.cleanOrders, this.ordersList)
      this.setOrdersList(this.cleanOrders)
      this.dispatch(updateOrders('cleanOrders', this.cleanOrders))
    }
  }
}
