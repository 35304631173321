import { useDispatch } from 'react-redux'
import FilterAnalytics from '../../../analytics/analyticsFlight/filterAnalytics'
import { changeDirect, changeLayovers, clearLayovers } from '../../../reducers/filter/actions'

export default class DirectFlightOnlyController {
  constructor () {
    this.dispatch = useDispatch()
    this.analytics = new FilterAnalytics()
  }

  handleClick = (directFlightsOnly) => {
    this.analytics.setDirectFlight(directFlightsOnly)
    this.dispatch(changeDirect(directFlightsOnly))
    this.dispatch(clearLayovers())
  }
}
