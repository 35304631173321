import React, { useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import currentLocation from '../../../hooks/currentLocation'
import { updatePaymentForm } from '../../../reducers/order/actions'
import Icons from '../../pageComponents/icons'
import Popup from '../../pageComponents/popup'
import CardForm from './_form'
import creditCardType from 'credit-card-type'
import CardController from './controller'
import _ from 'lodash'

const Card = ({ bookingDetail }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const controller = new CardController()
  const user = useSelector(state => state.user)

  const { isHotelsPage, isFlightsPage } = currentLocation()
  const [popupOpen, setPopupOpen] = useState(false)
  let payment
  if (isFlightsPage) {
    payment = useSelector(state => state.orderFlights.payment)
  }
  if (isHotelsPage) {
    payment = useSelector(state => state.orderHotels)
    payment.billing = payment.address || {}
    if (!payment.card) {
      payment.card = {}
    }
  }
  const cardSelected = payment.card?.number || payment.cardToken
  let cardBrand = 'clear'
  let cardNumber = ''
  let cardHolder = ''
  if (payment.card?.number?.length) {
    cardBrand = creditCardType(payment.card.number)[0].niceType
    cardNumber = '**** ' + payment.card.number.substr(-4, 4)
    cardHolder = payment.card.holder
  }
  if (payment.cardToken) {
    const paymentMethod = _.find(
      user.business.payment_methods, payment_method => payment_method.card_token === payment.cardToken
    )
    cardBrand = _.size(user.business.payment_methods) > 1 ? paymentMethod.brand : 'corporatecard'
    cardNumber = 'Corporate card ' + (_.size(user.business.payment_methods) > 1 ? '**** ' + paymentMethod.last4 : '')
  }

  return (
    <div className='card'>
      {cardSelected
        ? (
          <button
            onClick={() => {
              dispatch(updatePaymentForm(payment))
              setPopupOpen(!popupOpen)
            }}
            className='card__have'
          >
            <Icons
              group='card'
              icon={cardBrand}
              className='card__icon'
            />
            <div className='card__info'>
              <div className='card__number'>{cardNumber}</div>
              <div className='card__holder'>{cardHolder}</div>
            </div>
          </button>
          )
        : (
          <button
            className='card__add'
            onClick={() => controller.handleOnClickAddPayment(setPopupOpen)}
          >
            {t('Add payment method')}
          </button>
          )}
      <Popup
        child={<CardForm
          setPopupOpen={setPopupOpen}
          bookingDetail={bookingDetail}
               />}
        setPopupOpen={setPopupOpen}
        popupOpen={popupOpen}
      />
    </div>
  )
}

export default Card
