import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PriceTooltipController from './controller'

const PriceTooltip = ({ hotel }) => {
  const { t } = useTranslation()
  const { checkinDate, checkoutDate } = useSelector(state => state.hotel)
  const controller = new PriceTooltipController()
  const basePrice = controller.getBasePrice(hotel, checkinDate, checkoutDate)
  const taxesPrice = controller.getTaxesPrice(hotel)
  const totalPrice = controller.getTotalPrice(hotel)
  return (
    <div className='PriceTooltip'>
      <div className='tooltip__el'>
        {basePrice.basePricePerNight} x {t('night', { count: basePrice.nights })}
        <span className='white'>{basePrice.basePrice}</span>
      </div>
      <div className='tooltip__el'>
        {t('Taxes')}
        <span className='white'>{taxesPrice}</span>
      </div>
      <div className='tooltip__el total'>
        {t('Total')}
        <span className='white'>{totalPrice}</span>
      </div>
    </div>
  )
}

export default PriceTooltip
