import AnalyticsHotel from './index'


export default class HotelScreenAnalytics extends AnalyticsHotel {
  dataIsLoaded = () => {
    this.logEvent(
      this.NAMES.HOTEL_DETAILS,
      this.FEATURE.MAIN,
      'data',
      this.ACTIONS.LOADED,
      ''
    )
  }

  seePhotos = (params) => {
    this.logEvent(
      this.NAMES.HOTEL_DETAILS,
      this.FEATURE.MAIN,
      'photo',
      this.ACTIONS.SEE,
      params.hotel
        ? [params.hotel,
          `currency=${params.currency}`,
          params.checkin,
          params.checkout,
          params.people].join(';')
        : ''
    )
  }

  seeInfoAboutHotel = (params) => {
    this.logEvent(
      this.NAMES.HOTEL_DETAILS,
      this.FEATURE.MAIN,
      'overview',
      this.ACTIONS.SEE,
      [
        params.hotel,
        `currency=${params.currency}`,
        params.checkin,
        params.checkout,
        params.people
      ].join(';')
    )
  }

  seeInfoAboutRooms = (hotel) => {
    this.logEvent(
      this.NAMES.HOTEL_DETAILS,
      this.FEATURE.MAIN,
      'rooms',
      this.ACTIONS.SEE,
      hotel
    )
  }

  seeInfoAboutCart = () => {
    this.logEvent(
      this.NAMES.HOTEL_DETAILS,
      this.FEATURE.MAIN,
      'location',
      this.ACTIONS.SEE,
      ''
    )
  }

  seeInfoAmenities = () => {
    this.logEvent(
      this.NAMES.HOTEL_DETAILS,
      this.FEATURE.MAIN,
      'amenities',
      this.ACTIONS.SEE,
      ''
    )
  }

  seeInfoAboutReviews = () => {
    this.logEvent(
      this.NAMES.HOTEL_DETAILS,
      this.FEATURE.MAIN,
      'reviews',
      this.ACTIONS.SEE,
      ''
    )
  }

  clickPhoto = () => {
    this.logEvent(
      this.NAMES.HOTEL_DETAILS,
      this.FEATURE.MAIN,
      'photo',
      this.ACTIONS.TAP,
      ''
    )
  }

  scrollPhoto = () => {
    this.logEvent(
      this.NAMES.HOTEL_DETAILS,
      this.FEATURE.PHOTO,
      'scroll',
      this.ACTIONS.TAP,
      ''
    )
  }

  clickSelectRoom = (params, room) => {
    this.logEvent(
      this.NAMES.HOTEL_DETAILS,
      this.FEATURE.ROOMS,
      'reserve',
      this.ACTIONS.TAP,
      [
        params.hotel,
        `${room.name}`,
        `refunable=${room.refundable}`,
        `deposit=${room.prepayRequired}`,
        `price=${room.price.value}`,
        `currency=${params.currency}`,
        params.checkin,
        params.checkout,
        params.people
      ].join(';')
    )
  }

  clickShowAboutRoom = (params, room) => {
    this.logEvent(
      this.NAMES.HOTEL_DETAILS,
      this.FEATURE.ROOMS,
      'showMoreDetails',
      this.ACTIONS.TAP,
      [
        params.hotel,
        `${room.key}`,
        `currency=${params.currency}`,
        params.checkin,
        params.checkout,
        params.people
      ].join(';')
    )
  }

  clickMoreInfoAboutHotel = () => {
    this.logEvent(
      this.NAMES.HOTEL_DETAILS,
      this.FEATURE.ROOMS,
      'showMoreHotel',
      this.ACTIONS.TAP,
      ''
    )
  }

  clickMoreFare = (params, room) => {
    this.logEvent(
      this.NAMES.HOTEL_DETAILS,
      this.FEATURE.ROOMS,
      'showMoreFare',
      this.ACTIONS.TAP,
      [
        params.hotel,
        `roomName=${room.name}`,
        `currency=${params.currency}`,
        params.checkin,
        params.checkout,
        params.people
      ].join(';')
    )
  }

  clickPhotoRoom = (params, room) => {
    this.logEvent(
      this.NAMES.HOTEL_DETAILS,
      this.FEATURE.ROOMS,
      'photo',
      this.ACTIONS.TAP,
      [
        params.hotel,
        `roomName=${room.name}`,
        `currency=${params.currency}`,
        params.checkin,
        params.checkout,
        params.people
      ].join(';')
    )
  }

  scrollPhotoRoom = (params) => {
    this.logEvent(
      this.NAMES.HOTEL_DETAILS,
      this.FEATURE.ROOMS,
      'photo',
      this.ACTIONS.SCROLL,
      [
        params.orderHotels.hotel,
        `roomName=${params.room.name}`,
        `currency=${params.orderHotels.currency}`,
        params.orderHotels.checkin,
        params.orderHotels.checkout,
        params.orderHotels.people
      ].join(';')
    )
  }

  clickMoreReviews = () => {
    this.logEvent(
      this.NAMES.HOTEL_DETAILS,
      this.FEATURE.MAIN,
      'reviews',
      this.ACTIONS.TAP,
      ''
    )
  }
}
