import React, { useEffect, useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FilterAnalytics from '../../../analytics/analyticsFlight/filterAnalytics'
import Icons from '../../pageComponents/icons'
import AirlinesController from './controller'
import OutsideClickHandler from 'react-outside-click-handler'
import _ from 'lodash'
import SelectClearFilter from '../selectClearFilter'

const Airlines = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const filterAirlines = useSelector(state => state.filter.airlines)
  const controller = new AirlinesController()
  const analytics = new FilterAnalytics()
  const [isAirlines, setIsAirlines] = useState(false)

  useEffect(() => {
    controller.changeActiveFilter(filterAirlines, setIsAirlines)
  }, [filterAirlines])
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (open) {
          setOpen(false)
          analytics.setAirlines(filterAirlines)
        }
      }}
    >
      <div className={'airlines filter ' + (open && 'filter-open')}>
        <div
          className={`airlines__value filter__el ${isAirlines ? 'filter__active' : ''}`}
          onClick={() => {
            if (open) {
              analytics.setAirlines(filterAirlines)
            }
            setOpen(!open)
          }}
        >
          {t('Airlines')}
          <Icons
            group='arrow'
            icon='down'
            color='#8F8F8F'
            className='filter__el-icon'
          />
          <a
            className='cross__icon-wrapper'
            onClick={(e) => controller.handleOnClickButton(filterAirlines, true, e)}
          >
            <Icons
              group='icon'
              icon='close'
              className='wifi__label-icon'
              color='#3D73EC'
            />
          </a>
        </div>
        <div className='airlines__list filter__list'>
          <div className='filter__list-wrap'>
            <SelectClearFilter
              textOne={t('Select all')} textTwo={t('Clear all')}
              controller={controller} propertyes={filterAirlines}
              addedClass='list-buttons'
            />
            <div className='list__header'>
              {t('Airlines')}
            </div>
            <div className='list__elements'>
              {_.sortBy(filterAirlines, 'name')
                .map((airline, airlineIndex) => {
                  return (
                    <div
                      key={'airline__element' + airlineIndex}
                      className='element'
                      onClick={() => { controller.handleOnClickElement(filterAirlines, airline.code) }}
                    >
                      <div className={'element__checkbox ' + (airline.checked && 'element__checkbox-active')}>
                        <Icons
                          group='icon'
                          icon='checked'
                          color='#fff'
                          className='element__checkbox-icon'
                        />
                      </div>
                      <div className='element__text'>
                        {airline.name}
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>

  )
}
export default Airlines
