import React from 'react'
import { useTranslation } from 'react-i18next'
import { RangeSlider } from '../../rangeSlider'

import './style.sass'

interface SliderProps extends React.ComponentProps<typeof RangeSlider> {
    title: string
    intervalStart: any
    intervalEnd: any
    intervalSeparator?: string

    onClear?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const Slider: React.FC<SliderProps> = (
  { title, intervalEnd, intervalStart, intervalSeparator = ' - ', onClear, ...sliderProps }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className='slider-component__header'>
        <div className='left'>
          {title}
          <div className='slider-component__header-interval'>
            {intervalStart}
            {intervalSeparator}
            {intervalEnd}
          </div>
        </div>
        <button
          className='right clear'
          onClick={onClear}
        >
          {t('Clear')}
        </button>
      </div>
      <div className='time__slider'>
        <RangeSlider
          {...sliderProps}
        />
      </div>
    </>
  )
}
