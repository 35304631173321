import React from 'react'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import DatePicker from './_datePicker'
import Input from './_input'
import Phone from './_phone'


const Inputs = ({ field, error, passengerType }) => {
  if (field.field.toLowerCase().includes('date')) {
    return <DatePicker field={field} error={error} passengerType={passengerType} />
  } else if (field.field.toLowerCase().includes('phone')) {
    return <Phone field={field} error={error} />
  } else {
    return <Input field={field} error={error} />
  }
}

export default Inputs
