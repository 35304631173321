import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { HOTELS_SEARCH_PAGE } from '../../../navigation/routes'
import { SEARCH_SCREEN } from '../../../reducers/page/types'
import Icons from '../../pageComponents/icons'
import SearchButtonController from './controller'

const SearchButton = () => {
  const { t } = useTranslation()
  const controller = new SearchButtonController()
  const searchForm = useSelector(state => state.searchForm)
  const searchRS = useSelector(state => state.searchRS.model)
  const page = useSelector(state => state.page.name)
  const location = useSelector(state => state.page.currentLocation)
  const user = useSelector(state => state.user)
  return (
    <div className={'searchButton ' + (page === SEARCH_SCREEN && 'searchButton-fulls')}>
      <button
        onClick={() => {
          controller.handleOnClick(searchForm, searchRS, user)
        }}
      >
        <Icons
          group='icon'
          icon='search'
          color='#fff'
        />
        {(
          page === SEARCH_SCREEN ||
          location === HOTELS_SEARCH_PAGE
        ) &&
          <span>{t('Search')}</span>}
      </button>
    </div>
  )
}
export default SearchButton
