import AnalyticsHotel from './index'

export default class OrderAnalytics extends AnalyticsHotel {
  clickOrderCreate = (params) => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.MAIN,
      'orderCreate',
      this.ACTIONS.TAP,
      [
        params.hotel,
        params.room,
        `price=${params.price}`,
        `currency=${params.currency}`,
        params.checkin,
        params.checkout,
        params.people
      ].join(';')
    )
  }

  orderCreateSuccessfull = (params) => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.MAIN,
      'orderCreate',
      this.ACTIONS.SUCCESS,
      [
        params.hotel,
        params.room,
        `price=${params.price}`,
        `currency=${params.currency}`,
        params.checkin,
        params.checkout,
        params.people
      ].join(';')
    )
  }

  orderCreateError = (params) => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.MAIN,
      'orderCreate',
      this.ACTIONS.ERROR,
      params ? Object.keys(params).join(';') : ''
    )
  }

  orderIsLoaded = (params) => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.MAIN,
      'main',
      this.ACTIONS.SEE,
      [
        params.hotel,
        params.room,
        `price=${params.price}`,
        `currency=${params.currency}`,
        params.checkin,
        params.checkout,
        params.people
      ].join(';')
    )
  }

  changeRequest = () => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.MAIN,
      'request',
      this.ACTIONS.TAP,
      ''
    )
  }
}
