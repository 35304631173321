import * as IOClient from 'socket.io-client'
import moment from 'moment'
import _ from 'lodash'
import currentLocation from '../hooks/currentLocation'
import generateHotelPeople from '../hooks/generateHotelPeople'
import { setError } from '../reducers/error/actions'
import { updateHotel } from '../reducers/hotel/actions'
import { showLoadBar } from '../reducers/loadBar/actions'
import { updateSearchRS } from '../reducers/search/actions'
import { Cookies } from 'react-cookie'
import { CLIENT_MODE_BUSINESSES } from '../reducers/user/constans'

const cookies = new Cookies()


const connect = async (i18n) => {
  const { isFlightsPage } = currentLocation()

  const {
    REACT_APP_API_FIGHTS_VERSION,
    REACT_APP_API_HOTELS_VERSION,
    REACT_APP_BASE_URL,
    REACT_APP_SOCKET_FLIGHTS_PATH,
    REACT_APP_SOCKET_HOTELS_PATH,
    REACT_APP_API_PROVIDERS,
    REACT_APP_API_ENVIRONMENT_FLIGHT,
    REACT_APP_API_ENVIRONMENT_HOTEL,
  } = process.env
  const query = {
    apiVersion: isFlightsPage ? REACT_APP_API_FIGHTS_VERSION : REACT_APP_API_HOTELS_VERSION,
    apiEnvironment: isFlightsPage ? REACT_APP_API_ENVIRONMENT_FLIGHT : REACT_APP_API_ENVIRONMENT_HOTEL,
    authorization: cookies.get('auth_token'),
    acceptLanguage: i18n.language,
    userAgent: navigator.userAgent
  }
  if (REACT_APP_API_PROVIDERS !== 'null') {
    query.apiProviders = REACT_APP_API_PROVIDERS
  }
  const socket = IOClient(REACT_APP_BASE_URL, {
    withCredentials: false,
    path: isFlightsPage ? REACT_APP_SOCKET_FLIGHTS_PATH : REACT_APP_SOCKET_HOTELS_PATH,
    query
  })
  const conn = waitForEvent(socket, 'connect')
  const err = waitForEvent(socket, 'connect_error').then(error => Promise.reject(error))
  try {
    await Promise.race([conn, err])
  } catch (e) {
    console.error(`Failed to connect: ${e.message} (${e.description})`)
    throw e
  }
  return socket
}
const waitForEvent = async (socket, eventName, ackData, timeout = 50000) => {
  return new Promise((resolve, reject) => {
    socket.on(eventName, (message, ack) => {
      resolve(message)
      if (ack && ackData) {
        ack(ackData)
      }
    })
    setTimeout(() => reject(new Error(`Timed out to wait for ${eventName}`)), timeout)
  })
}
const emitWithAck = async (socket, eventName, message, timeout = 10000) => {
  return new Promise((resolve, reject) => {
    socket.emit(eventName, message, (ackMessage) => {
      const response = JSON.parse(ackMessage)
      if (response.ok) {
        resolve(response.data)
      } else {
        reject(new Error(response.error.message))
      }
    })
    if (timeout) {
      setTimeout(() => reject(new Error(`Timed out to wait for ack on ${eventName}`)), timeout)
    } else {
      resolve(null)
    }
  })
}

let socket
export const socketDisconnect = async () => {
  if (socket) {
    await socket.disconnect()
  }
}

export const search = async (
  searchForm, searchRS, dispatch, translation, hotel, analytics, user) => {
  const {
    searchAnalyticsFlight,
    resultAnalyticsFlight,
    resultAnalyticsHotel,
    searchAnalyticsHotel,
  } = analytics

  const { isFlightsPage, isHotelsPage } = currentLocation()
  socketDisconnect()

  socket = await connect(translation.i18n)

  if (isHotelsPage) {
    searchAnalyticsHotel?.searchStartSuccessfull(hotel, searchForm)
  }

  let params
  if (isFlightsPage) {
    params = {
      origin: searchForm.origin.code,
      destination: searchForm.destination.code,
      orignearby: false,
      destnearby: false,
      people: searchForm.passengers.value,
      outbound: moment(searchForm.calendar.start).format('YYYY-MM-DD'),
      inbound: searchForm.calendar.end ? moment(searchForm.calendar.end).format('YYYY-MM-DD') : null,
      currency: searchForm.currency,
      cabin: searchForm.cabin.value,
      country: searchForm.country,
      locale: translation.i18n.language,
    }
    if (user.clientMode === CLIENT_MODE_BUSINESSES) {
      params.clientMode = CLIENT_MODE_BUSINESSES
      params.companyId = user.business.company_info.id
    }
    params = JSON.stringify(params)
  }
  if (isHotelsPage) {
    params = new URLSearchParams()
    params.set('people', generateHotelPeople(searchForm, translation.t).people)
    params.set('currency', searchForm.currency)
    params.set('cityname', searchForm.whereStay.stay.city)
    params.set('name', searchForm.whereStay.stay.name)
    params.set('autocomplete_type', searchForm.whereStay.stay.type)
    params.set('autocomplete_id', searchForm.whereStay.stay.id)
    params.set('checkin', moment(searchForm.calendar.start).format('YYYY-MM-DD'))
    params.set('checkout', searchForm.calendar.end ? moment(searchForm.calendar.end).format('YYYY-MM-DD') : null)
    if (user.clientMode === CLIENT_MODE_BUSINESSES) {
      params.set('clientMode', CLIENT_MODE_BUSINESSES)
      params.set('companyId', user.business.company_info.id)
    }

    params = params.toString()
  }

  try {
    await emitWithAck(socket, 'search', params, isFlightsPage ? 10000 : 0)
  } catch (error) {
    if (isFlightsPage) {
      searchAnalyticsFlight?.searchError(error)
      resultAnalyticsFlight?.showFlights('error')
    } else if (isHotelsPage) {
      resultAnalyticsHotel?.searchError(error)
      resultAnalyticsHotel?.showHotels('error')
    }

    dispatch(setError({
      name: 'Oops',
      message: 'something went wrong'
    }))
  }

  let results = {}
  if (isFlightsPage) {
    results = {
      status: 'pending',
      itineraries: [],
      airports: {},
      carriers: {},
      agents: {},
      currencies: {},
      segments: {},
      descriptions: {}
    }
  }
  if (isHotelsPage) {
    results = {
      status: 'pending',
      chains: {},
      cities: {},
      hotels: [],
      recentlyBookedHotels: [],
      obsoleteHotels: [],
      proximityString: '',
    }
  }

  const setResults = (newResults) => {
    if (isFlightsPage) {
      results.itineraries = [...results.itineraries, ...newResults.itineraries]
      results.segments = { ...results.segments, ...newResults.segments }
      results.descriptions = { ...results.descriptions, ...newResults.descriptions }
      results.airports = { ...results.airports, ...newResults.airports }
      results.carriers = { ...results.carriers, ...newResults.carriers }
      results.agents = { ...results.agents, ...newResults.agents }
      results.currencies = { ...results.currencies, ...newResults.currencies }
      results.status = newResults.status
    }
    if (isHotelsPage) {
      results.chains = { ...results.chains, ...newResults.chains }
      results.cities = { ...results.cities, ...newResults.cities }
      results.hotels = [...results.hotels, ...newResults.hotels]
      results.recentlyBookedHotels = newResults.recentlyBookedHotels
      results.obsoleteHotels = [...results.obsoleteHotels, ...newResults.obsoleteHotels]
      results.proximityString = newResults.proximityString
      results.status = newResults.status
    }

    return results
  }

  return new Promise((resolve) => {
    socket.on('results', (message, ack) => {
      const newResults = JSON.parse(message)
      results = setResults(newResults)
      if (ack) {
        ack('OK')
      }
      if (!_.isEqual(searchRS, results)) {
        const dayGap = moment(searchForm.calendar.start).diff(moment(searchForm.calendar.end), 'days')
        if (isHotelsPage && Number(dayGap)) {
          const data = { ...results }
          let exceedsCount = 0
          data.hotels.forEach(item => {
            item.priceInfo.totalDayPrice = Math.round(item.priceInfo.total.convertedValue / Math.abs(dayGap) ||
            item.priceInfo.total.value / Math.abs(dayGap))
            if (item.b2bTravelPolicyStatus === 'exceeds') {
              exceedsCount++
            }
            return false
          })

          dispatch(updateSearchRS(data))
          dispatch(updateHotel('exceedsCount', exceedsCount))
        } else {
          // if error - save hotels without edit
          dispatch(updateSearchRS(results))
        }
      }
      if (results.status !== 'pending' && results.status !== 'new') {
        if (isFlightsPage) {
          searchAnalyticsFlight.searchSuccess(searchForm)
        }
        dispatch(showLoadBar(false))
        socket.disconnect()
        resolve(results)
      }
    })
  })
}
