import React, { useEffect } from 'react'
import './style.sass'
import { useSelector } from 'react-redux'
import SearchAnalyticFlight from '../../../analytics/analyticsFlight/searchAnalytics'
import SearchAnalyticHotel from '../../../analytics/analyticsHotel/searchAnalytics'
import HelloText from '../../../components/searchComponents/_helloText'
import Menu from '../../../components/searchComponents/_menu'
import SearchForm from '../../../components/searchComponents/searchForm'

import bgPng from '../../../assets/img/search/bg.png'
import bgWebp from '../../../assets/img/search/bg.webp'
import bgBusinessPng from '../../../assets/img/search/bg-business.png'
import bgBusinessWebp from '../../../assets/img/search/bg-business.webp'
import { SEARCH_SCREEN } from '../../../reducers/page/types'
import { CLIENT_MODE_BUSINESSES } from '../../../reducers/user/constans'
import { HOTELS_RESULTS_PAGE } from '../../routes'
import currentLocation from '../../../hooks/currentLocation'

const SearchScreen = () => {
  const location = useSelector(state => state.page.currentLocation)
  const page = useSelector(state => state.page.name)
  const clintMode = useSelector(state => state.user.clientMode)

  let analytics
  const { isFlightsPage, isHotelsPage } = currentLocation()
  if (isFlightsPage) {
    analytics = new SearchAnalyticFlight()
  } else if (isHotelsPage) {
    analytics = new SearchAnalyticHotel()
  }

  useEffect(() => {
    analytics.startSession()
  }, [])
  return (
    <div className={'searchScreen ' + ((
      location === HOTELS_RESULTS_PAGE ||
      page !== SEARCH_SCREEN
    ) && 'searchScreen__small')}
    >
      <picture>
        <source
          srcSet={clintMode === CLIENT_MODE_BUSINESSES ? bgBusinessWebp : bgWebp}
          type='image/webp'
          className='searchScreen__bg'
        />
        <img
          src={clintMode === CLIENT_MODE_BUSINESSES ? bgBusinessPng : bgPng}
          alt='logo'
          className='searchScreen__bg'
        />
      </picture>
      <div className='container'>
        <HelloText />
        <Menu />
        <SearchForm />
      </div>
    </div>
  )
}

export default SearchScreen
