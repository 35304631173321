import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import Icons from '../icons'
import { usePopup } from 'context/popup'

const PopupWindow = () => {
  const { t } = useTranslation()
  const { visible, show, options } = usePopup()
  return (
    <div className={'popupWindow ' + (visible && 'popupWindow__show')}>
      <div className='popupWindow__wrapper'>
        <button
          onClick={() => show(false)}
          className='popupWindow__close'
        >
          <Icons
            group='icon'
            icon='close'
          />
        </button>
        <div className='popupWindow__wrapper-header'>{t(options.title)}</div>
        <div className='popupWindow__wrapper-message'>
          {t(options.text)} <br />
        </div>
        <button className='popupWindow__wrapper-button' onClick={() => show(false)}>
          {t(options.buttonText)}
        </button>
      </div>
    </div>
  )
}

export default PopupWindow
