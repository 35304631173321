import { Cookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ProfileAnalytics from '../../../../analytics/analyticsFlight/profileAnalytics'
import { socketDisconnect } from '../../../../api/socket'
import currentLocation from '../../../../hooks/currentLocation'
import useLocalStorage from '../../../../hooks/useLocalStorage'
import OrdersController from '../../../../navigation/page/orders/controller'
import { HOTELS_SEARCH_PAGE } from '../../../../navigation/routes'
import { clearState } from '../../../../reducers/actions'
import { updateOrderHotels } from '../../../../reducers/order/actions'
import { ORDERS_SCREEN_PAST, ORDERS_SCREEN_UPCOMING } from '../../../../reducers/page/types'
import { updateSearchForm } from '../../../../reducers/search/actions'
import _ from 'lodash'

export default class LangController {
  constructor () {
    this.dispatch = useDispatch()
    this.i18n = useTranslation().i18n
    this.page = useSelector(state => state.page.name)
    this.ordersController = new OrdersController()
    this.analytics = new ProfileAnalytics()
    this.useLocalStorage = useLocalStorage()
    this.history = useHistory()
  }

  setLanguage = () => {
    const cookies = new Cookies()
    const cookiesLang = cookies.get('language')
    if (!cookiesLang) {
      const userLang = navigator.language || navigator.userLanguage
      this.saveLanguage(userLang)
    } else {
      this.saveLanguage(cookiesLang)
    }
  }

  changeLang = (lang, setOpenDropdown, setOrdersIds, setOrdersList) => {
    socketDisconnect()
    const { isHotelsPage } = currentLocation()
    this.dispatch(clearState())
    this.analytics.selectLanguage(lang)
    this.saveLanguage(lang)
    setOpenDropdown(false)
    if (isHotelsPage) {
      this.history.push(HOTELS_SEARCH_PAGE)
    }
    // TODO пока нету перевода, можно их не обновлять
    // setOrdersIds(undefined)
    // setOrdersList(undefined)
  }

  saveLanguage = (lang) => {
    if (lang.includes('ru')) {
      lang = 'ru-ru'
    } else if (lang.includes('en')) {
      lang = 'en-us'
    }
    const cookies = new Cookies()
    cookies.set('language', lang, {
      maxAge: 60 * 60 * 24 * 365,
      path: '/'
    })
    this.i18n.changeLanguage(lang)
    this.dispatch(updateSearchForm('language', lang))
    this.dispatch(updateOrderHotels('language', _.first(lang.split('-'))))
    if (this.page === ORDERS_SCREEN_UPCOMING || this.page === ORDERS_SCREEN_PAST) {
      this.ordersController.setScreen()
    }
  }
}
