type ObjectWithBooleanValues = Record<string, boolean>

type ObjectWithTypes<InitialObject extends ObjectWithBooleanValues, Value = any> = Record<keyof InitialObject, Value>

/**
 * @return {any}
 * @param {Object} obj
 * @param {Object} options
 *
 * @example
 * Функция принимает два объекта
 * Первый объект это должна содержать поля с булевыми значениями:
 *
 * {
 *  breakfastIncl: true,
 *  dinnerIncl: false
 * }
 *
 * Второй объект должен содержать такие же поля, что и первый, и любые значения:
 *
 * {
 *  breakfastIncl: 'Breakfast included',
 *  dinnerIncl: 13
 * }
 *
 * Функция возвратит массив соответствий, то есть если у поля в первом объекте положительное значение,
 * а во втром это же поле имеет какое-то значение, то оно будет возвращено как один из элементов массива
 *
 * В случае, если объекты будут такими, как показано выше, то результат будет ['Breakfast included']
*/
export const pickOptions = <T extends ObjectWithBooleanValues, R>(obj: T, options: ObjectWithTypes<T, R>) => {
  const result = []

  for (const key in obj) {
    const item = obj[key]

    if (item && key in options) {
      result.push(options[key])
    }
  }

  return result
}

export const pickOptionsAsList = <T extends ObjectWithBooleanValues>(obj: T, options: ObjectWithTypes<T, string>) => {
  const result = pickOptions(obj, options)

  if (!result.every(String)) {
    throw new TypeError('pickOptionAsList should have only a string values in second object')
  }

  return result.join(', ')
}
