export const UPDATE_ORDERS = 'UPDATE_ORDERS'
export const CLEAR_ORDERS = 'CLEAR_ORDERS'
export const SET_FROM = 'SET_FROM'

export const updateOrders = (field, payload) => ({
  type: UPDATE_ORDERS,
  field,
  payload
})
export const clearOrders = () => ({
  type: CLEAR_ORDERS,
})
export const setFrom = (payload) => ({
  type: SET_FROM,
  payload
})
