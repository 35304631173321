import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'

const ConfirmationDates = () => {
  const { t } = useTranslation()
  const hotelState = useSelector(state => state.hotel)
  return (
    <div className='confirmationDates confirmationScreen__block'>
      <div className='block__header'>
        {t('Dates')}
      </div>
      <div className='confirmationDates__info'>
        <div className='confirmationDates__el'>
          <div className='confirmationDates__el-time'>
            {t('Check-in from')} {hotelState.choice.checkInTime?.from}
          </div>
          <div className='confirmationDates__el-date'>
            <Moment
              format='MMM DD, ddd'
            >
              {hotelState.checkinDate}
            </Moment>
          </div>
        </div>
        <div className='confirmationDates__el'>
          <div className='confirmationDates__el-time'>
            {t('Check-out until')} {hotelState.choice.checkOutTime?.until}
          </div>
          <div className='confirmationDates__el-date'>
            <Moment
              format='MMM DD, ddd'
            >
              {hotelState.checkoutDate}
            </Moment>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationDates
