import React, { useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import hotelAmenities from '../../../../../data/hotelAmenities'
import _ from 'lodash'
import HotelScreenAnalytics from '../../../../../analytics/analyticsHotel/hotelScreenAnalytics'

const HotelPopularAmenities = ({ hotel }) => {
  const analyticsHotel = new HotelScreenAnalytics()
  const BEST_AMENITIES = 6
  const ALL_AMENITIES = undefined
  const { t } = useTranslation()
  const [slice, setSlice] = useState(BEST_AMENITIES)

  const amenities = _.compact(
    _.map(hotel.amenities, (amenity, key) => {
      if (amenity === 1)
        return key
    })
  ).slice(0, slice)

  return (
    <div className='popularAmenities'>
      <div className='popularAmenities__header'>
        {t('Popular amenities')}
      </div>
      <div className='popularAmenities__amenities'>
        {_.map(amenities, amenity =>
          <div key={'amenity' + amenity} className='popularAmenities__amenity'>{t(hotelAmenities[amenity])}</div>
        )}
      </div>
      {_.size(hotel.amenities) > BEST_AMENITIES &&
        <button
          onClick={() => {
            setSlice(slice !== BEST_AMENITIES ? BEST_AMENITIES : ALL_AMENITIES)
            slice === BEST_AMENITIES && analyticsHotel.clickMoreInfoAboutHotel()
          }}
          className='popularAmenities__more'
        >
          {slice === BEST_AMENITIES
            ? t('Show all')
            : t('Show less')}

        </button>}
    </div>
  )
}

export default HotelPopularAmenities
