import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { showLoadBar } from 'reducers/loadBar/actions'

export const useLoadBar = () => {
  const dispatch = useDispatch()

  const on = useCallback((value: unknown = true) => {
    dispatch(showLoadBar(value))
  }, [])

  const off = useCallback((value: unknown = false) => {
    dispatch(showLoadBar(value))
  }, [])

  return [on, off]
}
