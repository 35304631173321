import React from 'react'
import './style.sass'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CLIENT_MODE_BUSINESSES } from '../../../reducers/user/constans'

const HelloText = () => {
  const { t } = useTranslation()
  const user = useSelector(state => state.user)
  const profile = user.profile
  let userName = ''
  if (profile.success && profile.info.name) {
    userName = profile.info.name.split(' ')[0]
  }
  if (user.clientMode === CLIENT_MODE_BUSINESSES) {
    userName = user.business?.success ? ' ' + user.business.account_info.first_name + ',' : ','
  } else {
    userName = (profile.success && profile.info.name) ? ' ' + profile.info.name.split(' ')[0] + ',' : ','
  }
  return (
    <div className='helloText'>
      {t('Hey')}{userName}
      <span>{t('ready for your next journey')}?</span>
    </div>
  )
}
export default HelloText
