import { useDispatch } from 'react-redux'
import { updateBrowserInformation } from '../reducers/browserInformation/actions'

export default class NavigationController {
  constructor () {
    this.dispatch = useDispatch()
  }

  setBrowserInformation = (dimensions) => {
    // this.dispatch(updateBrowserInformation('browser', navigator.userAgent))
    // this.dispatch(updateBrowserInformation('pageSize', dimensions.width + 'x' + dimensions.height))
  }
}
