import { useDispatch } from 'react-redux'
import _ from 'lodash'

import { changeDirect, changeLayovers } from '../../../reducers/filter/actions'


export class LayoversController {
  // eslint-disable-next-line no-useless-constructor
  constructor (
        private setValue,
        private filters,
        private dispatch = useDispatch(),
  ) {}

  private removeDirectFlightsOnly = () => {
    if (this.filters.directFlightsOnly) {
      this.dispatch(changeDirect(false))
    }
  }

  handleClick = (name) => () => {
    this.dispatch(changeLayovers(name))
    this.removeDirectFlightsOnly()
  }

  handleClear = () => {
    this.dispatch(changeLayovers('oneStop', false))
    this.dispatch(changeLayovers('twoStops', false))
    this.dispatch(changeLayovers('moreStops', false))
  }

  handleClose = (limit) => () => {
    this.handleClear()
    this.handleSliderClear(limit)()
  }

  handleChangeValue = (value) => {
    this.setValue(value.sort((a, b) => a - b))
    _.debounce(() => {
      this.removeDirectFlightsOnly()
      this.dispatch(changeLayovers('value', value.sort((a, b) => a - b)))
    }, 300)()
  }

  handleSliderClear = (limit) => () => {
    this.setValue([])
    this.handleChangeValue(limit)
  }
}
