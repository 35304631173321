import _ from 'lodash'
import { useSelector } from 'react-redux'
import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import Icons from '../../../../../../pageComponents/icons'
import HotelScreenAnalytics from '../../../../../../../analytics/analyticsHotel/hotelScreenAnalytics'

const RoomDetailOptionsMore = ({ room, slice, setSlice, START_OPTIONS_SHOW, ALL_OPTIONS_SHOW }) => {
  const options = room.options
  const { t } = useTranslation()
  const orderHotels = useSelector(state => state.orderHotels)
  const analyticsHotel = new HotelScreenAnalytics()
  const moreRates = _.size(options) - START_OPTIONS_SHOW
  if (moreRates <= 0) return ''
  return (
    <button
      className='roomDetailOptionsMore'
      onClick={() => {
        setSlice(slice !== START_OPTIONS_SHOW ? START_OPTIONS_SHOW : ALL_OPTIONS_SHOW)
        analyticsHotel.clickMoreFare(orderHotels, room)
      }}
    >
      {slice === START_OPTIONS_SHOW
        ? `${t('Show')} ${t('More_rate', { count: moreRates })}`
        : t('Show less')}
      <Icons
        group='arrow'
        icon='detail'
        className={
              'roomDetailOptionsMore__icon ' +
              (slice !== START_OPTIONS_SHOW && 'roomDetailOptionsMore__icon-active')
            }
      />
    </button>
  )
}

export default RoomDetailOptionsMore
