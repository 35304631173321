import { useDispatch } from 'react-redux'
import Api from '../../../api'
import { updateButton } from '../../../reducers/button/actions'
import { FARE_BUTTON } from '../../../reducers/button/types'
import { FARE_SCREEN, PURCHASE__SCREEN } from '../../../reducers/page/types'
import { changePage } from '../../../reducers/page/actions'
import { updateUser } from '../../../reducers/user/actions'
import FareAnalytics from '../../../analytics/analyticsFlight/fareAnalytics'

export default class FarePriceController {
  constructor (price, fare, clientMode) {
    this.dispatch = useDispatch()
    this.api = new Api()
    this.price = price
    this.fare = fare
    this.clientMode = clientMode
    this.analytics = new FareAnalytics()
  }

  handleOnClick = () => {
    this.analytics.successFare(this.fare, this.price)
    this.dispatch(updateButton(FARE_BUTTON, 'disabled', true))
    this.dispatch(updateButton(FARE_BUTTON, 'isLoading', FARE_SCREEN))
    this.api.getPassengers(this.clientMode)
      .then(res => {
        if (res) {
          this.dispatch(updateUser('passengers', res))
          this.dispatch(changePage(PURCHASE__SCREEN))
          this.dispatch(updateButton(FARE_BUTTON, 'disabled', false))
          this.dispatch(updateButton(FARE_BUTTON, 'isLoading', false))
        }
      })
  }
}
