import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useAppSelector } from 'reducers'
import { changePage } from 'reducers/page/actions'

export const usePage = (name?: string) => {
  const dispatch = useDispatch()
  const page = useAppSelector(state => state.page)

  useEffect(() => {
    if (name) {
      dispatch(changePage(name))
    }
  }, [])

  return page
}
