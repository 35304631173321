import React, { useState } from 'react'
import './style.sass'
import _ from 'lodash'
import PhotoGallery from '../../../../../../pageComponents/photoGallery'
import HotelPhotosCount from '../../../../_photosCount'
import HotelScreenAnalytics from '../../../../../../../analytics/analyticsHotel/hotelScreenAnalytics'
import { useSelector } from 'react-redux'

const RoomDetailPhotos = ({ room }) => {
  const photos = room.photos
  const [showGallery, setShowGallery] = useState(false)
  const analyticsHotel = new HotelScreenAnalytics()
  const orderHotels = useSelector(state => state.orderHotels)
  const analyticsValue = { orderHotels, room }

  return (
    <div className='roomDetailPhotos'>
      <div
        className='roomDetailPhotos__image'
        onClick={() => {
          setShowGallery(true)
          analyticsHotel.clickPhotoRoom(orderHotels, room)
        }}
      >
        <img src={_.first(photos)} alt='' />
      </div>
      <HotelPhotosCount count={_.size(photos)} />
      {showGallery &&
        <PhotoGallery
          photosUrl={photos}
          setShowGallery={setShowGallery}
          analyticsType='room'
          analyticsValue={analyticsValue}
        />}
    </div>
  )
}

export default RoomDetailPhotos
