import React from 'react'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import { useSelector } from 'react-redux'
import InputDropdown from '../../searchComponents/_inputDropdown'
import { SpecialRequestDropDownController } from './controller'
import { SpecialRequestDropDown } from './specialRequestDropdown'
import './style.sass'


const SpecialRequest = () => {
  const hotelChoice = useSelector(state => state.hotel.choice)
  const { t } = useTranslation()
  const {
    open,
    valueRequest,
    handleChange,
    handleCheckText,
    handleFocus,
    handleBlur
  } = SpecialRequestDropDownController()

  const requestData = [
    { id:1, text:'Early check-in. Tentative check-in time is - ' },
    { id:2, text:'Late check-in. Tentative check-in time is - ' },
    { id:3, text:'Please send an email with the link to pay for the order at email in the order' },
    { id:4, text:'We ask for 1 large bed' },
    { id:5, text:'We ask for 2 separate beds' }
  ]

  if (hotelChoice.provider === 'amadeus') return null
  return (
    <div className='confirmationPaymentOptions confirmationScreen__block'>
      <div className='block__header'>
        {t('Special requests')}
      </div>
      <div className='block__description'>
        {t('Special requests cant be guaranteed')}
      </div>
      <OutsideClickHandler
        onOutsideClick={() => {
          if (open) {
            handleBlur()
          }
        }}
      >
        <div className='specialRequestDropdown'>
          <textarea
            className='specialRequestDropdown__input'
            onFocus={handleFocus}
            value={valueRequest}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('Special requests placeholder')}
          />

          <InputDropdown
            visible={open}
            child={<SpecialRequestDropDown items={requestData} handleCheckText={handleCheckText} />}
          />
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default SpecialRequest
