import React from 'react'
import { useCookies } from 'react-cookie'
import { Redirect, Route } from 'react-router-dom'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({ children, ...rest }) => {
  const [cookies] = useCookies()
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (cookies.auth_token || window.location.search.includes('code') || window.location.search.includes('token')) {
          return children
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location }
              }}
            />
          )
        }
      }}
    />
  )
}

export default PrivateRoute
