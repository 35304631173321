import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.sass'

const HotelsNotFound = () => {
  const { t } = useTranslation()
  return (
    <div className='notFound'>
      {t('No hotels found, select different dates and search again')}
    </div>
  )
}

export default HotelsNotFound
