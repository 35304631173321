import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import MenuElement from './_menuElement'
import availableMenu from '../../../data/availableMenu'
import _ from 'lodash'

const Menu = () => {
  const { t } = useTranslation()

  return (
    <div className='menu'>
      {_.map(availableMenu, menu =>
        <MenuElement
          key={`menuElement ${menu.text}`}
          icon={menu.icon}
          text={t(menu.text)}
          link={menu.link}
        />
      )}
    </div>
  )
}

export default Menu
