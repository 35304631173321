import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const SuccessAddress = () => {
  const { t } = useTranslation()
  const orderRS = useSelector(state => state.orderRS.model)
  return (
    <div className='successAddress'>
      <div className='successAddress__header'>
        {t('Address')}
      </div>
      <div className='successAddress__address'>
        {orderRS.trip.hotel.address}
      </div>
    </div>
  )
}

export default SuccessAddress
