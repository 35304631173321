import React from 'react'
import './style.sass'

const Tooltip = ({ child, top, bottom }) => {
  return (
    <div className={'tooltip ' + (top && 'tooltip-top') + (bottom && 'tooltip-bottom')}>
      {child}
    </div>
  )
}

export default Tooltip
