export const START_SEARCH = 'START_SEARCH'
export const CLEAR_STATE = 'CLEAR_STATE'

export const startSearch = () => ({
  type: START_SEARCH,
})

export const clearState = () => ({
  type: CLEAR_STATE,
})
