import { CLEAR_STATE } from '../actions'
import { SHOW_LOAD_BAR } from './actions'

const defaultState = {
  show: false
}

const loadBar = (state = defaultState, action) => {
  switch (action.type) {
    case SHOW_LOAD_BAR:
      return {
        ...state,
        show: action.payload
      }
    case CLEAR_STATE:
      return defaultState
    default:
      return state
  }
}

export default loadBar
