import { useDispatch } from 'react-redux'
import { changeStars, clearStars } from '../../../reducers/filter/actions'

export default class StarsFilterController {
  constructor () {
    this.dispatch = useDispatch()
  }

  handleChangeSlider = (value) => {
    this.dispatch(changeStars('value', value.sort((a, b) => a - b)))
  }

  handleClearFilter = (e) => {
    e.stopPropagation()
    this.dispatch(clearStars())
  }

  changeActiveFilter = (filter, action) => {
    filter.value[0] > filter.limit[0] ||
    filter.value[1] < filter.limit[1]
      ? action(true)
      : action(false)
  }
}
