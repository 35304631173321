import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import Icons from '../../../../../../pageComponents/icons'
import _ from 'lodash'

const RoomDetailOccupancy = ({ room }) => {
  const { t } = useTranslation()
  const occupancy = _.first(room.options)?.occupancy
  if (!occupancy) return ''
  return (
    <div className='roomDetailOccupancy'>
      <Icons
        group='icon'
        icon='personMax'
      />
      {occupancy} {t('Max')}
    </div>
  )
}

export default RoomDetailOccupancy
