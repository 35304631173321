import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Icons from '../../pageComponents/icons'
import SearchHotelsController from './controller'

const SearchHotels = () => {
  const { t } = useTranslation()
  const search = useSelector(state => state.filter.search)
  const controller = new SearchHotelsController()
  return (
    <div className='searchHotels'>
      <Icons
        group='icon'
        icon='search'
        className='searchHotels__icon'
      />
      <input
        type='text'
        className='searchHotels__input'
        placeholder={t('Search by property name')}
        defaultValue={search}
        value={search}
        onChange={controller.handleOnChange}
      />
    </div>
  )
}

export default SearchHotels
