import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import Tooltip from '../../../../pageComponents/tooltip'
import PriceTooltip from '../_priceTooltip'
import HotelPriceController from './controller'
import { getPriceWithCurrency } from '../../../../../utils/prices'

const HotelPrice = ({ hotel }) => {
  const { t, i18n } = useTranslation()
  const controller = new HotelPriceController()
  return (
    <div className='HotelPrice'>
      <div className='HotelPrice__base tooltip-hoverElement'>
        {controller.getBigPrice(hotel.priceInfo, i18n)}
        <Tooltip
          top
          child={<PriceTooltip hotel={hotel} />}
        />
      </div>
      <div className='HotelPrice-taxes'>
        {i18n.language.includes('ru')
          ? t('without taxes')
          : t('total including taxes')}
              &nbsp;
        <span>
          {controller.getTaxesPrice(hotel.priceInfo, i18n)}
        </span>
      </div>
    </div>

  )
}

export default HotelPrice
