import AnalyticsHotel from './index'

export default class ErrorAnalytics extends AnalyticsHotel {
  seeError = (error) => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.MAIN,
      'orderCreate',
      this.ACTIONS.ERROR,
      error
    )
  }

  clickSupport = () => {
    this.logEvent(
      this.NAMES.ERROR,
      this.FEATURE.MAIN,
      'supportButton',
      this.ACTIONS.TAP,
      ''
    )
  }
}
