import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getPriceWithCurrency } from '../../../utils/prices'

const SuccessPrice = () => {
  const { t, i18n } = useTranslation()
  const orderRS = useSelector(state => state.orderRS.model)
  return (
    <div className='successPrice'>
      <div className='successPrice__header'>
        {t('order amount')}
      </div>
      <div className='successPrice__price'>
        {getPriceWithCurrency(
          orderRS.order.fare.total.convertedValue ||
          orderRS.order.fare.total.value ||
          orderRS.order.price,
          orderRS.order.fare.total.convertedCurrency ||
          orderRS.order.fare.total.currency ||
          orderRS.order.currency,
          i18n.language
        )}
      </div>
    </div>
  )
}

export default SuccessPrice
