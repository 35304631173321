import React, { useCallback, useEffect, useState } from 'react'
import { useDebounce } from './useDebounce'

export const useTextInput = (defaultValue: string, change?: (value: string) => void, timeout = 300) => {
  const [input, setValue] = useState(defaultValue)
  const debouncedValue = useDebounce(input, timeout)

  useEffect(() => {
    if (change) {
      change(debouncedValue)
    }
  }, [debouncedValue])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value)
  }, [])

  return [input, onChange] as const
}
