import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useCountActiveFilters = () => {
  const paymentAndBooking = Object.entries(useSelector(state => state.filter.paymentAndBooking))
  const propertyTypes = useSelector(state => state.filter.propertyTypes)

  const [isPaymentAndBooking, seIsPaymentAndBooking] = useState(false)
  const [isPropertyTypesFilter, seIsPropertyTypesFilter] = useState(false)

  const [countActiveFilters, setCountActiveFilters] = useState(0)

  useEffect(() => {
    _checkChange(
      paymentAndBooking, isPaymentAndBooking, seIsPaymentAndBooking,
      item => !item[1].checked, setCountActiveFilters
    )
  }, [paymentAndBooking])

  useEffect(() => {
    _checkChange(
      propertyTypes, isPropertyTypesFilter, seIsPropertyTypesFilter, item => !item.checked, setCountActiveFilters
    )
  }, [propertyTypes])

  return countActiveFilters
}

const _checkChange = (array, isActive, setIsActive, ruleIsActive, setCountActiveFilters) => {
  const currentIsActive = array.some(ruleIsActive)
  if (isActive !== currentIsActive) {
    currentIsActive && setCountActiveFilters(count => count + 1)
    !currentIsActive && setCountActiveFilters(count => count - 1)
    setIsActive(currentIsActive)
  }
}

export default useCountActiveFilters
