import React, { useEffect } from 'react'
import './style.sass'
import OutsideClickHandler from 'react-outside-click-handler'
import Iframe3DSController from './controller'

const Iframe3DS = ({ iFrame3DSpaymentUrl, setIFrame3DSpaymentUrl, orderType }) => {
  const controller = new Iframe3DSController()
  useEffect(() => {
    const handler = event => controller.listener(event, setIFrame3DSpaymentUrl, orderType)

    window.addEventListener('message', handler)

    return () => window.removeEventListener('message', handler)
  }, [])

  if (!iFrame3DSpaymentUrl) return null
  return (

    <div className='Iframe3DS__overflow'>
      <button
        className='Iframe3DS__close'
        onClick={() => setIFrame3DSpaymentUrl(false)}
      />
      <OutsideClickHandler
        onOutsideClick={() => {
          setIFrame3DSpaymentUrl(false)
        }}
      >
        <iframe
          className='Iframe3DS__iframe'
          src={iFrame3DSpaymentUrl}
          frameBorder='0'
        />
      </OutsideClickHandler>
    </div>
  )
}

export default Iframe3DS
