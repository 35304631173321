import AnalyticsHotel from './index'


export default class GuestsAnalytics extends AnalyticsHotel {
  selectedGuest = () => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.GUEST,
      'selectGuest',
      this.ACTIONS.TAP,
      ''
    )
  }

  clickNewGuest = () => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.GUEST,
      'addGuestButton',
      this.ACTIONS.TAP,
      ''
    )
  }

  addMainGuest = () => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.GUEST,
      'addMainGuestButton',
      this.ACTIONS.TAP,
      ''
    )
  }

  addExtraGuest = (number) => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.GUEST,
      'addExtraGuestButton',
      this.ACTIONS.TAP,
      number
    )
  }

  clickOpenAllGuests = () => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.GUEST,
      'showAllGuestNames',
      this.ACTIONS.TAP,
      ''
    )
  }

  clickOnSaveGuest = () => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.NEW_GUEST,
      'addGuestSuccessButton',
      this.ACTIONS.TAP,
      ''
    )
  }

  clickEditGuest = () => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.NEW_GUEST,
      'changeGuestButton',
      this.ACTIONS.TAP,
      ''
    )
  }

  editField = (field) => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.NEW_GUEST,
      'changeField',
      this.ACTIONS.TAP,
      field
    )
  }

  cancelPassenger = () => {
    this.logEvent(
      this.NAMES.CHECKOUT,
      this.FEATURE.NEW_GUEST,
      'addGuestCancelButton',
      this.ACTIONS.TAP,
      ''
    )
  }
}
