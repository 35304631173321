import React from 'react'
import './style.sass'

const ItinerariesNotFound = ({ text }) => {
  return (
    <div className='itineraries__notFound'>
      {text}
    </div>
  )
}

export default ItinerariesNotFound
