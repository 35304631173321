import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { OrdersLayout, useOrders } from 'modules/orders'

import { ORDERS_PAGE, ORDERS_PAGE_PAST, ORDERS_PAGE_UPCOMING } from '../routes'
import { MainOrders } from 'modules/orders/pages/main'
import { ORDERS_SCREEN_PAST, ORDERS_SCREEN_UPCOMING } from 'reducers/page/types'

const OrdersNavigation = () => {
  const orders = useOrders()
  return (
    <>
      <Route>
        <Route exact path={ORDERS_PAGE}>
          <Redirect to={ORDERS_PAGE_UPCOMING} />
        </Route>
        <Route exact path={ORDERS_PAGE_UPCOMING}>
          <OrdersLayout>
            <MainOrders page={ORDERS_SCREEN_UPCOMING} orders={orders} />
          </OrdersLayout>
        </Route>

        <Route exact path={ORDERS_PAGE_PAST}>
          <OrdersLayout>
            <MainOrders page={ORDERS_SCREEN_PAST} orders={orders} />
          </OrdersLayout>
        </Route>
      </Route>
    </>
  )
}

export default OrdersNavigation
