import moment from 'moment'
import { CLIENT_MODE_BUSINESSES } from '../reducers/user/constans'
import currentLocation from './currentLocation'
import _ from 'lodash'

export default function createPassengerObject (form, type, currentCarrierName, currentChainsName, clientMode) {
  const { isHotelsPage } = currentLocation()
  const object = {}
  object.documents = {}
  object.frequentFlyer = []
  object.frequentGuest = []
  Object.keys(form).forEach(field => {
    if (!field.includes('error') && field !== 'documentExpiryDateUnlimited') {
      if (field.includes('document')) {
        const replaceField = field.replace('document', '')
        const newField = replaceField[0].toLowerCase() + replaceField.slice(1)
        object.documents[newField] = form[field]
      } else if (field === 'frequentFlyer') {
        if (form[field]?.length) {
          object.frequentFlyer.push({
            number: form[field],
            name: currentCarrierName
          })
        }
      } else if (field === 'frequentGuest') {
        if (form[field]?.length) {
          let number
          if (typeof form[field] === 'object') {
            const findNumber = _.find(form[field], chains => chains.name === currentChainsName)
            number = findNumber ? findNumber.number : ''
          } else {
            number = form[field]
          }
          object.frequentGuest.push({
            number: number,
            name: currentChainsName
          })
        }
      } else if (field.toLowerCase().includes('date')) {
        object[field] = form[field] ? moment(form[field]).format('YYYY-MM-DD') : form[field]
      } else if (field.toLowerCase().includes('phone')) {
        object[field] = form[field].replace(/[^\d;]/g, '')
      } else if (field.toLowerCase().includes('country') && !form[field]) {
        object[field] = null
      } else {
        object[field] = form[field]
      }
    }
  })
  if (form.documentExpiryDateUnlimited) {
    object.documents.documentExpiryDate = null
  }
  if (!_.size(object.documents)) {
    object.documents = []
  }
  object.type = type
  if (clientMode === CLIENT_MODE_BUSINESSES) {
    object.first_name = object.firstName
    object.last_name = object.lastName
    object.middle_name = object.middleName
    object.birth_date = object.birthDate
    object.home_country = object.country
    object.wheelchair_required = object.wheelchairRequired
    if (!_.size(object.documents) || !object.documents.type) {
      delete object.documents
    } else {
      object.documents = [
        {
          type: object.documents.type,
          number: object.documents.number,
          expiration_date: object.documents.expiryDate,
          issuing_country: object.documents.countryCode,
        }
      ]
    }
    delete object.firstName
    delete object.lastName
    delete object.middleName
    delete object.birthDate
    delete object.country
  }
  return object
}
