import React, { useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { clearPassengerInForm } from '../../../reducers/order/actions'
import Passenger from './_passenger'
import Popup from '../../pageComponents/popup'
import PassengerForm from './_form'

const Passengers = () => {
  const { t } = useTranslation()
  const [openOptionsPassenger, setOpenOptionsPassenger] = useState(false)
  const [popupOpen, setPopupOpenState] = useState(false)
  const dispatch = useDispatch()
  const setOpenOptions = (index) => {
    if (index === openOptionsPassenger) {
      setOpenOptionsPassenger(false)
    } else {
      setOpenOptionsPassenger(index)
    }
  }
  const passengers = useSelector(state => state.orderFlights.passengers)
  const setPopupOpen = (open) => {
    setPopupOpenState(open)
    if (!open) {
      dispatch(clearPassengerInForm())
    }
  }
  return (
    <div className='passengers'>
      <div className='block__header'>
        {t('Who’s travelling?')}
      </div>
      {passengers.map((passenger, index) =>
        <Passenger
          key={'passenger' + index}
          passenger={passenger}
          index={index}
          openOptionsPassenger={openOptionsPassenger}
          setOpenOptions={setOpenOptions}
          popupOpen={popupOpen}
          setPopupOpen={setPopupOpen}
        />)}
      {openOptionsPassenger !== false &&
        <Popup
          up
          child={
            <PassengerForm
              setPopupOpen={setPopupOpen}
              openOptionsPassengerNumber={openOptionsPassenger}
              setOpenOptionsPassenger={setOpenOptionsPassenger}
            />
          }
          popupOpen={popupOpen}
          setPopupOpen={setPopupOpen}
        />}
    </div>
  )
}

export default Passengers
