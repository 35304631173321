import React, { useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'
import FareElement from './_element'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const ChangeFare = () => {
  const { t } = useTranslation()
  const inbound = useSelector(state => state.itineraries.inbound)
  const outbound = useSelector(state => state.itineraries.outbound)
  const order = useSelector(state => state.orderFlights)
  const pricingOptions = inbound ? inbound.pricingOptions : outbound.pricingOptions
  const [active, setActive] = useState(order.pricingOption.ndc.offerId)
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  }

  return (
    <div className='changeFare'>
      <div className='changeFare__header'>
        {t('Change fare')}
        {inbound &&
          <span>{t('Your selection applies to both flights')}</span>}
      </div>
      <div className='fares'>
        <Slider {...settings}>
          {pricingOptions.map((pricingOption, index) =>
            <FareElement
              key={'pricingOption' + index}
              pricingOption={pricingOption}
              active={pricingOption.ndc.offerId === active}
              setActive={setActive}
            />
          )}
        </Slider>
      </div>
    </div>
  )
}

export default ChangeFare
