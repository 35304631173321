import { useCallback, useState } from 'react'

export const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState)

  const toggle = useCallback(() => setState(value => !value), [])

  const toggleCustom = useCallback((value: boolean) => setState(value), [])

  return [state, toggle, toggleCustom] as const
}
