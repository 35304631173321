import { createContext, useContext, useReducer, useState } from 'react'

interface PopupObjectType {
    title: string
    text: string
    buttonText: string
}

interface initialContextStateType {
    options: PopupObjectType
    setOptions: (val: PopupObjectType) => void
    visible: boolean
    show: (val: boolean) => void
}

const initialContextState: initialContextStateType = {
  options: {
    title: undefined,
    text: undefined,
    buttonText: undefined,
  },
  setOptions: () => null,
  visible: false,
  show: () => null
}

export const PopupContext = createContext<initialContextStateType>(initialContextState)

export const PopupProvider: React.FC = ({ children }) => {
  const [options, setOptions] = useState<PopupObjectType>({
    title: undefined,
    text: undefined,
    buttonText: undefined,
  })
  const [visible, show] = useState<boolean>(false)

  return (
    <PopupContext.Provider value={{ options, setOptions, visible, show }}>
      {children}
    </PopupContext.Provider>
  )
}

export const usePopup = () => useContext(PopupContext)
