import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FARE_SCREEN, PURCHASE__SCREEN } from '../../../reducers/page/types'
import Icons from '../icons'

const Breadcrumbs = () => {
  const { t } = useTranslation()
  const page = useSelector(state => state.page.name)
  return (
    <div className='breadcrumbs'>
      <div className='container'>
        <span className={page === FARE_SCREEN ? 'breadcrumbs__active' : ''}>{t('Review your itinerary')}</span>
        <Icons
          className='breadcrumbs__arrow'
          group='arrow'
          icon='right'
          color='#ADADAD'
        />
        <span className={page === PURCHASE__SCREEN ? 'breadcrumbs__active' : ''}>{t('Purchase')}</span>
      </div>
    </div>
  )
}

export default Breadcrumbs
