import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { updateItineraries } from '../../../reducers/itineraries/actions'
import { CLIENT_MODE_BUSINESSES } from '../../../reducers/user/constans'
import ItinerariesNotFound from '../itineraries/_notFound'

const ItinerariesTravelPolicy = ({ user, searchRS, exceedsCount, showExceeds }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  if (user.clientMode !== CLIENT_MODE_BUSINESSES) return ''
  if (!exceedsCount) return ''
  if (!searchRS.count) return ''
  if (showExceeds) return ''
  return (
    <>
      <div className='itinerariesTravelPolicy'>
        <div className='itinerariesTravelPolicy__text'>
          {t("Results in accordance with your company's travel policy")}
        </div>
        <button
          className='itinerariesTravelPolicy__button'
          onClick={() => dispatch(updateItineraries('showExceeds', true))}
        >
          {t('Show all')}
        </button>
      </div>
      {searchRS.count === exceedsCount &&
        <ItinerariesNotFound text={t('All flights are not eligible to purchase according to travel policy')} />}
    </>
  )
}

export default ItinerariesTravelPolicy
