import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import Icons from '../../../../../../../pageComponents/icons'

const OptionPerson = ({ option }) => {
  const { t } = useTranslation()
  const { maxAdults } = option

  if (!maxAdults) return null
  return (
    <div className='optionPersons'>
      <div className='person'>
        <Icons
          group='icon'
          icon='person'
          className='person__icon'
        />
        {t('For')} {t('person', { count: maxAdults })}
      </div>

    </div>
  )
}

export default OptionPerson
