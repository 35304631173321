import React from 'react'
import { RangeSlider as RangeSliderComponent, RangeSliderProps } from 'rsuite'
import cx from 'classnames'

import 'rsuite/dist/styles/rsuite-default.css'
import './style.sass'


export const RangeSlider: React.FC<RangeSliderProps> = ({ className, ...props }) => {
  return (
    <RangeSliderComponent className={cx('range-slider', className)} {...props} />
  )
}
