import React from 'react'
import '../style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import MapboxMap from '../../../pageComponents/mapBox'
import SuccessAddress from '../../_address'
import SuccessCancellation from '../../_cancellation'
import SuccessConfirmation from '../../_confirmation'
import SuccessDate from '../../_date'
import SuccessGuests from '../../_guests'
import SuccessHotelInfo from '../../_hotelInfo'
import SuccessPrice from '../../_price'

const SuccessHotels = () => {
  const { t } = useTranslation()
  const orderRS = useSelector(state => state.orderRS.model)
  const { longitude, latitude } = orderRS.trip.hotel
  return (
    <div className='success success__hotels'>
      <div className='success__info'>
        <div className='success__header'>
          {t('Reservation is confirmed!')}
          <span>
            {t('Order details')}
          </span>
        </div>
        {!orderRS.order.booker.email
          ? ''
          : (
            <div className='success__email'>
              {t('Email sent on')}
              <span> {orderRS.order.booker.email}</span>
            </div>)}
        <SuccessConfirmation />
        <SuccessHotelInfo />
        <SuccessDate />
        <SuccessGuests />
        <SuccessAddress />
        <SuccessCancellation />
        <SuccessPrice />
      </div>
      <div className='success__map'>
        <MapboxMap
          initialOptions={{
            center: [longitude, latitude],
            zoom: 15
          }}
          hotel={orderRS.trip.hotel}
          search={false}
        />
      </div>
    </div>
  )
}
export default SuccessHotels
