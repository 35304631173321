import React, { useEffect, useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import { useSelector } from 'react-redux'
import { RangeSlider } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import FilterAnalytics from '../../../analytics/analyticsFlight/filterAnalytics'
import Icons from '../../pageComponents/icons'
import DurationFilterController from './controller'
import getTimeFormat from '../../../hooks/getTimeFormat'

const Duration = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const filterDuration = useSelector(state => state.filter.duration)
  const controller = new DurationFilterController()
  const analytics = new FilterAnalytics()
  const [isDuration, setIsDuration] = useState(false)

  useEffect(() => {
    controller.changeActiveFilter(filterDuration, setIsDuration)
  }, [filterDuration.value])
  return (

    <OutsideClickHandler
      onOutsideClick={() => {
        if (open) {
          setOpen(false)
          analytics.setDuration(filterDuration.value)
        }
      }}
    >
      <div className={'duration filter ' + (open && 'filter-open')}>
        <div
          className={`duration__value filter__el ${isDuration ? 'filter__active' : ''}`}
          onClick={() => {
            if (open) {
              analytics.setDuration(filterDuration.value)
            }
            setOpen(!open)
          }}
        >
          {t('Duration')}
          <Icons
            group='arrow'
            icon='down'
            color='#8F8F8F'
            className='filter__el-icon'
          />
          <a
            className='cross__icon-wrapper'
            onClick={controller.handleClearFilter}
          >
            <Icons
              group='icon'
              icon='close'
              className='wifi__label-icon'
              color='#3D73EC'
            />
          </a>
        </div>
        <div className='duration__list filter__list'>
          <div className='filter__list-wrap'>
            <div className='list__header'>
              <div className='left'>
                {t('Duration')}
                <div className='list__header-time'>
                  {getTimeFormat(filterDuration.value[0], t)}
                  {' - '}
                  {getTimeFormat(filterDuration.value[1], t)}
                </div>
              </div>
              <button
                className='right clear'
                onClick={controller.handleClearFilter}
              >
                {t('Clear')}
              </button>
            </div>
            <div className='duration__slider'>
              <RangeSlider
                barClassName=''
                value={[filterDuration.value[0], filterDuration.value[1]]}
                min={filterDuration.limit[0]}
                max={filterDuration.limit[1]}
                tooltip={false}
                onChange={controller.handleChangeSlider}
              />
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  )
}
export default Duration
