import { useSelector } from 'react-redux'
import { ORDERS_SCREEN_PAST } from '../../reducers/page/types'
import AnalyticsFlight from './index'

export default class TripsAnalytics extends AnalyticsFlight {
  constructor () {
    super()
    this.page = useSelector(state => state.page.name)
  }

  tripsSee = () => {
    this.logEvent(
      this.NAMES.TRIPS,
      this.FEATURE.TRIPS.MAIN,
      'changeTrips',
      this.ACTIONS.SEE
    )
  }

  showDetail = (bookref) => {
    this.logEvent(
      this.NAMES.TRIPS,
      this.page === ORDERS_SCREEN_PAST
        ? this.FEATURE.TRIPS.PAST
        : this.FEATURE.TRIPS.UPCOMING,
      'orderDetails',
      this.ACTIONS.TAP,
      bookref
    )
  }
}
