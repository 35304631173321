import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import UseSavedState from '../hooks/useSavedState'
import useWindowDimensions from '../hooks/useWindowDimensions'
import PrivateRoute from './_privateRoute'
import NavigationController from './controller'
import ScrollToTop from './scrollTop'
import UsePageViews from './usePageViews'
import Main from './layouts/main'
import HotelsNavigation from './hotelsNavigation'

import Login from './page/login'
import FlightsPage from './page/flights'
import SavedState from './page/savedState'
import OrdersNavigation from './ordersNavigation'
import { setPrevPage } from '../reducers/page/actions'

const Navigation = () => {
  const controller = new NavigationController()
  const dispatch = useDispatch()
  const dimensions = useWindowDimensions()
  const page = useSelector(state => state.page.name)
  const [currentPage, setCurrentPage] = useState()

  useEffect(() => {
    if (!UseSavedState()) {
      controller.setBrowserInformation(dimensions)
    }
  }, [dimensions])

  useEffect(() => {
    dispatch(setPrevPage(currentPage))
    setCurrentPage(page)
  }, [page])

  return (
    <Router>
      <UsePageViews />
      <ScrollToTop />
      <Switch>
        {process.env.NODE_ENV === 'development' &&
          <Route path='/savedState' exact component={SavedState} />}

        <Route exact path='/'>
          <Redirect to='/flights' />
        </Route>
        <Route path='/login' exact component={Login} />

        <Route>
          <Main>
            <Switch>
              <PrivateRoute>
                <Route path='/flights' exact component={FlightsPage} />

                <HotelsNavigation />

                <OrdersNavigation />
              </PrivateRoute>
            </Switch>
          </Main>
        </Route>

      </Switch>

    </Router>
  )
}

export default Navigation


