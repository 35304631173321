import React, { useState, useEffect } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import useLocalStorage from '../../../../hooks/useLocalStorage'
import CurrencyController from './controller'
import availableCurrencies from '../../../../data/availableCurrencies'

const Currency = () => {
  const { i18n } = useTranslation()
  const [openDropdown, setOpenDropdown] = useState(false)
  let [currency, setCurrency] = useLocalStorage('currency')
  const controller = new CurrencyController()
  useEffect(() => {
    if (!currency) {
      currency = i18n.language.includes('ru') ? 'RUB' : 'USD'
    }
    controller.setCurrency(currency, setOpenDropdown, setCurrency)
  }, [i18n.language])

  return (
    <div className='currency'>
      <div
        className={'currency__value ' + (openDropdown && 'active')}
        onClick={() => setOpenDropdown(!openDropdown)}
      >
        {availableCurrencies[currency]}
      </div>
      <OutsideClickHandler
        onOutsideClick={() => {
          if (openDropdown) {
            setOpenDropdown(false)
          }
        }}
      >
        <div className={'currency__list ' + (openDropdown && 'currency__list-open')}>
          <div className='currency__list-wrap'>
            {Object.keys(availableCurrencies).map(key => {
              return (
                <div
                  key={'currencyAvailable' + key}
                  className={'currency__list-el ' + (currency === key && 'active')}
                  onClick={() => { controller.setCurrency(key, setOpenDropdown, setCurrency, 'reload') }}
                >
                  {availableCurrencies[key]}
                </div>
              )
            })}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default Currency
