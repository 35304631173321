import React, { useState } from 'react'
import './style.sass'
import PhotoGallery from '../../../../pageComponents/photoGallery'
import HotelPhotoController from './controller'
import LazyLoad from 'react-lazyload'
import Slider from 'react-slick'
import _ from 'lodash'
import loading from '../../../../../assets/img/loading.gif'
import ResultsAnalytics from '../../../../../analytics/analyticsHotel/resultsAnalytics'
import { useSelector } from 'react-redux'


const HotelPhoto = ({ hotel }) => {
  const controller = new HotelPhotoController()
  const analyticsHotel = new ResultsAnalytics()
  const hotelData = useSelector(state => state.hotel)
  const searchRS = useSelector(state => state.searchRS)
  const [activeSlide, setActiveSlide] = useState(0)
  const [showGallery, setShowGallery] = useState(false)

  const photos = []
  const photosUrl = []
  for (let i = 0; i < hotel.photosCount; i++) {
    const url = controller.generateUrl(hotel.key, i)
    photosUrl.push(url)
    photos.push(
      <LazyLoad
        key={'photos' + hotel.id + i}
      >
        <div
          onClick={() => {
            setShowGallery(true)
            analyticsHotel.clickPhoto(hotel, hotelData, searchRS)
          }}
        >
          <img src={url} alt='' className='HotelPhoto__image' />
        </div>
      </LazyLoad>
    )
  }

  const _renderDots = () => {
    const dots = []
    for (let i = 0; i < _.min([hotel.photosCount, 5]); i++) {
      dots.push(
        <div
          key={'dots' + hotel.id + i}
          className={'dot ' + (activeSlide === i && 'active')}
        />
      )
    }
    return dots
  }

  return (
    <div className='HotelPhoto'>
      <Slider {...controller.getSettingSlider(setActiveSlide, hotel)}>
        {photos}
      </Slider>
      <div className='HotelPhoto__dots'>
        {_renderDots()}
      </div>
      <img src={loading} alt='' className='HotelPhoto__loading' />
      {showGallery &&
        <PhotoGallery photosUrl={photosUrl} setShowGallery={setShowGallery} hotel={hotel} analyticsType='result' />}
    </div>
  )
}

export default HotelPhoto
