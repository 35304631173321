import { useDispatch } from 'react-redux'
import { changeAirline } from '../../../reducers/filter/actions'
import _ from 'lodash'

export default class AirlinesController {
  constructor () {
    this.dispatch = useDispatch()
  }

  handleOnClickElement = (airlines, index) => {
    const newAirlines = airlines.map(airline => {
      if (airline.code === index) {
        airline.checked = !airline.checked
      }
      return airline
    })

    this.dispatch(changeAirline(newAirlines))
  }

  activeButton = (filter) => {
    let count = 0
    _.forEach(filter, (airline) => {
      if (airline.checked) {
        count++
      }
    })
    return {
      clear: count,
      select: count < filter.length
    }
  }

  handleOnClickButton = (filter, checked) => {
    const newAirlines = filter.map(airline => {
      airline.checked = checked
      return airline
    })
    this.dispatch(changeAirline(newAirlines))
  }

  changeActiveFilter = (filter, action) => {
    _.some(filter, i => !i.checked) ? action(true) : action(false)
  }
}
