import React from 'react'
import './style.sass'
import { Cookies } from 'react-cookie'
import { useHistory, useLocation } from 'react-router-dom'
import LoginController from './controller'


const Login = () => {
  const history = useHistory()
  const cookies = new Cookies()

  /* This is a temporary auth method for Travelfusion certification. Should be removed after certification */
  if (process.env.REACT_APP_API_ENVIRONMENT_FLIGHT !== 'production') {
    const query = new URLSearchParams(useLocation().search)
    const authToken = query.get('auth_token')
    if (authToken) {
      cookies.set('auth_token', authToken, {
        maxAge: 60 * 60 * 24 * 10,
        path: '/'
      })
      history.push('/flights')
    }
  }

  const controller = new LoginController()
  if (process.env.NODE_ENV === 'development') {
    return (
      <div className='login'>
        <span>
          Copy the value of the 'auth_token' cookie from this&nbsp;
          <a
            href='https://booking-web-test-cko2sgb62q-uc.a.run.app/'
            target='blank'
          >
            site
          </a> and paste it into the field below
        </span>
        <br />
        <br />
        <input
          type='text'
          onChange={(e) => {
            cookies.set('auth_token', e.target.value, {
              maxAge: 60 * 60 * 24 * 10,
              path: '/'
            })
            history.push('/flights')
          }}
        />
      </div>
    )
  }
  return (
    <div className='login'>
      <a
        href={controller.generateLink()}
        className='login__button'
      >
        Login
      </a>
    </div>
  )
}

export default Login
