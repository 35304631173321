import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Api from '../../../api'
import _ from 'lodash'
import { CheckBusinessUser } from '../../../hooks/checkBusinessUser'
import CurrencySymbol from '../../../hooks/currencySymbol'
import { HOTELS_SUCCESS_PAGE } from '../../../navigation/routes'
import { updateButton } from '../../../reducers/button/actions'
import { CONFIRMATION_BUTTON } from '../../../reducers/button/types'
import { updateOrderRS } from '../../../reducers/order/actions'
import OrderAnalyticsHotel from '../../../analytics/analyticsHotel/orderAnalytics'
import { CLIENT_MODE_BUSINESSES } from '../../../reducers/user/constans'
import { getPriceWithCurrency } from '../../../utils/prices'

export default class ConfirmationPriceController {
  constructor () {
    this.api = new Api()
    this.dispatch = useDispatch()
    this.history = useHistory()
    this.t = useTranslation().t
    this.lang = useTranslation().i18n.language
    this.analyticsHotel = new OrderAnalyticsHotel()
  }

  getNights = (checkin, checkout) => {
    const nights = moment(checkout).diff(moment(checkin), 'days')
    return this.t('night', { count: nights })
  }

  getPricePerNight = (price, checkin, checkout) => {
    const nights = moment(checkout).diff(moment(checkin), 'days')
    if (nights > 1) {
      const { convertedValue, value, convertedCurrency, currency } = price
      const cost = Math.round(((convertedValue || value) / nights) * 100) / 100
      return `${getPriceWithCurrency(cost, convertedCurrency || currency, this.lang)} ${this.t('avg. per night')}`
    }
    return ''
  }

  getPriceValue = (price) => {
    const { convertedValue, value, convertedCurrency, currency } = price
    const currentValue = convertedValue || value
    return getPriceWithCurrency(currentValue, convertedCurrency || currency, this.lang)
  }

  getPriceLocalValue = (price) => {
    const { value, currency } = price
    const roundValue = value.toFixed(2).replace(/\.0+$/, '')
    return `${roundValue} ${CurrencySymbol(currency)}`
  }

  getPayNow = (prepayRequired, priceInfo) => {
    if (prepayRequired) {
      return this.getPriceValue(priceInfo.total)
    }
    return getPriceWithCurrency(0, priceInfo.total.convertedCurrency || priceInfo.total.currency, this.lang)
  }

  getPayProperty = (prepayRequired, priceInfo) => {
    if (prepayRequired) {
      return getPriceWithCurrency(0, priceInfo.base.convertedCurrency || priceInfo.base.currency, this.lang)
    }
    return this.getPriceValue(priceInfo.total)
  }

  handleOnClickBook = (order, setIFrame3DSpaymentUrl, user) => {
    this.analyticsHotel.clickOrderCreate(order)
    const cloneDeepOrder = _.cloneDeep(order)
    _.remove(cloneDeepOrder.extraGuests, extraGuest => !extraGuest.firstName && !extraGuest.lastName)
    if (_.size(cloneDeepOrder.address)) {
      cloneDeepOrder.address.zip = cloneDeepOrder.address.postal
      cloneDeepOrder.address.postal = undefined
      cloneDeepOrder.billing = undefined
    }
    if (CheckBusinessUser(user)) {
      cloneDeepOrder.clientMode = CLIENT_MODE_BUSINESSES
      cloneDeepOrder.companyId = user.business.company_info.id
    }
    this.dispatch(updateButton(CONFIRMATION_BUTTON, 'disabled', true))
    this.dispatch(updateButton(CONFIRMATION_BUTTON, 'isLoading', CONFIRMATION_BUTTON))
    this.api.orderHotel(cloneDeepOrder)
      .then(orderRS => {
        if (orderRS) {
          this.dispatch(updateOrderRS(orderRS))
          if (orderRS.paymentStatus === '3ds') {
            setIFrame3DSpaymentUrl(orderRS.paymentUrl)
          } else {
            this.history.push(HOTELS_SUCCESS_PAGE)
          }
        }
        this.dispatch(updateButton(CONFIRMATION_BUTTON, 'disabled', false))
        this.dispatch(updateButton(CONFIRMATION_BUTTON, 'isLoading', false))
      })
  }
}
