import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { changePrice, clearPrice, setPriceHotels } from '../../../../reducers/filter/actions'

export default class PriceFilterController {
  constructor () {
    this.dispatch = useDispatch()
    this.language = useTranslation().i18n.language
    this.filterPriceHotels = useSelector(state => state.filter.hotelsPrice)
    this.currency = useSelector(state => state.searchForm.currency)
    this.searchRS = useSelector(state => state.searchRS.model)
  }

  handleClearFilter = (e) => {
    e.stopPropagation()
    this.handleClearFilterCheckbox()
    this.handleClearFilterSlider()
  }

  handleClearFilterCheckbox = () => {
    this.dispatch(setPriceHotels(this.getPrice('number')))
  }

  handleClearFilterSlider = () => {
    this.dispatch(clearPrice())
  }

  getPriceFormat = (price, currencyObject) => {
    if (price)
      return this._getIntlNumber(currencyObject, price)
  }

  changeActiveFilter = (filter, filterSlider, action) => {
    const isFilterCheckbox = filter.some(interval => interval.isChecked)
    const isFilterSlider = filterSlider.value[0] > filterSlider.limit[0] ||
    filterSlider.value[1] < filterSlider.limit[1]

    isFilterCheckbox || isFilterSlider
      ? action(true)
      : action(false)
  }

  handleChangeFilterCheckbox = (interval) => {
    this.handleClearFilterSlider()

    this.dispatch(setPriceHotels(this._editInterval(interval, !interval.isChecked)))
  }

  handleChangeFilterSlider = (value) => {
    this.handleClearFilterCheckbox()

    this.dispatch(changePrice('value', value.sort((a, b) => a - b)))
  }

  getPrice = (type = 'string') => {
    const prices = []
    const stepCount = 4
    const step = this.currency === 'RUB' ? 5000 : 50

    for (let i = 0; i < stepCount; i++) {
      const priceInterval = this._getPriceInterval(type, i, step)
      prices.push(priceInterval)
    }

    const andMorePrice = this.searchRS.hotels.some(hotel => hotel.price.value > step * stepCount)
    if (andMorePrice) {
      prices.push(this._getMorePrice(type, step, stepCount))
    }

    return prices
  }

  closeSlider = (action) => {
    this.handleClearFilterSlider()
    action(prev => !prev)
  }

  _editInterval = (interval, value) => {
    return this.filterPriceHotels.map(price => {
      if (price === interval)
        return { ...price, isChecked: value }
      else
        return { ...price }
    })
  }

  _getPriceInterval = (type, i, step) => {
    let priceInterval = {}
    if (type === 'string') {
      priceInterval =
        i === 0
          ? this._pushPriceGap(this.currency, 0, step)
          : this._pushPriceGap(this.currency, (step * i) + 1, (step * (i + 1)))
    } else if (type === 'number') {
      priceInterval =
        i === 0
          ? priceInterval = { start: 0, end: step, isChecked: false }
          : priceInterval = { start: (step * i) + 1, end: step * (i + 1), isChecked: false }
    }
    return priceInterval
  }

  _getMorePrice = (type, step, stepCount) => {
    if (type === 'string') {
      return { start: this._getIntlNumber(this.currency, step * stepCount) + ' +' }
    } else if (type === 'number') {
      return { start: step * stepCount, isChecked: false }
    }
  }

  _pushPriceGap = (currency, priceStart, priceEnd) => {
    return {
      start: this._getIntlNumber(currency, priceStart),
      end: this._getIntlNumber(currency, priceEnd)
    }
  }

  _getIntlNumber = (currency, price) => {
    return new Intl.NumberFormat(
      this.language, { style: 'currency', currency: currency || 'RUB', minimumFractionDigits: 0 }).format(price)
  }
}
