import moment from 'moment'
import { useTranslation } from 'react-i18next'

export default class DetailListController {
  constructor (searchRS, element) {
    this.searchRS = searchRS
    this.element = element
    this.t = useTranslation().t
  }

  getSegments = () => {
    return Object.values(this.searchRS.segments)
      .filter(segment => this.element.indexOf(segment.flightId) >= 0)
      .sort((a, b) => moment(a.departureDate).unix() - moment(b.departureDate).unix())
  }

  getCarrier = (segment) => {
    return this.searchRS.carriers[segment.carrier]?.imageUrl
  }

  getAirport = (code) => {
    return this.searchRS.airports[code]
  }

  getTimeFormat = (duration) => {
    duration = moment.duration(duration, 'minutes')
    return (
      (duration.days() ? duration.days() + this.t('d') + ' ' : '') +
      (duration.hours() ? duration.hours() + this.t('h') + ' ' : '') +
      (duration.minutes() ? duration.minutes() + this.t('m') + ' ' : '')
    )
  }

  getTransferring = (index) => {
    if (!index) return false
    const segments = this.getSegments()
    const prevArrivalDate = moment(segments[index - 1].arrivalDate)
    const departureDate = moment(segments[index].departureDate)
    return {
      minutes: departureDate.diff(prevArrivalDate, 'minutes'),
      days: departureDate.startOf('day').diff(prevArrivalDate.startOf('day'), 'days')
    }
  }

  getDifferentDate = (segment) => {
    const startDepartureDate = moment(segment.departureDate).startOf('day')
    const startArrivalDate = moment(segment.arrivalDate).startOf('day')
    return startArrivalDate.diff(startDepartureDate, 'day', true)
  }

  showOtherTerminal = (index) => {
    if (!index) return false
    const segments = this.getSegments()
    return segments[index - 1].destination !== segments[index].origin
  }
}
