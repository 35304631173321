import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getPriceWithCurrency } from '../../../../../../../../utils/prices'

export default class OptionPriceController {
  constructor () {
    this.lang = useTranslation().i18n.language
  }

  getPrice = (price, checkin, checkout) => {
    const nights = moment(checkout).diff(moment(checkin), 'days')
    const { convertedValue, value, convertedCurrency, currency } = price
    const currentValue = convertedValue || value
    const currentCurrency = convertedCurrency || currency
    const total = getPriceWithCurrency(currentValue, currentCurrency, this.lang)
    const perNight = getPriceWithCurrency(currentValue / nights, currentCurrency, this.lang)
    return {
      total,
      nights,
      perNight
    }
  }
}
