import React from 'react'
import './style.sass'
import { useSelector } from 'react-redux'
import currentLocation from '../../../hooks/currentLocation'
import { useScroll } from '../../../hooks/useScroll'
import { loadBarSetting } from '../../../reducers/loadBar/actions'
import { ORDERS_SCREEN_PAST, ORDERS_SCREEN_UPCOMING } from '../../../reducers/page/types'
import _ from 'lodash'

const LoadBar = () => {
  const { scrollY } = useScroll()
  const loadBar = useSelector(state => state.loadBar)
  let page = useSelector(state => state.page.name)
  const location = useSelector(state => state.page.currentLocation)
  const { isHotelsPage } = currentLocation()
  if (page === ORDERS_SCREEN_PAST) {
    page = ORDERS_SCREEN_UPCOMING
  }
  return (
    <div
      className={'loadBar ' +
      (_.includes(page, loadBar.show) && ' loadBar__show ') +
      (_.includes(location, loadBar.show) && ' loadBar__show ') +
      (scrollY >= 100 && isHotelsPage && ' loadBar__fixed ') +
      ' ' + location.replace(/\//g, '')}
      style={{ top: loadBarSetting(page, isHotelsPage).loadBar?.top }}
    />
  )
}

export default LoadBar
