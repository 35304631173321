import React from 'react'
import ErrorPopup from '../../components/pageComponents/errorPopup'
import Header from '../../components/pageComponents/header'
import Footer from '../../components/pageComponents/footer'
import LoadBar from '../../components/pageComponents/loadBar'
import PopupWindow from 'components/pageComponents/popupWindow'

export default ({ children }) => {
  return (
    <div className='page'>
      <Header />
      <LoadBar />
      <div className='page__content'>
        {children}
      </div>
      <Footer />
      <ErrorPopup />
      <PopupWindow />
    </div>
  )
}
