import moment from 'moment'

export const getStopsDuration = (segments) => {
  let prevArrivalDate
  let prevDepartureDate
  let layoversSum = 0
  // Получаем все перелеты маршрута
  segments.forEach(segment => {
    // Забираем время вылета и прибытия
    const departureDate = moment(segment.departureDate)
    const arrivalDate = moment(segment.arrivalDate)
    if (prevArrivalDate && prevDepartureDate) {
      // Получаем разницу между временем прилета из прошлого отрезка и временем вылета в текущем
      // и получаем время пересадки между перелетами
      const layover = departureDate.diff(prevArrivalDate, 'minutes', false)
      // Суммируем время пересадок на всём маршруте
      layoversSum += layover
    }
    prevArrivalDate = arrivalDate
    prevDepartureDate = departureDate
  })
  prevArrivalDate = undefined
  prevDepartureDate = undefined

  return layoversSum
}
