import { useDispatch } from 'react-redux'
import { changePaymentAndBooking } from '../../../../reducers/filter/actions'

export default class PaymentAndBookingController {
  constructor () {
    this.dispatch = useDispatch()
  }

  handleOnChangeElement = (paymentAndBooking, type) => {
    paymentAndBooking[type].checked = !paymentAndBooking[type].checked

    this.dispatch(changePaymentAndBooking(paymentAndBooking))
  }
}
