import React from 'react'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import Icons from '../../pageComponents/icons'
import _ from 'lodash'

const Flight = ({ segment }) => {
  const { t, i18n } = useTranslation()
  const searchRS = useSelector(state => state.searchRS.model)
  const orderRS = useSelector(state => state.orderRS.model)
  return (
    <div className='flight'>
      <div className='flight__origin'>
        <div className='flight__date'>
          <Moment
            format='MMM DD, LT'
            locale={i18n.language}
          >
            {segment.departureDate}
          </Moment>
        </div>
        <div className='flight__detail'>
          {(orderRS.order.airports &&
          Object.values(orderRS.order.airports).length &&
          orderRS.order.airports[segment.origin])
            ? `${orderRS.order.airports[segment.origin].name} (${segment.origin})`
            : (searchRS.airports &&
              Object.values(searchRS.airports).length &&
              searchRS.airports[segment.origin])
                ? `${searchRS.airports[segment.origin].name} ${segment.origin}`
                : ''}
          <br />
          {segment.originTerminal &&
          t('Terminal') + ' ' + segment.originTerminal}

        </div>
      </div>
      <div className='flight__arrow'>
        <Icons
          group='arrow'
          icon='next'
          color='#525252'
        />
      </div>
      <div className='flight__destination'>
        <div className='flight__date'>
          <Moment
            format='MMM DD, LT'
            locale={i18n.language}
          >
            {segment.arrivalDate}
          </Moment>
        </div>
        <div className='flight__detail'>
          {(orderRS.order.airports &&
            Object.values(orderRS.order.airports).length &&
            orderRS.order.airports[segment.destination])
            ? `${orderRS.order.airports[segment.destination].name} (${segment.destination})`
            : (searchRS.airports &&
              Object.values(searchRS.airports).length &&
              searchRS.airports[segment.destination])
                ? `${searchRS.airports[segment.destination].name} ${segment.destination}`
                : ''}
          <br />
          {segment.destinationTerminal &&
          t('Terminal') + ' ' + segment.destinationTerminal}
        </div>
      </div>
      <div className='flight__carrier'>
        {(orderRS.order.carriers &&
        Object.values(orderRS.order.carriers).length &&
        orderRS.order.carriers[segment.carrier])
          ? <img src={orderRS.order.carriers[segment.carrier].imageUrl} alt='' className='flight__carrier-logo' />
          : (searchRS.carriers &&
            Object.values(searchRS.carriers).length &&
            searchRS.carriers[segment.carrier])
              ? <img src={searchRS.carriers[segment.carrier].imageUrl} alt='' className='flight__carrier-logo' />
              : ''}
        <div className='flight__carrier-name'>
          {segment.carrier + segment.number}
        </div>
        <div className='flight__carrier-fare'>
          {_.size(orderRS.order.descriptions)
            ? _.first(_.values(orderRS.order.descriptions)).name
            : ''}
        </div>
      </div>
    </div>
  )
}

export default Flight
