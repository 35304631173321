import React, { useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import { useSelector } from 'react-redux'
import Icons from '../../pageComponents/icons'
import InputDropdown from '../_inputDropdown'
import GuestsDropdown from './_dropdown'
import GuestsController from './controller'

const Guests = () => {
  const { t } = useTranslation()
  const countAdaltsWithChilds = useSelector(state => state.hotel.guests.people)
  const controller = new GuestsController()
  const [inputFocus, setInputFocus] = useState(false)
  const searchForm = useSelector(state => state.searchForm)

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (inputFocus) {
          controller.handleOnBlur(setInputFocus, countAdaltsWithChilds)
        }
      }}
    >
      <div className='room'>
        <div className={'room__input ' + (inputFocus && 'room__input-focus')}>
          <Icons
            group='icon'
            icon='person'
          />
          <div className='room__label room__label-top'>
            {t('Guests')}
          </div>
          <div className='room__value'>
            <input
              type='text'
              className='room__value-input'
              autoComplete='off'
              onFocus={() => controller.handleOnFocus(setInputFocus)}
              readOnly
              value={controller.getValue(searchForm)}
            />
          </div>
        </div>
        <InputDropdown
          visible={searchForm.guests.visible}
          child={<GuestsDropdown />}
        />
      </div>
    </OutsideClickHandler>
  )
}

export default Guests
