import { ORDERS_SCREEN_PAST, ORDERS_SCREEN_UPCOMING, SEARCH_SCREEN } from 'reducers/page/types'
import { AitaOrder, AitaOrderFlight, AitaOrderHotel } from 'modules/orders/types'

// разделяем будущие и прошлые ордеры
export const checkDateOrder = (page, aitaOrder) => {
  let date
  if (checkIsFlight(aitaOrder)) {
    date = aitaOrder.outbound_segments[0]?.departure_date
  }
  if (checkIsHotel(aitaOrder)) {
    date = aitaOrder.date_from
  }
  return (
    ((page === ORDERS_SCREEN_UPCOMING || page === SEARCH_SCREEN) && date < new Date().getTime() / 1000) ||
      (page === ORDERS_SCREEN_PAST && date > new Date().getTime() / 1000)
  )
}

// Проверка, что это полет по aitaOrder
export const checkIsFlight = (aitaOrder: AitaOrder | null): aitaOrder is AitaOrderFlight => {
  return aitaOrder !== null && 'booking_reference' in aitaOrder && aitaOrder.booking_reference !== undefined
}

// Проверка, что это отлель по aitaOrder
export const checkIsHotel = (aitaOrder: AitaOrder | null): aitaOrder is AitaOrderHotel => {
  return aitaOrder !== null && 'hotel_id' in aitaOrder && aitaOrder.hotel_id !== undefined
}
