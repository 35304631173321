import LazyLoad from 'react-lazyload'
import { useSelector } from 'react-redux'
import ResultsAnalytics from '../../../../analytics/analyticsHotel/resultsAnalytics'
import { useIsVisibleElement } from '../../../../hooks/useIsVisibleElement'
import HotelElement from '../_hotelElement'

const HotelElementWrapper = ({ className, hotel, showExceeds }) => {
  const resultAnalytics = new ResultsAnalytics()
  const hotelData = useSelector(state => state.hotel)
  const searchRS = useSelector(state => state.searchRS)
  const [element] =
    useIsVisibleElement(resultAnalytics.showHotelFromList, 100, [hotel, hotelData, searchRS])

  if (hotel.b2bTravelPolicyStatus === 'exceeds' && !showExceeds) return ''

  return (
    <div
      className={className}
      ref={element}
    >
      <LazyLoad
        height={192}
        once
      >
        <HotelElement
          hotel={hotel}
          actionAnalytic={() => resultAnalytics.clickDetailInformation(hotel, hotelData, searchRS)}
        />
      </LazyLoad>
    </div>
  )
}

export default HotelElementWrapper
