import moment from 'moment'
import { ORDERS_SCREEN_PAST, ORDERS_SCREEN_UPCOMING } from '../../reducers/page/types'
import AnalyticsHotel from './index'

const replaceSpacesWithUnderscores = (string) => {
  return string.split(' ').join('_')
}

const generateString = (item) => {
  if (item.deleted) {
    return ''
  }
  return [
    `${item.source}$${replaceSpacesWithUnderscores(item.name)}`,
    `price=${item.aita_order.price}`,
    `currency=${item.aita_order.currency}`,
    moment(new Date(item.aita_order.date_from * 1000)).format('YYYY/MM/DD'),
    moment(new Date(item.aita_order.date_to * 1000)).format('YYYY/MM/DD'),
    `guests=${item.guests}`,
    `city=${item.city}`,
    `breakfast=${item.room?.breakfastIncl}`,
    `dinner=${item.room?.dinnerIncl}`,
    `lunch=${item.room?.lunchIncl}`,
    `freeWifi=${item.room?.freeWifi}`,
    `refundable=${item.room?.refundable}`,
  ].join(';')
}

export default class OrderListAnalytics extends AnalyticsHotel {
  seeOrdersPast = (counts) => {
    this.logEvent(
      this.NAMES.ORDER_LIST,
      this.FEATURE.PAST,
      'screen',
      this.ACTIONS.SEE,
      `${counts}` || '0'
    )
  }

  seeOrdersFuture = (counts) => {
    this.logEvent(
      this.NAMES.ORDER_LIST,
      this.FEATURE.FUTURE,
      'screen',
      this.ACTIONS.SEE,
      `${counts}` || '0'
    )
  }

  hotelItemFuture = (item) => {
    this.logEvent(
      this.NAMES.ORDER_LIST,
      this.FEATURE.FUTURE,
      'hotel',
      this.ACTIONS.SEE,
      generateString(item)
    )
  }

  hotelItemPast = (item) => {
    this.logEvent(
      this.NAMES.ORDER_LIST,
      this.FEATURE.PAST,
      'hotel',
      this.ACTIONS.SEE,
      generateString(item)
    )
  }
}
