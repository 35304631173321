import React from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import './style.sass'

const ItinerariesHeader = ({ headerText, date }) => {
  const { i18n } = useTranslation()
  return (
    <div className='itineraries__header'>
      {headerText}
      <Moment
        format='MMM DD'
        locale={i18n.language}
      >
        {date}
      </Moment>
    </div>
  )
}

export default ItinerariesHeader
