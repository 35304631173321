import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const SuccessConfirmation = () => {
  const { t } = useTranslation()
  const orderRS = useSelector(state => state.orderRS.model)
  return (
    <div className='successConfirmation'>
      <div className='successConfirmation__el'>
        <div className='name'>
          {t('Confirmation number')}
        </div>
        <div className='value'>
          {orderRS.order.order_id}
        </div>
      </div>
      <div className='successConfirmation__el'>
        <div className='name'>
          {t('Pin')}
        </div>
        <div className='value'>
          {orderRS.order.pin}
        </div>
      </div>
    </div>
  )
}

export default SuccessConfirmation
