import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SaveState from '../saveState'

const Footer = () => {
  const { t } = useTranslation()
  const user = useSelector(state => state.user)
  return (
    <div className='footer'>
      <div className='container'>
        <div className='footer__link'>
          <a
            href='https://www.appintheair.mobi/privacypolicy'
            target='blank'
            className='footer__link-el'
          >
            {t('Privacy policy')}
          </a>
          <a
            href='https://support.appintheair.com/'
            target='blank'
            className='footer__link-el'
          >
            {t('Help')}
          </a>
          <a
            href={
              'mailto:support@appintheair.com?subject=b2b' +
              (Object.values(user.profile).length ? ' ' + user.profile.info.name : '')
            }
            target='blank'
            className='footer__link-el'
          >
            {t('Contact us')}
          </a>
        </div>
      </div>
      <SaveState />
    </div>
  )
}

export default Footer
