import { useEffect, useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import { useSelector, useDispatch } from 'react-redux'
import 'rsuite/dist/styles/rsuite-default.css'
import PriceFilterController from './controller'
import currentLocation from '../../../../hooks/currentLocation'
import Icons from '../../../pageComponents/icons'
import Checkbox from '../../../common/checkbox'
import { setPriceHotels } from '../../../../reducers/filter/actions'
import { RangeSlider } from 'rsuite'

const HotelPrice = ({ setMouseUp }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const filterPrice = useSelector(state => state.filter.price)
  const filterPriceHotels = useSelector(state => state.filter.hotelsPrice)
  const controller = new PriceFilterController()
  const { isHotelsPage } = currentLocation()
  const [isPrice, setIsPrice] = useState(false)
  const prices = controller.getPrice('number')
  const pricesString = controller.getPrice()
  const [isSliderOpen, setIsSliderOpen] = useState(false)

  useEffect(() => {
    controller.changeActiveFilter(filterPriceHotels, filterPrice, setIsPrice)
  }, [filterPriceHotels, filterPrice])

  useEffect(() => {
    dispatch(setPriceHotels(prices))
  }, [prices.length])

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (open) {
          setOpen(false)
        }
      }}
    >
      <div className={'price filter price__hotels ' + (open && 'filter-open')}>
        <div
          className={`price__value filter__el ${isPrice ? 'filter__active' : ''}`}
          onClick={() => {
            setOpen(!open)
          }}
        >
          {t('Price')}
          <Icons
            group='arrow'
            icon='down'
            color='#8F8F8F'
            className='filter__el-icon'
          />
          <a
            className='cross__icon-wrapper'
            onClick={controller.handleClearFilter}
          >
            <Icons
              group='icon'
              icon='close'
              className='wifi__label-icon'
              color='#3D73EC'
            />
          </a>
        </div>
        <div className='price__list filter__list'>
          <div className='filter__list-wrap'>
            <div className='list__header'>
              <div className='left'>
                <div className='list__header-time'>
                  {t('Budget per night')}
                </div>
              </div>
              <button
                className='right clear'
                onClick={controller.handleClearFilterCheckbox}
              >
                {t('Clear')}
              </button>
            </div>
            {pricesString.map((price, index) => (
              price.end
                ? <Checkbox
                    key={index}
                    text={`${price.start} - ${price.end}`}
                    checked={filterPriceHotels[index]?.isChecked}
                    onChange={() => controller.handleChangeFilterCheckbox(filterPriceHotels[index])}
                  />
                : <Checkbox
                    key={index}
                    text={`${price.start}`}
                    checked={filterPriceHotels[index]?.isChecked}
                    onChange={() => controller.handleChangeFilterCheckbox(filterPriceHotels[index])}
                  />
            )
            )}
          </div>
          <button
            className={`content__opener-button ${isSliderOpen ? 'open' : ''}`}
            onClick={() => controller.closeSlider(setIsSliderOpen)}
          >
            {t('Set own limit')}
            <Icons
              group='arrow'
              icon='down'
              color='#9CBBFD'
              className='content__opener-icon'
            />
          </button>
          {isSliderOpen &&
            <div className='price__hotels-rangeSlider'>
              <div className='list__header'>
                <div className='left'>
                  {t('Budget per night')}
                  <div className='list__header-time'>
                    {controller.getPriceFormat(filterPrice.value[0], filterPrice.currency)}
                    {' - '}
                    {controller.getPriceFormat(filterPrice.value[1], filterPrice.currency)}

                  </div>
                </div>
                <button
                  className='right clear'
                  onClick={controller.handleClearFilterSlider}
                >
                  {t('Clear')}
                </button>
              </div>
              <div
                className='price__slider'
                onMouseUp={() => {
                  if (isHotelsPage) {
                    setMouseUp(true)
                  }
                }}
              >
                <RangeSlider
                  barClassName=''
                  value={[filterPrice.value[0], filterPrice.value[1]]}
                  min={filterPrice.limit[0] - 1}
                  max={filterPrice.limit[1] + 1}
                  tooltip={false}
                  step={filterPrice.currency === 'RUB' ? 100 : 1}
                  onChange={controller.handleChangeFilterSlider}
                />
              </div>
            </div>}
        </div>
      </div>
    </OutsideClickHandler>
  )
}
export default HotelPrice
