import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import Icons from '../../../../pageComponents/icons'
import _ from 'lodash'

const MainInformationLocation = ({ hotel }) => {
  const { t } = useTranslation()
  const metroStations = _.sortBy(hotel.metroStations, [metroStation => metroStation.distance.value])
  if (!_.size(metroStations)) return ''
  const metroStation = _.first(metroStations)
  return (
    <div className='mainInformationLocation'>
      <div className='mainInformationLocation__header'>
        {t('Location')}
      </div>

      <div className='locationElement'>
        <div className='locationElement__type'>
          <Icons
            group='icon'
            icon='subway'
          />
          {metroStation?.stationName}
        </div>
        <div className='locationElement__value'>
          {metroStation?.distance?.value}{metroStation?.distance?.unit}
        </div>
      </div>

    </div>
  )
}

export default MainInformationLocation
