import './style.sass'
const ButtonBlue = ({ text = '', addedClass = '', onClick }) => {
  return (
    <button
      onClick={onClick}
      className={addedClass}
    >
      {text}
    </button>
  )
}

export default ButtonBlue
