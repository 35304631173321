import AnalyticsFlight from './index'

export default class FareAnalytics extends AnalyticsFlight {
  changeFlight = (type) => {
    const element = type === 'outbound' ? 'changeOutboundFlightButton' : 'changeInboundFlightButton'

    this.logEvent(
      this.NAMES.FARES,
      this.FEATURE.FLIGHT,
      element,
      this.ACTIONS.TAP
    )
  }

  clickDetail = (type, isOpen) => {
    const element = isOpen
      ? type === 'outbound'
        ? 'showMoreOutboundFlightButton'
        : 'showMoreInboundFlightButton'
      : type === 'outbound'
        ? 'showLessOutboundFlightButton'
        : 'showLessInboundFlightButton'

    this.logEvent(
      this.NAMES.FARES,
      this.FEATURE.FLIGHT,
      element,
      this.ACTIONS.TAP
    )
  }

  selectFare = () => {
    this.logEvent(
      this.NAMES.FARES,
      this.FEATURE.MAIN,
      'selectFares',
      this.ACTIONS.TAP
    )
  }

  successFare = (fare, price) => {
    this.logEvent(
      this.NAMES.FARES,
      this.FEATURE.MAIN,
      'successButton',
      this.ACTIONS.TAP,
      [fare.title, price.value].join(';')
    )
  }
}
