import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import usePrice from '../../../hooks/usePrice'

const BookingReference = () => {
  const { t } = useTranslation()
  const orderModel = useSelector(state => state.orderRS.model)
  const isDetailOrder = orderModel.aita_order
  let price = {}
  isDetailOrder
    ? price.value = '$' + orderModel.aita_order.price
    : price = usePrice(
      useSelector(state => state.searchRS.model.currencies),
      useSelector(state => state.orderFlights.pricingOption)
    )

  const currency = useSelector(state => state.searchForm.currency)
  return (
    <div className='bookingReference'>
      <div className='booking'>
        <div className='booking__header'>
          {t('booking reference')}
        </div>
        <div className='booking__value'>
          {orderModel.order.bookingReference}
        </div>
      </div>
      <div className='orderAmount'>
        <div className='orderAmount__header'>
          {t('order amount')}
        </div>
        <div className='orderAmount__value'>
          {price.value}
          {currency !== 'USD' && price.valueUSD &&
            <span>
              {price.valueUSD}
            </span>}
        </div>
      </div>
    </div>
  )
}

export default BookingReference
