import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import InfoElement from './_infoElement'
import RoomDetails from './_roomDetails'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import _ from 'lodash'
import RoomFilters from './_roomFilters'

const HotelRooms = () => {
  const { t, i18n } = useTranslation()
  const hotelState = useSelector(state => state.hotel)
  moment.locale(i18n.language)

  if (!_.size(hotelState.choice)) return null

  return (
    <>
      <div className='hotelRooms' id='Rooms'>
        <div className='hotelRooms__header'>{t('Rooms')}</div>

        <div className='hotelRooms__info'>
          {hotelState.choice.checkInTime &&
            <InfoElement
              header={t('check in')}
              text={moment(hotelState.checkinDate).format('MMM D, ddd')}
              small={
              hotelState.choice.checkInTime.from
                ? t('after') + ' ' + hotelState.choice.checkInTime.from
                : null
            }
            />}
          {hotelState.choice.checkOutTime &&
            <InfoElement
              header={t('check out')}
              text={moment(hotelState.checkoutDate).format('MMM D, ddd')}
              small={
              hotelState.choice.checkOutTime.until
                ? t('before') + ' ' + hotelState.choice.checkOutTime.until
                : null
            }
            />}
          <InfoElement
            header={t('guests')}
            text={hotelState.guests.value}
          />
          {/*<button*/}
          {/*  className='change'*/}
          {/*  onClick={() => controller.handleOnClickChange()}*/}
          {/*>*/}
          {/*  {t('Change search params')}*/}
          {/*</button>*/}
        </div>
        <RoomFilters />
      </div>
      <RoomDetails />
    </>
  )
}

export default HotelRooms
