import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Checkbox from '../_checkbox'
import PropertyTypeController from './controller'
import SelectClearFilter from '../../selectClearFilter'

const PropertyType = () => {
  const { t } = useTranslation()
  const propertyTypes = useSelector(state => state.filter.propertyTypes)
  const sortProperty = _.sortBy(propertyTypes, ['name'])
  const controller = new PropertyTypeController()

  return (
    <div className='propertyType'>
      <div className='list__header'>
        {t('Property Type')}
      </div>
      <SelectClearFilter
        addedClass='propertyType__otherFilter' textOne={t('Select all')} textTwo={t('Clear all')}
        controller={controller} propertyes={propertyTypes}
      />
      <div className='list__el'>
        {_.map(sortProperty, (property, index) =>
          <Checkbox
            key={'property' + property.type}
            filter={propertyTypes}
            id={'property' + property.type}
            type={property.type}
            name={property.name}
            checked={property.checked}
            index={index}
            onChange={controller.handleOnChangeElement}
          />
        )}
      </div>
    </div>
  )
}

export default PropertyType
