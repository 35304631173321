import Analytics from '../index'
import { SEARCH_SCREEN } from '../../reducers/page/types'

export default class AnalyticsFlight extends Analytics {
  NAMES = {
    SEARCH: 'b2b_bf_search',
    HOTELS: 'b2b_bf_hotels',
    OUTBOUND: 'b2b_bf_outbound',
    INBOUND: 'b2b_bf_inbound',
    FARES: 'b2b_bf_fares',
    CHECKOUT: 'b2b_bf_checkout',
    ERROR: 'b2b_bf_error',
    PROFILE: 'b2b_bf_profile',
    TRIPS: 'b2b_bf_trips'
  }

  FEATURE = {
    MAIN: 'main',
    FILTERS: 'filters',
    RESULTS: 'results',
    FLIGHT: 'flightInfo',
    PASSENGER: 'passenger',
    NEW_PASSENGER: 'newPassenger',
    NEW_CARD: 'newCard',
    PAYMENT: 'payment',
    PROFILE: 'profile',
    TRIPS: {
      MAIN: 'trips_main',
      UPCOMING: 'trips_upcoming',
      PAST: 'trips_past',
    }
  }

  ACTIONS = {
    TAP: 'tap',
    SEE: 'see',
    SUCCESS: 'success',
    ERROR: 'error'
  }

  getName = (hasOutbound, page = undefined) => {
    return page === SEARCH_SCREEN
      ? this.NAMES.SEARCH
      : hasOutbound
        ? this.NAMES.INBOUND
        : this.NAMES.OUTBOUND
  }
}
