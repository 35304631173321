import { useMemo } from 'react'

import { ORDERS_SCREEN_PAST, ORDERS_SCREEN_UPCOMING } from 'reducers/page/types'
import { usePage } from 'modules/common'

import { AitaOrder, OrderType } from '../types'
import { checkIsFlight, checkIsHotel } from '../utils/checks'

export const useSeparateOrders = (orders: OrderType[]) => {
  const { name } = usePage()

  const list = useMemo(() => orders.filter(order => {
    const flights = order?.flights?.[0]
    const hotels = order?.hotels?.[0]
    const orderDetail = flights || hotels
    if (!orderDetail) {
      return false
    }
    return shouldShowOrder(name, orderDetail.aita_order)
  }), [name, orders])

  return list
}

// разделяем будущие и прошлые ордеры
const shouldShowOrder = (page: string, aitaOrder: AitaOrder) => {
  let date: number
  if (checkIsFlight(aitaOrder)) {
    date = aitaOrder.outbound_segments[0]?.departure_date
  }
  if (checkIsHotel(aitaOrder)) {
    date = aitaOrder.date_from
  }

  return (
    (page === ORDERS_SCREEN_UPCOMING && date > Date.now() / 1000) ||
      (page === ORDERS_SCREEN_PAST && date < Date.now() / 1000)
  )
}
