import React from 'react'
import LazyLoad from 'react-lazyload'
import { useTranslation } from 'react-i18next'

import Icons from 'components/pageComponents/icons'
import { OrderType } from 'modules/orders/types'

import {
  getBookRef,
  getCitiesName,
  getDate,
  getIcon,
  getOrderDetails,
  getPeople,
  getPrice
} from './listElement.utils'
import 'moment/locale/ru'
import 'moment/locale/en-gb'

import './style.sass'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../reducers'
import { ORDERS_SCREEN_UPCOMING, ORDERS_SCREEN_PAST } from '../../../../reducers/page/types'
import Loader from '../../../../components/pageComponents/loader'

const RELEASE_DATE = 1665764550
const DATE_NOW = Date.now() / 1000

interface ListElementProps {
    order: OrderType
    onLinkClick?: () => void
    isHidden?: boolean
    isHotel?: boolean
    onRequestHelp?: () => void
}

export const ListElement: React.FC<ListElementProps> =
    React.memo(({ order, onLinkClick, isHotel, isHidden, onRequestHelp }) => {
      const { t, i18n } = useTranslation()
      const { orderDetail, flights } = getOrderDetails(order, isHotel)
      const aitaOrder = orderDetail.aita_order
      const loadBar = useSelector((state: RootState) => state.loadBar)

      if (isHidden) return null
      return (
        <LazyLoad
          height={82}
          once
        >
          <div
            className='order__element'
          >
            <div className='order__element-carrier'>
              {!isHotel && flights.airline.logo_url
                ? <img src={flights.airline.logo_url} alt='' />
                : <Icons
                    group='icon'
                    icon={getIcon(aitaOrder)}
                  />}
            </div>
            <div className='order__element-info'>
              <div className='direction'>
                {getCitiesName(orderDetail)}
              </div>
              <div className='description'>
                <div className='description__el'>
                  {getDate(aitaOrder, i18n)}
                </div>
                <div className='description__el'>
                  {getPeople(aitaOrder)}
                </div>
                <div className='description__el'>
                  {getBookRef(aitaOrder, t)}
                </div>
              </div>
            </div>
            <div className='order__element-price'>
              <div className='price'>
                {getPrice(aitaOrder)}
              </div>
              <div className='order__element-price-wrapper'>
                {isHotel && _.first(order.hotels)?.added > RELEASE_DATE &&
                            DATE_NOW > _.first(order.hotels)?.aita_order?.date_to &&
                              <button
                                className='link'
                                onClick={onRequestHelp}
                              >
                                {(loadBar.show === ORDERS_SCREEN_UPCOMING || loadBar.show === ORDERS_SCREEN_PAST)
                                  ? <Loader color='#3D72EC' />
                                  : t('Request help')}
                              </button>}
                <button
                  className='link'
                  onClick={onLinkClick}
                >
                  {t('Order details')}
                </button>
              </div>
            </div>
          </div>
        </LazyLoad>
      )
    })
