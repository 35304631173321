import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import 'moment-timezone'
import { useSelector } from 'react-redux'
import Icons from '../../pageComponents/icons'

const SuccessDate = () => {
  const { t } = useTranslation()
  const orderRS = useSelector(state => state.orderRS.model)
  Moment.globalTimezone = 'America/Danmarkshavn'
  return (
    <div className='successDate'>
      <div className='successDate__el'>
        <div className='date'>
          <Moment unix format='MMM DD, ddd'>
            {orderRS.order.date_from}
          </Moment>
        </div>
        <div className='time'>
          {t('Check-in from')}
          <Moment unix format=' LT'>
            {orderRS.order.date_from}
          </Moment>
        </div>
      </div>
      <Icons
        group='arrow'
        icon='next'
        className='successDate__icon'
      />
      <div className='successDate__el'>
        <div className='date'>
          <Moment unix format='MMM DD, ddd'>
            {orderRS.order.date_to}
          </Moment>
        </div>
        <div className='time'>
          {t('Check-out until')}
          <Moment unix format=' LT'>
            {orderRS.order.date_to}
          </Moment>
        </div>
      </div>
    </div>
  )
}

export default SuccessDate
