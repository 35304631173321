import _ from 'lodash'
import { RoomOptionsInitialState } from '../../../../../context/RoomOptionsContext/types'

import { RoomType, OptionType } from '../../../../../reducers/order/types'
import { checkObjectValues } from '../../../../../utils/checkObjectValues'

export default class RoomDetailsController {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    private readonly rooms?: RoomType[],
    private readonly options?: RoomOptionsInitialState
  ) {}

    getFilteredRooms = () => {
      return _.map(this.rooms, item => {
        const options = item.options
          .filter(this.checkRefundable)
          .filter(this.checkMeal)
          .filter(this.checkPaymentOptions)
        return { ...item, options }
      }).filter(item => item.options.length > 0)
    }

    private checkRefundable = (room: OptionType) => {
      if (this.options.refundable) {
        return this.options.refundable && room.refundable
      }
      return true
    }

    private checkMeal = (room: OptionType) => {
      if (checkObjectValues(this.options?.meal)) {
        if (this.options?.meal.withoutMeal) {
          return this.options?.meal.withoutMeal && !(room.breakfastIncl || room.dinnerIncl || room.dinnerIncl)
        }
        return ((this.options?.meal.breakfastIncl && room.breakfastIncl) ||
                    (this.options?.meal.dinnerIncl && room.dinnerIncl) ||
                    (this.options?.meal.lunchIncl && room.lunchIncl))
      }
      return true
    }

    private checkPaymentOptions = (room: OptionType) => {
      if (checkObjectValues(this.options?.paymentOptions)) {
        return ((this.options?.paymentOptions.withPrepay && room.prepayRequired) ||
        (this.options?.paymentOptions.withoutPrepay && !room.prepayRequired))
      }
      return true
    }
}
