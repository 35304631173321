import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SpecialRequestDropDownController } from './controller'

export const SpecialRequestDropDown = ({ items, handleCheckText }) => {
  const { t } = useTranslation()
  const orderHotel = useSelector(state => state.orderHotels)
  const email = orderHotel.booker.email

  const {
    replaceEmail
  } = SpecialRequestDropDownController()
  return (
    <div className='specialRequestDropdownWrapper'>
      {items.map(({ text, id }) => {
        return (
          <div
            key={id}
            onClick={() => handleCheckText(t(text), id, email)}
            className='inputDropdown-el'
          >
            {replaceEmail(t(text), id, email)}
          </div>
        )
      })}
    </div>
  )
}
