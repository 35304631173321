import _ from 'lodash'
import React, { useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import HotelScreenAnalytics from '../../../../../../../analytics/analyticsHotel/hotelScreenAnalytics'
import { useSelector } from 'react-redux'

const RoomDetailAmenities = ({ room }) => {
  const { t } = useTranslation()
  const orderHotels = useSelector(state => state.orderHotels)
  const analyticsHotel = new HotelScreenAnalytics()
  const FIRST_AMENITIES = 3
  const ALL_AMENITIES = undefined
  const [slice, setSlice] = useState(FIRST_AMENITIES)
  const option = _.first(room.options)
  const amenities = room.amenities
  return (
    <div className='roomDetailAmenities'>
      {option?.surface &&
        <div className='roomDetailAmenities__label'>
          {t('sq.meters', { count: option.surface })}
        </div>}
      {_.map(amenities.slice(0, slice), amenity =>
        <div
          key={'informationLabel' + amenity}
          className='roomDetailAmenities__label'
        >
          {amenity}
        </div>
      )}
      {_.size(amenities) > FIRST_AMENITIES &&
        <button
          className='roomDetailAmenities__more'
          onClick={() => {
            setSlice(slice !== FIRST_AMENITIES ? FIRST_AMENITIES : ALL_AMENITIES)
            slice === FIRST_AMENITIES && analyticsHotel.clickShowAboutRoom(orderHotels, room)
          }}
        >
          {slice === FIRST_AMENITIES
            ? t('Show all')
            : t('Show less')}

        </button>}
    </div>
  )
}

export default RoomDetailAmenities
