import { UPDATE_USER } from './actions'
import { CLIENT_MODE_PERSONAL } from './constans'

const defaultState = {
  model: {},
  passengers: [],
  profile: {},
  business: {},
  clientMode: CLIENT_MODE_PERSONAL
}

const user = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        [action.field]: action.payload
      }
    default:
      return state
  }
}

export default user
