import React from 'react'
import HotelsList from '../../../components/hotelsComponents/_hotelsList'
import SearchScreen from '../../screen/searchScreen'

const HotelsResultsPage = () => {
  return (
    <div className='hotels__page'>
      <SearchScreen />
      <div className='container'>
        <HotelsList />
      </div>
    </div>
  )
}

export default HotelsResultsPage
