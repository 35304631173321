import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SuccessController from '../controller'

const Passengers = () => {
  const { t } = useTranslation()
  const controller = new SuccessController()
  const passengers = useSelector(state => state.orderRS.model.order.passengers)
  return (
    <div className='passengersOrder'>
      <div className='passengersOrder__header'>
        {t('Passengers')}
      </div>
      {passengers.map((passenger, index) => {
        return (
          <div
            key={'passenger' + index}
            className='passengersOrder__row'
          >
            {passenger.title ? controller.getUpperCaseFirstLatter(passenger.title) + '. ' : ''}
            {`${controller.getUpperCaseFirstLatter(passenger.name)} `}
            {`${controller.getUpperCaseFirstLatter(passenger.surname)}`}
          </div>
        )
      })}
    </div>
  )
}

export default Passengers
