import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { ORDERS_PAGE_PAST, ORDERS_PAGE_UPCOMING } from 'navigation/routes'

import { Tab } from './tab/tab'

import './style.sass'

export const Tabs = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  return (
    <div className='tab'>
      <Tab to='/orders/upcoming' active={pathname.includes(ORDERS_PAGE_UPCOMING)}>
        {t('Upcoming')}
      </Tab>
      <Tab to='/orders/past' active={pathname.includes(ORDERS_PAGE_PAST)}>
        {t('Past')}
      </Tab>
    </div>
  )
}
