import React from 'react'
import './style.sass'

const RoomDetailName = ({ name }) => {
  return (
    <div className='roomDetailName'>
      {name}
    </div>
  )
}

export default RoomDetailName
