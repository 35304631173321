import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import HotelScreenAnalytics from '../../../../../../../../analytics/analyticsHotel/hotelScreenAnalytics'
import Api from '../../../../../../../../api'
import { useRoomOptions } from '../../../../../../../../context'
import { HOTELS_CONFIRMATION_PAGE, HOTELS_RESULTS_DETAIL_PAGE } from '../../../../../../../../navigation/routes'
import { updateHotel } from '../../../../../../../../reducers/hotel/actions'
import { showLoadBar } from '../../../../../../../../reducers/loadBar/actions'
import { updateOrderHotels } from '../../../../../../../../reducers/order/actions'
import { updateUser } from '../../../../../../../../reducers/user/actions'

export default class OptionReserveController {
  constructor () {
    this.api = new Api()
    this.dispatch = useDispatch()
    this.history = useHistory()
    this.orderHotels = useSelector(state => state.orderHotels)
    this.analyticsHotel = new HotelScreenAnalytics()
    this.roomFilters = useRoomOptions().state
  }

  handleOnClickReserve = (room, option, clientMode) => {
    window.localStorage.setItem('roomOptions', JSON.stringify({ ...this.roomFilters, prev: null }))
    this.dispatch(updateHotel('reserve', { room, option }))
    this.dispatch(updateOrderHotels('room', option.key))
    this.dispatch(updateOrderHotels('price', option.price.convertedValue || option.price.value))
    this.dispatch(showLoadBar(HOTELS_RESULTS_DETAIL_PAGE))
    this.api.getPassengers(clientMode)
      .then((res) => {
        if (res) {
          this.dispatch(updateUser('passengers', res))
          this.dispatch(showLoadBar(false))
          this.analyticsHotel.clickSelectRoom(this.orderHotels, option)
          this.history.push(HOTELS_CONFIRMATION_PAGE)
        }
      })
  }
}
