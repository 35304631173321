import moment from 'moment'

export default function getTimeFormat (time, t) {
  const duration = moment.duration(time, 'minutes')
  if (duration.asMilliseconds() === 0) {
    return '0'
  }
  return (
    (duration.days() ? duration.days() + t('d') + ' ' : '') +
    (duration.hours() ? duration.hours() + t('h') + ' ' : '') +
    (duration.minutes() ? duration.minutes() + t('m') : '')
  )
}

