import React, { useEffect, useLayoutEffect, useState } from 'react'
import './style.sass'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import HotelsElements from './_hotelsElements'
import HotelsMap from './_hotelsMap'
import HotelsNotFound from './_notFound'
import HotelsListController from './controller'
import ResultsAnalyticsHotel from '../../../analytics/analyticsHotel/resultsAnalytics'


const HotelsList = () => {
  const controller = new HotelsListController()
  const resultAnalyticsHotel = new ResultsAnalyticsHotel()
  const [fullMap, setFullMap] = useState(false)
  const searchRS = useSelector(state => state.searchRS)
  const location = useLocation()
  const [sendAnalytics, setSendAnalytics] = useState(false)
  const user = useSelector(state => state.user)

  useEffect(() => {
    if (!searchRS.count) {
      controller.firstMount(user)
    }
  }, [location.search])

  useEffect(() => {
    if (searchRS.count > 0 && !sendAnalytics) {
      resultAnalyticsHotel.showHotels(searchRS.count)
      setSendAnalytics(true)
    }
  }, [searchRS.count])

  return (
    <div className='hotelsList'>
      {searchRS.status === 'success' && !searchRS.count
        ? <HotelsNotFound />
        : <HotelsElements fullMap={fullMap} />}
      <HotelsMap fullMap={fullMap} setFullMap={setFullMap} />
    </div>
  )
}

export default HotelsList
