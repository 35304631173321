import { useSelector } from 'react-redux'
import AnalyticsFlight from './index'


export default class ResultsAnalytics extends AnalyticsFlight {
  constructor () {
    super()
    this.hasOutbound = useSelector(state => state.itineraries.outbound)
  }

  showFlights = (itineraries) => {
    this.logEvent(
      this.getName(this.hasOutbound),
      this.FEATURE.RESULTS,
      'flights',
      this.ACTIONS.SEE,
      typeof itineraries === 'string' ? itineraries : (Object.values(itineraries).length) ? 'true' : 'false'
    )
  }

  clickDetailInformation = (isShow) => {
    this.logEvent(
      this.getName(this.hasOutbound),
      this.FEATURE.RESULTS,
      'showMoreButton',
      this.ACTIONS.TAP,
      isShow ? 'show' : 'hide'
    )
  }

  selectFlight = (isDetail, segments) => {
    this.logEvent(
      this.getName(this.hasOutbound),
      this.FEATURE.RESULTS,
      isDetail ? 'selectFlightButton' : 'selectFlight',
      this.ACTIONS.TAP,
      segments.join(';')
    )
  }

  clickChangeOutbound = () => {
    this.logEvent(
      this.getName(this.hasOutbound),
      this.FEATURE.RESULTS,
      'changeOutboundButton',
      this.ACTIONS.TAP,
    )
  }
}
