import { createReducer } from '@reduxjs/toolkit'

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf())
  date.setDate(date.getDate() + days)
  return date
}

const defaultState = {
  origin: {
    value: '',
    name: '',
    city: '',
    country: '',
    code: '',
    showInput: false,
    autocomplete: {
      visible: false,
      model: {
        airports: [],
        cities: []
      }
    },
    error: false
  },
  destination: {
    value: '',
    name: '',
    city: '',
    country: '',
    code: '',
    showInput: false,
    autocomplete: {
      visible: false,
      model: {
        airports: [],
        cities: []
      }
    },
    error: false
  },
  whereStay: {
    value: '',
    stay: {},
    autocomplete: {
      visible: false,
      model: {
        items: {},
        cities: {}
      }
    },
    error: false,
  },
  calendar: {
    visible: false,
    start: null,
    end: null,
    error: false
  },
  passengers: {
    visible: false,
    count: {
      ADT: 1,
      CHD: 0,
      INF: 0
    },
    value: '1;0;0'
  },
  guests: {
    visible: false,
    value: 'A',
    rooms: [
      {
        adults: 1,
        children: []
      }
    ]
  },
  currency: 'RUB',
  cabin: {
    name: 'Economy',
    value: 'e'
  },
  country: 'RU',
  language: '',
}

const searchForm = createReducer(defaultState, {
  SET_SEARCH_FORM: (state, action) => {
    state.whereStay = action.payload.whereStay
    state.guests = action.payload.guests
    state.calendar = action.payload.calendar
  },
  UPDATE_SEARCH_FORM_AUTOCOMPLETE: (state, action) => {
    if (state[action.payload.name].autocomplete !== undefined) {
      state[action.payload.name].autocomplete[action.payload.type] = action.payload.value
    }
  },
  UPDATE_SEARCH_FORM: (state, action) => {
    if (state[action.payload.name][action.payload.field] !== undefined) {
      state[action.payload.name][action.payload.field] = action.payload.value
    } else {
      state[action.payload.name] = action.payload.field
    }
  },
  CHANGE_DIRECTION: (state) => {
    const oldOrigin = state.origin
    state.origin = state.destination
    state.destination = oldOrigin
  },
  // CLEAR_STATE: () => {
  //   return defaultState
  // }

})

export default searchForm
