import React from 'react'
import './style.sass'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useSelector } from 'react-redux'
import MapboxMap from '../../../pageComponents/mapBox'
import HotelsListController from '../controller'
import FullMap from './_fullMap'

const HotelsMap = ({ fullMap, setFullMap }) => {
  const hotelsStay = useSelector(state => state.hotel.stay)
  const latitude = hotelsStay?.latitude || 0
  const longitude = hotelsStay?.longitude || 0
  const searchRS = useSelector(state => state.searchRS)

  const filter = useSelector(state => state.filter)
  const controller = new HotelsListController()
  const hotels = controller.getHotelsList(searchRS.model, filter)
  return (
    <div className={'hotelsMap ' + (fullMap && 'hotelsMap__open')}>
      <MapboxMap
        initialOptions={{
          center: [longitude, latitude],
        }}
        hotels={hotels}
        fullMap={fullMap}
        search
      />
      <FullMap fullMap={fullMap} setFullMap={setFullMap} />
    </div>
  )
}

export default HotelsMap
