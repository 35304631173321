import React, { useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Icons from '../../pageComponents/icons'
import InputDropdown from '../_inputDropdown'
import WhereStayController from './controller'
import _ from 'lodash'

const WhereStay = () => {
  const { t } = useTranslation()
  const [inputFocus, setInputFocus] = useState(false)
  const controller = new WhereStayController()
  const whereStay = useSelector(state => state.searchForm.whereStay)

  return (
    <div className='whereStay'>
      <div
        className={
          'whereStay__input ' +
          (inputFocus && ' whereStay__input-focus ') +
          (whereStay.error && ' whereStay__input-error ')
        }
      >
        <div
          className={'whereStay__label ' + (controller.isInputActive(inputFocus, whereStay) && 'whereStay__label-top')}
        >
          {controller.isInputActive(inputFocus, whereStay) ? t('Location') : t('Where to stay') + '?'}
        </div>
        <div className='whereStay__value'>
          <input
            type='text'
            className='whereStay__value-input'
            autoComplete='off'
            value={controller.getValue(whereStay, inputFocus)}
            onChange={controller.handleOnChange}
            onFocus={() => controller.handleOnFocus(setInputFocus)}
            onBlur={() => controller.handleOnBlur(setInputFocus)}
          />
        </div>
      </div>
      <InputDropdown
        visible={whereStay.autocomplete.visible}
        child={_.map(whereStay.autocomplete.model.items, item =>
          <div
            key={item.id}
            className='inputDropdown-el'
            onClick={() => {
              controller.handleOnClick(item)
            }}
          >
            <Icons
              className='icon'
              group='icon'
              icon='city'
            />
            <div className='description'>
              <span className='description__name'>
                {item.name}
              </span>
              <span className='description__city'>
                {item.countryName}
              </span>
            </div>
          </div>
        )}
      />
      {whereStay.error &&
        <div className='whereStay__error'>
          {whereStay.error}
        </div>}
    </div>
  )
}

export default WhereStay
