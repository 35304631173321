import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Api from '../../../api'
import UseSavedState from '../../../hooks/useSavedState'
import { updateSearchFormAutocomplete, updateSearchForm, changeDirection } from '../../../reducers/search/actions'
import defaultDirection from '../../../data/defaultDirection'
import SearchAnalytic from '../../../analytics/analyticsFlight/searchAnalytics'
import reverseKeyboard from '../../../hooks/reverseKeyboard'

export default class DirectionFlightController {
  constructor () {
    this.api = new Api()
    this.dispatch = useDispatch()
    this.selector = useSelector
    this.lang = useTranslation()
    this.analytics = new SearchAnalytic()
    this.i18n = useTranslation().i18n
  }

  setDefaultData = (type, field) => {
    if (this.i18n.language.includes('ru') && field.name === '' && !UseSavedState()) {
      Object.keys(defaultDirection[type]).forEach(directionField => {
        this.dispatch(updateSearchForm(type, directionField, this.lang.t(defaultDirection[type][directionField])))
      })
    }
  }

  getAutocomplete = (e) => {
    let cycle = 0
    const getRequest = (value, name) => {
      this.api.autocomplete(value, this.lang.i18n.language)
        .then(result => {
          if (result.airports && result.cities) {
            if (!result.airports.length && !result.cities.length && cycle < 1) {
              cycle += 1
              const reverseKeyboardText = reverseKeyboard(e.target.value)
              getRequest(reverseKeyboardText, name)
            }
            this.dispatch(updateSearchFormAutocomplete(name, 'model', result))
          }
        })
        .catch(() => {})
    }

    getRequest(e.target.value, e.target.name)
  }

  handleOnChange = (e) => {
    this.dispatch(updateSearchForm(e.target.name, 'value', e.target.value))
    this.dispatch(updateSearchForm(e.target.name, 'error', false))
  }

  handleOnFocus = (e) => {
    this.dispatch(updateSearchFormAutocomplete(e.target.name, 'visible', true))
  }

  handleOnBlur = (e) => {
    setTimeout(() => {
      this.dispatch(updateSearchForm(e.target.name, 'showInput', false))
      this.dispatch(updateSearchFormAutocomplete(e.target.name, 'visible', false))
    }, 500)
  }

  handleOnClickOption = (nameInput, element) => {
    if (element.type === 'airport') {
      this.dispatch(updateSearchForm(nameInput, 'name', element.name))
      this.dispatch(updateSearchForm(nameInput, 'city', element.city.name))
      this.dispatch(updateSearchForm(nameInput, 'country', element.country.name))
      this.dispatch(updateSearchForm(nameInput, 'code', element.iata))
    } else {
      this.dispatch(updateSearchForm(nameInput, 'name', element.name))
      this.dispatch(updateSearchForm(nameInput, 'city', element.name))
      this.dispatch(updateSearchForm(nameInput, 'country', element.country.name))
      this.dispatch(updateSearchForm(nameInput, 'code', element.code))
    }
    this.dispatch(updateSearchForm(nameInput, 'error', false))
    this.dispatch(updateSearchFormAutocomplete(nameInput, 'visible', false))
    this.analytics.setAirport(nameInput, element.iata || element.code)
  }

  handleOnClickInput = (nameInput, option) => {
    if (nameInput === 'origin' || nameInput === 'destination') {
      this.dispatch(updateSearchForm(nameInput, 'value', ''))
      this.dispatch(updateSearchForm(nameInput, 'showInput', true))
      option.focus()
    }
    if (nameInput === 'passengers') {
      this.dispatch(updateSearchForm(nameInput, 'visible', !option))
    }
  }

  handleOnClickChangeDirection = () => {
    this.dispatch(changeDirection())
  }
}
