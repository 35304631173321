import { useEffect, useRef } from 'react'

import { useAppSelector } from 'reducers'
import OrderListAnalytics from 'analytics/analyticsHotel/orderListAnalytics'
import { ORDERS_SCREEN_PAST, ORDERS_SCREEN_UPCOMING } from 'reducers/page/types'
import { usePage } from 'modules/common'

import { OrderType } from '../types'

const analyticsHotel = new OrderListAnalytics()

export const useOrdersPageAnalytics = <RefType extends HTMLElement = HTMLDivElement>() => {
  const parent = useRef<RefType>()
  const { name } = usePage()
  const cleanOrders = useAppSelector(state => state.orders.cleanOrders)

  useEffect(() => {
    if (cleanOrders && parent.current) {
      if (name === ORDERS_SCREEN_UPCOMING) {
        analyticsHotel.seeOrdersFuture(parent.current.children.length)
      } else {
        analyticsHotel.seeOrdersPast(parent.current.children.length)
      }

      definitionAnalytics(cleanOrders, name, analyticsHotel)
    }
  }, [cleanOrders, name, parent.current])

  return parent
}

const definitionAnalytics = (orders: OrderType, page, analytics) => {
  Object.values(orders).filter(Boolean).forEach(order => {
    const hotels = order.hotels[0]
    if (!hotels) return null

    hotels.aita_order && order.hotels.forEach(hotel => {
      const isUpcoming = checkDateOrder(hotel)

      if (isUpcoming && page === ORDERS_SCREEN_UPCOMING) {
        analytics.hotelItemFuture(hotel)
      } else if (!isUpcoming && page === ORDERS_SCREEN_PAST) {
        analytics.hotelItemPast(hotel)
      }
    })
  })
}

const checkDateOrder = (aitaOrder) => {
  const date = aitaOrder.date_from
  if (date < new Date().getTime() / 1000) {
    return false
  } else {
    return true
  }
}
