import React from 'react'
import { useSelector } from 'react-redux'
import PeopleController from './controller'

const Counter = ({ type }) => {
  const controller = new PeopleController()
  const passengers = useSelector(state => state.searchForm.passengers.count)
  return (
    <div className='counter'>
      <div
        className='counter__description'
        dangerouslySetInnerHTML={{ __html: controller.counterDescription(type) }}
      />
      <div className='counter__calculation'>
        <button
          className='counter__btn counter__btn-minus'
          disabled={controller.disabledCounterBtn(passengers, type, 'minus')}
          onClick={() => controller.clickCounterBtn(passengers, type, 'minus')}
        />
        <span className='counter__count'>{passengers[type]}</span>
        <button
          className='counter__btn counter__btn-plus'
          disabled={controller.disabledCounterBtn(passengers, type, 'plus')}
          onClick={() => controller.clickCounterBtn(passengers, type, 'plus')}
        />
      </div>
    </div>
  )
}

export default Counter
