import React from 'react'
import './style.sass'
const LoadButton = () => {
  return (
    <div className='loaderButton'>
      <div className='circle' />
      <div className='circle' />
      <div className='circle' />
    </div>
  )
}

export default LoadButton
