import React, { useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { clearPassengerInForm } from '../../../reducers/order/actions'
import Icons from '../../pageComponents/icons'
import Popup from '../../pageComponents/popup'
import PassengerForm from '../../purchaseComponents/_passengers/_form'
import Passenger from '../../purchaseComponents/_passengers/_passenger'
import GuestsAnalytics from '../../../analytics/analyticsHotel/guestsAnalytics'

const ConfirmationWhoIs = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showExtraGuests, setShowExtraGuests] = useState(false)
  const [openOptionsPassenger, setOpenOptionsPassenger] = useState(false)
  const [popupOpen, setPopupOpenState] = useState(false)
  const setOpenOptions = (index) => {
    if (index === openOptionsPassenger) {
      setOpenOptionsPassenger(false)
    } else {
      setOpenOptionsPassenger(index)
    }
  }
  const guests = useSelector(state => state.hotel.guests)
  const passengerBooker = useSelector(state => state.orderHotels.booker)
  const passengersExtra = useSelector(state => state.orderHotels.extraGuests)
  const analyticsHotel = new GuestsAnalytics()
  const setPopupOpen = (open) => {
    setPopupOpenState(open)
    if (!open) {
      dispatch(clearPassengerInForm())
    }
  }
  return (
    <div className='confirmationWhoIs confirmationScreen__block'>
      <div className='block__header'>
        {t('Who’s checking in')}
      </div>
      <Passenger
        passenger={passengerBooker}
        index={0}
        openOptionsPassenger={openOptionsPassenger}
        setOpenOptions={setOpenOptions}
        popupOpen={popupOpen}
        setPopupOpen={setPopupOpen}
      />
      {guests.count > 1 &&
        <button
          className={'confirmationWhoIs__extra ' + (showExtraGuests && 'confirmationWhoIs__extra-show')}
          onClick={() => {
            setShowExtraGuests(!showExtraGuests)
            analyticsHotel.clickOpenAllGuests()
          }}
        >
          {showExtraGuests
            ? t('Hide all the guests names')
            : t('Enter all the guests names')}
          <Icons
            className='confirmationWhoIs__extra-icon'
            group='arrow'
            icon='detail'
          />
        </button>}
      {showExtraGuests &&
        guests.people.split(',').map((key, index) => index
          ? <Passenger
              key={index}
              passenger={passengersExtra[index - 1]}
              index={index}
              number={index}
              openOptionsPassenger={openOptionsPassenger}
              setOpenOptions={setOpenOptions}
              popupOpen={popupOpen}
              setPopupOpen={setPopupOpen}
            />
          : ''
        )}
      {openOptionsPassenger !== false &&
        <Popup
          up
          child={
            <PassengerForm
              setPopupOpen={setPopupOpen}
              openOptionsPassengerNumber={openOptionsPassenger}
              setOpenOptionsPassenger={setOpenOptionsPassenger}
            />
        }
          popupOpen={popupOpen}
          setPopupOpen={setPopupOpen}
        />}
    </div>
  )
}

export default ConfirmationWhoIs
