import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import AnchorLink from 'react-anchor-link-smooth-scroll'


const HotelDetailMenu = () => {
  const { t } = useTranslation()
  const FIRST_BLOCK_OFFSET = '64'
  const BLOCK_OFFSET = '120'
  return (
    <nav className='hotelDetailMenu '>
      <AnchorLink
        href='#Overview'
        offset={FIRST_BLOCK_OFFSET}
        className='hotelDetailMenu__element'
      >
        {t('Overview')}
      </AnchorLink>
      <AnchorLink
        href='#Rooms'
        offset={BLOCK_OFFSET}
        className='hotelDetailMenu__element'
      >
        {t('Rooms')}
      </AnchorLink>
      <AnchorLink
        href='#Location'
        offset={BLOCK_OFFSET}
        className='hotelDetailMenu__element'
      >
        {t('Location')}
      </AnchorLink>
      <AnchorLink
        href='#Amenities'
        offset={BLOCK_OFFSET}
        className='hotelDetailMenu__element'
      >
        {t('Amenities')}
      </AnchorLink>
      <AnchorLink
        href='#Reviews'
        offset={BLOCK_OFFSET}
        className='hotelDetailMenu__element'
      >
        {t('Top reviews')}
      </AnchorLink>
    </nav>
  )
}

export default HotelDetailMenu
