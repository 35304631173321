import React from 'react'
import './style.sass'
import { Dots } from 'react-activity'
import 'react-activity/lib/Dots/Dots.css'

const Loader = ({ color }) => {
  return (
    <div className='loader'>
      <Dots color={color} />
    </div>
  )
}
export default Loader
