import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import getSegments from '../../../../hooks/getSegments'
import Carrier from '../../../itinerariesComponents/itinerariesElement/_carrier'
import Info from '../../../itinerariesComponents/itinerariesElement/_info'
import ItinerariesElementController from '../../../itinerariesComponents/itinerariesElement/controller'
import FareItineraryController from '../controller'

const FlightFare = ({ element, type, openInfo }) => {
  const { t } = useTranslation()
  const searchRS = useSelector(state => state.searchRS.model)
  const controller = new FareItineraryController()
  const itineraryController = new ItinerariesElementController(element, searchRS, type)
  const segments = getSegments(element[type])
  return (
    <div className='flightFare'>
      <Carrier carriers={itineraryController.carrier()} />
      <div className={'flightFare__name ' + (openInfo ? 'flightFare__show' : 'flightFare__hide')}>
        {segments
          .map(segment => [segment.carrier, segment.number].join(' '))
          .join(', ')}
      </div>
      <Info carriers={itineraryController.carrier()} duration={itineraryController.duration()} openDetail={openInfo} />
      <div className={'flightFare-direction ' + (!openInfo ? 'flightFare__show' : 'flightFare__hide')}>
        <div className='firstRow'>
          {itineraryController.getTimeFormat(itineraryController.duration().duration)},
          {itineraryController.duration().transferring.count}
        </div>
        <div className='secondRow'>
          {itineraryController.allDirection()}
        </div>
      </div>

      <button
        className='flightFare-change'
        onClick={() => { controller.handleClickChange(type) }}
      >
        {t('Change flight')}
      </button>
    </div>
  )
}

export default FlightFare

