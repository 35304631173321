import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getPriceWithCurrency } from '../../../utils/prices'

export default class ConfirmationPaymentOptionsController {
  constructor () {
    this.t = useTranslation().t
    this.lang = useTranslation().i18n.language
  }

  getNights = (checkin, checkout) => {
    const nights = moment(checkout).diff(moment(checkin), 'days')
    return this.t('night', { count: nights })
  }

  getPriceValue = (price) => {
    const { convertedValue, value, convertedCurrency, currency } = price
    const currentValue = convertedValue || value
    return getPriceWithCurrency(currentValue, convertedCurrency || currency, this.lang)
  }
}
