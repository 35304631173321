import React, { useEffect } from 'react'
import './style.sass'
import ConfirmationDates from '../../../components/confirmationComponents/_dates'
import ConfirmationHeader from '../../../components/confirmationComponents/_header'
import ConfirmationPaymentOptions from '../../../components/confirmationComponents/_paymentOptions'
import ConfirmationPrice from '../../../components/confirmationComponents/_price'
import ConfirmationRoomInfo from '../../../components/confirmationComponents/_roomInfo'
import ConfirmationWhoIs from '../../../components/confirmationComponents/_whoIs'
import SpecialRequest from '../../../components/confirmationComponents/_specialRequest'
import OrderAnalytics from '../../../analytics/analyticsHotel/orderAnalytics'
import { useSelector } from 'react-redux'

const ConformationScreen = () => {
  const orderHotels = useSelector(state => state.orderHotels)
  const hotelAnalytics = new OrderAnalytics()
  useEffect(() => {
    hotelAnalytics.orderIsLoaded(orderHotels)
  }, [])
  return (
    <div className='confirmationScreen'>
      <ConfirmationHeader />
      <div className='confirmationScreen__info'>
        <div className='info__left'>
          <ConfirmationDates />
          <ConfirmationWhoIs />
          <ConfirmationPaymentOptions />
          <SpecialRequest />
        </div>
        <div className='info__right'>
          <ConfirmationRoomInfo />
          <ConfirmationPrice />
        </div>
      </div>
    </div>
  )
}

export default ConformationScreen
