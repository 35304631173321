import React, { useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { Calendar } from './calendar'

export const Export = () => {
  const [openPopup, setOpenPopup] = useState(false)
  const { t } = useTranslation()
  return (
    <div className='export'>
      <button
        className='export__btn'
        onClick={() => {
          setOpenPopup(true)
        }}
      >
        {t('Export bookings')}
      </button>
      <Calendar openPopup={openPopup} setOpenPopup={setOpenPopup} />
    </div>
  )
}

