import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'

export const useDispatchOnce = (action: AnyAction) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(action)
  }, [])
}
