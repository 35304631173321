const hotelTypes = [
  {
    'name': 'Apartments',
    'hotel_type_id': 201,
    'default_checked': true
  },
  {
    'name': 'Guest accommodation',
    'hotel_type_id': 202,
    'default_checked': true
  },
  {
    'name': 'Hostels',
    'hotel_type_id': 203,
    'default_checked': false
  },
  {
    'name': 'Hotels',
    'hotel_type_id': 204,
    'default_checked': true
  },
  {
    'name': 'Motels',
    'hotel_type_id': 205,
    'default_checked': false
  },
  {
    'name': 'Resorts',
    'hotel_type_id': 206,
    'default_checked': true
  },
  {
    'name': 'Residences',
    'hotel_type_id': 207,
    'default_checked': true
  },
  {
    'name': 'Bed and breakfasts',
    'hotel_type_id': 208,
    'default_checked': true
  },
  {
    'name': 'Ryokans',
    'hotel_type_id': 209,
    'default_checked': true
  },
  {
    'name': 'Farm stays',
    'hotel_type_id': 210,
    'default_checked': false
  },
  {
    'name': 'Holiday parks',
    'hotel_type_id': 212,
    'default_checked': false
  },
  {
    'name': 'Villas',
    'hotel_type_id': 213,
    'default_checked': true
  },
  {
    'name': 'Campsites',
    'hotel_type_id': 214,
    'default_checked': false
  },
  {
    'name': 'Boats',
    'hotel_type_id': 215,
    'default_checked': false
  },
  {
    'name': 'Guest houses',
    'hotel_type_id': 216,
    'default_checked': false
  },
  {
    'name': 'Uncertain',
    'hotel_type_id': 217,
    'default_checked': false
  },
  {
    'name': 'Inns',
    'hotel_type_id': 218,
    'default_checked': true
  },
  {
    'name': 'Aparthotels',
    'hotel_type_id': 219,
    'default_checked': true
  },
  {
    'name': 'Holiday homes',
    'hotel_type_id': 220,
    'default_checked': false
  },
  {
    'name': 'Lodges',
    'hotel_type_id': 221,
    'default_checked': true
  },
  {
    'name': 'Homestays',
    'hotel_type_id': 222,
    'default_checked': false
  },
  {
    'name': 'Country houses',
    'hotel_type_id': 223,
    'default_checked': true
  },
  {
    'name': 'Luxury tents',
    'hotel_type_id': 224,
    'default_checked': true
  },
  {
    'name': 'Capsule hotels',
    'hotel_type_id': 225,
    'default_checked': true
  },
  {
    'name': 'Love hotels',
    'hotel_type_id': 226,
    'default_checked': false
  },
  {
    'name': 'Riads',
    'hotel_type_id': 227,
    'default_checked': false
  },
  {
    'name': 'Chalets',
    'hotel_type_id': 228,
    'default_checked': true
  },
  {
    'name': 'Condos',
    'hotel_type_id': 229,
    'default_checked': true
  },
  {
    'name': 'Cottages',
    'hotel_type_id': 230,
    'default_checked': true
  },
  {
    'name': 'Economy hotels',
    'hotel_type_id': 231,
    'default_checked': true
  },
  {
    'name': 'Gites',
    'hotel_type_id': 232,
    'default_checked': true
  },
  {
    'name': 'Health resorts',
    'hotel_type_id': 233,
    'default_checked': true
  },
  {
    'name': 'Cruises',
    'hotel_type_id': 234,
    'default_checked': false
  },
  {
    'name': 'Student accommodation',
    'hotel_type_id': 235,
    'default_checked': false
  }
]

export default hotelTypes
