import { FLIGHTS_SEARCH_PAGE, HOTELS_SEARCH_PAGE } from '../navigation/routes'


const availableMenu = [
  {
    icon: 'airport',
    text: 'Flights',
    link: FLIGHTS_SEARCH_PAGE,
  },
  {
    icon: 'hotels',
    text: 'Hotels',
    link: HOTELS_SEARCH_PAGE,
  }
]

export default availableMenu
