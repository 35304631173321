import { combineReducers, createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import searchForm from './search/_searchForm'
import searchRS from './search/_searchRS'
import filter from './filter'
import itineraries from './itineraries'
import hotel from './hotel'
import button from './button'
import page from './page'
import map from './map'
import orderFlights from './order/flights'
import orderHotels from './order/hotels'
import orderReserve from './order/reserve'
import orders from './orders'
import orderRS from './order/_orderRS'
import priceRS from './order/_priceRS'
import user from './user'
import bookerForm from './order/bookerForm'
import passengerForm from './order/passengerForm'
import paymentForm from './order/paymentForm'
import loadBar from './loadBar'
import infoPopup from './infoPopup'
import error from './error'
import browserInformation from './browserInformation'
import { TypedUseSelectorHook, useSelector } from 'react-redux'

const rootReducer = combineReducers({
  searchForm,
  filter,
  searchRS,
  itineraries,
  map,
  hotel,
  orderFlights,
  orderHotels,
  orderReserve,
  orders,
  orderRS,
  priceRS,
  page,
  button,
  user,
  bookerForm,
  passengerForm,
  paymentForm,
  loadBar,
  infoPopup,
  error,
  browserInformation
})

const actionSanitizer = action => {
  let sanitizedAction = action
  if ('model' in action) {
    sanitizedAction = {
      ...action,
      model: '<<This object is too large, if you wanna see the action change the sanitizer>>',
    }
  }
  if ('payload' in action && (Array.isArray(action.payload) || typeof action.payload === 'object')) {
    sanitizedAction = { ...action, payload: '<<If you wanna see the action change the sanitizer>>' }
  }

  return sanitizedAction
}

export const store =
    createStore(rootReducer, composeWithDevTools({ actionSanitizer })(applyMiddleware(thunk)))

export type RootState = ReturnType<typeof store.getState>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
