import { useDispatch } from 'react-redux'
import FilterAnalyticsFlight from '../../../analytics/analyticsFlight/filterAnalytics'
import FilterAnalyticsHotel from '../../../analytics/analyticsHotel/resultsAnalytics'
import currentLocation from '../../../hooks/currentLocation'
import { changeOrder } from '../../../reducers/filter/actions'

export default class OrderController {
  constructor () {
    this.dispatch = useDispatch()
    this.analyticsFlight = new FilterAnalyticsFlight()
    this.analyticsHotel = new FilterAnalyticsHotel()
  }

  handleClick = (order) => {
    const { isFlightsPage, isHotelsPage } = currentLocation()
    if (isFlightsPage) {
      this.analyticsFlight.setOrder(order)
    } else if (isHotelsPage) {
      this.analyticsHotel.sortByPrice(order)
    }
    this.dispatch(changeOrder())
  }
}
