import React from 'react'
import './style.sass'
import { useSelector } from 'react-redux'
import HotelName from '../../_hotelsList/_hotelElement/_name'
import HotelReviews from '../../_hotelsList/_hotelElement/_reviews'
import HotelStars from '../../_hotelsList/_hotelElement/_stars'
import MainInformationLocation from './_location'
import HotelPopularAmenities from './_popularAmenities'
import MainInformationMap from './_map'

const HotelMainInformation = () => {
  const hotelState = useSelector(state => state.hotel.choice)

  return (
    <div className='mainInformation' id='Overview'>
      <div className='left'>
        <HotelStars hotel={hotelState} />
        <HotelName hotel={hotelState} />
        <HotelReviews hotel={hotelState} />
        <HotelPopularAmenities hotel={hotelState} />
      </div>
      <div className='right'>
        <MainInformationMap hotel={hotelState} />
        <MainInformationLocation hotel={hotelState} />
      </div>
    </div>
  )
}

export default HotelMainInformation
