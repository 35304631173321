import React from 'react'
import './style.sass'

const Carrier = ({ carriers }) => {
  return (
    <div className='carrier'>
      {carriers.map((carrier, index) => {
        if (!carrier) return ''
        return (
          <img
            key={carrier?.imageUrl + index}
            src={carrier?.imageUrl}
            alt=''
            className='carrier-logo'
          />
        )
      })}
    </div>
  )
}

export default Carrier
