import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ConfirmationHeaderController from './_controller'

const ConfirmationHeader = () => {
  const { t } = useTranslation()
  const hotelState = useSelector(state => state.hotel)
  const controller = new ConfirmationHeaderController()

  return (
    <div className='confirmationHeader'>
      <div className='confirmationHeader__text'>
        {t('Confirmation')}
      </div>
      <div className='confirmationHeader__place'>
        {hotelState.choice.city}: {controller.getDate(hotelState.checkinDate, hotelState.checkoutDate)}
      </div>
    </div>
  )
}

export default ConfirmationHeader
