import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import FareHeader from '../../../components/fareComponents/_header'
import Passengers from '../../../components/purchaseComponents/_passengers'
import Summary from '../../../components/purchaseComponents/_summary'

const PurchaseScreen = () => {
  const { t } = useTranslation()
  return (
    <div className='purchaseScreen'>
      <FareHeader text={t('Purchase')} />
      <div className='purchaseScreen__flex'>
        <div className='purchaseScreen__passengers purchaseScreen__block'>
          <Passengers />
        </div>
        <div className='purchaseScreen__summary purchaseScreen__block'>
          <Summary />
        </div>
      </div>
    </div>
  )
}

export default PurchaseScreen
