import React from 'react'
import './style.sass'
import { useDispatch, useSelector } from 'react-redux'
import { socketDisconnect } from '../../../api/socket'
import currentLocation from '../../../hooks/currentLocation'
import { FLIGHTS_SEARCH_PAGE, HOTELS_SEARCH_PAGE } from '../../../navigation/routes'
import { clearState } from '../../../reducers/actions'
import { changePage } from '../../../reducers/page/actions'
import { screenSetting, SEARCH_SCREEN } from '../../../reducers/page/types'
import Icons from '../icons'
import { Link } from 'react-router-dom'


import Account from './_account'
import BusinessSwitcher from './_businessSwitch'
import Language from './_language'
import Currency from './_currency'
import HeaderController from './controller'

const Header = () => {
  const page = useSelector(state => state.page.name)
  const location = useSelector(state => state.page.currentLocation)
  const businessData = useSelector(state => state.user.business)
  const { isFlightsPage, isHotelsPage, isOrdersPage } = currentLocation()
  const controller = new HeaderController(page)
  const dispatch = useDispatch()
  return (
    <div
      className={'header ' +
      (isHotelsPage && ' header__fixed ') +
      ((isFlightsPage && page !== SEARCH_SCREEN) && ' header__white ') +
      ((isHotelsPage && location !== HOTELS_SEARCH_PAGE) && ' header__white ') +
      ((isOrdersPage) && ' header__white ')}
    >
      <div className='container'>
        {screenSetting(page).header.showArrowBack && isFlightsPage &&
          <button
            className='header__back'
            onClick={controller.handleOnClick}
          >
            <Icons
              group='arrow'
              icon='back'
              color='#000'
              className='header__back-icon'
            />
          </button>}
        <Link
          to={isHotelsPage ? HOTELS_SEARCH_PAGE : FLIGHTS_SEARCH_PAGE}
          className='logo'
          onClick={() => {
            dispatch(clearState())
            socketDisconnect()
            dispatch(changePage(SEARCH_SCREEN))
          }}
        >
          <Icons
            group='icon'
            icon='logo'
            color={screenSetting(page).header.logoColor}
          />
        </Link>
        <div className='right'>
          {/*// TODO вернуть при деплоии business логики*/}
          {/*{businessData.success &&*/}
          {/*  <BusinessSwitcher />}*/}
          <Language />
          <Currency />
          <Account />
        </div>
      </div>
    </div>
  )
}

export default Header
