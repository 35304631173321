import React, { useState, useEffect } from 'react'
import _ from 'lodash'

const KitCut = ({ review, initLimit = 150 }) => {
  const [limit, setLimit] = useState(initLimit)
  const [reviews, setReviews] = useState('')
  const [showMore, setShowMore] = useState(false)
  useEffect(() => {
    const kitcut = () => {
      let text = review.trim()
      if (_.size(text) <= limit) {
        setReviews(text)
        setShowMore(false)
      } else {
        text = text.slice(0, limit) // тупо отрезать по лимиту
        const lastSpace = text.lastIndexOf(' ')
        if (lastSpace > 0) { // нашлась граница слов, ещё укорачиваем
          text = text.substr(0, lastSpace) + ' ...'
        }
        setReviews(text)
        setShowMore(true)
      }
    }
    kitcut()
  }, [setShowMore, setReviews, limit, reviews, review.reviews])

  return (
    <div
      className='text'
    >
      {reviews}
      {showMore &&
        <button
          type='button'
          className='more'
          onClick={() => setLimit(_.size(review))}
        >
          Read more
        </button>}
    </div>
  )
}

export default KitCut
