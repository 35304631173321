import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'

import { AitaOrder, FlightOrder, HotelOrder, OrderType } from 'modules/orders/types'
import CurrencySymbol from 'hooks/currencySymbol'

import { checkIsFlight, checkIsHotel } from './checks'

export const getOrderDetails = (order: OrderType, isHotel: boolean) => {
  const flights = order.flights[0]
  const hotels = order.hotels[0]
  const orderDetail = isHotel ? hotels : flights || hotels
  return { orderDetail, flights, hotels }
}

// получаем форматированную дату
export const getDate = (aitaOrder: AitaOrder, i18n) => {
  moment.locale(i18n.language)
  let dateStart
  let dateEnd
  let formatStart = 'DD MMM'
  const formatEnd = ' - DD MMM YYYY'
  let date = ''
  // для отелей
  if (checkIsHotel(aitaOrder)) {
    dateStart = moment.unix(aitaOrder.date_from)
    dateEnd = moment.unix(aitaOrder.date_to)
    // если года разные, в первую дату допишем год
    if (dateEnd.get('year') !== dateStart.get('year')) {
      formatStart += ' YYYY'
    }
    return dateStart.format(formatStart) + dateEnd.format(formatEnd)
  }
  // для полетов
  if (checkIsFlight(aitaOrder)) {
    dateStart = moment.unix((_.first(aitaOrder.outbound_segments)?.departure_date))
    // проевряем наличие обратного полета
    if (!_.size(aitaOrder.inbound_segments)) {
      // обратно нету, дата с годом
      formatStart += ' YYYY'
      date = dateStart.format(formatStart)
    } else {
      dateEnd = moment.unix(_.last(aitaOrder.inbound_segments).arrival_date)
      // обратный есть, сравниваем года и если разные допишем год в первую дату
      if (dateEnd.get('year') !== dateStart.get('year')) {
        formatStart += ' YYYY'
      }
      date = dateStart.format(formatStart) + dateEnd.format(formatEnd)
    }
    return date
  }
}


// формируем строку городов/названий отелей
export const getCitiesName = (orderDetail: HotelOrder | FlightOrder) => {
  const aitaOrder = orderDetail.aita_order
  const cities = []
  if (checkIsFlight(aitaOrder)) {
    cities.push(_.first(aitaOrder.outbound_segments).origin.city)
    cities.push(_.last(aitaOrder.outbound_segments).destination.city)
  }
  if (checkIsHotel(aitaOrder) && 'name' in orderDetail) {
    cities.push(orderDetail.name)
  }
  return cities[0] + (cities[1] ? ' - ' + cities[1] : '')
}

// иконка для строки
export const getIcon = (aitaOrder: AitaOrder) => {
  return checkIsFlight(aitaOrder) ? 'aircraft' : 'hotel'
}

// пассажиры или буккер
export const getPeople = (aitaOrder: AitaOrder) => {
  if (checkIsFlight(aitaOrder)) {
    return aitaOrder.passengers
      .map(passenger =>
        _.capitalize(passenger.last_name) + ' ' + _.capitalize(passenger.first_name.slice(0, 1) + '.')
      )
      .join(', ')
  }
  if (checkIsHotel(aitaOrder)) {
    return _.capitalize(aitaOrder.booker.lastName) +
        ' ' +
        _.capitalize(aitaOrder.booker.firstName.slice(0, 1) + '.')
  }
}

// номер бронирования
export const getBookRef = (aitaOrder: AitaOrder, t) => {
  if (checkIsFlight(aitaOrder)) {
    return `${t('Bookref')}: ${aitaOrder.booking_reference}`
  }
  if (checkIsHotel(aitaOrder)) {
    return `${t('Confirmation number')}: ${aitaOrder.confirmation_id}`
  }
}

// цена покупки
export const getPrice = (aitaOrder: AitaOrder) => {
  return aitaOrder?.price + CurrencySymbol(aitaOrder?.currency)
}
