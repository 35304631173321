import React, { useEffect } from 'react'
import './style.sass'

import DayPicker from 'react-day-picker/DayPicker'
import MomentLocaleUtils from 'react-day-picker/moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import 'react-day-picker/lib/style.css'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler'
import { useSelector } from 'react-redux'
import currentLocation from '../../../hooks/currentLocation'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { HOTELS_SEARCH_PAGE } from '../../../navigation/routes'
import { SEARCH_SCREEN } from '../../../reducers/page/types'
import DatePickerController from './controller'


const DatePicker = () => {
  const { t, i18n } = useTranslation()
  const controller = new DatePickerController()
  const page = useSelector(state => state.page.name)
  const calendar = useSelector(state => state.searchForm.calendar)
  let from = calendar.start
  let to = calendar.end
  if (typeof from === 'string') {
    from = new Date(from)
    to = new Date(to)
  }
  const error = calendar.error
  const visible = calendar.visible
  const modifiers = { start: from, end: to }
  const { height } = useWindowDimensions()
  const location = useSelector(state => state.page.currentLocation)
  const { isFlightsPage, isHotelsPage } = currentLocation()
  useEffect(() => {
    controller.setStartDate(page, isHotelsPage)
  }, [isHotelsPage])

  return (

    <div className='datePicker'>
      <div
        className={'datePicker__input ' +
        (visible && ' datePicker__input-focus ') +
        (error && ' datePicker__input-error ')}
        onClick={() => controller.handleShowChange(!visible)}
      >
        <div className='datePicker__element'>
          <div className={'datePicker__label ' + (!from && 'datePicker__label-center')}>
            {isHotelsPage
              ? t('Check in')
              : from ? t('Depart') : t('Depart date')}
          </div>
          <Moment
            className={'datePicker__value ' + (from && 'datePicker__value-show')}
            format='MMM DD, ddd'
            locale={i18n.language}
          >
            {from}
          </Moment>
        </div>
        <div className='datePicker__element'>
          <div className={'datePicker__label ' + (!to && 'datePicker__label-center')}>
            {isHotelsPage
              ? t('Check out')
              : to ? t('Return') : t('Return date')}
          </div>
          <Moment
            className={'datePicker__value ' + (to && 'datePicker__value-show')}
            format='MMM DD, ddd'
            locale={i18n.language}
          >
            {to}
          </Moment>
        </div>
      </div>
      <OutsideClickHandler
        onOutsideClick={() =>
          visible &&
          controller.handleShowChange(false)}
      >
        <div className={
          'datePicker__list ' +
          (visible && ' datePicker__list-open ') +
          ((height < 820 &&
            (
              (isFlightsPage && page === SEARCH_SCREEN) ||
              (isHotelsPage && location === HOTELS_SEARCH_PAGE)
            )
          ) && ' datePicker__list-up ')
        }
        >
          <div className='datePicker__list-wrap'>
            <button
              className='reset'
              onClick={controller.handleToReset}
            >
              {t('Reset')}
            </button>
            <DayPicker
              selectedDays={[from, { from, to }]}
              modifiers={modifiers}
              firstDayOfWeek={1}
              locale={i18n.language}
              localeUtils={MomentLocaleUtils}
              fromMonth={new Date()}
              disabledDays={{ before: new Date() }}
              numberOfMonths={2}
              onDayClick={(day) => controller.handleOnClickDayCalendar(day, calendar, isHotelsPage)}
            />
          </div>
        </div>
      </OutsideClickHandler>
      {error &&
        <div className='datePicker__error'>
          {error}
        </div>}
    </div>
  )
}
export default DatePicker
