import { createContext, useContext, useReducer } from 'react'
import { RoomOptionsAction, RoomOptionsContextType, RoomOptionsInitialState } from './types'

const initialContextState: RoomOptionsInitialState = {
  meal: {
    breakfastIncl: false,
    dinnerIncl: false,
    lunchIncl: false,
    withoutMeal: false,
  },
  bedType: [
    { name: '2 double beds', checked: false },
    { name: '2 beds', checked: false },
    { name: 'King size bed', checked: false },
  ],
  refundable: false,
  paymentOptions: {
    withPrepay: false,
    withoutPrepay: false,
  },
  prev: null
}

export const RoomOptionsContext = createContext<RoomOptionsContextType>({
  state: initialContextState,
  dispatch: () => null
})

const reducer = (state = initialContextState, action: RoomOptionsAction): RoomOptionsInitialState => {
  switch (action.field) {
    case 'meal':
    case 'paymentOptions': {
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          [action.payload.name]: !state[action.field][action.payload.name]
        }
      }
    }
    case 'bedType': {
      const options = state[action.field].map(item => item.name === action.payload.name
        ? { ...item, checked: !action.payload.checked }
        : item)

      return {
        ...state,
        [action.field]: options
      }
    }
    case 'refundable': {
      return {
        ...state,
        [action.field]: !state[action.field]
      }
    }
    case 'clear': {
      return {
        ...state,
        [action.payload]: initialContextState[action.payload]
      }
    }
    case 'setPrev': {
      return { ...state.prev, prev: state.prev }
    }
    default:
      return state
  }
}

interface RoomOptionsProviderProps {
  previousOptions?: RoomOptionsInitialState['prev']
}

export const RoomOptionsProvider: React.FC<RoomOptionsProviderProps> = ({ children, previousOptions }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialContextState, prev: previousOptions })

  return (
    <RoomOptionsContext.Provider value={{ state, dispatch }}>
      {children}
    </RoomOptionsContext.Provider>
  )
}

export const useRoomOptions = () => useContext(RoomOptionsContext)
