import { useDispatch } from 'react-redux'
import ResultsAnalyticsHote from '../../../analytics/analyticsHotel/resultsAnalytics'
import { changeSearch } from '../../../reducers/filter/actions'

export default class SearchHotelsController {
  constructor () {
    this.dispatch = useDispatch()
    this.resultAnalyticsHotel = new ResultsAnalyticsHote()
  }

  handleOnChange = (e) => {
    const { value } = e.target
    this.dispatch(changeSearch(value))
    this.resultAnalyticsHotel.changeSearchByProperty(value)
  }
}
