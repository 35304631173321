import _ from 'lodash'

const HandleSaveToPC = (allState, name) => {
  const cloneDeepState = _.cloneDeep(allState)
  cloneDeepState.order.payment.card = cloneDeepState.paymentForm.card = {
    number: '',
    expMonth: '',
    expYear: '',
    cvc: '',
    holder: ''
  }
  const fileData = JSON.stringify(cloneDeepState)
  const blob = new Blob([fileData], { type: 'text/plain' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.download = name + '.json'
  link.href = url
  link.click()
}

export default HandleSaveToPC
