import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { SearchInput, useTextInput } from 'modules/common'
import { updateOrders } from 'reducers/orders/actions'

import './style.sass'

export const OrderHeader = React.memo(() => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [value, onChange] = useTextInput('', (val) => dispatch(updateOrders('search', val)))
  return (
    <div className='orders__header'>
      <div className='orders__header-text'>
        {t('Orders')}
      </div>
      <div className='orders__header-search'>
        <SearchInput placeholder={t('Search in orders')} value={value} onChange={onChange} />
      </div>
    </div>
  )
})
