import React, { useEffect } from 'react'
import './style.sass'
import { useSelector } from 'react-redux'
import UseSavedState from '../../../hooks/useSavedState'
import { SEARCH_SCREEN } from '../../../reducers/page/types'
import DirectFlightsOnly from '../_directFlightsOnly'
import Airlines from '../_airlines'
import Duration from '../_duration'
import Time from '../_time'
import Price from '../_price'
import FilterFlightsController from './flightsController'
import Layovers from '../_layovers'

const FilterFlights = () => {
  const page = useSelector(state => state.page.name)
  const uniqueOutboundItineraries = useSelector(state => state.itineraries.uniqueOutboundItineraries)
  const allInboundItineraries = useSelector(state => state.itineraries.allInboundItineraries)
  const searchRS = useSelector(state => state.searchRS.model)
  const filter = useSelector(state => state.filter)
  const controller = new FilterFlightsController(
    searchRS,
    allInboundItineraries || uniqueOutboundItineraries,
    filter,
    allInboundItineraries === null
  )
  useEffect(() => {
    if (!UseSavedState()) {
      controller.setFilter()
    }
  }, [uniqueOutboundItineraries])
  useEffect(() => {
    setTimeout(() => {
      if (!UseSavedState())
        controller.setFilter()
    }, allInboundItineraries ? 600 : 0)
  }, [allInboundItineraries])

  return (
    <>
      <DirectFlightsOnly />
      {page !== SEARCH_SCREEN && Object.keys(uniqueOutboundItineraries).length
        ? (
          <>
            <Layovers />
            <Duration />
            <Time />
            <Airlines />
            <Price />
          </>
          )
        : ''}
    </>
  )
}
export default FilterFlights
