import React, { useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CONFIRMATION_BUTTON } from '../../../reducers/button/types'
import LoadButton from '../../pageComponents/loadButton'
import Card from '../../purchaseComponents/_card'
import ConfirmationPriceController from './controller'
import OrderType from '../../../data/OrderType'
import Iframe3DS from '../_Iframe3DS'

const ConfirmationPrice = () => {
  const { t } = useTranslation()
  const [iFrame3DSpaymentUrl, setIFrame3DSpaymentUrl] = useState(false)
  const orderHotels = useSelector(state => state.orderHotels)
  const hotelState = useSelector(state => state.hotel)
  const bookingDetail = useSelector(state => state.priceRS.model.details)
  const currency = useSelector(state => state.searchForm.currency)
  const { priceInfo, prepayRequired, originalPrice } = hotelState.reserve.option
  const controller = new ConfirmationPriceController()
  const confirmationButton = useSelector(state => state.button[CONFIRMATION_BUTTON])
  const user = useSelector(state => state.user)

  return (
    <div className='confirmationPrice confirmationScreen__block'>
      <div className='block__header'>
        {t('Price details')}
      </div>
      <div className='confirmationPrice__row'>
        <div className='text'>
          {controller.getNights(hotelState.checkinDate, hotelState.checkoutDate)}
          <span>
            {controller.getPricePerNight(priceInfo.total, hotelState.checkinDate, hotelState.checkoutDate)}
          </span>
        </div>
        <div className='value'>
          {controller.getPriceValue(priceInfo.total)}
        </div>
      </div>
      {!priceInfo.taxes.total.value
        ? ''
        : (
          <div className='confirmationPrice__row'>
            <div className='text'>
              {t('Taxes & fees')}
            </div>
            <div className='value'>
              {controller.getPriceValue(priceInfo.taxes.total)}
            </div>
          </div>)}
      <div className='confirmationPrice__bh' />
      {(bookingDetail.cardRequired || bookingDetail.addressRequired) &&
        <div className='confirmationPrice__card'>
          {t('Pay with')}
          <Card bookingDetail={bookingDetail} />
        </div>}
      <div className='confirmationPrice__row confirmationPrice__row-total'>
        <div className='text'>
          {t('Total')}
        </div>
        <div className='value__container'>
          <div className='value'>
            {controller.getPriceValue(priceInfo.total)}
          </div>
          {originalPrice?.value && originalPrice?.currency !== currency &&
            <span>
              {controller.getPriceLocalValue(originalPrice)}
            </span>}
        </div>
      </div>
      <div className='confirmationPrice__row confirmationPrice__row-pay'>
        <div className='text'>
          {t('Pay now')}
        </div>
        <div className='value'>
          {controller.getPayNow(prepayRequired, priceInfo)}
        </div>
      </div>
      <div className='confirmationPrice__row'>
        <div className='text'>
          {t('Pay at property')}
        </div>
        <div className='value'>
          {controller.getPayProperty(prepayRequired, priceInfo)}
        </div>
      </div>
      {orderHotels.booker.id && (!bookingDetail.cardRequired || orderHotels.card.number || orderHotels.cardToken) &&
        <button
          onClick={() => controller.handleOnClickBook(orderHotels, setIFrame3DSpaymentUrl, user)}
          className='confirmationPrice__book'
          disabled={confirmationButton.disabled}

        >
          {confirmationButton.isLoading
            ? <LoadButton />
            : t('Book reservation')}

        </button>}
      <Iframe3DS
        iFrame3DSpaymentUrl={iFrame3DSpaymentUrl}
        setIFrame3DSpaymentUrl={setIFrame3DSpaymentUrl}
        orderType={OrderType.HOTEL}
      />
    </div>
  )
}

export default ConfirmationPrice
