import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  updateHotelAutocompleteFeatures,
  updateHotelAutocompleteShow,
  updateHotelAutocompleteValue
} from '../../../../../reducers/hotel/actions'
import MapBoxController from '../../../../pageComponents/mapBox/controller'
export default class HotelsMapSearchController {
  constructor () {
    this.dispatch = useDispatch()
    this.lang = useTranslation().i18n.language
    this.mapBoxController = new MapBoxController()
  }

  setSearch = (e) => {
    this.dispatch(updateHotelAutocompleteValue(e.target.value))
  }

  clearSearch = () => {
    this.dispatch(updateHotelAutocompleteValue(''))
    this.dispatch(updateHotelAutocompleteFeatures([]))
  }

  setShow = (show) => {
    this.dispatch(updateHotelAutocompleteShow(show))
  }

  getAutocomplete = async (stay, search) => {
    //генерим search
    let searchQuery = ''
    if (search.includes(stay.city)) {
      searchQuery = search
    } else {
      searchQuery = `${stay.country} ${stay.region} ${stay.city} ${search}`
    }
    searchQuery = searchQuery + '.json'

    // генерим url
    let url = process.env.REACT_APP_MAX_BOX_SEARCH_URL + searchQuery

    // добавляем параметры
    url += '?' + 'language=' + this.lang
    url += '&' + 'limit=10'
    url += '&' + 'fuzzyMatch=true'
    url += '&' + 'country=' + stay.country
    url += '&' + 'region=' + stay.region
    url += '&' + 'place=' + stay.city
    url += '&' + 'proximity=' + stay.longitude + ',' + stay.latitude
    url += '&access_token=' + process.env.REACT_APP_MAP_BOX_API_KEY

    // eslint-disable-next-line no-undef
    await fetch(url, {
      method: 'GET'
    })
      .then(res => {
        if (res.ok) {
          return res.json()
        }
      })
      .then(res => {
        if (res) {
          this.dispatch(updateHotelAutocompleteShow(true))
          this.dispatch(updateHotelAutocompleteFeatures(res.features))
        }
      })
      .catch(e => {
        console.error(e)
        this.dispatch(updateHotelAutocompleteFeatures([]))
      })
  }

  clickEl = (el, map) => {
    this.dispatch(updateHotelAutocompleteValue(el.text + ' ' + (el.address || '')))
    this.mapBoxController.setCenter(map, el.center, 13)
    this.mapBoxController.addPointer(map, el.center[0], el.center[1])
    this.setShow(false)
  }
}
