import React from 'react'
import './style.sass'
import { useSelector } from 'react-redux'
import currentLocation from '../../../hooks/currentLocation'
import { CLIENT_MODE_BUSINESSES } from '../../../reducers/user/constans'
import ResultCounter from '../../filterComponents/_resultCounter'
import FilterFlights from '../../filterComponents/filter/flights'
import FilterHotels from '../../filterComponents/filter/hotels'
import PeopleAndCabin from '../_peopleAndCabin'
import DirectionFlight from '../_directionFlight'
import DatePicker from '../_datePicker'
import Guests from '../_guests'
import SearchButton from '../_searchButton'
import WhereStay from '../_whereStay'

const SearchForm = () => {
  const { isFlightsPage, isHotelsPage } = currentLocation()
  const user = useSelector(state => state.user)

  return (
    <div className='searchForm'>
      {isFlightsPage && <PeopleAndCabin />}
      <div className='row'>
        {isFlightsPage && <DirectionFlight />}
        {isHotelsPage && <WhereStay />}
        <DatePicker />

        {isHotelsPage &&
          (user.clientMode !== CLIENT_MODE_BUSINESSES ||
          user?.businessInfo?.permission_level !== 'basic') &&
            <Guests />}
        <SearchButton />
      </div>
      <div className='filter__flex'>
        <div className='filter__row'>
          {isFlightsPage && <FilterFlights />}
          {isHotelsPage && <FilterHotels />}
        </div>
        <ResultCounter />
      </div>
    </div>
  )
}

export default SearchForm
