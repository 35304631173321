import React, { useEffect } from 'react'
import './style.sass'
import { useDispatch, useSelector } from 'react-redux'
import { updateCounter } from '../../../../reducers/filter/actions'
import Order from '../../../filterComponents/_order'
import SearchHotels from '../../../filterComponents/_search'
import { forceCheck } from 'react-lazyload'
import RecentlyBookedHotels from '../_recentlyBooked'
import HotelTravelPolicy from '../_hotelTravelPolicy'
import HotelsListController from '../controller'
import _ from 'lodash'
import HotelElementWrapper from '../_hotelElementWrapper'
import FilterAnalytics from '../../../../analytics/analyticsHotel/filterAnalytics'


const HotelsElements = ({ fullMap }) => {
  const searchRS = useSelector(state => state.searchRS.model)
  const filter = useSelector(state => state.filter)
  const controller = new HotelsListController()
  const analyticsHotel = new FilterAnalytics()
  const allResultCount = useSelector(state => state.filter.allResultCount)
  const sortedResultCount = useSelector(state => state.filter.sortedResultCount)
  const hotels = controller.getHotelsList(searchRS, filter)
  const dispatch = useDispatch()
  const showExceeds = useSelector(state => state.hotel.showExceeds)
  const exceedsCount = useSelector(state => state.hotel.exceedsCount)

  useEffect(() => {
    forceCheck()
  }, [filter])
  useEffect(() => {
    const element = document.getElementsByClassName('activeHotel')
    if (_.size(element)) {
      element[0].scrollIntoView({
        block: 'center'
      })
    }
  }, [hotels.length])

  useEffect(() => {
    let countHotel = {}
    countHotel = _.unionWith(searchRS.hotels, (a, b) => a.id === b.id)
    countHotel = countHotel.filter((hotel) => controller.filterObsoleteHotels(searchRS.obsoleteHotels, hotel))
    _.size(countHotel) && analyticsHotel.allCountsHotels(_.size(countHotel))
    if (allResultCount !== _.size(countHotel)) {
      dispatch(updateCounter('allResultCount', _.size(countHotel)))
    }
    if (sortedResultCount !== _.size(hotels)) {
      dispatch(updateCounter('sortedResultCount', _.size(hotels)))
    }
  }, [hotels])

  return (
    <div className={'hotelsList__result ' + (fullMap && 'hotelsList__hidden')}>
      <div className='hotelsList__elements'>
        <div className='hotelsList__controls'>
          <SearchHotels />
          <Order />
        </div>
        <HotelTravelPolicy exceedsCount={exceedsCount} showExceeds={showExceeds} />
        <RecentlyBookedHotels />
        {_.map(hotels, hotel =>
          <HotelElementWrapper
            key={hotel.key}
            className={hotel.key}
            hotel={hotel}
            showExceeds={showExceeds}
          />
        )}
      </div>
    </div>
  )
}

export default HotelsElements
