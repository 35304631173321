import { CLEAR_STATE } from '../actions'
import { UPDATE_BOOKER_IN_FORM, CLEAR_PASSENGER_IN_FORM } from './actions'

const defaultState = {
  bookerFields: [
    {
      type: 'field',
      field: 'id',
      visible: false,
      required: false
    },
    {
      type: 'field',
      field: 'firstName',
      visible: true,
      required: true
    },
    {
      type: 'field',
      field: 'lastName',
      visible: true,
      required: true
    },
    {
      type: 'field',
      field: 'phone',
      visible: true,
      required: true
    },
    {
      type: 'field',
      field: 'email',
      visible: true,
      required: true,
      header: 'Additional information'
    },
    {
      type: 'field',
      field: 'country',
      visible: true,
      required: true
    },
    {
      type: 'field',
      field: 'frequentGuest',
      visible: true,
      required: false,
      header: 'Loyalty program'
    },
  ],
  extraGuestFields: [
    {
      type: 'field',
      field: 'id',
      visible: false,
      required: false
    },
    {
      type: 'field',
      field: 'firstName',
      visible: true,
      required: true
    },
    {
      type: 'field',
      field: 'lastName',
      visible: true,
      required: true
    },
  ],
  form: {
    booker: {
      id: null,
      country: null,
      email: null,
      firstName: null,
      lastName: null,
      phone: null,
      frequentGuest: null,
    },
    extraGuest: {
      id: null,
      firstName: null,
      lastName: null,
    },
    card: {
      cvc: null,
      expMonth: null,
      expYear: null,
      holder: null,
      number: null,
      type: null,
    },
    address: {
      city: null,
      street: null,
      zip: null,
      country: null,
    },
    errors: {}
  }
}

const bookerForm = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_BOOKER_IN_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          [action.field]: action.payload
        }
      }
    case CLEAR_STATE:
    case CLEAR_PASSENGER_IN_FORM:
      return defaultState
    default:
      return state
  }
}

export default bookerForm
