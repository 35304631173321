import iuliia from 'iuliia'
import React, { useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import { useSelector } from 'react-redux'
import Icons from '../../../../pageComponents/icons'
import HotelsMapSearchController from './controller'
import _ from 'lodash'

const HotelsMapSearch = ({ map }) => {
  const [open, setOpen] = useState(false)
  const stay = useSelector(state => state.hotel.stay)
  const show = useSelector(state => state.hotel.autocomplete.show)
  const value = useSelector(state => state.hotel.autocomplete.value)
  const autocomplete = useSelector(state => state.hotel.autocomplete.features)
  const controller = new HotelsMapSearchController()
  const { t } = useTranslation()
  return (
    <div className={'hotelsMapSearch ' + (open && ' hotelsMapSearch__open')}>
      <button
        className='hotelsMapSearch__button'
        onClick={() => setOpen(!open)}
      >
        <Icons
          className='hotelsMapsSearch__icon'
          group='icon'
          icon='search'
        />
      </button>
      {open &&
        <>
          <input
            type='text'
            className='hotelsMapSearch__input'
            value={value}
            placeholder={t('Search by location')}
            onFocus={() => controller.setShow(!!value)}
            onInput={async (e) => {
              controller.setSearch(e)
              controller.getAutocomplete(stay, e.target.value)
            }}
          />
          <button
            className='hotelsMapSearch__cancel'
            onClick={() => controller.clearSearch()}
          >
            <Icons
              className='hotelsMapSearch__cancel-icon'
              group='icon'
              icon='cancel'
            />
          </button>
          {!show
            ? ''
            : (
              <OutsideClickHandler
                onOutsideClick={() => {
                  controller.setShow(false)
                }}
              >
                <div className='hotelsMapSearch__autocomplete'>
                  {_.map(
                    _.filter(autocomplete, el => {
                      const cityName = stay.city.toLowerCase()
                      const cityNameTranslate = iuliia.translate(cityName, iuliia.ICAO_DOC_9303)

                      return (
                        el?.place_name?.toLowerCase().includes(cityName) ||
                        el?.matching_place_name?.toLowerCase().includes(cityName) ||
                        el?.['place_name_en-us']?.toLowerCase().includes(cityName) ||
                        el?.place_name?.toLowerCase().includes(cityNameTranslate) ||
                        el?.matching_place_name?.toLowerCase().includes(cityNameTranslate) ||
                        el?.['place_name_en-us']?.toLowerCase().includes(cityNameTranslate)
                      )
                    }),
                    el =>
                      <div
                        key={el.id}
                        className='hotelsMapSearch__autocomplete-el'
                        onClick={() => controller.clickEl(el, map)}
                      >
                        <div className='hotelsMapSearch__autocomplete-name'>{el.text} {el?.address || ''}</div>
                        <div className='hotelsMapSearch__autocomplete-city'>{el.place_name}</div>
                      </div>
                  )}
                </div>
              </OutsideClickHandler>
              )}
        </>}
    </div>
  )
}

export default HotelsMapSearch
