import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import countries from '../../../../../data/iso3166'
import currentLocation from '../../../../../hooks/currentLocation'
import PassengerController from '../../controller'
import OutsideClickHandler from 'react-outside-click-handler'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import _ from 'lodash'


const Input = ({ field, error }) => {
  const { t } = useTranslation()
  const { isFlightsPage, isHotelsPage } = currentLocation()
  const user = useSelector(state => state.user)
  const controller = new PassengerController()
  const [openDropdown, setOpenDropdown] = useState(false)
  let passengerForm
  if (isFlightsPage) {
    passengerForm = useSelector(state => state.passengerForm)
  }
  if (isHotelsPage) {
    passengerForm = useSelector(state => state.bookerForm.form)
  }
  let value = passengerForm[field.field]

  if (field.field === 'frequentGuest' && typeof passengerForm[field.field] === 'object') {
    // const chains = _.first(hotelState.chains)
    // const chainsName = searchRS?.chains[chains]?.name
    // if (chainsName) {
    //   const findInPassengerChains = _.find(value, passengerChains => passengerChains.name === chainsName)
    //   value = findInPassengerChains ? findInPassengerChains.number : _.first(value).number
    // } else {
    value = _.first(value)?.number || ''
    // }
  }
  const valueTranslate = passengerForm[field.field + '-translate']
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (openDropdown)
          setOpenDropdown(false)
      }}
    >
      <div className='dropdown'>
        <label htmlFor={field.field}>
          {controller.getLabelAndOrder(field.field).label}{field.required ? '*' : ''}
        </label>
        <input
          type='text'
          name={field.field}
          id={field.field}
          className={'input ' + (openDropdown && 'focus')}
          value={value || ''}
          autoComplete={field.field.toLowerCase().includes('country') || field.field.includes('Name') ? 'nope' : 'on'}
          onFocus={() => { setOpenDropdown(true) }}
          onChange={controller.handleOnChange}
          tabIndex={controller.getLabelAndOrder(field.field).order}
          onKeyUp={controller.handleOnKeyUp}
          disabled={controller.checkReadOnlyStatus(field.field, user, passengerForm)}
        />
        {field.field.toLowerCase().includes('name') && valueTranslate &&
          <div className={'inputDiv__dropdown inputDiv__dropdown-top ' + (openDropdown && 'inputDiv__dropdown-open')}>
            <div className='inputDiv__dropdown-wrap'>
              <div
                className='inputDiv__dropdown-name'
                onClick={() => controller.handleOnClickTranslate(field.field, valueTranslate)}
              >
                {valueTranslate}
              </div>
            </div>
          </div>}
        {error &&
          <div className='error'>
            {error}
          </div>}
        {(field.field.toLowerCase().includes('country')) &&
          <div className={'inputDiv__dropdown ' + (openDropdown && 'inputDiv__dropdown-open')}>
            <div className='inputDiv__dropdown-wrap'>
              {Object.keys(countries)
                .sort((a, b) => {
                  return (t(countries[a].country)).localeCompare(t(countries[b].country))
                })
                .map(country => {
                  if (value && t(countries[country].country).toLowerCase().indexOf(value.toLowerCase()) === -1) {
                    return ''
                  }
                  return (
                    <div
                      key={'country' + country}
                      className='inputDiv__dropdown-country'
                      onClick={() => {
                        controller.handleOnChange({ target:{ name: field.field, value: country } })
                        setOpenDropdown(false)
                      }}
                    >
                      {t(countries[country].country)}
                    </div>
                  )
                })}
            </div>
          </div>}
      </div>
    </OutsideClickHandler>
  )
}

export default Input
