import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ConfirmationPaymentOptionsController from './controller'
import { getPriceWithCurrency } from '../../../utils/prices'

const ConfirmationPaymentOptions = () => {
  const { t, i18n } = useTranslation()
  const controller = new ConfirmationPaymentOptionsController()
  const hotelState = useSelector(state => state.hotel)
  const { priceInfo, prepayRequired } = hotelState.reserve.option

  return (
    <div className='confirmationPaymentOptions confirmationScreen__block'>
      <div className='block__header'>
        {t('Payment options')}
      </div>
      <div className='confirmationPaymentOptions__elements'>
        <div className='confirmationPaymentOptions__element confirmationPaymentOptions__element-active'>
          <div className='time'>
            {prepayRequired
              ? t('Pay now')
              : t('Pay later')}
            <div className='time__description'>
              {prepayRequired
                ? t('Use Debit/Credit card or PayPal')
                : t('You will not be charged until your stay')}
            </div>
          </div>
          <div className='price'>
            <div className='total'>
              {prepayRequired
                ? controller.getPriceValue(priceInfo.total)
                : getPriceWithCurrency(0, priceInfo.base.convertedCurrency || priceInfo.base.currency, i18n.language)}
            </div>
            <div className='small'>
              {t('Total for')} {controller.getNights(hotelState.checkinDate, hotelState.checkoutDate)} <br />
              {t('Includes taxes & fees')}
            </div>

            <div
              className='small' dangerouslySetInnerHTML={{
                __html:
                  (prepayRequired && priceInfo.taxes.total.value)
                    ? `${t('Taxes')} ${controller.getPriceValue(priceInfo.taxes.total)}`
                    : `${t('Pay at property')} <b>${controller.getPriceValue(priceInfo.total)}</b>`
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationPaymentOptions
