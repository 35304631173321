export const UPDATE_ORDER_FLIGHTS = 'UPDATE_ORDER_FLIGHTS'
export const UPDATE_ORDER_HOTELS = 'UPDATE_ORDER_HOTELS'
export const UPDATE_BOOKER_IN_FORM = 'UPDATE_BOOKER_IN_FORM'
export const UPDATE_PASSENGER_IN_FORM = 'UPDATE_PASSENGER_IN_FORM'
export const CLEAR_PASSENGER_IN_FORM = 'CLEAR_PASSENGER_IN_FORM'
export const UPDATE_PAYMENT_FORM = 'UPDATE_PAYMENT_FORM'
export const UPDATE_PAYMENT_IN_FORM = 'UPDATE_PAYMENT_IN_FORM'
export const UPDATE_ERROR_IN_FORM = 'UPDATE_ERROR_IN_FORM'
export const UPDATE_CORPORATE_CARD_PAYMENT_IN_FORM = 'UPDATE_CORPORATE_CARD_PAYMENT_IN_FORM'
export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD'
export const UPDATE_BOOKER = 'UPDATE_BOOKER'
export const CLEAR_BOOKER = 'CLEAR_BOOKER'
export const UPDATE_EXTRA_GUEST = 'UPDATE_EXTRA_GUEST'
export const CREATE_EXTRA_GUEST = 'CREATE_EXTRA_GUEST'
export const CLEAR_EXTRA_GUEST = 'CLEAR_EXTRA_GUEST'
export const UPDATE_PASSENGER = 'UPDATE_PASSENGER'
export const CLEAR_PASSENGER = 'CLEAR_PASSENGER'
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT'
export const CLEAR_PAYMENT = 'CLEAR_PAYMENT'
export const UPDATE_PRICE_RS = 'UPDATE_PRICE_RS'
export const CLEAR_PRICE_RS = 'CLEAR_PRICE_RS'
export const UPDATE_ORDER_RS = 'UPDATE_ORDER_RS'
export const CLEAR_ORDER_RS = 'UPDATE_ORDER_RS'
export const UPDATE_ORDER_RESERVE = 'UPDATE_ORDER_RESERVE'

export const updateOrderFlights = (field, payload) => ({
  type: UPDATE_ORDER_FLIGHTS,
  field,
  payload
})
export const updateOrderHotels = (field, payload) => ({
  type: UPDATE_ORDER_HOTELS,
  field,
  payload
})

export const updateBookerInForm = (field, payload) => ({
  type: UPDATE_BOOKER_IN_FORM,
  field,
  payload
})
export const updatePassengerInForm = (field, payload) => ({
  type: UPDATE_PASSENGER_IN_FORM,
  field,
  payload
})
export const clearPassengerInForm = () => ({
  type: CLEAR_PASSENGER_IN_FORM,
})
export const updatePassenger = (number, payload) => ({
  type: UPDATE_PASSENGER,
  number,
  payload
})
export const updateBooker = (payload) => ({
  type: UPDATE_BOOKER,
  payload
})
export const clearBooker = () => ({
  type: CLEAR_BOOKER
})
export const updateExtraGuest = (number, payload) => ({
  type: UPDATE_EXTRA_GUEST,
  number,
  payload
})
export const createExtraGuest = (payload) => ({
  type: CREATE_EXTRA_GUEST,
  payload
})
export const clearExtraGuest = (number) => ({
  type: CLEAR_EXTRA_GUEST,
  number
})
export const clearPassenger = (number) => ({
  type: CLEAR_PASSENGER,
  number,
})


export const updatePaymentForm = (payload) => ({
  type: UPDATE_PAYMENT_FORM,
  payload
})
export const updatePaymentInForm = (object, field, payload) => ({
  type: UPDATE_PAYMENT_IN_FORM,
  object,
  field,
  payload
})
export const updateErrorInForm = () => ({
  type: UPDATE_ERROR_IN_FORM
})
export const updateCorporateCardPaymentInForm = (payload) => ({
  type: UPDATE_CORPORATE_CARD_PAYMENT_IN_FORM,
  payload
})
export const updatePaymentMethod = (payload) => ({
  type: UPDATE_PAYMENT_METHOD,
  payload
})
export const updatePayment = (payload) => ({
  type: UPDATE_PAYMENT,
  payload
})
export const clearPayment = () => ({
  type: CLEAR_PAYMENT,
})

export const updateOrderRS = (payload) => ({
  type: UPDATE_ORDER_RS,
  payload
})
export const clearOrderRS = (payload) => ({
  type: CLEAR_ORDER_RS,
  payload
})

export const updatePriceRS = (payload) => ({
  type: UPDATE_PRICE_RS,
  payload
})
export const clearPriceRS = (payload) => ({
  type: CLEAR_PRICE_RS,
  payload
})

export const updateReseveRS = (payload) => ({
  type: UPDATE_ORDER_RESERVE,
  payload
})
