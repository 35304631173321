import React from 'react'
import './style.sass'

interface CheckboxProps {
  id: string
  checked: boolean
  name: string
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void
}

export const Checkbox: React.FC<CheckboxProps> = React.memo(({ id, checked, name, onChange }) => {
  return (
    <div className='checkbox'>
      <input
        className='checkbox__input'
        type='checkbox'
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label className='checkbox__label' htmlFor={id}>
        {name}
      </label>
    </div>
  )
})
