export const UPDATE_SEARCH_RS_MODEL = 'UPDATE_SEARCH_RS_MODEL'
export const UPDATE_SEARCH_RS_STATUS = 'UPDATE_SEARCH_RS_STATUS'
export const SET_SEARCH_FORM = 'SET_SEARCH_FORM'
export const UPDATE_SEARCH_FORM = 'UPDATE_SEARCH_FORM'
export const UPDATE_SEARCH_FORM_AUTOCOMPLETE = 'UPDATE_SEARCH_FORM_AUTOCOMPLETE'
export const CHANGE_DIRECTION = 'CHANGE_DIRECTION'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'


export const setSearchForm = (payload) => ({
  type: SET_SEARCH_FORM,
  payload
})

export const updateSearchForm = (name, field, value) => ({
  type: UPDATE_SEARCH_FORM,
  payload: {
    name,
    field,
    value
  }
})
export const updateSearchFormAutocomplete = (name, type, value) => ({
  type: UPDATE_SEARCH_FORM_AUTOCOMPLETE,
  payload: {
    name,
    type,
    value
  }
})
export const updateSearchRS = (model) => ({
  type: UPDATE_SEARCH_RS_MODEL,
  model
})
export const updateSearchRSStatus = (payload) => ({
  type: UPDATE_SEARCH_RS_STATUS,
  payload
})
export const changeDirection = () => ({
  type: CHANGE_DIRECTION
})
export const clearSearchRS = () => ({
  type: CLEAR_SEARCH
})
