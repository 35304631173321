import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Flight from './_flight'
import _ from 'lodash'

const Flights = () => {
  const { t } = useTranslation()
  const segments = useSelector(state => state.orderRS.model.order.segments)
  return (
    <div className='flights'>
      <div className='flights__header'>
        {t('booked flights')}
      </div>
      {_.map(segments, (segment, index) => <Flight key={'flight' + index} segment={segment} />)}
    </div>
  )
}

export default Flights
