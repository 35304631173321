import AnalyticsHotel from './index'

const generateSearchString = (hotel, hotelData, searchRS) => {
  return [hotel.key,
    `price_total=${hotel.price.value}`,
    `cur=${hotel.price.currency}`,
    hotelData.checkinDate,
    hotelData.checkoutDate,
    hotelData.guests.people,
    searchRS.status === 'success' ? 'end' : 'go'
  ].join(';')
}

export default class ResultsAnalytics extends AnalyticsHotel {
  showHotels = (counts) => {
    this.logEvent(
      this.NAMES.RESULT,
      this.FEATURE.HOTEL_LIST,
      'main',
      this.ACTIONS.SEE,
      typeof counts === 'string'
        ? counts
        : counts === 0
          ? 'false'
          : 'true'
    )
  }

  showHotelFromList = (hotel, hotelData, searchRS) => {
    this.logEvent(
      this.NAMES.RESULT,
      this.FEATURE.HOTEL_LIST,
      'hotel',
      this.ACTIONS.SEE,
      generateSearchString(hotel, hotelData, searchRS)
    )
  }

  clickDetailInformation = (hotel, hotelData, searchRS) => {
    this.logEvent(
      this.NAMES.RESULT,
      this.FEATURE.HOTEL_LIST,
      'hotel',
      this.ACTIONS.TAP,
      generateSearchString(hotel, hotelData, searchRS)
    )
  }

  clickPhoto = (hotel, hotelData, searchRS) => {
    this.logEvent(
      this.NAMES.RESULT,
      this.FEATURE.HOTEL_LIST,
      'photo',
      this.ACTIONS.TAP,
      generateSearchString(hotel, hotelData, searchRS)
    )
  }

  scrollPhoto = (hotel, hotelData, searchRS) => {
    this.logEvent(
      this.NAMES.RESULT,
      this.FEATURE.HOTEL_LIST,
      'photo',
      this.ACTIONS.SCROLL,
      generateSearchString(hotel, hotelData, searchRS)
    )
  }

  clickDetailInformationMap = (hotel, hotelData, searchRS) => {
    this.logEvent(
      this.NAMES.RESULT,
      this.FEATURE.HOTEL_MAP,
      'hotel',
      this.ACTIONS.TAP,
      generateSearchString(hotel, hotelData, searchRS)
    )
  }

  changeSearchByProperty = (value) => {
    this.logEvent(
      this.NAMES.RESULT,
      this.FEATURE.SORT,
      'searchByName',
      this.ACTIONS.TAP,
      value
    )
  }

  sortByPrice = (order) => {
    this.logEvent(
      this.NAMES.RESULT,
      this.FEATURE.SORT,
      'sortButton',
      this.ACTIONS.TAP,
      [
        'price',
        order ? 'DESC' : 'ASC'
      ].join(';')
    )
  }
}
