import { DeviceUUID } from 'device-uuid'
import { Base64 } from 'js-base64'
import { Cookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import Api from '../../../api'
import { updateUser } from '../../../reducers/user/actions'
import OrdersController from '../../../components/ordersComponents/_orders/controller'
import { ORDERS_LIST_KEY, ORDERS_STATE_KEY } from 'modules/orders/constants'
import { CLIENT_MODE_BUSINESSES } from '../../../reducers/user/constans'

export default class LoginController {
  constructor (ordersIds, ordersList, setOrdersIds, setOrdersList) {
    this.api = new Api()
    this.dispatch = useDispatch()
    this.ordersController = new OrdersController(ordersIds, ordersList, setOrdersIds, setOrdersList)
  }

  newVisit = () => {
    const params = new URLSearchParams(window.location.search)
    const code = params.get('code')
    const token = params.get('token')
    const clientMode = params.get('clientMode')
    const cookies = new Cookies()

    if (token) {
      this._clearOrders()
      setToken({
        success: true,
        auth_token: token
      })
      if (clientMode && clientMode === CLIENT_MODE_BUSINESSES) {
        cookies.set('clientMode', clientMode, {
          maxAge: 60 * 60 * 24 * 10,
          secure: true,
          path: '/'
        })
      }
      cookies.set('oldLocation', window.location.origin + window.location.pathname, {
        maxAge: 60,
        secure: true,
        path: '/'
      })
      if (cookies.get('oldLocation')) {
        window.location.href = cookies.get('oldLocation')
      } else {
        window.location.href = '/flights'
      }
    } else if (code) {
      this._clearOrders()
      sendFetch('', code)
        .then(setToken)
        .then(() => {
          if (cookies.get('oldLocation')) {
            window.location.href = cookies.get('oldLocation')
          } else {
            window.location.href = '/flights'
          }
        })
    } else {
      if (cookies.get('auth_token')) {
        const expireDate = cookies.get('auth_expire_date')
        if (window.location.pathname === '/login') {
          if (cookies.get('oldLocation')) {
            window.location.href = cookies.get('oldLocation')
          } else {
            window.location.href = '/flights'
          }
        } else if (!expireDate || expireDate < Date.now()) {
          sendFetch('/renew-token')
            .then(setToken)
            .then(this.getProfile)
        } else {
          this.getProfile()
        }
      } else {
        if (window.location.pathname !== '/login') {
          window.location.href = '/login'
        }
      }
    }
  }

  generateLink = () => {
    const params = new URLSearchParams()
    params.set('client_id', process.env.REACT_APP_GOOGLE_CLIENT_ID)
    params.set('response_type', 'code')
    params.set('redirect_uri', process.env.REACT_APP_APPSPOT_URL + '/api/user/web/auth')
    params.set('scope', 'email profile')
    params.set('access_type', 'offline')
    params.set('state', generateState())
    return process.env.REACT_APP_GOOGLE_BASE_API_URL + '?' + params.toString()
  }

  getProfile = () => {
    this.api.getProfile()
      .then(this.setProfile)

    // TODO вернуть при деплоии business логики
    // this.api.getBusinessData()
    //   .then(this.setBusinessDate)
  }

  setProfile = (profile) => {
    if (profile && profile.success) {
      this.dispatch(updateUser('profile', profile))
    }
  }

  setBusinessDate = (business) => {
    if (business && business.success) {
      this.dispatch(updateUser('business', business))
      this.api.getBusinessPermissions()
        .then(this.setBusinessInfo)
    }
  }

  setBusinessInfo = (businessInfo) => {
    if (businessInfo && businessInfo.success) {
      this.dispatch(updateUser('businessInfo', businessInfo))
    }
  }

  logout = (setOrdersIds, setOrdersList) => {
    this._clearOrders()
    sendFetch('/logout')
      .then(res => {
        if (res.success) {
          clearUserData(setOrdersIds, setOrdersList)
          window.location.href = '/login'
        }
      })
  }

  _clearOrders = () => {
    window.localStorage.removeItem('newOrdersList')
    window.localStorage.removeItem('ordersState')
    window.localStorage.removeItem(ORDERS_LIST_KEY)
    window.localStorage.removeItem(ORDERS_STATE_KEY)
  }
}

const generateState = () => {
  const deviceUUID = new DeviceUUID()
  const du = deviceUUID.parse()

  return Base64.encode(
    JSON.stringify({
      'redirect_url': process.env.REACT_APP_GOOGLE_BASE_FRONT_URL,
      'app_version': process.env.REACT_APP_VERSION,
      'device_id': deviceUUID.get(),
      'device_name': du.browser,
      'app_name': du.source,
      'lang': du.language,
    })
  )
}

const sendFetch = async (url, code) => {
  const cookies = new Cookies()
  // eslint-disable-next-line no-undef
  const res = await fetch(process.env.REACT_APP_APPSPOT_URL + '/api/user/web/auth' + url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': '*',
      'Aita-Api-Environment': process.env.REACT_APP_API_ENVIRONMENT,
      'Authorization': 'Bearer ' + cookies.get('auth_token'),
      'aita-user-agent': 'AitaWeb/1.0.0',
    },
    body: code ? JSON.stringify({ code }) : ''
  })

  return res.json()
}

const setToken = (res) => {
  const cookies = new Cookies()
  if (res && res.success) {
    cookies.set('auth_token', res.auth_token, {
      maxAge: 60 * 60 * 24 * 10,
      secure: true,
      path: '/'
    })
    cookies.set('auth_expire_date', Date.now() + (360000 * 24), {
      maxAge: 60 * 60 * 24 * 10,
      secure: true,
      path: '/'
    })
  }
}

const clearUserData = (setOrdersIds, setOrdersList) => {
  const cookies = new Cookies()
  cookies.set('auth_token', '', {
    maxAge: -1
  })
  cookies.set('auth_expire_date', '', {
    maxAge: -1
  })
  cookies.set('oldLocation', '', {
    maxAge: -1
  })
  cookies.set('clientMode', '', {
    maxAge: -1
  })
  setOrdersIds(undefined)
  setOrdersList(undefined)
}

