import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'

const SelectFlight = ({ onClick, openDetail }) => {
  const { t } = useTranslation()

  return (
    <div className={'selectFlight ' + (openDetail ? 'selectFlight-show' : 'selectFlight-hide')}>
      <button
        className='selectFlight__button'
        onClick={onClick}
      >
        {t('Select flight')}
      </button>
    </div>
  )
}

export default SelectFlight
