import { useEffect, useMemo } from 'react'
import _ from 'lodash'

import Api from 'api'
import { ORDERS_SCREEN_PAST, ORDERS_SCREEN_UPCOMING } from 'reducers/page/types'
import { ORDERS_PAGE, ORDERS_PAGE_PAST } from 'navigation/routes'
import { useDispatchAction, useLocalStorage, getLocalStorageValue, useLoadBar, usePage } from 'modules/common'
import { EMPTY_OBJECT } from 'modules/common/constants'
import { updateOrders } from 'reducers/orders/actions'

import { sortOrders } from '../utils/sortOrders'
import { OrdersStateType, OrderType } from '../types'
import { checkIfObjectIsEmpty } from 'modules/common/utils/object'
import useUpdate from 'modules/common/hooks/useUpdate'
import { ORDERS_LIST_KEY, ORDERS_STATE_KEY } from '../constants'

const MAX_IDS_PER_REQUEST = 5

export const useOrders = () => {
  const api = new Api()
  const page = usePage()
  const [newOrdersList, setNewOrdersList, resetOrdersList] =
    useLocalStorage<Record<string, OrderType>>(ORDERS_LIST_KEY, EMPTY_OBJECT)
  const [ordersState, setOrdersState, resetOrdersState] =
    useLocalStorage<OrdersStateType>(ORDERS_STATE_KEY, EMPTY_OBJECT)

  const update = useUpdate()

  const [showLoadBar, hideLoadBar] = useLoadBar()

  useDispatchAction(updateOrders('cleanOrders', newOrdersList), [newOrdersList])
  const list = useMemo(() => {
    if (_.size(newOrdersList) > 0) {
      const cleanedList = _.values(removeOrders(newOrdersList, ordersState.deleted)).filter(Boolean)
      if (page.name === ORDERS_SCREEN_UPCOMING) {
        return cleanedList.sort((a, b) => sortOrders(a, b, 'asc'))
      } else {
        return cleanedList.sort((a, b) => sortOrders(a, b, 'desc'))
      }
    }
    return []
  }, [Object.keys(newOrdersList)])

  useEffect(() => {
    if (page.name === ORDERS_SCREEN_UPCOMING || page.name === ORDERS_SCREEN_PAST) {
      if ((checkIfObjectIsEmpty(newOrdersList) || checkIfObjectIsEmpty(ordersState))) {
        resetOrdersState()
        resetOrdersList()
      }
      getOrders()
    }
  }, [page.name])

  const getOrders = async () => {
    try {
      if (page.name === ORDERS_PAGE_PAST || page.name === ORDERS_SCREEN_UPCOMING) {
        showLoadBar(ORDERS_PAGE)
      }
      let stateParams = null

      if (ordersState.state) {
        stateParams = new URLSearchParams({
          state: ordersState.state
        })
      }
      // Получаем updated и deleted с бэка
      const orderStateRS = await api.getOrdersIds(stateParams)

      if (stateParams) {
        if (orderStateRS.success && _.size(orderStateRS.deleted)) {
          const orderList = removeOrders(newOrdersList, orderStateRS.deleted)
          setNewOrdersList(orderList)
        }
      }
      // Проверяем, что всё успешно и есть updated
      if (orderStateRS?.success && _.size(orderStateRS?.updated)) {
        // Забираем предыдущий стейт из localStorage
        const prevOrdersState = getLocalStorageValue<OrdersStateType>(ORDERS_STATE_KEY, EMPTY_OBJECT)

        // Проверяем, что предыдущий стейт не равен новому
        if (prevOrdersState?.state !== orderStateRS?.state) {
          const chunks = await getOrdersChunk(orderStateRS.updated)
          // Счётчик, чтобы определить, сколько чанков было запрошено
          let count = 0
          // Запрашиваем каждый ордеры по очереди
          for await (const ids of chunks) {
            await getOrdersInfo(ids, api).then((orderInfo) => {
              const order = filterOrders(orderInfo)
              // Добавляем новые ордеры
              setNewOrdersList((val) => _.assign(val, order))
              ++count
              // Если было запрошено столько же чанков, сколько и было изначально, то скрываем лоадбар
              if (count === chunks.length) {
                if (page.name === ORDERS_PAGE_PAST || page.name === ORDERS_SCREEN_UPCOMING) {
                  hideLoadBar()
                }
              }
              // Убираем удаленные оредры из списка
              if (stateParams) {
                if (orderStateRS.success && _.size(orderStateRS.deleted)) {
                  const orderList = removeOrders(newOrdersList, orderStateRS.deleted)
                  setNewOrdersList(orderList)
                }
              }
              // Форсируем рендер
              update()
            })
          }
        }
      } else {
        if (page.name === ORDERS_PAGE_PAST || page.name === ORDERS_SCREEN_UPCOMING) {
          hideLoadBar()
        }
      }


      setOrdersState(orderStateRS)
    } catch (error) {
      console.error(error)
      hideLoadBar()
    }
  }
  return list
}

const getOrdersChunk = async (updateIds: string[]) => {
  const chunksIds = _.chunk(updateIds, MAX_IDS_PER_REQUEST)
  return chunksIds
}


const getOrdersInfo = async (ids: string[], api) => {
  if (_.size(_.compact(ids))) {
    const idsParams = new URLSearchParams({
      ids: _.compact(ids) as any
    })
    return api.getOrdersInfo(idsParams)
  }
}

const filterOrders = (info) => {
  const cleanOrders = {}
  const newOrdersList = getLocalStorageValue<OrdersStateType>(ORDERS_LIST_KEY, EMPTY_OBJECT)
  if (info?.success && info?.trips.length) {
    const orders = (info.trips as OrderType[]).filter(order =>
      (_.size(_.first(order.flights)?.aita_order) ||
          _.size(_.first(order.hotels)?.aita_order))
    )
    const filteredOrders = orders.filter(order => !order.deleted)
    _.forEach(filteredOrders, order => {
      cleanOrders[order.id] = order
    })

    _.assign(newOrdersList, cleanOrders)

    return newOrdersList
  }
  return newOrdersList
}


const removeOrders = (orders: Record<string, OrderType>, deleteIds: string[] = []) => {
  const newOrdersList = _.assign({}, orders)
  _.forEach(deleteIds, deleteId => {
    newOrdersList[deleteId] = undefined
  })
  return newOrdersList
}
