import React, { useState } from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import usePrice from '../../../hooks/usePrice'
import { updateButton } from '../../../reducers/button/actions'
import { PAY_BUTTON } from '../../../reducers/button/types'
import { showInfo } from '../../../reducers/infoPopup/actions'
import InfoPopup from '../../pageComponents/infoPopup'
import SummaryController from './controller'
import FlightRow from './_flightRow'
import Card from '../_card'
import LoadButton from '../../pageComponents/loadButton'
import OrderType from '../../../data/OrderType'
import Iframe3DS from '../../confirmationComponents/_Iframe3DS'

const Summary = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [iFrame3DSpaymentUrl, setIFrame3DSpaymentUrl] = useState(false)
  const searchRS = useSelector(state => state.searchRS.model)
  const outbound = useSelector(state => state.itineraries.outbound)
  const inbound = useSelector(state => state.itineraries.inbound)
  const pricingOption = useSelector(state => state.priceRS.model.pricingOption)
  const isTwoStepBooking = useSelector(state => state.priceRS.model.isTwoStepBooking)
  const currencies = searchRS.currencies
  const price = usePrice(currencies, pricingOption)
  const controller = new SummaryController()
  const order = useSelector(state => state.orderFlights)
  const passengers = order.passengers
  const payment = order.payment
  const currency = useSelector(state => state.searchForm.currency)
  const payButton = useSelector(state => state.button[PAY_BUTTON])
  const error = useSelector(state => state.error)
  const orderReserve = useSelector(state => state.orderReserve.model)
  const user = useSelector(state => state.user)

  return (
    <div className='summary'>
      <div className='block__header'>
        {t('Summary')}
      </div>
      <div className='summary__flight'>
        <div className='subhead'>
          {t('Flight')}
        </div>
        <div className='summary__outbound'>
          {outbound.outbound.map(itinerary => <FlightRow key={itinerary} itineraries={[itinerary]} />)}
        </div>
        {inbound &&
        (
          <div className='summary__inbound'>
            {inbound.inbound.map(itinerary => <FlightRow key={itinerary} itineraries={[itinerary]} />)}
          </div>
        )}

      </div>
      <div className='summary__card'>
        <div className='subhead'>
          {t('Pay with')}
        </div>
        <Card />
      </div>
      <div className='summary__total'>
        <div className='text'>
          {t('Total')}:
        </div>
        <div className='price'>
          {price.value}
          {currency !== 'USD' &&
            <span>
              {price.valueUSD}
            </span>}
        </div>
      </div>
      {(!error.hasError && controller.showBookButton(passengers, payment)) &&
        <div className='book'>
          <button
            className='book__button'
            disabled={payButton.disabled}
            onClick={() => {
              if (!orderReserve.orderId) {
                controller.handleClickBook(
                  order,
                  currency,
                  pricingOption,
                  setIFrame3DSpaymentUrl,
                  isTwoStepBooking,
                  user
                )
              } else {
                dispatch(showInfo())
                dispatch(updateButton(PAY_BUTTON, 'disabled', true))
                dispatch(updateButton(PAY_BUTTON, 'isLoading', true))
              }
            }}
          >
            {payButton.isLoading
              ? <LoadButton />
              : t('Book for') + ' ' + price.value}
          </button>
        </div>}
      <Iframe3DS
        iFrame3DSpaymentUrl={iFrame3DSpaymentUrl}
        setIFrame3DSpaymentUrl={setIFrame3DSpaymentUrl}
        orderType={OrderType.FLIGHT}
      />
      <InfoPopup
        order={order}
        currency={currency}
        pricingOption={pricingOption}
        setIFrame3DSpaymentUrl={setIFrame3DSpaymentUrl}
      />
    </div>
  )
}

export default Summary
