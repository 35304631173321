import moment from 'moment'
import AnalyticsFlight from './index'

const search = (action, label) => {
  const analytics = new AnalyticsFlight()
  analytics.logEvent(
    analytics.NAMES.SEARCH,
    analytics.FEATURE.MAIN,
    'searchButton',
    action,
    label || ''
  )
}

const generateSearchString = (searchForm) => {
  return [
    searchForm.origin.code,
    searchForm.destination.code,
    [searchForm.calendar.outbound, searchForm.calendar.inbound]
      .filter(date => moment(date).isValid())
      .map(date => moment(date).format('YYYY/MM/DD'))
      .join(';'),
    searchForm.passengers.value,
    searchForm.cabin.value
  ].join(';')
}

export default class SearchAnalytic extends AnalyticsFlight {
  startSession = () => search(this.ACTIONS.SEE)

  startSearch = (searchForm) => search(this.ACTIONS.TAP, generateSearchString(searchForm))

  searchSuccess = (searchForm) => search(this.ACTIONS.SUCCESS, generateSearchString(searchForm))

  searchError = (error) => search(this.ACTIONS.ERROR, error)


  setPassenger = (passenger) => {
    this.logEvent(
      this.NAMES.SEARCH,
      'setPassanger',
      'selectPassanger',
      this.ACTIONS.TAP,
      Object.values(passenger).join(';')
    )
  }

  setClasses = (classes) => {
    this.logEvent(
      this.NAMES.SEARCH,
      'setClasses',
      'selectClasses',
      this.ACTIONS.TAP,
      classes
    )
  }

  setAirport = (type, code) => {
    this.logEvent(
      this.NAMES.SEARCH,
      'directions',
      type === 'origin' ? 'departureAirport' : 'arrivalAirport',
      this.ACTIONS.TAP,
      code
    )
  }

  setDate = (dates) => {
    this.logEvent(
      this.NAMES.SEARCH,
      'dates',
      dates ? 'selectDate' : 'resetDates',
      this.ACTIONS.TAP,
      dates
        ? [dates.outbound, dates.inbound]
            .filter(date => moment(date).isValid())
            .map(date => moment(date).format('YYYY/MM/DD'))
            .join(';')
        : ''
    )
  }
}
