import { SET_PREV_PAGE } from './types'

export const CHANGE_PAGE = 'CHANGE_PAGE'
export const CHANGE_LOCATION = 'CHANGE_LOCATION'

export const changePage = (payload) => ({
  type: CHANGE_PAGE,
  payload
})

export const changeLocation = (payload) => ({
  type: CHANGE_LOCATION,
  payload
})

export const setPrevPage = (payload) => ({
  type: SET_PREV_PAGE,
  payload
})
