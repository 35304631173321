import moment from 'moment'
import AnalyticsHotel from './index'

const search = (action, label) => {
  const analytics = new AnalyticsHotel()
  analytics.logEvent(
    analytics.NAMES.SEARCH,
    analytics.FEATURE.MAIN,
    'searchButton',
    action,
    label || ''
  )
}

const generateSearchString = (hotel, searchForm) => {
  const baseFunctions = new AnalyticsHotel()
  return [
    hotel.whereStay || searchForm.whereStay.stay.city,
    `cur=${searchForm.currency}`,
    moment(searchForm.calendar.start).format('YYYY-MM-DD'),
    moment(searchForm.calendar.end).format('YYYY-MM-DD'),
    baseFunctions.separationIntoAdultAndChild(hotel.guests.people).join(';')
  ].join(';')
}

export default class SearchAnalytic extends AnalyticsHotel {
  startSession = () => search(this.ACTIONS.SEE)

  startSearch = (hotel, searchForm) => {
    this.logEvent(
      this.NAMES.SEARCH,
      this.FEATURE.MAIN,
      'searchButton',
      this.ACTIONS.TAP,
      generateSearchString(hotel, searchForm)
    )
  }

  searchStartSuccessfull = (hotel, searchForm) => {
    this.logEvent(
      this.NAMES.SEARCH,
      this.FEATURE.MAIN,
      'searchButton',
      this.ACTIONS.SUCCESS,
      generateSearchString(hotel, searchForm)
    )
  }

  searchError = (error) => search(this.ACTIONS.ERROR, error)

  setGuests = (guests) => {
    this.logEvent(
      this.NAMES.SEARCH,
      this.FEATURE.SET_GUESTS,
      'selectGuests',
      this.ACTIONS.TAP,
      this.separationIntoAdultAndChild(guests).join(';')
    )
  }

  clickRooms = () => {
    this.logEvent(
      this.NAMES.SEARCH,
      this.FEATURE.SET_GUESTS,
      'addRoom',
      this.ACTIONS.TAP,
      ''
    )
  }

  searchLocation = (location) => {
    this.logEvent(
      this.NAMES.SEARCH,
      this.FEATURE.MAIN,
      'location',
      this.ACTIONS.TAP,
      location
    )
  }

  setDate = (dates) => {
    this.logEvent(
      this.NAMES.SEARCH,
      this.FEATURE.DATES,
      dates ? 'selectDate' : 'resetDates',
      this.ACTIONS.TAP,
      dates
        ? [dates.outbound, dates.inbound]
            .filter(date => moment(date).isValid())
            .map(date => moment(date).format('YYYY/MM/DD'))
            .join(';')
        : ''
    )
  }
}
