import React from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import Icons from '../../pageComponents/icons'
import SummaryController from './controller'

const FlightRow = ({ itineraries }) => {
  const { i18n } = useTranslation()
  const controller = new SummaryController()
  const flightInfo = controller.getFlightInfo(itineraries)
  return (
    <div className='flight__row '>
      <div className='date'>
        <Moment
          format='MMM D, ddd'
          locale={i18n.language}
        >
          {flightInfo.departureDate}
        </Moment>
      </div>
      <div className='direction'>
        <Moment
          format='LT'
          locale={i18n.language}
        >
          {flightInfo.departureDate}
        </Moment> &nbsp;
        {flightInfo.origin}
        <div className='tooltip-hoverElement'>
          <Icons
            group='arrow'
            icon='aircraft'
            className='direction__icon'
            color='#ADADAD'
          />
        </div>
        {flightInfo.destination} &nbsp;
        <Moment
          format='LT'
          locale={i18n.language}
        >
          {flightInfo.arrivalDate}
        </Moment>
      </div>
    </div>
  )
}

export default FlightRow
