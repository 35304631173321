import { useDispatch } from 'react-redux'
import SearchAnalyticFlight from '../../../analytics/analyticsFlight/searchAnalytics'
import SearchAnalyticHotel from '../../../analytics/analyticsHotel/searchAnalytics'
import Api from '../../../api'
import currentLocation from '../../../hooks/currentLocation'
import { SEARCH_SCREEN } from '../../../reducers/page/types'
import { updateSearchForm } from '../../../reducers/search/actions'

export default class DatePickerController {
  constructor () {
    this.api = new Api()
    this.dispatch = useDispatch()
    this.analyticsFlight = new SearchAnalyticFlight()
    this.analyticsHotel = new SearchAnalyticHotel()
    this.isFlightsPage = currentLocation().isFlightsPage
    this.isHotelsPage = currentLocation().isHotelsPage
  }

  handleShowChange = (show) => {
    this.dispatch(updateSearchForm('calendar', 'visible', show))
    this.dispatch(updateSearchForm('calendar', 'error', false))
  }

  handleOnClickDayCalendar = (day, calendar, isHotelPage) => {
    const { start, end } = calendar
    let newOutbound = start
    let newInbound = end
    // пустые даты
    if (!start && !end) {
      newOutbound = day
    }
    // есть только первая дата
    if (start && !end) {
      // вторая дата меньше текушей?
      if (day < start) {
        newOutbound = day
        newInbound = start
      } else if (day > start) {
        newInbound = day
      } else {
        if (!isHotelPage) {
          newOutbound = day
          newInbound = day
        }
      }
    }
    // есть две даты
    if (start && end) {
      if (this.isHotelsPage) {
        if (day !== start) {
          newOutbound = day
          newInbound = null
        }
      } else if (+day === +start || +day === +end) {
        if (+day === +start) {
          // выбор равен первому дню, оставляем одну дату
          newOutbound = day
          newInbound = null
        } else if (+day === +end) {
          // выбор равен второму дню, оставляем одну дату
          newOutbound = day
          newInbound = null
        }
      } else if (day < start) {
        // выбор меньше первой, ставм новую на первое место
        newOutbound = day
      } else if (day > end) {
        // выбор больше второго, ставм новую на второе место
        newInbound = day
      } else {
        // выбор между выбраными датами, смотрим какая ближе
        if (day - start < end - day) {
          newOutbound = day
        } else {
          newInbound = day
        }
      }
    }
    this.dispatch(updateSearchForm('calendar', 'end', newInbound))
    this.dispatch(updateSearchForm('calendar', 'start', newOutbound))
    if (this.isFlightsPage) {
      this.analyticsFlight.setDate({ inbound: newInbound, outbound: newOutbound })
    } else if (this.isHotelsPage) {
      this.analyticsHotel.setDate({ inbound: newInbound, outbound: newOutbound })
    }
  }

  handleToReset = () => {
    this.dispatch(updateSearchForm('calendar', 'start', null))
    this.dispatch(updateSearchForm('calendar', 'end', null))
    if (this.isFlightsPage) {
      this.analyticsFlight.setDate()
    } else if (this.isHotelsPage) {
      this.analyticsHotel.setDate()
    }
  }

  setStartDate = (page, isHotelsPage) => {
    if (isHotelsPage) {
      this.dispatch(updateSearchForm('calendar', 'start', (new Date()).addDays(1)))
      this.dispatch(updateSearchForm('calendar', 'end', (new Date()).addDays(2)))
    } else if (!isHotelsPage && page === SEARCH_SCREEN) {
      this.dispatch(updateSearchForm('calendar', 'start', null))
      this.dispatch(updateSearchForm('calendar', 'end', null))
    }
  }
}
