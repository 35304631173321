import { useDispatch } from 'react-redux'
import FilterAnalyticsHotel from '../../../analytics/analyticsHotel/filterAnalytics'
import { changeBreakfast } from '../../../reducers/filter/actions'

export default class BreakfastController {
  constructor () {
    this.dispatch = useDispatch()
    this.analyticsHotel = new FilterAnalyticsHotel()
  }

  handleClick = (breakfast) => {
    this.analyticsHotel.setBreakfast(breakfast)
    this.dispatch(changeBreakfast(breakfast))
  }
}
