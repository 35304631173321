import { getPriceWithCurrency } from '../../../../../utils/prices'

export default class HotelPriceController {
  getBigPrice = (priceInfo, i18n) => {
    const lang = i18n.language
    if (lang.includes('ru')) {
      return getPrice(priceInfo.total, lang)
    } else {
      return getPrice(priceInfo.base, lang)
    }
  }

  getTaxesPrice = (priceInfo, i18n) => {
    const lang = i18n.language
    if (lang.includes('ru')) {
      return getPrice(priceInfo.base, lang)
    } else {
      return getPrice(priceInfo.total, lang)
    }
  }
}

const getPrice = (price, locale) => {
  const { convertedValue, value, convertedCurrency, currency } = price
  const currentValue = convertedValue || value
  const roundValue = currentValue.toFixed(2).replace(/\.0+$/, '')
  return getPriceWithCurrency(Number(roundValue), convertedCurrency || currency, locale)
}
