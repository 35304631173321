import React from 'react'
import './style.sass'

const InputDropdown = ({ child, visible }) => {
  return (
    <div className={'inputDropdown ' + (visible && 'inputDropdown__open')}>
      <div className='inputDropdown__wrap'>
        {child}
      </div>
    </div>
  )
}

export default InputDropdown
