import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import { useSelector } from 'react-redux'
import FareItineraryController from '../controller'

const DateFare = ({ element, type }) => {
  const { i18n } = useTranslation()
  const searchRS = useSelector(state => state.searchRS.model)
  const controller = new FareItineraryController()
  return (
    <div className='dateFare'>
      <Moment
        format='MMM D, ddd'
        locale={i18n.language}
      >
        {controller.getDate(searchRS, element[type])}
      </Moment>
    </div>
  )
}

export default DateFare
