import iuliia from 'iuliia'
import parsePhoneNumber from 'libphonenumber-js'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Api from '../../../api'
import createPassengerObject from '../../../hooks/createPassengerObject'
import currentLocation from '../../../hooks/currentLocation'
import passengerValidation from '../../../hooks/passengerValidation'
import updatePassengerObject from '../../../hooks/updatePassengerObject'
import { resetError } from '../../../reducers/error/actions'
import {
  clearBooker, clearExtraGuest,
  clearPassenger,
  clearPassengerInForm,
  updateBooker,
  updateBookerInForm,
  updateExtraGuest, updateOrderHotels,
  updatePassenger,
  updatePassengerInForm
} from '../../../reducers/order/actions'
import { updateUser } from '../../../reducers/user/actions'
import moment from 'moment'
import PassengerAnalytics from '../../../analytics/analyticsFlight/passengerAnalytics'
import _ from 'lodash'
import GuestsAnalytics from '../../../analytics/analyticsHotel/guestsAnalytics'
import handleOnChangeDateInput from '../../../hooks/handleOnChangeDateInput'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import { CLIENT_MODE_BUSINESSES } from '../../../reducers/user/constans'

export default class PassengerController {
  constructor () {
    this.dispatch = useDispatch()
    this.api = new Api()
    this.t = useTranslation().t
    this.language = useTranslation().i18n.language
    this.flightAnalytics = new PassengerAnalytics()
    this.hotelAnalytics = new GuestsAnalytics()
    this.isFlightsPage = currentLocation().isFlightsPage
    this.isHotelsPage = currentLocation().isHotelsPage
    this.outbound = useSelector(state => state.itineraries.outbound)
    this.user = useSelector(state => state.user)
  }

  handleOnChange = (e) => {
    const { name, type, value, checked } = e.target
    if (this.isFlightsPage) {
      this.flightAnalytics.editField(name)
    } else if (this.isHotelsPage) {
      this.hotelAnalytics.editField(name)
    }

    if (name.toLowerCase().includes('name')) {
      const translateValue = iuliia.translate(value, iuliia.ICAO_DOC_9303)
      if (value !== translateValue) {
        this.updateForm(name + '-translate', translateValue)
      } else {
        this.updateForm(name + '-translate', undefined)
      }
    }
    if (name === 'documentExpiryDateUnlimited') {
      this.updateForm('documentExpiryDate-error', undefined)
    }
    this.updateForm(name, type === 'checkbox' ? checked : value)
    this.updateForm(name + '-error', undefined)
  }

  handleOnChangeDateInput = (target) => {
    handleOnChangeDateInput(target, this.updateForm, this.language)

    if (this.isFlightsPage) {
      const { name } = target
      this.flightAnalytics.editField(name)
    }
  }

  setValueSavedPassenger = (value) => {
    if (this.language.includes('ru')) {
      return moment(value).format('DD.MM.YYYY')
    } else {
      return moment(value).format('MM.DD.YYYY')
    }
  }

  handleOnKeyUp = (e) => {
    const { name, value } = e.target
    const { keyCode } = e
    if (name.toLowerCase().includes('name') && keyCode === 13) {
      const translateValue = iuliia.translate(value, iuliia.ICAO_DOC_9303)
      this.updateForm(name, translateValue)
      this.updateForm(name + '-translate', undefined)
    }
  }

  handleOnClickTranslate = (name, translateValue) => {
    this.updateForm(name, translateValue)
    this.updateForm(name + '-translate', undefined)
  }

  handleOnClickDayCalendar = (day, name) => {
    if (this.isFlightsPage) {
      this.flightAnalytics.editField(name)
    }
    this.updateForm(name, moment(day).lang(this.language).format('YYYY.MM.DD'))
    this.updateForm(name + '-error', undefined)
  }

  handleOnClickCancel = (setPopupOpen) => {
    if (this.isFlightsPage) {
      this.flightAnalytics.cancelPassenger()
    } else if (this.isHotelsPage) {
      this.hotelAnalytics.cancelPassenger()
    }
    this.dispatch(clearPassengerInForm())
    setPopupOpen(false)
  }

  handleOnClickSave = (
    passengerForm,
    passengerFormConfig,
    setPopupOpen,
    number,
    setOpenOptionsPassenger,
    passengerType,
    currentCarrierName,
    currentChainsName,
    clientMode
  ) => {
    const passengerFormConfigForDateCheck = this._passengerFormConfigForDateCheck(passengerFormConfig, passengerType)

    const validation = passengerValidation(passengerForm, passengerFormConfigForDateCheck, this)
    if (!validation.length) {
      if (passengerForm.id) {
        this.api.updatePassengers(
          passengerForm.id,
          createPassengerObject(passengerForm, passengerType, currentCarrierName, currentChainsName, clientMode),
          clientMode
        )
          .then((passenger) => this.parseResponsePassenger(passenger, number, clientMode))
      } else {
        if (this.isFlightsPage) {
          this.flightAnalytics.savePassenger()
        } else if (this.isHotelsPage) {
          this.hotelAnalytics.clickOnSaveGuest()
        }
        this.api.savePassengers(
          createPassengerObject(passengerForm, passengerType, currentCarrierName, currentChainsName, clientMode)
        )
          .then((passenger) => this.parseResponsePassenger(passenger, number, clientMode))
      }
      this.dispatch(resetError())
      this.dispatch(clearPassengerInForm())
      setPopupOpen(false)
      setOpenOptionsPassenger(false)
    } else {
      validation.forEach(error => {
        this.updateForm(error.field, error.message)
      })
    }
  }

  parseResponsePassenger = (passenger, number, clientMode) => {
    if (passenger) {
      const passengerObject = updatePassengerObject(passenger, undefined, clientMode)
      this.api.getPassengers(clientMode)
        .then(res => {
          if (res) {
            const findPassenger = _.find(res, resPassenger => resPassenger.id === passenger.id)
            this.dispatch(updateUser('passengers', res))
            if (this.isFlightsPage) {
              if (clientMode === CLIENT_MODE_BUSINESSES) {
                findPassenger.businessAccountId = findPassenger.id
                // TODO удалить хардкод
                if (!findPassenger.documents && !findPassenger.documents[0].countryCode) {
                  findPassenger.documents[0].countryCode = 'RU'
                }
                if (!findPassenger.title) findPassenger.title = 'MR'
                if (!findPassenger.gender) findPassenger.gender = 'M'
                if (!findPassenger.middleName) delete findPassenger.middleName
              }
              this.dispatch(updatePassenger(number, findPassenger))
            }

            if (this.isHotelsPage) {
              if (number) {
                this.dispatch(updateExtraGuest(number - 1, updatePassengerObject(passenger, number, clientMode)))
              } else {
                if (clientMode === CLIENT_MODE_BUSINESSES) {
                  findPassenger.businessAccountId = findPassenger.id
                }
                this.dispatch(updateBooker(
                  _.pick(
                    findPassenger,
                    ['id', 'businessAccountId', 'firstName', 'lastName', 'phone', 'email', 'country']
                  )
                ))
                this.dispatch(updateOrderHotels('fgp', passengerObject.fgp))
              }
            }
          }
        })
    }
  }

  getLabelAndOrder = (field) => {
    const $return = {}
    switch (field) {
      case 'birthDate':
        $return.label = this.t('Birth date')
        $return.order = '5'
        break
      case 'firstName':
        $return.label = this.t('First name - in Latin')
        $return.errorLabel = this.t('first name')
        $return.order = '2'
        break
      case 'lastName':
        $return.label = this.t('Last name - in Latin')
        $return.errorLabel = this.t('last name')
        $return.order = '3'
        break
      case 'middleName':
        $return.label = this.t('Middle name - in Latin')
        $return.errorLabel = this.t('middle name')
        $return.order = '3'
        break
      case 'gender':
        $return.label = this.t('Gender')
        $return.order = '4'
        break
      case 'title':
        $return.label = this.t('Title')
        $return.order = '1'
        break
      case 'phone':
        $return.label = this.t('Phone')
        $return.order = '7'
        break
      case 'email':
        $return.label = this.t('E-mail')
        $return.order = '6'
        break
      case 'country':
        $return.label = this.t('Country')
        $return.errorLabel = this.t('Country')
        $return.order = '7'
        break
      case 'frequentFlyer':
        $return.label = this.t('Frequent Flyer')
        $return.order = '8'
        break
      case 'frequentGuest':
        $return.label = this.t('Number')
        $return.order = '8'
        break
      case 'wheelchair':
        $return.name = 'wheelchairRequired'
        $return.label = this.t('Wheelchair needed')
        $return.order = '9'
        break
      case 'documentType':
        $return.label = this.t('Document type')
        $return.order = '11'
        break
      case 'documentCountryCode':
        $return.label = this.t('Issuing country')
        $return.order = '13'
        break
      case 'documentNumber':
        $return.label = this.t('Number')
        $return.order = '12'
        break
      case 'documentExpiryDate':
        $return.label = this.t('Expiration date')
        $return.order = '14'
        break
      case 'documentExpiryDateUnlimited':
        $return.label = this.t('No expiration date')
        $return.order = '15'
        break
      default:
        $return.label = ''
        $return.order = ''
        break
    }
    return $return
  }

  handleClickNewPassenger = (setPopupOpen, index) => {
    if (this.isFlightsPage) {
      this.flightAnalytics.passengerAction('new', index)
    } else if (this.isHotelsPage) {
      this.hotelAnalytics.clickNewGuest()
    }
    this.dispatch(clearPassengerInForm())
    setPopupOpen(true)
  }

  handleClickPassenger = (
    passenger,
    number,
    setOpenOptions,
    setPassengerFind,
    passengerFormConfig,
    setPopupOpen,
    clientMode
  ) => {
    this.setNoExpDate(passenger)
    const validation = passengerValidation(passenger, passengerFormConfig, this)
    if (validation.length) {
      validation.forEach(error => {
        this.updateForm(error.field, error.message)
      })
      this.handleClickEditPassenger(number, passenger, setPopupOpen, 'clearPassengerForm')
    } else {
      if (this.isFlightsPage) {
        this.flightAnalytics.selectPassenger(number)
      } else if (this.isHotelsPage) {
        this.hotelAnalytics.selectedGuest()
      }
      this.dispatch(resetError())
      if (this.isFlightsPage) {
        const passengerObject = updatePassengerObject(passenger, undefined, clientMode)
        if (clientMode === CLIENT_MODE_BUSINESSES) {
          _.map(this.outbound.loyaltyCarriers, loyaltyCarrier => {
            const findLoyaltyPrograms = _.find(this.user.business.loyalty_programs,
              loyaltyProgram => loyaltyProgram.code === loyaltyCarrier)
            if (findLoyaltyPrograms) {
              passengerObject.corporateLoyaltyNumber = findLoyaltyPrograms.number
            }
          })
        }

        this.dispatch(updatePassenger(number, passengerObject))
      }
      if (this.isHotelsPage) {
        if (number) {
          if (clientMode === CLIENT_MODE_BUSINESSES) {
            passenger.businessAccountId = passenger.id
          }
          this.dispatch(updateExtraGuest(number - 1, updatePassengerObject(passenger, number, clientMode)))
        } else {
          const passengerObject = updatePassengerObject(passenger, undefined, clientMode)
          if (clientMode === CLIENT_MODE_BUSINESSES) {
            passenger.businessAccountId = passenger.id
          }
          this.dispatch(updateBooker(
            _.pick(passenger, ['id', 'businessAccountId', 'firstName', 'lastName', 'phone', 'email', 'country'])
          ))
          this.dispatch(updateOrderHotels('fgp', passengerObject.fgp))
        }
      }
      setOpenOptions(false)
      setPassengerFind('')
    }
  }

  handleClickRemovePassenger = (number) => {
    if (this.isFlightsPage) {
      this.dispatch(clearPassenger(number))
      this.flightAnalytics.passengerAction('delete', number)
    } else {
      !number
        ? this.dispatch(clearBooker())
        : this.dispatch(clearExtraGuest(number - 1))
    }
  }

  handleClickEditPassenger = (index, passenger, setPopupOpen, clearPassengerForm) => {
    if (!clearPassengerForm) {
      this.dispatch(clearPassengerInForm())
    }
    this.setNoExpDate(passenger)
    if (this.isFlightsPage) {
      this.flightAnalytics.passengerAction('edit', index)
    } else if (this.isHotelsPage) {
      this.hotelAnalytics.clickEditGuest()
    }
    setPopupOpen(true)
    Object.keys(passenger).forEach(field => {
      if (
        field === 'documents' &&
        passenger[field] &&
        passenger[field].length &&
        Object.keys(passenger[field][0]).length
      ) {
        Object.keys(passenger[field][0]).forEach(docField => {
          this.updateForm(
            'document' + docField[0].toUpperCase() + docField.slice(1),
            passenger[field][0][docField])
        })
      } else if (field === 'frequentFlyer' && passenger[field] && passenger[field].length) {
        this.updateForm(field, passenger[field][0].number)
      } else {
        this.updateForm(field, passenger[field])
      }
    })
  }


  isChecked = (passengerId, passengersList) => {
    let checked = false
    passengersList.forEach(passenger => {
      if (passenger.id && passenger.id === passengerId) {
        checked = true
      }
    })
    return checked
  }

  setNoExpDate = (passenger) => {
    passenger.documentExpiryDateUnlimited = false
    if (_.size(passenger.documents) && _.size(passenger.documents[0])) {
      const documents = passenger.documents[0]
      if (
        _.size(documents.countryCode) &&
        _.size(documents.number) &&
        _.size(documents.type) &&
        documents.expiryDate === null
      ) {
        passenger.documentExpiryDateUnlimited = true
      }
    }
  }

  getFormatPhone (phoneField) {
    if (phoneField && !phoneField.includes('+')) {
      phoneField = '+' + phoneField
    }
    const phoneNumber = parsePhoneNumber(phoneField || '')
    let formatPhone = phoneField
    if (phoneNumber) {
      formatPhone = phoneNumber.formatInternational()
    }
    if (phoneField !== formatPhone) {
      this.updateForm('phone', formatPhone)
    }
    return formatPhone
  }

  updateForm = (field, payload) => {
    if (this.isFlightsPage) {
      this.dispatch(updatePassengerInForm(field, payload))
    }
    if (this.isHotelsPage) {
      this.dispatch(updateBookerInForm(field, payload))
    }
  }

  limitededYear = (field, passengerType) => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()
    const nowMonth = new Date(currentYear, currentMonth)
    let minusYear = 0
    if (field === 'birthDate') {
      minusYear = 150
      if (passengerType === 'CHD') {
        minusYear = 12
      } else if (passengerType === 'INF') {
        minusYear = 2
      }
    }
    const fromMonth = new Date(currentYear - minusYear, 0)
    return { currentYear, nowMonth, fromMonth }
  }

  _passengerFormConfigForDateCheck = (passengerFormConfig, passengerType) => {
    return passengerFormConfig.map(field => {
      if (field.field === 'birthDate') {
        const { fromMonth, nowMonth } = this.limitededYear(field.field, passengerType)
        field.limit = fromMonth
        field.now = nowMonth
        return field
      }
      return field
    })
  }

  checkReadOnlyStatus = (field, user, passengerForm) => {
    let readOnly = false
    if (passengerForm.id) {
      const passengerFind = _.find(user.passengers, passenger => passenger.id === passengerForm.id)
      let value = passengerFind[field]
      if (field.includes('document')) {
        value = _.first(passengerFind.documents)?.[_.lowerFirst(field.replace('document', ''))]
      }
      const haveError = !!passengerForm[field + '-error']
      if (user.clientMode === CLIENT_MODE_BUSINESSES) {
        switch (user?.businessInfo?.permission_level) {
          case 'full':
            readOnly = false
            break
          case 'financial':
            if (_.size(value) && !haveError) {
              readOnly = !!_.size(value)
            } else if (field.toLowerCase().includes('name') || field.toLowerCase().includes('document')) {
              readOnly = !!_.size(value)
            }
            break
          case 'basic':
            readOnly = !!_.size(value)
            break
        }
      }
    }
    return readOnly
  }
}
