import { CLEAR_STATE } from '../actions'
import { RESET_ERROR, SET_ERROR, HIDE_ERROR, SET_ERROR_DETAIL } from './actions'

const defaultState = {
  show: false,
  hasError: false,
  header: null,
  message: null,
  detail: {
    error: null,
    requestUrl: null,
    method: null,
    headers: null,
    body: null,
    date: null
  }
}

const error = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        show: true,
        hasError: true,
        header: action.payload.name || '',
        message: action.payload.message || ''
      }
    case SET_ERROR_DETAIL:
      return {
        ...state,
        detail: action.payload
      }
    case CLEAR_STATE:
    case RESET_ERROR:
      return defaultState
    case HIDE_ERROR:
      return {
        ...state,
        show: false
      }
    default:
      return state
  }
}

export default error
