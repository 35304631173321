import i18n from 'i18next'
import { Cookies } from 'react-cookie'
import { initReactI18next } from 'react-i18next'
import i18nExpress from 'i18next-http-middleware'
import * as resources from './translations'

const coolies = new Cookies()
i18n
  .use(i18nExpress.LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ...resources
    },
    lng: coolies.get('language') || 'ru-RU',
    fallbackLng: coolies.get('language') || 'ru-RU',
    // debug: process.env.NODE_ENV === 'development',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    preload: ['en', 'ru'],
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    }
  })

export default i18n
