import React from 'react'
import './style.sass'
import { useTranslation } from 'react-i18next'
import currentLocation from '../../../../../hooks/currentLocation'
import Icons from '../../../../pageComponents/icons'

const PassengerAdd = ({ index, passengerFind, setPassengerFind, number }) => {
  const { t } = useTranslation()
  const { isHotelsPage } = currentLocation()
  const words = [
    (isHotelsPage ? 'main' : 'first'),
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth'
  ]
  return (
    <div className='passenger__add'>
      <Icons
        group='icon'
        icon='person_add'
        color='#707070'
        className='passenger__add-icon'
      />
      <input
        type='text'
        className='passenger__add-text'
        placeholder={t(`Add ${words[number || index]} ${isHotelsPage ? 'guest' : 'passenger'}`)}
        onChange={e => setPassengerFind(e.target.value)}
        value={passengerFind}
        autoComplete='nope'
      />
    </div>
  )
}

export default PassengerAdd
